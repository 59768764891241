var PatientDrugButtonOptions = [{
    'status': {
      'value': 'no_operation_yet',
      'label': 'Henüz işlem yapılmadı.'
    },
    'perm': '',
    'status_perm': [],
    'model_perm': []
  },
  {
    'status': {
      'value': 'doctor_0',
      'label': 'Tedavi burada uygulanacak (ilaç istemi yapılarak).'
    },
    'perm': 'oncology_treatment_doctor-approval-0',
    'status_perm': ['no_operation_yet', 'doctor_1', 'doctor_2', 'doctor_3', 'doctor_4', 'doctor_5', 'doctor_6', 'doctor_7', 'doctor_8', 'nurse_1'],
    'model_perm': ['daily_followup', 'show_wdmr10']
  },
  {
    'status': {
      'value': 'doctor_1',
      'label': 'Tedavi burada uygulanacak (ilaç istemi yapılmadan).'
    },
    'perm': 'oncology_treatment_doctor-approval-1',
    'status_perm': ['no_operation_yet', 'doctor_0', 'doctor_2', 'doctor_3', 'doctor_4', 'doctor_5', 'doctor_6', 'doctor_7', 'doctor_8', 'nurse_1'],
    'model_perm': ['daily_followup', 'show_wdmr10']
  },
  {
    'status': {
      'value': 'doctor_2',
      'label': 'Tedavi burada uygulanacak (İlaç istemi yapılmadan. (Klinik araştırma hastası))'
    },
    'perm': 'oncology_treatment_doctor-approval-2',
    'status_perm': ['no_operation_yet', 'doctor_0', 'doctor_1', 'doctor_3', 'doctor_4', 'doctor_5', 'doctor_6', 'doctor_7', 'doctor_8', 'nurse_1'],
    'model_perm': ['daily_followup', 'show_wdmr10']
  },
  {
    'status': {
      'value': 'doctor_3',
      'label': 'Tedavi başka bir merkezde uygulanmış.'
    },
    'perm': 'oncology_treatment_doctor-approval-3',
    'status_perm': ['no_operation_yet', 'doctor_0', 'doctor_1', 'doctor_2', 'doctor_4', 'doctor_5', 'doctor_6', 'doctor_7', 'doctor_8', 'nurse_1'],
    'model_perm': ['daily_followup', 'show_wdmr10']
  },
  {
    'status': {
      'value': 'doctor_4',
      'label': 'Hasta ilacı evde aldığını ifade etti.'
    },
    'perm': 'oncology_treatment_doctor-approval-4',
    'status_perm': ['no_operation_yet', 'doctor_0', 'doctor_1', 'doctor_2', 'doctor_3', 'doctor_5', 'doctor_6', 'doctor_7', 'doctor_8', 'nurse_1'],
    'model_perm': ['daily_followup', 'show_wdmr10']
  },
  {
    'status': {
      'value': 'doctor_5',
      'label': 'Tedavi onayı geri alındı'
    },
    'perm': 'oncology_treatment_doctor-approval-5',
    'status_perm': ['doctor_0', 'doctor_1', 'doctor_2', 'doctor_3', 'doctor_4', 'doctor_6', 'doctor_7', 'doctor_8', 'doctor_9', 'doctor_10', 'doctor_11', 'doctor_12', 'doctor_13', 'doctor_14', 'doctor_15', 'doctor_16', 'nurse_1'],
    'model_perm': ['intermittently_followup', 'cycle_followup', 'daily_followup', 'show_wdmr10']
  },
  {
    'status': {
      'value': 'doctor_6',
      'label': 'Bu ilaç bugünde uygulanmayacak. İptal edildi.'
    },
    'perm': 'oncology_treatment_doctor-approval-6',
    'status_perm': ['no_operation_yet', 'doctor_0', 'doctor_1', 'doctor_2', 'doctor_3', 'doctor_4', 'doctor_5', 'doctor_7', 'doctor_8', 'doctor_9', 'nurse_1'],
    'model_perm': ['daily_followup', 'show_wdmr10']
  },
  {
    'status': {
      'value': 'doctor_7',
      'label': 'Hasta bu tedaviyi daha önceden aldı.'
    },
    'perm': 'oncology_treatment_doctor-approval-7',
    'status_perm': ['no_operation_yet', 'doctor_0', 'doctor_1', 'doctor_2', 'doctor_3', 'doctor_4', 'doctor_5', 'doctor_6', 'doctor_8', 'nurse_1'],
    'model_perm': ['daily_followup', 'show_wdmr10']
  },
  {
    'status': {
      'value': 'doctor_8',
      'label': 'Hasta bu tedaviyi kendisi uygulayacak'
    },
    'perm': 'oncology_treatment_doctor-approval-8',
    'status_perm': ['no_operation_yet', 'doctor_0', 'doctor_1', 'doctor_2', 'doctor_3', 'doctor_4', 'doctor_5', 'doctor_6', 'doctor_7', 'nurse_1'],
    'model_perm': ['daily_followup', 'show_wdmr10']
  },
  {
    'status': {
      'value': 'doctor_9',
      'label': 'Radyoterapi uygulandı'
    },
    'perm': 'oncology_treatment_doctor-approval-9',
    'status_perm': ['no_operation_yet', 'doctor_5', 'doctor_6', 'nurse_1'],
    'needed_drug': ['radium-ra-223', 'radiotherapy', 'cranial-irradiation'],
    'model_perm': ['daily_followup', 'show_wdmr10']
  },
  {
    'status': {
      'value': 'doctor_10',
      'label': 'Önerildiği gibi uygulanmıştır. Aynı şekilde devam edilecek.'
    },
    'perm': 'oncology_treatment_doctor-approval-10',
    'status_perm': ['no_operation_yet', 'doctor_5'],
    'model_perm': ['intermittently_followup', 'show_wdmr10']
  },
  {
    'status': {
      'value': 'doctor_11',
      'label': 'Önerildiği gibi uygulanmıştır.'
    },
    'perm': 'oncology_treatment_doctor-approval-11',
    'status_perm': ['no_operation_yet', 'doctor_5'],
    'model_perm': ['intermittently_followup', 'cycle_followup', 'show_wdmr10']
  },
  {
    'status': {
      'value': 'doctor_12',
      'label': 'Önerilenden farklı uygulanmıştır. Aynı şekilde devam edilecek.'
    },
    'perm': 'oncology_treatment_doctor-approval-12',
    'status_perm': ['no_operation_yet', 'doctor_5'],
    'model_perm': ['intermittently_followup', 'show_wdmr10']
  },
  {
    'status': {
      'value': 'doctor_13',
      'label': 'Önerilenden farklı uygulanmıştır.'
    },
    'perm': 'oncology_treatment_doctor-approval-13',
    'status_perm': ['no_operation_yet', 'doctor_5'],
    'model_perm': ['intermittently_followup', 'cycle_followup', 'show_wdmr10']
  },
  {
    'status': {
      'value': 'doctor_14',
      'label': 'Tedavi uygulanmamıştır.'
    },
    'perm': 'oncology_treatment_doctor-approval-14',
    'status_perm': ['no_operation_yet', 'doctor_5'],
    'model_perm': ['intermittently_followup', 'cycle_followup', 'show_wdmr10']
  },
  {
    'status': {
      'value': 'nurse_0',
      'label': 'Ürün seçimi yapıldı.'
    },
    'perm': 'oncology_treatment_nurse-approval-0',
    'status_perm': ['doctor_0', 'nurse_1', 'nurse_2', 'pharmacy_1', 'pharmacy_2', 'pharmacy_3', 'pharmacy_4'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'nurse_1',
      'label': 'Ürün seçimi iptal edildi.'
    },
    'perm': 'oncology_treatment_nurse-approval-1',
    'status_perm': ['nurse_0', 'nurse_2', 'pharmacy_1', 'pharmacy_2', 'pharmacy_3', 'pharmacy_4'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'nurse_2',
      'label': 'Ürün seçimleri düzenlendi'
    },
    'perm': 'oncology_treatment_nurse-approval-2',
    'status_perm': ['nurse_0', 'pharmacy_1', 'pharmacy_2', 'pharmacy_3', 'pharmacy_4'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'pharmacy_0',
      'label': 'İlaç verildi.'
    },
    'perm': 'oncology_treatment_pharmacy-approval-0',
    'status_perm': ['nurse_0', 'nurse_2', 'pharmacy_1', 'pharmacy_2', 'pharmacy_3', 'pharmacy_4', 'preparation_3', 'preparation_4'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'pharmacy_1',
      'label': 'İlaç verilemedi.'
    },
    'perm': 'oncology_treatment_pharmacy-approval-1',
    'status_perm': ['nurse_0', 'nurse_2', 'pharmacy_0', 'pharmacy_2', 'pharmacy_3', 'pharmacy_4', 'preparation_3', 'preparation_4'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'pharmacy_2',
      'label': 'İlaç verilemedi. (Stokta yok)'
    },
    'perm': 'oncology_treatment_pharmacy-approval-2',
    'status_perm': ['nurse_0', 'nurse_2', 'pharmacy_0', 'pharmacy_1', 'pharmacy_3', 'pharmacy_4', 'preparation_3', 'preparation_4'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'pharmacy_3',
      'label': 'İlaç verilemedi. (Hatalı doz?)'
    },
    'perm': 'oncology_treatment_pharmacy-approval-3',
    'status_perm': ['nurse_0', 'nurse_2', 'pharmacy_0', 'pharmacy_1', 'pharmacy_2', 'pharmacy_4', 'preparation_3', 'preparation_4'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'pharmacy_4',
      'label': 'İlaç verilemedi. (Sistemde ilacın kaydı yok)'
    },
    'perm': 'oncology_treatment_pharmacy-approval-4',
    'status_perm': ['nurse_0', 'nurse_2', 'pharmacy_0', 'pharmacy_1', 'pharmacy_2', 'pharmacy_3', 'preparation_3', 'preparation_4'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'preparation_0',
      'label': 'İlaç hazırlandı. (Manuel)'
    },
    'perm': 'oncology_treatment_preparation-approval-0',
    'status_perm': ['doctor_1', 'doctor_2', 'pharmacy_0', 'preparation_1', 'preparation_2', 'preparation_3', 'preparation_4', 'match_1'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'preparation_1',
      'label': 'İlaç hazırlandı. (Tam otomatik sistemde)'
    },
    'perm': 'oncology_treatment_preparation-approval-1',
    'status_perm': ['doctor_1', 'doctor_2', 'pharmacy_0', 'preparation_0', 'preparation_2', 'preparation_3', 'preparation_4', 'match_1'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'preparation_2',
      'label': 'İlaç kullanıma hazır bir ürün.'
    },
    'perm': 'oncology_treatment_preparation-approval-2',
    'status_perm': ['doctor_1', 'doctor_2', 'pharmacy_0', 'preparation_0', 'preparation_1', 'preparation_3', 'preparation_4', 'match_1'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'preparation_3',
      'label': 'İlaç hazırlanamadı.'
    },
    'perm': 'oncology_treatment_preparation-approval-3',
    'status_perm': ['doctor_1', 'doctor_2', 'pharmacy_0', 'preparation_0', 'preparation_1', 'preparation_2', 'preparation_4', 'match_1'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'preparation_4',
      'label': 'İlaç hazırlanamadı. (Doz hatası?)'
    },
    'perm': 'oncology_treatment_preparation-approval-4',
    'status_perm': ['doctor_1', 'doctor_2', 'pharmacy_0', 'preparation_0', 'preparation_1', 'preparation_2', 'preparation_3', 'match_1'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'match_0',
      'label': 'Hasta ilaç karşılaştırması başarıyla tamamlandı.'
    },
    'perm': 'oncology_treatment_match-approval-0',
    'status_perm': ['preparation_0', 'preparation_1', 'preparation_2', 'match_1', 'administration_2', 'administration_3', 'administration_4'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'match_1',
      'label': 'Bu ilaç hastaya ait değil.'
    },
    'perm': 'oncology_treatment_match-approval-1',
    'status_perm': ['preparation_0', 'preparation_1', 'preparation_2', 'match_0', 'administration_2', 'administration_3', 'administration_4'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'administration_0',
      'label': 'İlacın tamamı uygulandı.'
    },
    'perm': 'oncology_treatment_administration-approval-0',
    'status_perm': ['match_0', 'administration_1', 'administration_2', 'administration_3', 'administration_4'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'administration_1',
      'label': 'İlaç uygulandı. Fakat tamamı uygulanamadı.'
    },
    'perm': 'oncology_treatment_administration-approval-1',
    'status_perm': ['match_0', 'administration_0', 'administration_2', 'administration_3', 'administration_4'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'administration_2',
      'label': 'İlaç hastaya uygulanamadı.'
    },
    'perm': 'oncology_treatment_administration-approval-2',
    'status_perm': ['match_0', 'administration_0', 'administration_1', 'administration_3', 'administration_4'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'administration_3',
      'label': 'İlaç hastaya uygulanamadı. (Damar yolu tespit edilemedi)'
    },
    'perm': 'oncology_treatment_administration-approval-3',
    'status_perm': ['match_0', 'administration_0', 'administration_1', 'administration_2', 'administration_4'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'administration_4',
      'label': 'İlaç hastaya uygulanamadı. (Hasta tedaviyi reddetti)'
    },
    'perm': 'oncology_treatment_administration-approval-4',
    'status_perm': ['match_0', 'administration_0', 'administration_1', 'administration_2', 'administration_3'],
    'model_perm': []
  },
  {
    'status': {
      'value': 'general_0',
      'label': 'Tedaviyi kopyala'
    },
    'perm': 'oncology_treatment_general-0',
    'status_perm': [],
    'model_perm': []
  },
  {
    'status': {
      'value': 'general_1',
      'label': 'Sil'
    },
    'perm': 'oncology_treatment_general-1',
    'status_perm': [],
    'model_perm': []
  },
  {
    'status': {
      'value': 'general_2',
      'label': 'Düzenle'
    },
    'perm': 'oncology_treatment_general-2',
    'status_perm': [],
    'model_perm': []
  },
  {
    'status': {
      'value': 'general_3',
      'label': 'Yazdır'
    },
    'perm': 'oncology_treatment_general-3',
    'status_perm': [],
    'model_perm': []
  }
];

export { PatientDrugButtonOptions as options };
