<template>
  <tbody>
    <template v-if="view_mode === 'macro'">
      <template v-if="drug_data[prem_type].length !== 0" v-for="(prem, prem_ind) in drug_data[prem_type]">
        <tr style="border-bottom: solid 1px black; border-top: solid 1px black; width: 100%;">
          <td colspan="2" style="text-align: left;">
            <b style="float:left; padding-left: 3px;"> {{ prem.order }} - &nbsp
              {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug.label }}
              ( {{ prem_type + ' ' + treatment_data.protocol_cycles.drugs[drug_key].drug.label }} )
            </b>
            <b style="float:right; padding-right: 3px;" v-if="singleDoctorOperation[next].length > 1">
              <i class="fa fa-user-o"></i>Onaylayan: {{ getLastOperatorDoctor(treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index]) }}
            </b>
          </td>
        </tr>
        <tr>
          <td style="width: 62%; vertical-align: top;">
            <table>
              <tr>
                <td><b> {{ $t('wdm16.3837') }} </b></td>
                <td>
                  {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].dosage }} {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].unit.value | show_unit }}
                </td>
                <td><b>{{ $t('wdm16.4031') }}</b></td>
                <td>
                  <span v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].fluid">
                    {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].fluid.label }}
                  </span>
                </td>
              </tr>
              <tr>
                <td><b> {{ $t('wdm16.4000') }} </b></td>
                <td>
                  {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].calculated_dosage }} {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].unit.value | show_unit_calculate }}
                </td>
                <td><b>{{ $t('wdm16.4357') }}</b></td>
                <td>
                  {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].fluid_value }} ml
                </td>
              </tr>
              <tr>
                <td><b> {{ $t('wdm16.4071') }} </b></td>
                <td>
                  {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].planned_dosage }} {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].unit.value | show_unit_calculate }}
                </td>
                <td><b>{{ $t('wdm16.4221') }}</b></td>
                <td>
                  {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].duration }} dakika
                </td>
              </tr>
              <tr>
                <td><b> {{ $t('wdm16.3946') }} </b></td>
                <td>
                  {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].given_dosage }} {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].unit.value | show_unit_calculate }}
                </td>
                <td><b> {{ $t('wdm16.4059') }} </b></td>
                <td>
                  {{ Math.round(treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].fluid_value / (treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].duration/60)) }} {{ $t('wdm16.3735') }} ( {{ Math.round((( treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].fluid_value / (treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].duration/60)) * 33) / 100) }} {{ $t('wdm16.4216') }} )
                </td>
              </tr>
              <tr>
                <td><b> {{ $t('wdm16.4126') }} </b></td>
                <td>
                  {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].dosage_form.label }}
                </td>
                <td><b>{{ $t('wdm16.3931') }}</b></td>
                <td>
                  {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].duration_wait }} dakika
                </td>
              </tr>
              <tr>
                <td><b> {{ $t('wdm16.1136') }} </b></td>
                <td>
                  {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].frequency.label }}
                </td>
                <td></td>
                <td> </td>
              </tr>
              <tr v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].starting_time">
                <td><b> Başlama saati </b></td>
                <td>
                  {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].starting_time }}
                </td>
                <td></td>
                <td> </td>
              </tr>
              <tr v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].ending_time">
                <td><b> Bitiş saati </b></td>
                <td>
                  {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].ending_time }}
                </td>
                <td></td>
                <td> </td>
              </tr>
            </table>
            <div class="row" style="padding-left: 25px; padding-top: 5px;" v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].dosage_changes.data.percent !== undefined">
              <div class="col-sm-12">
                <b> {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug.label }} {{ $t('wdm16.4121') }} </b>
              </div>
              <div class="col-sm-12">
                {{ $t('wdm16.4208') }} = {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].dosage_changes.data.percent }}
                <br> {{ $t('wdm16.3754') }} = {{ (treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].dosage_changes.data.new_planned_dosage - treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].dosage_changes.data.old_planned_dosage).toFixed(1) }} {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].dosage_changes.data.new_unit.value | show_unit_calculate }}
              </div>
              <div class="col-sm-12"><strong> {{ $t('wdm16.3963') }} </strong></div>
              <div class="row">
                <div class="col-sm-12" v-for="(reason, reason_ind) in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].dosage_changes.reasons">
                  {{ (reason_ind + 1) + '- '+ reason }}
                </div>
              </div>
            </div>
            <div v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix.length" style="padding-left: 10px; padding-top: 5px;"><u> {{ $t('wdm16.4162') }} </u></div>
            <div style="padding-left: 15px;">
              <div v-for="(dmix, mix_ind) in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix">
                <strong>{{ dmix.drug.label }}</strong>
                <br>
                <div class="row">
                  <div class="col-sm-5">
                    <div class="row" style="padding-left: 5px;">
                      <div class="col-sm-4"><b> {{ $t('wdm16.3837') }} </b> </div>
                      <div class="col-sm-8">
                        {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].dosage }} {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].unit.value | show_unit }}
                      </div>
                    </div>
                    <div class="row" style="padding-left: 5px;">
                      <div class="col-sm-4"><b> {{ $t('wdm16.4000') }} </b> </div>
                      <div class="col-sm-8">
                        {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].calculated_dosage }} {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].unit.value | show_unit_calculate }}
                      </div>
                    </div>
                    <div v-if="eger(drug_key, drug_data.cycle_index, drug_data.day_index, prem_type, prem.prem_index, 'none', 'none', 'planned_given')" class="row" style="padding-left: 5px;">
                      <div class="col-sm-4"><b> {{ $t('wdm16.4071') }} </b> </div>
                      <div class="col-sm-8">
                        {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].planned_dosage }} {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].unit.value | show_unit_calculate }}
                      </div>
                    </div>
                    <div v-else class="row" style="padding-left: 5px;">
                      <div class="col-sm-4"><b> {{ $t('wdm16.3946') }} </b> </div>
                      <div class="col-sm-8">
                        {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].given_dosage }} {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].unit.value | show_unit_calculate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="padding-left: 25px; padding-top: 5px;" v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].dosage_changes.data.percent !== undefined">
                  <div class="col-sm-12">
                    <b> {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].drug.label }} {{ $t('wdm16.4121') }} </b>
                  </div>
                  <div class="col-sm-12">
                    {{ $t('wdm16.4208') }} = {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].dosage_changes.data.percent }}
                    <br> {{ $t('wdm16.3754') }} = {{ (treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].dosage_changes.data.new_planned_dosage - treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].dosage_changes.data.old_planned_dosage).toFixed(1) }} {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].dosage_changes.data.new_unit.value | show_unit_calculate }}
                  </div>
                  <div class="col-sm-12"><strong> {{ $t('wdm16.3963') }} </strong></div>
                  <div class="row">
                    <div class="col-sm-12" v-for="(reason, reason_ind) in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].dosage_changes.reasons">
                      {{ (reason_ind + 1) + '- '+ reason }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td style="vertical-align: top;">
            <div style="padding: 5px; background-color: rgb(93, 221, 221); text-align: center"><b> {{ $t('wdm16.3866') }} </b></div>
            <div v-if="show_status === 'all' || show_status === 'last'">
              <div style="background-color: #ccffff;">
                <div><strong style="padding-left: 3px;"> {{ $t('wdm16.4213') }} </strong></div>
                <div>
                  <div style="padding: 5px;">
                    {{ getLastOperatorUser(treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index]) }}{{ $t('wdm16.' + variable_langs_id_list.approval_choices[treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].status]) }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="show_status === 'all'">
              <div style="background-color: #ccffff;">
                <div><strong style="padding-left: 3px;"> {{ $t('wdm16.4213') }} </strong></div>
                <div>
                  <div style="padding: 5px;">
                    {{ getLastOperatorUser(treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index]) }}{{ $t('wdm16.' + variable_langs_id_list.approval_choices[treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].status]) }}
                  </div>
                </div>
              </div>
              <div style="background-color: #aff9f9">
                <div><strong style="padding-left: 3px;"> {{ $t('wdm16.3862') }} </strong></div>
                <div>
                  <div style="padding: 5px;" v-for="doctor in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].doctor.data">
                    {{ doctor.approve_date | show_time }} {{ doctor.user }} {{ $t('wdm16.' + variable_langs_id_list.approval_choices[doctor.status]) }}
                  </div>
                </div>
              </div>
              <div style="background-color: #ccffff;">
                <div><strong style="padding-left: 3px;">{{ $t('wdm16.3973') }}</strong></div>
                <div>
                  <div style="padding: 5px;" v-for="nurse in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].nurse.data">
                    {{ nurse.approve_date | show_time }} {{ nurse.user }} {{ $t('wdm16.' + variable_langs_id_list.approval_choices[nurse.status]) }}
                  </div>
                  <div v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].barcode_list.length > 0">
                    <strong>{{ $t('wdm16.3973') }}</strong>
                    <table>
                      <tr v-for="(brand, brand_ind) in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].brand_count">
                        <td>{{ brand_ind }}</td>
                        <td> {{ brand.label }} </td>
                        <td>{{ $t('wdm16.4368') }}: {{ brand.count }} </td>
                      </tr>
                      <template v-for="(mix, mix_ind) in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix">
                        <template v-if="mix.barcode_list.length > 0">
                          <tr v-for="(brand, brand_ind) in mix.brand_count">
                            <td>{{ brand_ind }}</td>
                            <td> {{ brand.label }} </td>
                            <td>{{ $t('wdm16.4368') }}: {{ brand.count }} </td>
                          </tr>
                        </template>
                      </template>
                    </table>
                  </div>
                  <div v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].fluid_brands.barcode_list.length > 0">
                    <strong> {{ $t('wdm16.4222') }} </strong>
                    <table>
                      <tr v-for="(brand, brand_ind) in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].fluid_brands.brand_count">
                        <td>{{ brand_ind }}</td>
                        <td> {{ brand.label }} </td>
                        <td>{{ $t('wdm16.4368') }}: {{ brand.count }} </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div style="background-color: #aff9f9">
                <div><strong style="padding-left: 3px;">{{ $t('wdm16.3962') }}</strong></div>
                <div>
                  <div style="padding: 5px;" v-for="pharmacy in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].pharmacy.data">
                    {{ pharmacy.approve_date | show_time }} {{ pharmacy.user }} {{ $t('wdm16.' + variable_langs_id_list.approval_choices[pharmacy.status]) }}
                  </div>
                </div>
              </div>
              <div style="background-color: #ccffff;">
                <div><strong style="padding-left: 3px;">{{ $t('wdm16.4573') }}</strong></div>
                <div>
                  <div style="padding: 5px;" v-for="preparation in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].preparation.data">
                    {{ preparation.approve_date | show_time }} {{ preparation.user }} {{ $t('wdm16.' + variable_langs_id_list.approval_choices[preparation.status]) }}
                  </div>
                </div>
              </div>
              <div style="background-color: #aff9f9">
                <div><strong style="padding-left: 3px;">{{ $t('wdm16.3978') }}</strong></div>
                <div>
                  <div style="padding: 5px;" v-for="match in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].match.data">
                    {{ match.approve_date | show_time }} {{ match.user }} {{ $t('wdm16.' + variable_langs_id_list.approval_choices[match.status]) }}
                  </div>
                </div>
              </div>
              <div style="background-color: #ccffff;">
                <div><strong style="padding-left: 3px;">{{ $t('wdm16.3829') }}</strong></div>
                <div>
                  <div style="padding: 5px;" v-for="administration in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].administration.data">
                    {{ administration.approve_date | show_time }} {{ administration.user }} {{ $t('wdm16.' + variable_langs_id_list.approval_choices[administration.status]) }}
                  </div>
                </div>
              </div>
            </div>
            <div style="padding: 5px; background-color: rgb(93, 221, 221); text-align: center" v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].note_list.length > 0">
              <b>>{{ $t('wdm16.3758') }}</b>
            </div>
            <div style="padding: 5px; background-color: #ccffff;" v-for="(note, i) in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].note_list">
              {{ note.date_at | show_time }} {{ note.user }} {{ note.note }}
            </div>
            <div style="padding: 5px; background-color: #ff9999; text-align: center" v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].toxicity.length > 0">
              <b>{{ $t('wdm16.3795') }}</b>
            </div>
            <div style="padding: 5px; background-color: #f2cece;" v-for="(toxicity, i) in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].toxicity">
              {{ toxicity.date | show_time }} {{ toxicity.user }} (Toxicity date = {{ toxicity.toxicity_date | show_time }} ) {{ toxicity.grade_label }} {{ toxicity.toxicity_label }} {{ toxicity.note }}
            </div>
          </td>
        </tr>
      </template>
    </template>
    <template v-if="view_mode === 'normal'">
      <template v-if="drug_data[prem_type].length !== 0" v-for="(prem, prem_ind) in drug_data[prem_type]">
        <tr style="border-bottom: solid 1px black; border-top: solid 1px black;">
          <td style="text-align: left; width: 80%;">
            <template v-if="singleDoctorOperation[next].length > 1">
              <strong style="float:left; padding-left: 3px;"><i class="fa fa-user-o"></i>Onaylayan: {{ getLastOperatorDoctor(treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index]) }}</strong> <br>
            </template>
            <b style="float:left; padding-left: 3px;"> {{ prem.order }} - &nbsp
              ({{ treatment_data.protocol_cycles.drugs[drug_key].drug.label }} {{ prem_type }} )
              {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug.label }}
              {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].planned_dosage }}
              {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].unit.value | show_unit_calculate }}
              {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].frequency.label }}
              &nbsp
            </b>
            <br>
            <b style="color: red;">[</b>
            <!-- {{ $t('wdm16.4031') }} = -->
            <span v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].fluid">
              {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].fluid.label }}
            </span> {{ $t('wdm16.4357') }} = {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].fluid_value }} ml,
            <!-- {{ $t('wdm16.4221') }} = -->{{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].duration }} dakika,
            <span v-if="Math.round(treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].fluid_value / (treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].duration/60))">
              <!-- {{ $t('wdm16.4059') }} = --> {{ Math.round(treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].fluid_value / (treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].duration/60)) }} {{ $t('wdm16.3735') }},
            </span>
            <!-- {{ $t('wdm16.4126') }} = -->{{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].dosage_form.label }}
            <span v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].starting_time"> Başlama saati = {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].starting_time }} </span>
            <span v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].ending_time"> Bitiş saati = {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].ending_time }} </span>
            <b style="color: red;">]</b>
            <br>
            <div v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix.length" style="padding-left: 10px; padding-top: 5px;"><u> {{ $t('wdm16.4162') }} </u></div>
            <div style="padding-left: 15px;">
              <div v-for="(dmix, mix_ind) in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix">
                {{ mix_ind + 1 }} ) {{ dmix.drug.label }} {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].planned_dosage }} {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].unit.value | show_unit_calculate }}
              </div>
            </div>
          </td>
          <td>
            <div><strong style="padding-left: 3px;"> {{ getLastOperatorUser(treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index]) }}{{ $t('wdm16.' + variable_langs_id_list.approval_choices[treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].status]) }}
              </strong></div>
          </td>
        </tr>
      </template>
    </template>
    <template v-if="view_mode === 'micro'">
      <template v-if="drug_data[prem_type].length !== 0" v-for="(prem, prem_ind) in drug_data[prem_type]">
        <tr style="border-bottom: solid 1px black; border-top: solid 1px black;">
          <td style="text-align: left; width: 79%;">
            <template v-if="singleDoctorOperation[next].length > 1">
              <strong style="float:left; padding-left: 3px;"><i class="fa fa-user-o"></i>Onaylayan: {{ getLastOperatorDoctor(treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index]) }}</strong><br>
            </template>
            <b style="float:left; padding-left: 3px;"> {{ prem.order }} - &nbsp
              ({{ treatment_data.protocol_cycles.drugs[drug_key].drug.label }} {{ prem_type }} )
              {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug.label }}
              {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].planned_dosage }}
              {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].unit.value | show_unit_calculate }}
              {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].frequency.label }}
              &nbsp
            </b>
            <!-- {{ $t('wdm16.4031') }} = -->
            <span v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].fluid">
              {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].fluid.label }}
            </span> {{ $t('wdm16.4357') }} = {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].fluid_value }} ml,
            <!-- {{ $t('wdm16.4221') }} = -->{{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].duration }} dakika,
            <span v-if="Math.round(treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].fluid_value / (treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].duration/60))">
              <!-- {{ $t('wdm16.4059') }} = --> {{ Math.round(treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].fluid_value / (treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].duration/60)) }} {{ $t('wdm16.3735') }},
            </span>
            <!-- {{ $t('wdm16.4126') }} = -->{{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].dosage_form.label }}
            <span v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].starting_time"> Başlama saati = {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].starting_time }} </span>
            <span v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].ending_time"> Bitiş saati = {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].ending_time }} </span>
            <span v-if="treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix.length" style="padding-left: 10px; padding-top: 5px;">
              <u> {{ $t('wdm16.4162') }} </u>
            </span>
            <span v-for="(dmix, mix_ind) in treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix">
              {{ mix_ind + 1 }} )
              {{ dmix.drug.label }}
              {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].planned_dosage }}
              {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].drug_mix[mix_ind].unit.value | show_unit_calculate }}
            </span>
          </td>
          <td>
            <div v-if="show_status === 'all' || show_status === 'last'">
              <strong style="padding-left: 3px;"> {{ getLastOperatorUser(treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index]) }}{{ $t('wdm16.' + variable_langs_id_list.approval_choices[treatment_data.protocol_cycles.drugs[drug_key].cycles[drug_data.cycle_index].days[drug_data.day_index].data[prem_type][prem.prem_index].approvals[prem.approvals_index].status]) }} </strong>
            </div>
          </td>
        </tr>
      </template>
    </template>
    <template v-if="view_mode === 'free_cells'">
    </template>
  </tbody>
</template>
<script>
  import { mapGetters } from 'vuex';
// import moment from 'moment';

export default {
  name: 'ReportingModalPremedication',
  mounted () {},
  computed: {
    ...mapGetters({
      lang: 'lang'
    })
  },
  props: {
    view_mode: {
      type: String,
      required: true,
      default: 'macro'
    },
    show_status: {
      type: String,
      required: true
    },
    prem_type: {
      type: String,
      required: true
    },
    drug_key: {
      type: String,
      required: true
    },
    treatment_data: {
      type: Object,
      required: true
    },
    drug_data: {
      type: Object,
      required: true
    },
    next: {
      type: String,
      required: true
    },
    singleDoctorOperation: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      all_users: [],
      variable_langs_id_list: require('@/options/variable_langs_id_list').options
    };
  },
  created () {
    this.all_users = JSON.parse(localStorage.getItem('all_users'));
  },
  methods: {
    getLastOperatorDoctor: function (approvalsData) {
      let totalDoctorOperation = approvalsData.doctor.data.length;
      if (totalDoctorOperation > 0) {
        return this.get_from_all_users(approvalsData.doctor.data[totalDoctorOperation - 1].user);
      }
      return '';
    },
    getLastOperatorUser: function (approvalsData) {
      if (approvalsData.status === 'no_operation_yet') {
        return '';
      }
      let lastStatusType = approvalsData.status.split('_')[0];
      let totalLastOperation = approvalsData[lastStatusType].data.length;
      return this.get_from_all_users(approvalsData[lastStatusType].data[totalLastOperation - 1].user) + ': ';
    },
    get_from_all_users: function (username) {
      for (let i in this.all_users) {
        if (this.all_users[i].username === username) {
          return this.all_users[i].group + ' ' + this.all_users[i].first_name + ' ' + this.all_users[i].last_name;
        }
      }
    },
    eger: function (drug_key, cycle_index, day_index, type, prem_ind, prem_day, mix_ind, subject, dy_ind) {
      let res = true;
      if (subject === 'planned_given') {
        let pg_data = {};
        if (type === 'drug') {
          pg_data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.approvals;
          if (['administration_0'].indexOf(pg_data.status) !== -1) {
            res = false;
            return res;
          }
        } else { // before after during
          pg_data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind];
          for (let pr_dy in pg_data.day) {
            let x = pg_data.day[pr_dy].toString();
            if (['administration_0'].indexOf(pg_data.approvals[x].status) !== -1) {
              res = false;
              return res;
            }
          }
        }
      }
      return res;
    }
  },
  watch: {},
  components: {}
};

</script>
<style type="text/css">
</style>
