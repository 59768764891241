<template>
    <div>
        <div class="row" style="font-size: 12px; background-color: white; margin: 5px;">
          <div class="col-2">
            &nbsp&nbsp&nbsp
            <img v-if="hospital_data.logo" :src="hospital_data.logo.logo_1" style="width: 20px; height: 20px;">
          </div>
          <div class="col-8">
           <strong v-if="hospital_data.headers" style="font-family: 'Comic Sans MS', cursive, sans-serif;"> 
            {{ hospital_data.headers.hospital_name.translation[lang] }}  
            {{ hospital_data.headers[header_type].translation[lang] }} 
          </strong>
          </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HospitalService from '@/services/hospital';

// /* global $ */

export default {
  name: 'LogoView',
  created () {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user.hospital_group && this.user.hospital_id) {
      let query = 'group=' + this.user.hospital_group + '&hospital=' + this.user.hospital_id;
      HospitalService.get(query)
                    .then(resp => {
                      if (resp.data.status === 'success') {
                        this.hospital_data = resp.data.result[0];
                      } else {
                        alert('Hastane kaydı henüz yok.');
                      }
                    });
    }
  },
  mounted () {},
  computed: mapGetters({
    lang: 'lang'
  }),
  props: {
    header_type: {
      type: String,
      required: true,
      default: 'chemotherapy_protocol_scheme'
    },
    view_mode: {
      type: String,
      required: true,
      default: 'normal'
    }
  },
  data () {
    return {
      hospital_data: {},
      user: {}
    };
  },
  methods: {},
  watch: {
    lang: function (nlang) {
    }
  },
  components: {
  }
};
</script>
<style></style>
