<template>
  <div>
    <b-card style="margin-bottom: 0px;" no-body>
      <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
        <b-row>
          <b-col cols="3">
            <b-form-input type="text" v-model="filterTable" placeholder="Filteleyebilirsiniz" @input="f_calculateRows"></b-form-input>
          </b-col>
          <b-col cols="3">
            <b-form-select v-model="d_selectedLaboratoryType">
              <option value="all">Tümü</option>
              <option value="anormal">Anormal Testler</option>
            </b-form-select>
          </b-col>
          <b-col cols="3">
            <b-form-select v-if="last_x_days" v-model="d_showLastXDays">
              <option value="no">Tüm tarihlerdeki son değerleri göster</option>
              <option value="yes">Son {{ last_x_days }} gündeki değerler</option>
            </b-form-select>
          </b-col>
          <b-col cols="3">
            <b-button variant="primary" size="md" style="width: 100%;" @click="print()"><i class="fa fa-print"></i> yazdır</b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <!-- <b-table head-variant="dark" striped :filter="filterTable" responsive stacked="sm" ref="ref_patient_last_laboratory" bordered small hover :items="d_patientLastLaboratoryRows" :fields="d_patientLastLaboratoryColumns" selectable select-mode="single" selectedVariant="success" @row-selected="" @row-contextmenu="" @row-clicked="" @row-dblclicked="" @row-hovered="" @row-unhovered="">
        <template v-slot:cell(rowNumber)="row">
          {{ row.index + 1 }}
        </template>
        <template v-slot:cell(range)="row">
          {{ row.item.lb + ' - ' + row.item.ub }}
        </template>
        <template v-slot:cell(sample_date)="row">
          {{ DateFormat(row.item.sample_date) }}
        </template>
      </b-table> -->
      <table id="report_here" style="border: solid 1px; border-collapse: collapse; width: 100%;">
        <thead>
          <tr style="border: solid 1px #c3d8d8; padding: 5px;">
            <th style="border: solid 1px #c3d8d8; text-align: center; background-color: #ececec; padding: 5px;">
              #
            </th>
            <th style="border: solid 1px #c3d8d8; text-align: center; background-color: #ececec; padding: 5px;">
              Test Grubu
            </th>
            <th style="border: solid 1px #c3d8d8; text-align: center; background-color: #ececec; padding: 5px;">
              Test Adı
            </th>
            <th style="border: solid 1px #c3d8d8; text-align: center; background-color: #ececec; padding: 5px;">
              Değer
            </th>
            <th style="border: solid 1px #c3d8d8; text-align: center; background-color: #ececec; padding: 5px;">
              Test Aralığı
            </th>
            <th style="border: solid 1px #c3d8d8; text-align: center; background-color: #ececec; padding: 5px;">
              Numune Alma Tarihi
            </th>
            <th style="border: solid 1px #c3d8d8; text-align: center; background-color: #ececec; padding: 5px;">
              Kaç Gün Önce
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(row, row_ind) in d_patientLastLaboratoryRowsFiltered">
            <tr style="border: solid 1px #c3d8d8; padding: 5px;">
              <td style="border: solid 1px #c3d8d8; padding: 5px;">
                {{ row_ind + 1 }}
              </td>
              <td style="border: solid 1px #c3d8d8; padding: 5px;">
                {{ row.param_group_name }}
              </td>
              <td style="border: solid 1px #c3d8d8; padding: 5px;">
                {{ row.test_name }}
              </td>
              <td style="border: solid 1px #c3d8d8; padding: 5px;" :class="[f_calculateCellBackground(row, 'test_val')]">
                <template v-if="f_calculateProblematicValue(row, 'test_val')">
                  <span style="font-weight: bold; font-style: italic;">{{ row.test_val }}</span>
                </template>
                <template v-else>
                  {{ row.test_val }}
                </template>
              </td>
              <td style="border: solid 1px #c3d8d8; padding: 5px; text-align: center;">
                {{ row.lb + ' - ' + row.ub }}
              </td>
              <td style="border: solid 1px #c3d8d8; padding: 5px;">
                {{ DateFormat(row.sample_date) }}
              </td>
              <td style="border: solid 1px #c3d8d8; padding: 5px;" :class="[f_calculateCellBackground(row, 'how_many_days_ago')]">
                {{ row.how_many_days_ago }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <!--         <b-button style="margin-right: 5px;" @click="d_expandAllClosedCollapse ? d_expandAllClosedCollapse = false : d_expandAllClosedCollapse = true" title="Hepsini Aç-Kapa"><i class="fa fa-arrows-v"></i>Hepsini Kapa - Aç</b-button>
          <b-card v-for="(pg, pg_ind) in patient_last_laboratory.matched" :key="'param_group-' + pg_ind" no-body class="mb-1" header-tag="header" footer-tag="footer" border-variant="primary" header-bg-variant="primary">
            <b-card-header header-bg-variant="secondary" class="p-1">
              <b-row v-b-toggle="'param_group_' + pg_ind" style="cursor: pointer;">
                <b-col cols="12">
                  <strong>{{ pg.name.label }}</strong>
                </b-col>
              </b-row>
            </b-card-header>
            <b-collapse :visible="d_expandAllClosedCollapse" :id="'param_group_' + pg_ind">
              <b-row v-for="(test, test_ind) in pg.list" :key="'test_id_' + test_ind" style="padding: 5px; margin: 0px; border-bottom: solid 1px #ccbdbd;">
                <b-col cols="2"> {{ test.name.label }} </b-col>
                <b-col cols="2"> <span :class="[f_calculateLabTestClass(test)]">{{ test.val }}</span> </b-col>
                <b-col cols="2"> {{ test.lb }} - {{ test.ub }}</b-col>
                <b-col cols="2"> {{ DateFormat(test.date) }} </b-col>
                <b-col cols="2"> <strong style="color: green;">{{ Math.abs(DateFunctions.date_dif_today(test.date)) }} gün önce</strong> </b-col>
              </b-row>
            </b-collapse>
          </b-card> -->
    </b-card>
  </div>
</template>
<script>
import { ClsSearch } from '@/services/public/search';
import { DateFunctions } from '@/services/public/date_functions';
import { mapGetters } from 'vuex';
import HospitalService from '@/services/hospital';
import moment from 'moment';
// /* global $ */

export default {
  name: 'LogoView',
  computed: mapGetters({
    lang: 'lang'
  }),
  props: {
    patient_data: {
      type: Object,
      required: true
    },
    show_mode: {
      type: String,
      required: false,
      default: 'all'
    },
    last_x_days: {
      type: Number,
      required: false
    }
  },
  created() {
    // this.user = JSON.parse(localStorage.getItem('user'));
    if (this.show_mode) {
      this.d_selectedLaboratoryType = this.show_mode;
    }
    this.f_showPatientLastLaboratory();
  },
  mounted() {
    for (let i in this.patient_data.last_laboratory) {
      let is_test_anormal = this.f_calculateLabTestClass(this.patient_data.last_laboratory[i]);
      if (is_test_anormal) {
        this.showNotification({ title: '<= WisdomEra Laboratuar KDS =>', message: 'Anormal Laboratuar Testleri Bulunmaktadır', type: 'error', timeout: 5000 });
        break;
      }
    }
  },
  data() {
    return {
      d_showLastXDays: 'yes',
      d_selectedLaboratoryType: 'all',
      filterTable: '',
      d_patientLastLaboratoryRows: [],
      d_patientLastLaboratoryRowsFiltered: [],
      d_patientLastLaboratoryColumns: [{
        key: "rowNumber",
        label: " "
      }, {
        key: "param_group_name",
        label: "Test Grubu",
        sortable: true
      }, {
        key: "test_name",
        label: "Test Adı",
        sortable: true
      }, {
        key: "test_val",
        label: "Değer",
        sortable: true
      }, {
        key: "range",
        label: "Test Aralığı",
        sortable: true
      }, {
        key: "sample_date",
        label: "Numune Alma Tarihi",
        sortable: true
      }, {
        key: "how_many_days_ago",
        label: "Kaç Gün Önce",
        sortable: true
      }]
    };
  },
  methods: {
    f_calculateRows: function() {
      this.d_patientLastLaboratoryRowsFiltered = [];
      if (this.filterTable) {
        this.d_patientLastLaboratoryRowsFiltered = ClsSearch.list(this.filterTable, this.d_patientLastLaboratoryRows, this.d_patientLastLaboratoryRowsFiltered, ['param_group_name', 'test_name', 'test_val', 'ub', 'lb', 'sample_date', 'how_many_days_ago']);
      } else {
        this.d_patientLastLaboratoryRowsFiltered = JSON.parse(JSON.stringify(this.d_patientLastLaboratoryRows));
      }
    },
    f_calculateCellBackground: function(row, cell) {
      if (row['_cellVariants'][cell]) {
        return 'bg-' + row['_cellVariants'][cell];
      }
      return '';
    },
    f_calculateProblematicValue: function(row, cell) {
      if (row['_cellVariants'][cell]) {
        return true;
      }
      return false;
    },
    print: function() {
      let x = new Date();
      let now_date = moment(x.toString()).format('DD/MM/YYYY HH:mm');
      var divToPrint=document.getElementById("report_here");
      let w = window.open('', '_blank', 'height=500,width=800,toolbar=0,location=0,menubar=0');
      w.document.write('<h3 style="text-align: center;">Hasta Son Laboratuar Verileri</h3>');
      w.document.write('<b>Hasta:</b> ' + this.patient_data.firstname + ' <b>Çıktı Tarihi:</b> ' + now_date);
      w.document.write(divToPrint.outerHTML);
      w.document.close();
      w.onload = function() {
        w.focus();
        w.print();
      };
    },
    f_calculateLabTestClass: function(test) {
      if (test.val) {
        if (test.clb !== undefined && test.clb !== '' && test.clb !== null) {
          if (test.val < test.clb) {
            return 'clb-value';
          }
        }
        if (test.cub !== undefined && test.cub !== '' && test.cub !== null) {
          if (test.val > test.cub) {
            return 'cub-value';
          }
        }
        if (test.ub !== undefined && test.ub !== '' && test.ub !== null) {
          if (test.val > test.ub) {
            return 'ub-value';
          }
        }
        if (test.lb !== undefined && test.lb !== '' && test.lb !== null) {
          if (test.val < test.lb) {
            return 'lb-value';
          }
        }
        return '';
      }
    },
    DateFormat: function(date) {
      if (date) {
        if (date.indexOf('T') === -1) {
          return moment(date, "YYYY-MM-DD hh:mm:ss").format("DD.MM.YYYY");
        } else {
          return moment(date, "YYYY-MM-DDThh:mm:ss").format("DD.MM.YYYY HH:mm");
        }
      } else {
        return "";
      }
    },
    f_showPatientLastLaboratory: function() {
      this.d_patientLastLaboratoryRows = [];
      let param_group_list = { 'not_matched': {}, 'matched': {} };
      for (let i in this.patient_data.last_laboratory) {
        let is_test_anormal = this.f_calculateLabTestClass(this.patient_data.last_laboratory[i]);
        if (this.d_selectedLaboratoryType === 'anormal' && !is_test_anormal) {
          continue;
        }
        let how_many_days_ago = Math.abs(DateFunctions.date_dif_today(this.patient_data.last_laboratory[i].date));
        // belirlenen gün aralığının dışında ise ve last x days belirtildiyse atlıyoruz.
        if (this.last_x_days && this.d_showLastXDays === 'yes' && how_many_days_ago > this.last_x_days) {
          continue;
        }
        if (how_many_days_ago === 0) {
          how_many_days_ago = 'Bugün';
        }
        let data = {
          '_cellVariants': {},
          'test_name': this.patient_data.last_laboratory[i].name && this.patient_data.last_laboratory[i].name.label ? this.patient_data.last_laboratory[i].name.label : 'HATA ' + i,
          'test_val': this.patient_data.last_laboratory[i].val,
          'param_group_name': this.patient_data.last_laboratory[i].param_group_name.label,
          'sample_date': this.patient_data.last_laboratory[i].date,
          'ub': this.patient_data.last_laboratory[i].ub ? this.patient_data.last_laboratory[i].ub : '',
          'lb': this.patient_data.last_laboratory[i].lb ? this.patient_data.last_laboratory[i].lb : '',
          'cub': this.patient_data.last_laboratory[i].cub ? this.patient_data.last_laboratory[i].cub : '',
          'clb': this.patient_data.last_laboratory[i].clb ? this.patient_data.last_laboratory[i].clb : '',
          'how_many_days_ago': how_many_days_ago
        }
        if (how_many_days_ago === 'Bugün') {
          data['_cellVariants']['how_many_days_ago'] = 'success';
        }
        // en az bir tane anormallik varsa kırmızı olacak.
        if (is_test_anormal) {
          data['_cellVariants']['test_val'] = 'danger';
        }
        this.d_patientLastLaboratoryRows.push(data);
        let param_group = this.patient_data.last_laboratory[i].param_group;
        if (param_group) {
          if (!param_group_list.matched[param_group]) {
            param_group_list.matched[param_group] = { 'name': this.patient_data.last_laboratory[i].param_group_name, 'list': {} };
          }
          param_group_list.matched[param_group]['list'][i] = this.patient_data.last_laboratory[i];
        } else {
          param_group_list.not_matched[i] = this.patient_data.last_laboratory[i];
        }
      }
      this.d_patientLastLaboratoryRowsFiltered = JSON.parse(JSON.stringify(this.d_patientLastLaboratoryRows));
      this.patient_data_last_laboratory = param_group_list;
      this.d_showPatientLastLaboratory = true
    },
  },
  watch: {
    'd_selectedLaboratoryType': function() {
      this.f_showPatientLastLaboratory();
    },
    'd_showLastXDays': function() {
      this.f_showPatientLastLaboratory();
    }
  },
  components: {},
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  }
};

</script>
<style></style>
