<template>
  <div>
    <b-row key="rule_engine">
      <b-col sm="12" md="10">
        <template v-if="d_filterRuleEngineMode">
          <template v-if="d_ruleEditMode">
            <wdm-rule-a :data_type="d_columnRule.data_type" :option_data="d_columnRule.option_data" :prepare_data="d_columnRule.prepare_data" :query_list="d_columnRule.query_list" :compile_str_array="d_columnRule.compile_str_array" :compile_str_date="d_columnRule.compile_str_date" :wdmr_change="d_columnRule.wdmr_change" :p_wdmList="d_columnRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_columnRule.wps_data" :p_wdmRuleAsettings="d_columnRule.wra_settings" :compile_str_text="d_columnRule.compile_str_text"></wdm-rule-a>
          </template>
          <template v-if="!d_ruleEditMode">
            <b-row style="margin-top: 10px;">
              <b-col sm="12" lg="12">
                <rule-query :query_list="d_columnRule.query_list" :compile_str="d_columnRule.compile_str_array" :compile_str_date="d_columnRule.compile_str_date" :mode="'view'" :compile_str_text="d_columnRule.compile_str_text"></rule-query>
                <!-- <rule-compile-str :compile_str_array="data.rule.main_rule.compile_str_array" :mode="'view'" :show_caption="'sorgu'" :acordion_index="'rule-1'"></rule-compile-str> -->
              </b-col>
            </b-row>
          </template>
        </template>
      </b-col>
      <b-col sm="12" md="2">
        <b-row style="margin: 5px; text-align: right;">
          <b-col cols="12">
            <b-button @click="f_prepareInspectionWdmrAndWdm()" style="width: 100%;">
              <img src="@/icon/1430869.png" style="width: 1.5em;">filtre hazırla
            </b-button>
          </b-col>
        </b-row>
        <template v-if="d_filterRuleEngineMode">
          <b-row style="margin: 5px; text-align: right;">
            <b-col cols="12">
              <b-button @click="f_closeFilterRuleEngine()" style="width: 100%;">
                <img src="@/icon/1890926.png" style="width: 1.5em;">filtre kapat
              </b-button>
            </b-col>
          </b-row>
          <b-row style="margin: 5px; text-align: right;">
            <b-col cols="12">
              <b-button :variant="d_ruleEditMode ? 'warning' : 'secondary'" @click="f_editInpectionRule()" style="width: 100%;">
                <img src="@/icon/2333399.png" style="width: 1.5em;"> filtre düzenle
              </b-button>
            </b-col>
          </b-row>
          <b-row style="margin: 5px; text-align: right;">
            <b-col cols="12">
              <b-button @click="f_filterInspectionInfo()" style="width: 100%;">
                <img src="@/icon/2230573.png" style="width: 1.5em;"> filtre uygula
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="12" lg="6">
        <b-card no-body border-variant="primary">
          <b-card-header header-bg-variant="primary" class="p-1">
            <i class="fa fa-align-justify"></i><strong> HBYS Başvuru Protokol Listesi </strong>
          </b-card-header>
          <b-navbar style="padding: 0px;">
            <b-nav pills>
              <b-nav-item @click="d_selectedHimsProtocolTab = 'open'; openOrClosedProtocol = 'opened'; ProtocolInspectionData = {}" :active="d_selectedHimsProtocolTab === 'open'">
                Açık
              </b-nav-item>
              <b-nav-item @click="d_selectedHimsProtocolTab = 'closed'; openOrClosedProtocol = 'closed'; PatientAllProtocolList(); ProtocolInspectionData = {}" :active="d_selectedHimsProtocolTab === 'closed'">
                Kapalı
              </b-nav-item>
            </b-nav>
          </b-navbar>
          <template v-if="d_selectedHimsProtocolTab === 'open'">
            <b-card header-tag="header" footer-tag="footer" no-body style="margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                Açık HBYS Başvuru Protokolleri
              </b-card-header>
              <template v-if="patient_open_protocol_list && patient_open_protocol_list.length > 0">
                <b-row v-for="(open_pro, open_pro_ind) in patient_open_protocol_list" :key="open_pro_ind" style="padding: 5px; margin: 0px; border-bottom: solid 1px #ccbdbd;">
                  <b-col cols="6">
                    <div :style="SelectedProtocolInd === open_pro_ind ? 'background-color: #b0e8f5; cursor: pointer;' : 'cursor: pointer;'" @click="SelectThisProtocol('opened', open_pro_ind, open_pro, open_pro.protocol_no, open_pro.doctor, open_pro.department_name)">
                      <i class="fa fa-hand-o-right" style="color: green;"></i> {{ w_presentation ? 'Departman' : open_pro.department_name }}
                      <i class="fa fa-user"></i> {{ w_presentation ? 'Doktor' : open_pro.doctor }}
                      <br>
                      <i class="fa fa-key"></i> {{ w_presentation ? 'Protokol No' : open_pro.protocol_no }}
                      <i class="fa fa-calendar-o"></i> ({{ moment(open_pro.open_date).format('DD/MM/YYYY HH:mm') }})
                      <span :style="f_calculateStyleProtocolType(open_pro.type_id)"> [{{ CalculateProtocolType(open_pro.type_id) }}] </span>
                    </div>
                  </b-col>
                  <b-col cols="6" v-if="hims_protocol_list && hims_protocol_list[open_pro.protocol_no]">
                    <b-form-checkbox :value="1" :unchecked-value="0" v-model="hims_protocol_list[open_pro.protocol_no].send_status">HBYS fatura muayene bilgilerine gönder</b-form-checkbox>
                  </b-col>
                </b-row>
              </template>
              <template v-else>
                <span style="color: red;">Açık HBYS Başvuru Protokolü Bulunmamaktadır</span>
              </template>
            </b-card>
            <b-card v-if="prepared_hims_data && prepared_hims_data.care_plan && prepared_hims_data.care_plan.length > 0 && prepared_hims_data.care_plan[prepared_hims_data.care_plan.length - 1].txt" header-tag="header" footer-tag="footer" no-body style="margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                Otomatik Hazırlanan Bilgi
              </b-card-header>
              <b-row style="white-space: pre-line;">
                <b-col cols="12">
                  {{ prepared_hims_data.care_plan[prepared_hims_data.care_plan.length - 1].txt }}
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-if="d_selectedHimsProtocolTab === 'closed'">
            <b-card header-tag="header" footer-tag="footer" no-body style="margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                Kapalı HBYS Başvuru Protokolleri
                <b-button v-if="d_filterRuleEngineMode" :variant="d_showPositiveRule ? 'warning' : 'secondary'" style="margin-right: 5px;" class="pull-right" @click="d_showPositiveRule ? d_showPositiveRule = false : d_showPositiveRule = true" title="Pozitif sonuçları göster">
                  <img src="@/icon/1430869.png" style="width: 1.5em;">
                </b-button>
                <b-button style="margin-right: 5px;" class="pull-right" @click="d_expandAllClosedCollapse ? d_expandAllClosedCollapse = false : d_expandAllClosedCollapse = true" title="Hepsini Aç-Kapa"><i class="fa fa-arrows-v"></i></b-button>
              </b-card-header>
              <template v-if="Object.keys(CloseProtocolList).length > 0">
                <div style="overflow-y: auto; height: 500px; overflow-x: hidden;">
                  <template v-for="(dep, dep_ind) in CloseProtocolList">
                    <b-card v-if="f_controlForDepartmentShowing(dep)" :key="'department-' + dep_ind" no-body class="mb-1" header-tag="header" footer-tag="footer" border-variant="primary" header-bg-variant="primary">
                      <b-card-header header-bg-variant="secondary" class="p-1">
                        <b-row v-b-toggle="'closed_protocol_' + dep_ind" style="cursor: pointer;">
                          <b-col cols="12">
                            <strong>{{ w_presentation ? 'Departman' : dep.department_name }}</strong>
                          </b-col>
                        </b-row>
                      </b-card-header>
                      <b-collapse :visible="d_expandAllClosedCollapse" :id="'closed_protocol_' + dep_ind">
                        <template v-for="(pro_date, pro_date_ind) in dep.dates">
                          <b-row v-if="(dep.protocols[pro_date].rule && d_showPositiveRule) || !d_showPositiveRule" :key="'protocol-date-' + pro_date_ind" style="padding: 5px; margin: 0px; border-bottom: solid 1px #ccbdbd;">
                            <b-col cols="12">
                              <div :style="SelectedProtocolInd === pro_date ? 'background-color: #b0e8f5; cursor: pointer;' : 'cursor: pointer;'" @click="SelectThisProtocol('closed', pro_date, dep.protocols[pro_date], dep.protocols[pro_date].protocol_no, dep.protocols[pro_date].doctor, dep.department_name)">
                                <i class="fa fa-user"></i> {{ w_presentation ? 'Doktor' : dep.protocols[pro_date].doctor }}
                                <i class="fa fa-key"></i> {{ w_presentation ? 'Protokol No' : dep.protocols[pro_date].protocol_no }}
                                <i class="fa fa-calendar-o"></i> ({{ moment(pro_date).format('DD/MM/YYYY HH:mm') }})
                                <span :style="f_calculateStyleProtocolType(dep.protocols[pro_date].type_id)">[{{ CalculateProtocolType(dep.protocols[pro_date].type_id) }}]</span>
                                <img v-if="dep.protocols[pro_date].rule" src="@/icon/1430869.png" style="width: 1.5em;">
                              </div>
                            </b-col>
                          </b-row>
                        </template>
                      </b-collapse>
                    </b-card>
                  </template>
                </div>
              </template>
              <template v-else>
                <span style="color: red;">Kapalı HBYS Başvuru Protokolü Bulunmamaktadır</span>
              </template>
            </b-card>
          </template>
        </b-card>
      </b-col>
      <b-col sm="12" md="12" lg="6">
        <b-card no-body border-variant="primary" v-if="SelectedProtocolInd !== ''">
          <b-card-header header-bg-variant="primary" class="p-1">
            <i class="fa fa-align-justify"></i>
            <strong> Protokol Detayları </strong> ( {{ w_presentation ? 'Protokol' : d_selectedDepartmentName }}, {{ w_presentation ? 'Doktor' : d_selectedDoctor }}, {{ w_presentation ? 'Protokol No' : selectedProtocolNo }} )
            <span :style="f_calculateStyleProtocolType(SelectedProtocolDetails.type_id)">
              [{{ CalculateProtocolType(SelectedProtocolDetails.type_id) }}]
            </span>
          </b-card-header>
          <b-navbar style="padding: 0px;">
            <b-nav pills>
              <b-nav-item v-if="selectedProtocolNo" :active="SelectedNavRightItem === 'protocol_inspection_details'" @click="f_selectInspectionOfProtocol()">Muayene Bilgileri</b-nav-item>
              <b-nav-item :active="SelectedNavRightItem === 'protocol_details'" @click="SelectedNavRightItem = 'protocol_details'">Detaylar</b-nav-item>
            </b-nav>
          </b-navbar>
          <template v-if="SelectedNavRightItem === 'protocol_details' && SelectedProtocolDetails.patient_id">
            <b-card header-tag="header" footer-tag="footer" no-body style="margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                Başvuru Protokolü Genel Bilgileri
              </b-card-header>
              <b-row v-if="SelectedProtocolDetails.patient_id">
                <b-col cols="6">Hasta No</b-col>
                <b-col cols="6">{{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : SelectedProtocolDetails.patient_id }}</b-col>
              </b-row>
              <b-row v-if="SelectedProtocolDetails.department_id">
                <b-col cols="6">Departman ID</b-col>
                <b-col cols="6">{{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : SelectedProtocolDetails.department_id }}</b-col>
              </b-row>
              <b-row v-if="SelectedProtocolDetails.department_name">
                <b-col cols="6">Departman Adı</b-col>
                <b-col cols="6">{{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : SelectedProtocolDetails.department_name }}</b-col>
              </b-row>
              <b-row v-if="SelectedProtocolDetails.branch_id">
                <b-col cols="6">Branş ID</b-col>
                <b-col cols="6">{{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : SelectedProtocolDetails.branch_id }}</b-col>
              </b-row>
              <b-row v-if="SelectedProtocolDetails.resource_id">
                <b-col cols="6">Doktor Kaynak ID</b-col>
                <b-col cols="6">{{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : SelectedProtocolDetails.resource_id }}</b-col>
              </b-row>
              <b-row v-if="SelectedProtocolDetails.doctor">
                <b-col cols="6">Doktor</b-col>
                <b-col cols="6">{{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : SelectedProtocolDetails.doctor }}</b-col>
              </b-row>
              <b-row v-if="SelectedProtocolDetails.is_have_lab !== undefined">
                <b-col cols="6">Laboratuar Kaydı Var mı?</b-col>
                <b-col cols="6">{{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : SelectedProtocolDetails.is_have_lab === 1 ? 'Var' : 'Yok' }}</b-col>
              </b-row>
              <b-row v-if="SelectedProtocolDetails.is_have_rad !== undefined">
                <b-col cols="6">Radyoloji Kaydı Var mı?</b-col>
                <b-col cols="6">{{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : SelectedProtocolDetails.is_have_rad === 1 ? 'Var' : 'Yok' }}</b-col>
              </b-row>
              <b-row v-if="SelectedProtocolDetails.open_date">
                <b-col cols="6">Protokol Açılma Tarihi</b-col>
                <b-col cols="6">{{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : SelectedProtocolDetails.open_date }}</b-col>
              </b-row>
              <b-row v-if="SelectedProtocolDetails.payer_name">
                <b-col cols="6">Kurum</b-col>
                <b-col cols="6">{{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : SelectedProtocolDetails.payer_name }}</b-col>
              </b-row>
              <b-row v-if="SelectedProtocolDetails.process_id">
                <b-col cols="6">İşlem no</b-col>
                <b-col cols="6">{{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : SelectedProtocolDetails.process_id }}</b-col>
              </b-row>
              <b-row v-if="SelectedProtocolDetails.state !== undefined">
                <b-col cols="6">Protokol Durumu</b-col>
                <b-col cols="6">{{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : [1,2].indexOf(SelectedProtocolDetails.state) !== -1 ? 'Açık' : 'Kapalı' }}</b-col>
              </b-row>
              <b-row v-if="SelectedProtocolDetails.type_id">
                <b-col cols="6">Protokol Tipi</b-col>
                <b-col cols="6">
                  <span :style="f_calculateStyleProtocolType(SelectedProtocolDetails.type_id)">
                    {{ CalculateProtocolType(SelectedProtocolDetails.type_id) }}
                  </span>
                </b-col>
              </b-row>
              <b-row v-if="SelectedProtocolDetails.discharge_date !== undefined">
                <b-col cols="6">Protokol Kapanma/Taburcu tarihi</b-col>
                <b-col cols="6">{{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : SelectedProtocolDetails.discharge_date }}</b-col>
              </b-row>
            </b-card>
          </template>
          <template v-if="SelectedNavRightItem === 'protocol_inspection_details'">
            <b-card header-tag="header" footer-tag="footer" no-body style="margin-bottom: 0px;">
              <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                Başvuru Protokolü Fatura Muayene Bilgileri
                <b-button style="margin-right: 5px;" class="pull-right" @click="d_expandAllCollapsed ? d_expandAllCollapsed = false : d_expandAllCollapsed = true; d_expandGeneralInformation = d_expandAllCollapsed;" title="Hepsini Aç-Kapa"><i class="fa fa-arrows-v"></i></b-button>
                <!-- OPEN EDİT MODE ADD -->
                <b-button id="edit-button-adding" v-if="ProtocolInspectionData.manuel_edit === 0 && d_userHimsBranchId === SelectedProtocolDetails.branch_id && openOrClosedProtocol === 'opened'" style="margin-right: 5px;" :variant="d_himsInspectionEditMode ? 'warning' : 'secondary'" class="pull-right" @click="f_openEditMode('add')"><i class="fa fa-edit"></i><i class="fa fa-plus"></i></b-button>
                <b-popover target="edit-button-adding" triggers="hover focus">
                  <template slot="title">Düzenleme Modu</template>
                  Tıklandığında düzenleme modu önerilen yeşil alanların fatura muayene bilgilerine otomatik olarak eklenmesini sağlar. Eklenen verilerle düzenleyebilirsiniz.
                </b-popover>
                <!-- OPEN EDİT MODE NOT ADD -->
                <b-button id="edit-button-not-add" v-if="d_userHimsBranchId === SelectedProtocolDetails.branch_id && openOrClosedProtocol === 'opened'" style="margin-right: 5px;" :variant="d_himsInspectionEditMode ? 'warning' : 'secondary'" class="pull-right" @click="f_openEditMode('not_add')" title="Düzenleme Modu Önerileri Eklemeden"><i class="fa fa-edit"></i></b-button>
                <b-popover target="edit-button-not-add" triggers="hover focus">
                  <template slot="title">Düzenleme Modu</template>
                  Tıklandığında düzenleme modu önerilen yeşil alanların eklenmeden açılmasını sağlar.
                </b-popover>
                <!-- <b-button style="margin-right: 5px;" v-if="[1,2].indexOf(SelectedProtocolDetails.state) !== -1 && d_himsInspectionEditMode && openOrClosedProtocol === 'opened'" class="pull-right" variant="primary" @click="SendOriginalInspectionOnWebService()" title="Kaydet"> <i class="fa fa-save"></i></b-button> -->
                <b-button style="margin-right: 5px;" v-if="d_himsInspectionEditMode" class="pull-right" variant="primary" @click="f_resetProtocolInspectionData()" title="Geri Al"> <i class="fa fa-undo"></i></b-button>
                <b-button v-if="d_himsInspectionEditMode" class="pull-right" style="margin-right: 5px;" @click="f_newWdm('diagnosis')" variant="secondary"><i class="icon-plus"></i>yeni tanı</b-button>
              </b-card-header>
              <div>
                <span v-if="!selectedProtocolNo" style="color: red;">Henüz Başvuru Protokolü Seçilmedi</span>
              </div>
              <div v-if="ProtocolInspectionData.id" style="overflow-y: auto; height: 500px; overflow-x: hidden; padding: 5px;">
                <span v-if="ProtocolInspectionData.id === '0'" style="color: red;">Seçilen Başvuru Protokolünde Kayıtlı Muayene Bilgisi Yoktur.</span>
                <b-card no-body class="mb-1" header-tag="header" footer-tag="footer" border-variant="primary" header-bg-variant="primary">
                  <b-card-header header-bg-variant="secondary" class="p-1">
                    <b-row v-b-toggle="'protocol_inspection_general'" style="cursor: pointer;">
                      <b-col cols="12"><i class="fa fa-pencil-square-o"></i>Genel Bilgiler</b-col>
                    </b-row>
                  </b-card-header>
                  <b-collapse :visible="d_expandGeneralInformation" id="protocol_inspection_general">
                    <b-row v-if="ProtocolInspectionData.id !== undefined">
                      <b-col cols="6"><strong>Muayene ID</strong></b-col>
                      <b-col cols="6"> {{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : ProtocolInspectionData.id }} </b-col>
                    </b-row>
                    <b-row v-if="ProtocolInspectionData.patient_id !== undefined">
                      <b-col cols="6"><strong>Hasta No</strong></b-col>
                      <b-col cols="6"> {{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : ProtocolInspectionData.patient_id }} </b-col>
                    </b-row>
                    <b-row v-if="ProtocolInspectionData.activity_id !== undefined">
                      <b-col cols="6"><strong>Aktivite ID</strong></b-col>
                      <b-col cols="6"> {{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : ProtocolInspectionData.activity_id }} </b-col>
                    </b-row>
                    <b-row v-if="ProtocolInspectionData.workitem_id !== undefined">
                      <b-col cols="6"><strong>Workitem ID</strong></b-col>
                      <b-col cols="6"> {{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : ProtocolInspectionData.workitem_id }} </b-col>
                    </b-row>
                    <b-row v-if="ProtocolInspectionData.process_id !== undefined">
                      <b-col cols="6"><strong>Process ID</strong></b-col>
                      <b-col cols="6"> {{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : ProtocolInspectionData.process_id }} </b-col>
                    </b-row>
                    <b-row v-if="ProtocolInspectionData.branch_name !== undefined">
                      <b-col cols="6"><strong>Branş Adı</strong></b-col>
                      <b-col cols="6"> {{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : ProtocolInspectionData.branch_name }} </b-col>
                    </b-row>
                    <b-row v-if="ProtocolInspectionData.open_date !== undefined">
                      <b-col cols="6"><strong>Protokol Açılma Tarihi</strong></b-col>
                      <b-col cols="6"> {{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : ProtocolInspectionData.open_date }} </b-col>
                    </b-row>
                    <b-row v-if="ProtocolInspectionData.resource_id !== undefined">
                      <b-col cols="6"><strong>Kaynak ID</strong></b-col>
                      <b-col cols="6"> {{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : ProtocolInspectionData.resource_id }} </b-col>
                    </b-row>
                    <b-row v-if="ProtocolInspectionData.resource_name !== undefined">
                      <b-col cols="6"><strong>Doktor Adı</strong></b-col>
                      <b-col cols="6"> {{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : ProtocolInspectionData.resource_name }} </b-col>
                    </b-row>
                    <b-row v-if="ProtocolInspectionData.is_changeable !== undefined">
                      <b-col cols="6"><strong>Değiştirilebilir Mi?</strong></b-col>
                      <b-col cols="6"> {{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : ProtocolInspectionData.is_changeable ? 'Evet' : 'Hayır' }} </b-col>
                    </b-row>
                    <b-row v-if="ProtocolInspectionData.is_digital_signed_for_complete !== undefined">
                      <b-col cols="6"><strong>Dijital olarak imzalandı mı?</strong></b-col>
                      <b-col cols="6"> {{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : ProtocolInspectionData.is_digital_signed_for_complete ? 'Evet' : 'Hayır' }} </b-col>
                    </b-row>
                    <b-row v-if="ProtocolInspectionData.epikiriz_complete_date !== undefined">
                      <b-col cols="6"><strong>Epikriz tamamlanma tarihi</strong></b-col>
                      <b-col cols="6">{{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : ProtocolInspectionData.epikiriz_complete_date ? 'Tamamlandı' : 'Tamamlanmadı' }}</b-col>
                    </b-row>
                    <b-row v-if="ProtocolInspectionData.discharge_date !== undefined">
                      <b-col cols="6"><strong>Protokol Kapanma/Taburcu tarihi</strong></b-col>
                      <b-col cols="6">{{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : ProtocolInspectionData.discharge_date }}</b-col>
                    </b-row>
                  </b-collapse>
                </b-card>
                <!--                 <b-card no-body class="mb-1" header-tag="header" footer-tag="footer" border-variant="primary" header-bg-variant="primary">
                  <b-card-header header-bg-variant="secondary" class="p-1">
                    <b-row v-b-toggle="'protocol_inspection_symptom_start_date'" style="cursor: pointer;">
                      <b-col cols="12"><i class="fa fa-pencil-square-o"></i>Semptomlar Başlama Tarihi</b-col>
                    </b-row>
                  </b-card-header>
                  <b-collapse :visible="d_expandAllCollapsed" id="protocol_inspection_symptom_start_date">
                    <b-row v-if="ProtocolInspectionData.symptom_start_date !== undefined">
                      <b-col cols="12">
                        <template v-if="[1,2].indexOf(SelectedProtocolDetails.state) !== -1 && d_himsInspectionEditMode">
                          <datepicker :config="{ enableTime: true, maxDate:'today'}" class="form-control" v-model="ProtocolInspectionData.symptom_start_date" name="symptom_start_date" style="background: transparent;"></datepicker>
                        </template>
                        <template v-else>
                          {{ ProtocolInspectionData.symptom_start_date ? ProtocolInspectionData.symptom_start_date : 'Kayıt Yok' }}
                        </template>
                      </b-col>
                    </b-row>
                  </b-collapse>
                </b-card> -->
                <b-card v-for="(param, param_ind) in WebserviceProtocolInsuranceData['MLPCARE']['parameter_order']" :key="'hims_webservice_mlpcare_parameter_order_' + param_ind" no-body class="mb-1" header-tag="header" footer-tag="footer" border-variant="primary" header-bg-variant="primary">
                  <b-card-header header-bg-variant="secondary" class="p-1">
                    <b-row>
                      <b-col cols="10" v-b-toggle="'protocol_inspection_' + param" style="cursor: pointer;"><i class="fa fa-pencil-square-o"></i>{{ WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param].name.label }}</b-col>
                      <b-col cols="2">
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-collapse :visible="d_expandAllCollapsed" :id="'protocol_inspection_' + param">
                    <b-row v-if="ProtocolInspectionData[param] !== undefined">
                      <b-col cols="12">
                        <template v-if="[1,2].indexOf(SelectedProtocolDetails.state) !== -1 && d_himsInspectionEditMode">
                          <b-row>
                            <b-col sm="12" md="12" lg="11">
                              <v-select v-model="WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param].selected" :placeholder="ProtocolInspectionData.param_list[param]['text_list'].length === 0 ? 'Öneri Listeniz Boş' : 'Listeden Seçim Yapabilirsiniz'" :options="ProtocolInspectionData.param_list[param]['text_list']">
                                <template slot="option" slot-scope="option">
                                  <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                                    <span> {{ option.label }} </span>
                                  </div>
                                </template>
                              </v-select>
                            </b-col>
                            <b-col sm="12" md="12" lg="1" style="padding: 0px;">
                              <b-button size="sm" variant="success" v-if="WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param].selected" @click="f_addThisSelection(param)">ekle</b-button>
                            </b-col>
                          </b-row>
                          <template v-if="WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param].div_tag === 'textarea'">
                            <b-form-textarea v-model="ProtocolInspectionData[param]" style="background-color: #eadcbe; color: black;" rows="6" max-rows="10" placeholder="Metninizi buraya yazabilirsiniz."></b-form-textarea>
                          </template>
                          <template v-else-if="WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param].div_tag === 'text'">
                            <b-form-input v-model="ProtocolInspectionData[param]" style="background-color: #eadcbe; color: black;"></b-form-input>
                          </template>
                          <template v-else-if="WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param].div_tag === 'date'">
                            <datepicker :config="{ enableTime: true, maxDate:'today'}" class="form-control" v-model="ProtocolInspectionData[param]" name="symptom_start_date" style="background: transparent;"></datepicker>
                          </template>
                          <template v-else-if="param === 'main_diagnosis' || param === 'pre_diagnosis'">
                            <div v-if="ProtocolInspectionData[param]" style="background-color: #eadcbe; color: black;">
                              <div v-for="(icd, icd_ind) in ProtocolInspectionData[param].split(',')">
                                <b-button variant="danger" size="sm" @click="f_deleteIcdCode(param, icd)"><i class="fa fa-trash"></i> </b-button>
                                {{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : icd }}
                              </div>
                            </div>
                          </template>
                        </template>
                        <template v-else>
                          <template v-if="ProtocolInspectionData[param]">
                            {{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : ProtocolInspectionData[param] }}
                          </template>
                          <template v-else>
                            <span style="color: red;"> Kayıt Yok </span>
                          </template>
                          <div v-if="ProtocolInspectionData.manuel_edit === 0 && ProtocolInspectionData.param_list && ProtocolInspectionData.param_list[param] && ProtocolInspectionData.param_list[param]['text_list'].length > 0 && ProtocolInspectionData.param_list[param]['text_list'][ProtocolInspectionData.param_list[param]['text_list'].length - 1] && ProtocolInspectionData.param_list[param]['text_list'][ProtocolInspectionData.param_list[param]['text_list'].length - 1].txt" style="background-color: #a1efa18f;">
                            {{ w_presentation ? 'SUNUM SEBEBİYLE GİZLİ' : ProtocolInspectionData.param_list[param]['text_list'][ProtocolInspectionData.param_list[param]['text_list'].length - 1].txt }}
                          </div>
                        </template>
                      </b-col>
                    </b-row>
                  </b-collapse>
                </b-card>
              </div>
            </b-card>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <modal v-if="show_wisdom_data_modal" @close="show_wisdom_data_modal = false" :width="'750'">
      <h3 slot="header"> Tanı ekleme sayfası </h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="wisdom_data_save()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="show_wisdom_data_modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import { ClsRule } from '@/services/public/rule';
import {
  default as WisdomDataModal
} from "@/components/widgets/WisdomDataModal";
import {
  default as RuleQuery
} from "@/components/widgets/RuleQuery";
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import WisdomDataService from '@/services/wisdom_data';
import GlobalService from "@/services/global";
import OptionService from '@/services/option';
import { mapGetters } from 'vuex';
import Datepicker from 'wisdom-vue-datepicker';
import WebService from '@/services/webservice';
import moment from 'moment';
import vSelect from 'vue-select';
import {
  default as Modal
} from '@/components/widgets/Modal';
import {
  default as WdmRuleA
} from '@/components/widgets/WdmRuleA';


export default {
  name: 'HimsProtocolDetails',
  components: {
    Datepicker,
    Modal,
    vSelect,
    WisdomDataModal,
    WdmRuleA,
    RuleQuery
  },
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  },
  props: {
    protocol_no: {
      type: String,
      required: false
    },
    protocolOpenOrClosedState: {
      type: String,
      required: false
    },
    prepared_hims_data: {
      type: Object,
      required: false
    },
    hims_protocol_list: {
      type: Object,
      required: false
    },
    p_ruleData: {
      type: Object,
      required: false
    },
    patient: {
      type: Object,
      required: false
    },
    patient_open_protocol_list: {
      type: Array,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      // patient: 'patient_selected',
      device: 'device',
      StoreTest: 'test'
    })
  },
  data () {
    return {
      d_ruleEditMode: false,
      d_showPositiveRule: false,
      d_filterRuleEngineMode: false,
      d_selectedAnatomySchemaList: [],
      w_presentation: false,
      d_approveToSendHims: [],
      d_himsInspectionEditMode: false,
      d_userHimsBranchId: '',
      d_selectedHimsProtocolTab: 'open',
      moment: moment,
      SelectedNavLeftItem: '',
      SelectedProtocolInd: '',
      SelectedNavRightItem: '',
      SelectedProtocolDetails: {},
      ProtocolInspectionData: {},
      CloseProtocolList: {},
      selectedProtocolNo: '',
      openOrClosedProtocol: '',
      WebserviceProtocolInsuranceData: require('@/options/webservice').ProtocolInsuranceData,
      d_expandAllCollapsed: true,
      d_expandGeneralInformation: false,
      d_expandAllClosedCollapse: true,
      d_selectedDoctor: '',
      d_selectedDepartmentName: '',
      d_tempHimsProtocolList: [],
      option_data: {},
      data_type: '',
      wisdom_data: {},
      show_wisdom_data_modal: false,
      mother_children: { 'forceUpdate': 0, 'wdm_parameters_data_type': 0 },
      d_columnRule: {},
      d_columnRuleForNew: {
        'date_ability': '',
        'wdmr_change': { 'rule': 0 },
        'data_type': 'inspection_info',
        'option_data': {},
        'prepare_data': {},
        'query_list': [],
        'compile_str_date': [],
        'compile_str_text': [],
        'compile_str_array': [],
        'wra_settings': {
          'mode': 'param',
          'options': [
            { 'label': 'WDM & QUERY', 'value': 'wdm_query' },
            { 'label': 'PARAM', 'value': 'param' },
            { 'label': 'PARAM & QUERY', 'value': 'param_query' }
          ]
        },
        'wps_data': [{
          'operation': '',
          'selected_value': '',
          'loc': [],
          'val_list': [],
          'selected_wdm': '',
          'd_level': 0,
          'd_level_count': 0,
          'significant': 'true'
        }],
        'wdm_list': []
      },
      d_inspWdmrList: [],
      d_allProtocolList: []
    }
  },
  created: function () {
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.user = JSON.parse(localStorage.getItem('user'));
    for (let i in this.user.position_department_title) {
      if (this.user.position_department_title[i].active && this.user.position_department_title[i].department.hims_branch_id) {
        this.d_userHimsBranchId = parseInt(this.user.position_department_title[i].department.hims_branch_id);
      }
    }
  },
  mounted: function () {
    this.getInsuranceDataOfOpenProtocols();
    this.d_columnRule = JSON.parse(JSON.stringify(this.d_columnRuleForNew));
    if (this.p_ruleData !== undefined) {
      if (this.p_ruleData.query_list.length > 0) {
        this.f_prepareInspectionWdmrAndWdm(JSON.parse(JSON.stringify(this.p_ruleData)));
      } else {
        this.f_prepareInspectionWdmrAndWdm();
        this.d_ruleEditMode = true;
      }
    }
  },
  methods: {
    f_editInpectionRule: function () {
      if (this.d_ruleEditMode) {
        this.d_ruleEditMode = false;
      } else {
        this.d_ruleEditMode = true;
      }
      this.$forceUpdate();
    },
    f_closeFilterRuleEngine: function () {
      this.d_filterRuleEngineMode = false;
      this.d_showPositiveRule = false;
      this.d_ruleEditMode = false;
    },
    f_controlForDepartmentShowing: function (dep) {
      if (this.d_showPositiveRule) {
        let det = false;
        for (let d in dep.protocols) {
          if (dep.protocols[d].rule) {
            det = true;
          }
        }
        if (!det) {
          return false;
        }
      }
      return true;
    },
    f_prepareInspectionWdmrAndWdm: function (rule_algoritm_data = false) {
      let data = {
        'hospital_group': this.user.hospital_group,
        'hospital_code': this.patient.hospital_id,
        'patient_no': this.patient.hospitals[this.user.hospital_group][this.patient.hospital_id].data.patient_no
      };
      if (rule_algoritm_data) {
        data.rule_algoritm = rule_algoritm_data;
        this.d_columnRule.query_list = rule_algoritm_data.query_list;
        this.d_columnRule.compile_str_array = rule_algoritm_data.compile_str_array;
        this.d_columnRule.compile_str_date = rule_algoritm_data.compile_str_date;
        this.d_columnRule.compile_str_text = rule_algoritm_data.compile_str_text;
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Muayene veri modelleri hazırlığı yapılıyor. Lütfen bekleyiniz.' } });
      WebService.prepare_inspection_wdmr_and_wdm(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status === 'success') {
            // console.log(resp.data.result);
            let wdm_type_data = {
              'label': resp.data.result.insp_wdm.name.label,
              'value': resp.data.result.insp_wdm.name.value,
              'type': resp.data.result.insp_wdm.type
            };
            this.d_columnRule.wdm_list = [];
            this.d_columnRule.wdm_list.push(wdm_type_data);
            this.d_columnRule.option_data[resp.data.result.insp_wdm.name.value] = resp.data.result.insp_wdm;
            this.d_inspWdmrList = resp.data.result.insp_wdmr_list;
            this.d_allProtocolList = resp.data.result.all_protocol_list;
            this.f_createCloseProtocolList();
            this.d_selectedHimsProtocolTab = 'closed';
            if (rule_algoritm_data) {
              this.d_showPositiveRule = true;
            }
            this.f_showRuleEngineMode();
          } else {
            console.log('Error: ', resp.data.result);
          }
          this.$forceUpdate();
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        });
    },
    f_showRuleEngineMode: function () {
      this.d_filterRuleEngineMode = false;
      setTimeout(function () {
        this.d_filterRuleEngineMode = true;
      }.bind(this), 100);
    },
    f_filterInspectionInfo: function () {
      if (this.d_columnRule.wra_settings.mode === 'param') {
        ClsRule.f_paramToQuery(this.d_columnRule.query_list, this.d_columnRule.compile_str_array, this.d_columnRule.wps_data);
      }
      if (this.d_columnRule.query_list.length > 0) {
        let data = {
          'all_protocol_list': this.d_allProtocolList,
          'insp_wdmr_list': this.d_inspWdmrList,
          'rule_algoritm': {
            'query_list': JSON.parse(JSON.stringify(this.d_columnRule.query_list)),
            'compile_str_array': JSON.parse(JSON.stringify(this.d_columnRule.compile_str_array)),
            'compile_str_text': JSON.parse(JSON.stringify(this.d_columnRule.compile_str_text)),
            'compile_str_date': JSON.parse(JSON.stringify(this.d_columnRule.compile_str_date))
          }
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analiz yapılıyor. Lütfen bekleyiniz.' } });
        WebService.filter_inspection_info(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            if (resp.data.status === 'success') {
              this.d_allProtocolList = resp.data.result.all_protocol_list;
              this.f_createCloseProtocolList();
            } else {
              console.log('Error: ', resp.data.result);
            }
            this.$forceUpdate();
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          });
      } else {
        alert('Lütfen sorgu yapınızı dizayn ediniz.');
      }
    },
    getInsuranceDataOfOpenProtocols: function () {
      if (this.patient_open_protocol_list && this.patient_open_protocol_list.length > 0) {
        // Aşağıda tüm açık ve branşı uygun protokollerin inspection bilgilerini çekmemizin sebebi, düzenleme yapıp bu düzenlenen datanın kaydını sağlamak.
        // Eğer kullanıcının düzeltmeye yetkisi olan protokol muayene bilgilerini her tıklamada yeniden getirirsek düzenlenmiş data kaybolur.
        let last_open_and_eligible_protocol = '';
        for (let i in this.patient_open_protocol_list) {
          let pro_no = this.patient_open_protocol_list[i].protocol_no;
          // düzenleyebileceği protokollerin inspection datası başta getiriliyor.
          if (this.patient_open_protocol_list[i].branch_id === this.d_userHimsBranchId) {
            let query = 'protocolNo=' + this.patient_open_protocol_list[i].protocol_no + '&' + 'hospitalGroup=' + this.patient.hospital_group + '&' + 'hospitalCode=' + this.patient.hospital_id;
            WebService.GetInspectionOfThisProtocol(query)
              .then(resp => {
                last_open_and_eligible_protocol = pro_no;
                // console.log(resp.data);
                if (resp.data.status === 'success') {
                  let data = {};
                  if (resp.data.result.length > 0) {
                    data = resp.data.result[0];
                    for (let x in data) {
                      // pain_condition false olarak gelebiliyor. Burası üzerine daha sonra farklı bir mantık düşünülebilir.
                      if (data[x] === null || data[x] === false) {
                        data[x] = '';
                      }
                    }
                  } else { // Henüz hiç muayene kaydı olmayan bir protokol ise;
                    for (let y in this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_order']) {
                      let param = this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_order'][y];
                      data[param] = '';
                    }
                    data.id = '0';
                  }
                  data['resource_id'] = this.patient_open_protocol_list[i].resource_id;
                  data['send_status'] = 1;
                  data['manuel_edit'] = 0;
                  data['param_list'] = JSON.parse(JSON.stringify(this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list']));
                  // eğer hazırlanmış bir text ile bu modül açılıyorsa ilgii açık protokoldeki yerine koyuyoruz.
                  if (this.prepared_hims_data) {
                    for (let p in this.prepared_hims_data) {
                      if (data['param_list'][p]) {
                        data['param_list'][p].text_list = this.prepared_hims_data[p];
                      }
                    }
                  }
                  this.hims_protocol_list[pro_no] = JSON.parse(JSON.stringify(data));
                  this.d_tempHimsProtocolList = JSON.parse(JSON.stringify(this.hims_protocol_list));
                  // Bir protokol nosuna tıklayıp bu komponenti açtıysak öncelik onda. Değilse son açık ve kullanıcının düzenleyebileceği protokolü saçiyoruz.
                  let sel = false;
                  if (this.protocol_no) {
                    for (let pr in this.patient_open_protocol_list) {
                      if (this.patient_open_protocol_list[pr].protocol_no === this.protocol_no) {
                        this.SelectThisProtocol('opened', parseInt(pr), this.patient_open_protocol_list[pr], this.patient_open_protocol_list[pr].protocol_no, this.patient_open_protocol_list[pr].doctor, this.patient_open_protocol_list[pr].department_name);
                        sel = true;
                        break;
                      }
                    }
                  }
                  if (last_open_and_eligible_protocol && !sel) {
                    for (let pr in this.patient_open_protocol_list) {
                      if (this.patient_open_protocol_list[pr].protocol_no === last_open_and_eligible_protocol) {
                        this.SelectThisProtocol('opened', parseInt(pr), this.patient_open_protocol_list[pr], this.patient_open_protocol_list[pr].protocol_no, this.patient_open_protocol_list[pr].doctor, this.patient_open_protocol_list[pr].department_name);
                        break;
                      }
                    }
                  }
                } else {
                  console.log('Error: ', resp.data.result);
                }
              });
          }
        }
      }
      this.openOrClosedProtocol = this.protocolOpenOrClosedState;
    },
    wisdom_data_save: function () {
      for (let i in this.option_data[this.wisdom_data.type].param_group.param_order) {
        let group = this.option_data[this.wisdom_data.type].param_group.param_order[i];
        for (let k in this.option_data[this.wisdom_data.type][group].parameters) {
          let param_data = this.option_data[this.wisdom_data.type][this.option_data[this.data_type].param_group.param_order[i]].parameters[k];
          if (param_data.required && param_data.required === 'yes') {
            if (!this.wisdom_data['data'][group][k]['val']) {
              alert('Lütfen zorunlu alanları tamamlayınız');
              return;
            }
          }
        }
      }
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name']);
      this.wisdom_data['label'] = return_data.name;
      // this.$refs.wisdom_data_modal.calculate_name();
      this.show_wisdom_data_modal = false;
      let d_uuid = this.wisdom_data.id ? this.wisdom_data.id : '';
      let data = { 'webservice': {}, 'data': this.wisdom_data };
      WisdomDataService.save_wdm(this.patient.id, d_uuid, data)
        .then(resp => {
          if (resp.data.result && resp.data.result.data.general && resp.data.result.data.general.diagnosis && resp.data.result.data.general.diagnosis.val) {
            let data = { 'label': '', 'value': '', 'txt': '' };
            data['label'] = resp.data.result.date + ' - ' + resp.data.result['data']['general']['diagnosis']['val']['label'];
            data['value'] = resp.data.result['id'];
            data['txt'] = resp.data.result.date + ' - ' + resp.data.result['data']['general']['diagnosis']['val']['label'];
            data['icd_code'] = resp.data.result['data']['general']['diagnosis']['val']['value'];
            for (let i in this.hims_protocol_list) {
              this.hims_protocol_list[i].param_list['pre_diagnosis'].text_list.push(data);
              this.hims_protocol_list[i].param_list['main_diagnosis'].text_list.push(data);
            }
            this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list']['pre_diagnosis'].selected = data;
            this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list']['main_diagnosis'].selected = data;
          }
        });
    },
    f_newWdm: function (data_type) {
      this.data_type = data_type;
      // predata hazırlanma sebebi bir değişkene eşittir diğer değişken dendiğinde computed property değişiyor. Fakat datanın içerisindeki sadece bir bilgi değişince sayfada re render olmuyor. dolayısıyla önce predata hazırlayıp. asıl datamıza yani wisdom data ya eşitliyoruz aşağıda.
      let pre_data = { 'data': {}, 'date': '', 'type': this.data_type, 'reference_wdm': {} };

      let wdm_version = JSON.parse(localStorage.getItem('wdm_version'));
      let doc_key = '';
      let doc_version = '';
      let bucket = '';
      if (wdm_version.hospital.last[data_type]) {
        doc_key = wdm_version.hospital.last[data_type].key;
        doc_version = wdm_version.hospital.last[data_type].version;
        bucket = 'hospital';
      }
      if (wdm_version.wisdom.last[data_type]) {
        doc_key = wdm_version.wisdom.last[data_type].key;
        doc_version = wdm_version.wisdom.last[data_type].version;
        bucket = 'option';
      }
      if (wdm_version.user.last[data_type]) {
        doc_key = wdm_version.user.last[data_type].key;
        doc_version = wdm_version.user.last[data_type].version;
        bucket = 'hospital';
      }

      GlobalService.get_one_cb_document({ 'key': doc_key, 'bucket': bucket })
        .then(resp => {
          if (resp.data.status === 'success') {
            let option_data = resp.data.result;
            this.option_data = {};
            this.option_data[data_type] = option_data;
            for (let i in option_data.param_group.param_order) {
              pre_data['data'][option_data.param_group.param_order[i]] = {};
            }
            let wdm_key = '';
            if (option_data.owner_type === 'hospital') {
              wdm_key = 'wdm_hospital_' + option_data.parent + '_' + option_data.hospital_group + '_' + option_data.hospital_id + '_v' + option_data.version;
            } else {
              wdm_key = 'wdm_' + option_data.owner_type + '_' + option_data.parent + '_v' + option_data.version;
            }
            pre_data['reference_wdm']['version'] = option_data['version'];
            pre_data['reference_wdm']['owner_type'] = option_data['owner_type'];
            pre_data['reference_wdm']['key'] = wdm_key;
            this.wisdom_data = pre_data;
            this.show_wisdom_data_modal = true;
          } else {
            alert('error ', resp.data.message);
          }
        });
    },
    f_resetProtocolInspectionData: function () {
      this.ProtocolInspectionData = JSON.parse(JSON.stringify(this.d_tempHimsProtocolList[this.selectedProtocolNo]));
      this.showNotification({ title: 'Geri Alma İşlemi', message: 'Fatura başvuru protokolü bilgileri geri alındı.', type: 'warn', timeout: 2000 });
    },
    f_openEditMode: function (type) {
      this.d_himsInspectionEditMode ? this.d_himsInspectionEditMode = false : this.d_himsInspectionEditMode = true;
      if (this.d_himsInspectionEditMode && this.ProtocolInspectionData.manuel_edit === 0 && type === 'add') {
        this.ProtocolInspectionData.manuel_edit = 1;
        for (let i in this.ProtocolInspectionData.param_list) {
          if (this.ProtocolInspectionData.param_list[i].type === 'many') {
            if (this.ProtocolInspectionData.param_list[i]['text_list'].length > 0) {
              let last_ind = this.ProtocolInspectionData.param_list[i]['text_list'].length - 1;
              // null değerler olabildiğinden önce onları boş text haline getiriyoruz aşağıda.
              if (!this.ProtocolInspectionData[i]) {
                this.ProtocolInspectionData[i] = '';
                if (i === 'main_diagnosis' || i === 'pre_diagnosis') {
                  this.ProtocolInspectionData[i] = this.ProtocolInspectionData.param_list[i]['text_list'][last_ind].icd_code;
                  this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][i].selected = this.ProtocolInspectionData.param_list[i]['text_list'][last_ind];
                } else {
                  this.ProtocolInspectionData[i] = this.ProtocolInspectionData.param_list[i]['text_list'][last_ind].txt;
                  this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][i].selected = this.ProtocolInspectionData.param_list[i]['text_list'][last_ind];
                }
              } else { // ilgili alan bir kez doldurulmuş ise
                if (i === 'main_diagnosis' || i === 'pre_diagnosis') {
                  if (this.ProtocolInspectionData[i].indexOf(this.ProtocolInspectionData.param_list[i]['text_list'][last_ind].icd_code) === -1) {
                    this.ProtocolInspectionData[i] += ',' + this.ProtocolInspectionData.param_list[i]['text_list'][last_ind].icd_code;
                    this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][i].selected = this.ProtocolInspectionData.param_list[i]['text_list'][last_ind];
                  }
                } else {
                  if (this.ProtocolInspectionData[i].indexOf(this.ProtocolInspectionData.param_list[i]['text_list'][last_ind].txt) === -1) {
                    console.log(this.ProtocolInspectionData.param_list[i]['text_list'][last_ind].txt);
                    this.ProtocolInspectionData[i] += ' ' + this.ProtocolInspectionData.param_list[i]['text_list'][last_ind].txt;
                    this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][i].selected = this.ProtocolInspectionData.param_list[i]['text_list'][last_ind];
                  }
                }
              }
              this.ProtocolInspectionData[i] = this.ProtocolInspectionData[i].replace(/\n/g, '');
            } else {
              if (!this.ProtocolInspectionData[i]) {
                this.ProtocolInspectionData[i] = '';
              }
            }
          } else if (this.ProtocolInspectionData.param_list[i].type === 'one') {
            if (this.ProtocolInspectionData.param_list[i]['text_list'].length > 0) {
              let last_ind = this.ProtocolInspectionData.param_list[i]['text_list'].length - 1;
              this.ProtocolInspectionData[i] = this.ProtocolInspectionData.param_list[i]['text_list'][last_ind].txt;
              this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][i].selected = this.ProtocolInspectionData.param_list[i]['text_list'][last_ind];
            } else {
              if (!this.ProtocolInspectionData[i]) {
                this.ProtocolInspectionData[i] = '';
              }
            }
          }
        }
      } else { // add_edit

      }
    },
    f_deleteIcdCode: function (param, icd) {
      let arr = this.ProtocolInspectionData[param].split(',');
      let new_arr = [];
      for (let i in arr) {
        if (arr[i] !== icd) {
          new_arr.push(arr[i]);
        }
      }
      this.ProtocolInspectionData[param] = new_arr.join(',');
    },
    f_addThisSelection: function (param) {
      if (param === 'pre_diagnosis' || param === 'main_diagnosis') {
        if (!this.ProtocolInspectionData[param]) {
          this.ProtocolInspectionData[param] = this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param].selected.icd_code;
        } else {
          if (this.ProtocolInspectionData[param].indexOf(this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param].selected.icd_code) === -1) {
            this.ProtocolInspectionData[param] += ',' + this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param].selected.icd_code;
          } else {
            alert('Bu veri ilgili alanda ekli görünmektedir.');
          }
        }
      } else {
        if (!this.ProtocolInspectionData[param]) {
          this.ProtocolInspectionData[param] = this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param].selected.txt;
        } else {
          if (this.ProtocolInspectionData[param].indexOf(this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param].selected.txt) === -1) {
            this.ProtocolInspectionData[param] += ' ' + this.WebserviceProtocolInsuranceData['MLPCARE']['parameter_list'][param].selected.txt;
          } else {
            alert('Bu veri ilgili alanda ekli görünmektedir.');
          }
        }
      }
    },
    SendOriginalInspectionOnWebService: function (SendingWisdomData, FirstPrepare, DataType) {
      let TempInspectionData = JSON.parse(JSON.stringify(this.ProtocolInspectionData));
      if (!this.patient.diagnosis || this.patient.diagnosis.length === 0) {
        let msg = 'Hastaya ekli tanı tespit edilemedi.';
        let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Verinin webservis üzerinden protokol muayene bilgilerine gönderilme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      } else {
        TempInspectionData.pre_diagnosis = this.GetPatientDiagnosisListWithComma(this.patient);
        TempInspectionData.main_diagnosis = this.GetPatientDiagnosisListWithComma(this.patient);
      }
      if (!TempInspectionData.symptom_start_date) {
        let msg = 'Semptom başlama tarihi dolu olmalıdır.';
        let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Protokol muayene kaydı hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (!TempInspectionData.care_plan && !TempInspectionData.story) {
        let msg = 'Öykü ya da bakım planı dolu olmalıdır.';
        let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Protokol muayene kaydı hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let InspectionId = '0';
      if (TempInspectionData.id) {
        InspectionId = TempInspectionData.id;
      }
      if (this.patient.hospital_group === 'MLPCARE') {
        for (let i in TempInspectionData) {
          if (this.WebserviceProtocolInsuranceData['MLPCARE'][i] === undefined) {
            this.$delete(TempInspectionData, i);
          }
          if (TempInspectionData[i] === null) {
            TempInspectionData[i] = '';
          }
        }
        // console.log('TempInspectionData :::', TempInspectionData);
        let data = {
          'resourceId': this.SelectedProtocolDetails.resource_id,
          'InspectionId': InspectionId,
          'protocolNo': this.selectedProtocolNo,
          'hospitalGroup': this.patient.hospital_group,
          'hospitalCode': this.patient.hospital_id,
          'patientId': this.SelectedProtocolDetails.patient_id,
          'SendingJsonData': TempInspectionData
        };
        // console.log(data);
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Muayene kaydı yapılıyor.' } });
        WebService.SendProtocolInspectionOriginalData(data).
        then(resp => {
          console.log(resp.data);
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        });
      }
    },
    GetPatientDiagnosisListWithComma: function (patientData) {
      let diagnosisList = [];
      for (let i in patientData.diagnosis) {
        diagnosisList.push(patientData.diagnosis[i].value);
      }
      return diagnosisList.join(',');
    },
    PatientAllProtocolList: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kapalı protokolleri listeleniyor.' } });
      this.CloseProtocolList = {};
      if (this.patient.hospital_group === 'MLPCARE') {
        let hospitalCode = this.patient.hospital_id;
        if (this.patient.hospitals && this.patient.hospitals['MLPCARE'] && this.patient.hospitals['MLPCARE'][hospitalCode] && this.patient.hospitals['MLPCARE'][hospitalCode].webservice === 1 && this.patient.hospitals['MLPCARE'][hospitalCode].data.patient_no) {
          let query =
            'HospitalGroup=' + this.user.hospital_group + '&' +
            'hospitalCode=' + hospitalCode + '&' +
            'patientNo=' + this.patient.hospitals['MLPCARE'][hospitalCode].data.patient_no;
          WebService.getAllProtocolList(query)
            .then(resp => {
              // console.log(resp.data);
              if (resp.data.status === 'success') {
                this.d_allProtocolList = resp.data.result;
                this.f_createCloseProtocolList();
                this.$store.commit('PatientWebserviceProtocolListAll', resp.data.result);
              } else {
                console.log('Error: ', resp.data.result);
              }
              this.$forceUpdate();
              this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            });
        }
      }
    },
    f_createCloseProtocolList: function () {
      this.CloseProtocolList = {};
      for (let i in this.d_allProtocolList) {
        if ([1, 2].indexOf(this.d_allProtocolList[i].state) === -1) {
          if (!this.CloseProtocolList[this.d_allProtocolList[i].branch_id]) {
            this.CloseProtocolList[this.d_allProtocolList[i].branch_id] = {
              'protocols': {},
              'dates': [],
              'department_name': this.d_allProtocolList[i].department_name
            };
          }
          this.CloseProtocolList[this.d_allProtocolList[i].branch_id]['protocols'][this.d_allProtocolList[i].open_date] = this.d_allProtocolList[i];
          this.CloseProtocolList[this.d_allProtocolList[i].branch_id]['protocols'][this.d_allProtocolList[i].open_date].rule = this.d_allProtocolList[i].rule ? 1 : 0;
          if (this.CloseProtocolList[this.d_allProtocolList[i].branch_id]['dates'].indexOf(this.d_allProtocolList[i].open_date) === -1) {
            this.CloseProtocolList[this.d_allProtocolList[i].branch_id]['dates'].push(this.d_allProtocolList[i].open_date);
          }
          this.CloseProtocolList[this.d_allProtocolList[i].branch_id]['dates'].sort((a, b) => b - a);
        }
      }
    },
    SelectThisProtocol: function (openOrClosed, protocol_index, protocolDetails, protocolNo, doctor, department_name) {
      this.d_himsInspectionEditMode = false;
      this.d_selectedDoctor = doctor;
      this.d_selectedDepartmentName = department_name;
      this.openOrClosedProtocol = openOrClosed;
      if (openOrClosed === 'closed') {
        this.d_himsInspectionEditMode = false;
      }
      this.selectedProtocolNo = protocolNo;
      this.SelectedProtocolInd = protocol_index;
      this.SelectedProtocolDetails = JSON.parse(JSON.stringify(protocolDetails));
      this.SelectedNavRightItem = 'protocol_inspection_details';
      this.f_selectInspectionOfProtocol();
    },
    f_selectInspectionOfProtocol: function () {
      this.ProtocolInspectionData = {};
      this.SelectedNavRightItem = 'protocol_inspection_details';
      this.d_expandAllCollapsed = true;
      // Kullanıcının aktif düzenleyebileceği protokolleri listesinde var mı önce onu kontrol ediyoruz.
      if (this.hims_protocol_list && this.hims_protocol_list[this.selectedProtocolNo]) {
        this.ProtocolInspectionData = this.hims_protocol_list[this.selectedProtocolNo];
        this.$forceUpdate();
      } else {
        // Açık olsa bile kullanıcının düzenleyebileceği protokolleri listesinde yok ise
        let query = 'protocolNo=' + this.selectedProtocolNo + '&' + 'hospitalGroup=' + this.patient.hospital_group + '&' + 'hospitalCode=' + this.patient.hospital_id;
        if (!this.w_presentation) {
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.selectedProtocolNo + ' nolu protokole ait muayene bilgileri getiriliyor.' } });
        } else {
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'SUNUM SEBEBİYLE GİZLİ' + ' nolu protokole ait muayene bilgileri getiriliyor.' } });
        }
        WebService.GetInspectionOfThisProtocol(query)
          .then(resp => {
            // console.log(resp.data);
            if (resp.data.status === 'success') {
              if (resp.data.result.length > 0) {
                this.ProtocolInspectionData = resp.data.result[0];
                for (let k in this.ProtocolInspectionData) {
                  if (this.ProtocolInspectionData[k] === null) {
                    this.ProtocolInspectionData[k] = '';
                  }
                }
              }
            } else {
              console.log('Error: ', resp.data.result);
            }
            this.$forceUpdate();
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          });
      }
    },
    f_calculateStyleProtocolType (type_id) {
      if (this.patient.hospital_group === 'MLPCARE') {
        if (type_id === 0) {
          return 'color: red; font-weight: bold;';
        } else if (type_id === 1) {
          return 'color: green; font-weight: bold;';
        } else if (type_id === 2) {
          return 'color: #b3246c; font-weight: bold;';
        } else if (type_id === 3) {
          return 'color: red; font-weight: bold;';
        } else if (type_id === 4) {
          return 'color: blue; font-weight: bold;';
        } else if (type_id === 5) {
          return 'color: black; font-weight: bold;';
        }
      }
    },
    CalculateProtocolType: function (type_id) {
      if (this.patient.hospital_group === 'MLPCARE') {
        if (type_id === 0) {
          return 'Diğer';
        } else if (type_id === 1) {
          return 'Poliklinik';
        } else if (type_id === 2) {
          return 'Yatan Hasta';
        } else if (type_id === 3) {
          return 'Acil';
        } else if (type_id === 4) {
          return 'Günübirlik';
        } else if (type_id === 5) {
          return 'TempInpatient';
        }
      }
    }
  },
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  },
  watch: {}
}

</script>

