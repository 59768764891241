<template>
  <div>
    <b-card no-body bg-variant="secondary" text-variant="dark" :class="[d_fullScreen ? 'full-screen-mode' : 'normal-mode']">
      <b-card-header header-bg-variant="secondary" class="p-1">
        <b-row>
          <b-col cols="6">
            <img src="@/icon/3163497.png" :title="$t('wdm16.6589')" style="width: 2em;" /> Zaman Çizelgesi Görünümü
          </b-col>
          <b-col cols="6">
            <b-button class="pull-right" size="md" :variant="d_fullScreen ? 'warning' : 'secondary'" :style="d_fullScreen ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">
              <img src="@/icon/629153.png" :title="$t('wdm16.6589')" style="width: 2em;" />
            </b-button>
            <b-button class="pull-right" size="md" :variant="d_wdmrDateAdjustMode ? 'warning' : 'secondary'" :style="d_wdmrDateAdjustMode ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_wdmrDateAdjustMode ? d_wdmrDateAdjustMode = false : d_wdmrDateAdjustMode = true">
              <img src="@/icon/2301012.png" title="Tarih Düzenleme Modu" style="width: 2em;" />
            </b-button>
            <b-button class="pull-right" size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="f_scrollToToday()">
              <img src="@/icon/3121596.png" title="Bugüne Git" style="width: 2em;" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-header v-if="d_wdmrDateAdjustMode" header-bg-variant="secondary" class="p-1">
        <b-row>
          <b-col cols="12">
            <b-button size="md" title="Kaydet" variant="secondary" @click="f_saveDateAdjust()" style="padding: 1px; margin-right: 5px;" class="pull-right">
              <img src="@/icon/2087726.png" style="width: 2em;" /> Kaydet
            </b-button>
            <b-button size="md" title="Reset" variant="secondary" @click="f_createDateAdjustData()" style="padding: 1px; margin-right: 5px;" class="pull-right">
              <img src="@/icon/3012385.png" style="width: 2em;" /> Reset
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <div ref="timeline_general_div" :style="f_fullScreenMode()">
        <table>
          <thead>
            <tr>
              <th style="text-align: center; background-color: #ececec; min-width: 150px; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8;">
                <img src="@/icon/2958560.png" class="img-rounded img-responsive" style="width: 2em;" />
              </th>
              <template v-for="(col, col_ind) in timeline_data[0]">
                <th v-if="f_controlTodayIntervalForAddNewColumn(col_ind, 'before')" style="background-color: #88bcbb;">
                </th>
                <th v-if="col_ind !== 0" :style="f_calculateYearStyle(col_ind)">
                  {{ col[0]['date'] ? DateFormat(col[0]['date'], 'year') : '' }}
                </th>
                <th v-if="f_controlTodayIntervalForAddNewColumn(col_ind, 'after')" style="background-color: #88bcbb;">
                </th>
              </template>
            </tr>
            <tr>
              <th style="text-align: center; background-color: #ececec; min-width: 150px; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8;">
                <img src="@/icon/1585563.png" class="img-rounded img-responsive" style="width: 2em;" />
              </th>
              <template v-for="(col, col_ind) in timeline_data[0]">
                <th v-if="f_controlTodayIntervalForAddNewColumn(col_ind, 'before')" style="background-color: #88bcbb;">
                </th>
                <th v-if="col_ind !== 0" :style="f_calculateMonthStyle(col_ind)">
                  {{ col[0]['date'] ? DateFormat(col[0]['date'], 'month') : '' }}
                </th>
                <th v-if="f_controlTodayIntervalForAddNewColumn(col_ind, 'after')" style="background-color: #88bcbb;">
                </th>
              </template>
            </tr>
            <tr>
              <th style="position: sticky; z-index: 1000; top: 0; text-align: center; background-color: #ececec; min-width: 150px; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8;">
                <img src="@/icon/719135.png" class="img-rounded img-responsive" style="width: 2em;" />
              </th>
              <template v-for="(col, col_ind) in timeline_data[0]">
                <th ref="timeline_today" v-if="f_controlTodayIntervalForAddNewColumn(col_ind, 'before')" style="position: sticky; z-index: 1000; top: 0; background-color: #88bcbb; min-width: 10px;">
                  bugün
                </th>
                <th :ref="f_calculateTodayRef(col_ind)" v-if="col_ind !== 0" style="position: sticky; z-index: 1000; top: 0; white-space: nowrap; text-align: center; border-right: solid 1px #c3d8d8; border-bottom: solid 1px #c3d8d8; background-color: #ececec; min-width: 200px;">
                  {{ col[0]['date'] ? DateFormat(col[0]['date']) : '' }}
                  <template v-if="d_wdmrDateAdjustMode && col[0]['date'] && d_bulkDateAdjustDataForCol[col_ind.toString()]">
                    <b-row>
                      <b-col cols="8" style="padding-right: 1px">
                        <datepicker class="form-control" style="background-color: transparent;" name="delay_data_new_date" v-model="d_bulkDateAdjustDataForCol[col_ind.toString()].date" @input="f_updateDayDatepickerAndNumber(col[0]['date'].split('T')[0], col_ind.toString(), 'number')"></datepicker>
                      </b-col>
                      <b-col cols="4" style="padding-left: 1px">
                        <b-form-input type="number" class="form-control-warning" @keydown="InputControl.input_filter($event, 'delay')" @input="f_updateDayDatepickerAndNumber(col[0]['date'].split('T')[0], col_ind.toString(), 'datepicker')" v-model="d_bulkDateAdjustDataForCol[col_ind.toString()].date_interval"></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-button @click="f_updateFutureDatesIntervals(col_ind, d_bulkDateAdjustDataForCol[col_ind.toString()].date_interval)">sonraki günlere de uygula</b-button>
                      </b-col>
                    </b-row>
                  </template>
                </th>
                <th ref="timeline_today" v-if="f_controlTodayIntervalForAddNewColumn(col_ind, 'after')" style="position: sticky; z-index: 1000; top: 0; background-color: #88bcbb;">
                  bugün
                </th>
              </template>
            </tr>
          </thead>
          <tbody>
            <template v-for="(row, row_ind) in timeline_data">
              <!-- <tr v-if="row_ind !== 0" style="border-bottom: solid 1px #00968852; border-top: solid 1px #00968852;"> -->
              <tr v-if="row_ind !== 0" :style="'border-bottom: solid 1px ' + row[0][0]['background_color'] + '; border-top: solid 1px ' + row[0][0]['background_color']">
                <template v-for="(col, col_ind) in row">
                  <td v-if="f_controlTodayIntervalForAddNewColumn(col_ind, 'before')" style="background-color: #88bcbb;">
                  </td>
                  <td v-if="col_ind === 0" :style="f_calculateCellStyle(col_ind, col, row)">
                    <div style="min-height: 50px;">
                      <i class="fa fa-database"></i> <strong>{{ col[0]['label'] }}</strong>
                      <template v-if="d_wdmrDateAdjustMode">
                        <b-row>
                          <b-col cols="12" style="padding-left: 1px">
                            <b-form-input type="number" class="form-control-warning" v-model="d_bulkDateAdjustDataForRow[row_ind.toString()]"></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12">
                            <b-button @click="f_updateRowIntervals(row_ind, d_bulkDateAdjustDataForRow[row_ind.toString()])"> Bu data modelinin tamamına uygula</b-button>
                          </b-col>
                        </b-row>
                      </template>
                    </div>
                  </td>
                  <td v-else :style="f_calculateCellStyle(col_ind, col, row)">
                    <template v-for="(x, x_ind) in col">
                      <div :style="d_wdmrDateAdjustMode && p_wdmrDateAdjustData.data[x.value] ? (parseInt(p_wdmrDateAdjustData.data[x.value].date_interval) === 0 ? 'border: dotted 3px red;' : 'border: solid 5px green;') : ''">
                        <b-row v-if="d_wdmrDateAdjustMode && p_wdmrDateAdjustData.data[x.value] && f_userHasPermissionToEditThisModel(x.department, x.type)">
                          <b-col cols="8" style="padding-right: 1px">
                            <datepicker class="form-control" style="background-color: transparent;" name="delay_data_new_date" v-model="p_wdmrDateAdjustData.data[x.value].date" @input="f_updateDatepickerAndNumber(x.date.split('T')[0], x.value, 'number')"></datepicker>
                          </b-col>
                          <b-col cols="4" style="padding-left: 1px">
                            <b-form-input type="number" class="form-control-warning" @keydown="InputControl.input_filter($event, 'delay')" @input="f_updateDatepickerAndNumber(x.date.split('T')[0], x.value, 'datepicker')" v-model="p_wdmrDateAdjustData.data[x.value].date_interval"></b-form-input>
                          </b-col>
                        </b-row>
                        <div class="wdmr-class" :style="f_valueStyle(col_ind, x, row)" @click="f_getWdmAndWdmr(x)" :title="x['label'] ? x['label'] : row[0][0]['label']">
                          {{ x['label'] ? f_showThisChars(x['label'], 100) : row[0][0]['label'] }}
                        </div>
                      </div>
                    </template>
                  </td>
                  <td v-if="f_controlTodayIntervalForAddNewColumn(col_ind, 'after')" style="background-color: #88bcbb;">
                  </td>
                </template>
              </tr>
              <div style="width: 100%; height: 5px;"></div>
            </template>
          </tbody>
        </table>
      </div>
    </b-card>
    <!--     <b-modal v-model="d_showWdmModal" :title="d_wdmrData.data.label ? d_wdmrData.data.label : d_wdmrData.data.date" centered>
        <wisdom-data-show :change="change" :view_mode="d_wdmrData.view_mode" :data="d_wdmrData.data" :data_type="d_wdmrData.type" :option_data="d_wdmrData.option_data"></wisdom-data-show>
    </b-modal>
 -->
    <modal v-if="d_showWdmModal" @close="d_showWdmModal = false" :width="'750'">
      <h3 slot="header">
        {{ d_wdmrData.data.label ? d_wdmrData.data.label : d_wdmrData.data.date }}
      </h3>
      <div slot="body">
        <wisdom-data-show :change="change" :view_mode="d_wdmrData.view_mode" :data="d_wdmrData.data" :data_type="d_wdmrData.type" :option_data="d_wdmrData.option_data"></wisdom-data-show>
      </div>
      <div slot="footer">
        <b-button variant="success" @click="d_showWdmModal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import GlobalService from "@/services/global";
import WdmService from "@/services/wdm";
import { DateFunctions } from "@/services/public/date_functions";
import WisdomDataService from "@/services/wisdom_data";
import {
  default as WisdomDataShow
} from "@/components/widgets/WisdomDataShow";
import {
  default as Modal
} from "@/components/widgets/Modal";
import moment from 'moment';
import { mapGetters } from 'vuex';
import Datepicker from 'wisdom-vue-datepicker';
import { InputControl } from '@/services/public/functions';

export default {
  name: 'TimeLine',
  computed: mapGetters({
    lang: 'lang',
    StoreLoading: 'loading',
    patient: 'patient_selected'
  }),
  components: {
    Modal,
    Datepicker,
    WisdomDataShow
  },
  props: {
    timeline_data: {
      type: Array,
      required: true
    },
    year_month_mode: {
      type: Object,
      required: true
    },
    p_wdmrDateAdjustData: {
      type: Object,
      required: false,
      default: () => ({ data: {} })
    },
    p_drugAdjustMode: {
      type: Boolean,
      required: false
    },
    mother_children: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      InputControl: InputControl,
      d_fullScreen: false,
      d_wdmrDateAdjustMode: false,
      change: "0",
      d_showWdmModal: false,
      d_wdmrData: { 'view_mode': 'table', 'type': '', 'data': {}, 'option_data': {} },
      d_bulkDateAdjustDataForCol: {},
      d_bulkDateAdjustDataForRow: {}
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.f_createDateAdjustData();
  },
  methods: {
    f_calculateTodayRef: function (col_ind) {
      if (col_ind > 0) {
        let cell_date = this.timeline_data[0][col_ind][0]['date'];
        if (DateFunctions.day_dif(cell_date, 'today') === 0) {
          return 'timeline_today';
        } else {
          return 'timeline_not_today';
        }
      }
    },
    f_scrollToToday: function () {
      let element = this.$refs['timeline_today'][0];
      let left = element.offsetLeft;
      this.$refs['timeline_general_div'].scrollLeft = parseInt(element.offsetLeft);
    },
    f_userHasPermissionToEditThisModel: function (department_value, data_type) {
      for (let i in this.user.position_department_title) {
        if (this.user.position_department_title[i].active && this.user.position_department_title[i].department.value === department_value && this.user.position_department_title[i].wisdom_data_model && this.user.position_department_title[i].wisdom_data_model[data_type] && this.user.position_department_title[i].wisdom_data_model[data_type].permission && this.user.position_department_title[i].wisdom_data_model[data_type].permission.indexOf('record_add_edit') !== -1) {
          return true;
        }
      }
      return false;
    },
    f_saveDateAdjust: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Tarih düzenleme işlemi gerçekleştiriliyor.' } });
      let data = { 'list': [] };
      for (let i in this.p_wdmrDateAdjustData.data) {
        if (this.p_wdmrDateAdjustData.data[i].date_interval !== 0) {
          data.list.push({ 'wdmr_key': i, 'date_interval': this.p_wdmrDateAdjustData.data[i].date_interval });
        }
      }
      if (data.list.length > 0) {
        WdmService.date_adjust(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.f_motherChildren('get_patient_records');
              this.d_wdmrDateAdjustMode = false;
              this.$store.commit('Loading', { 'status': false, 'data': {} });
            } else {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              this.d_wdmrDateAdjustMode = false;
              alert('error ', resp.data.message);
            }
          });
      } else {
        this.d_wdmrDateAdjustMode = false;
        this.$store.commit('Loading', { 'status': false, 'data': {} });
      }
    },
    f_motherChildren: function (op) {
      if (this.mother_children[op] === 0) {
        this.mother_children[op] = 1;
      } else {
        this.mother_children[op] = 0;
      }
    },
    f_calculateMinMaxDatepicker: function (source_date, date_interval) {
      return moment(source_date).add(date_interval, 'days').format('YYYY-MM-DD')
    },
    f_updateDatepickerAndNumber: function (x_date, x_value, aim) {
      if (aim === 'number') {
        this.p_wdmrDateAdjustData.data[x_value].date_interval = DateFunctions.day_dif(x_date, this.p_wdmrDateAdjustData.data[x_value].date);
      } else if (aim === 'datepicker') {
        this.p_wdmrDateAdjustData.data[x_value].date = moment(x_date).add(parseInt(this.p_wdmrDateAdjustData.data[x_value].date_interval), 'days').format('YYYY-MM-DD');
      }
      this.$forceUpdate();
    },
    f_updateFutureDatesIntervals: function (col_ind, interval) {
      for (let c_index = col_ind; c_index <= this.timeline_data[0].length; c_index++) {
        if (this.d_bulkDateAdjustDataForCol[c_index.toString()]) {
          this.d_bulkDateAdjustDataForCol[c_index.toString()].date_interval = interval;
          this.f_updateDayDatepickerAndNumber(this.timeline_data[0][c_index][0]['date'].split('T')[0], c_index, 'datepicker');
        }
      }
    },
    f_updateRowIntervals: function (row_ind, interval) {
      for (let wdmr_key in this.p_wdmrDateAdjustData.data) {
        if (row_ind.toString() === this.p_wdmrDateAdjustData.data[wdmr_key].row_ind.toString()) {
          this.p_wdmrDateAdjustData.data[wdmr_key].date_interval = this.d_bulkDateAdjustDataForRow[row_ind.toString()];
          let c_index = this.p_wdmrDateAdjustData.data[wdmr_key].col_ind;
          let col_date = this.timeline_data[0][c_index][0]['date'].split('T')[0];
          this.f_updateDatepickerAndNumber(col_date, wdmr_key, 'datepicker')
        }
      }
    },
    f_updateDayDatepickerAndNumber: function (col_date, col_ind, aim) {
      if (aim === 'number') {
        this.d_bulkDateAdjustDataForCol[col_ind.toString()].date_interval = DateFunctions.day_dif(col_date, this.d_bulkDateAdjustDataForCol[col_ind.toString()].date);
      } else if (aim === 'datepicker') {
        this.d_bulkDateAdjustDataForCol[col_ind.toString()].date = moment(col_date).add(parseInt(this.d_bulkDateAdjustDataForCol[col_ind.toString()].date_interval), 'days').format('YYYY-MM-DD');
      }
      for (let wdmr_key in this.p_wdmrDateAdjustData.data) {
        if (col_ind.toString() === this.p_wdmrDateAdjustData.data[wdmr_key].col_ind.toString()) {
          this.p_wdmrDateAdjustData.data[wdmr_key].date_interval = this.d_bulkDateAdjustDataForCol[col_ind.toString()].date_interval;
          this.p_wdmrDateAdjustData.data[wdmr_key].date = this.d_bulkDateAdjustDataForCol[col_ind.toString()].date;
        }
      }
      this.$forceUpdate();
    },
    f_createDateAdjustData: function () {
      this.p_wdmrDateAdjustData.data = {};
      this.d_bulkDateAdjustDataForCol = {};
      this.d_bulkDateAdjustDataForRow = {};
      for (let r_index in this.timeline_data) {
        this.d_bulkDateAdjustDataForRow[r_index.toString()] = 0;
        for (let c_index in this.timeline_data[r_index]) {
          for (let i in this.timeline_data[r_index][c_index]) {
            let wdmr_data = this.timeline_data[r_index][c_index][i];
            if (wdmr_data.wdm_key && wdmr_data.value && this.f_userHasPermissionToEditThisModel(wdmr_data.department, wdmr_data.type)) {
              this.p_wdmrDateAdjustData.data[wdmr_data.value] = { 'date': wdmr_data.date.split('T')[0], 'date_interval': 0, 'col_ind': c_index, 'row_ind': r_index };
              if (this.timeline_data[0][c_index][0]['date']) {
                this.d_bulkDateAdjustDataForCol[c_index.toString()] = { 'date': this.timeline_data[0][c_index][0]['date'].split('T')[0], 'date_interval': 0 };
              }
              if (false && this.p_drugAdjustMode) {
                try {
                  this.p_wdmrDateAdjustData.data[wdmr_data.value].wdmr_connection = wdmr_data.wdmr_connection;
                } catch (err) {}
                try {
                  this.p_wdmrDateAdjustData.data[wdmr_data.value].is_eligible_forward = wdmr_data.is_eligible_forward;
                } catch (err) {}
                try {
                  this.p_wdmrDateAdjustData.data[wdmr_data.value].forward_one = wdmr_data.forward_one;
                  this.p_wdmrDateAdjustData.data[wdmr_data.value].forward_one_datepicker = this.f_calculateMinMaxDatepicker(wdmr_data.date.split('T')[0], wdmr_data.forward_one);
                } catch (err) {}
                try {
                  this.p_wdmrDateAdjustData.data[wdmr_data.value].forward_all = wdmr_data.forward_all;
                  this.p_wdmrDateAdjustData.data[wdmr_data.value].forward_all_datepicker = this.f_calculateMinMaxDatepicker(wdmr_data.date.split('T')[0], wdmr_data.forward_all);
                } catch (err) {}
                try {
                  this.p_wdmrDateAdjustData.data[wdmr_data.value].is_eligible_backward = wdmr_data.is_eligible_backward;
                } catch (err) {}
                try {
                  this.p_wdmrDateAdjustData.data[wdmr_data.value].backward_one = wdmr_data.backward_one;
                  this.p_wdmrDateAdjustData.data[wdmr_data.value].backward_one_datepicker = this.f_calculateMinMaxDatepicker(wdmr_data.date.split('T')[0], wdmr_data.backward_one);
                } catch (err) {}
                try {
                  this.p_wdmrDateAdjustData.data[wdmr_data.value].backward_all = wdmr_data.backward_all;
                  this.p_wdmrDateAdjustData.data[wdmr_data.value].backward_all_datepicker = this.f_calculateMinMaxDatepicker(wdmr_data.date.split('T')[0], wdmr_data.backward_all);
                } catch (err) {}
              }
            }
          }
        }
      }
    },
    f_controlTodayIntervalForAddNewColumn: function (col_ind, type) {
      let today = new Date();
      today = today.toISOString();
      today = moment(today, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD");
      if (col_ind === 0) {
        // before the first data included cell.....
        let cell_date = this.timeline_data[0][col_ind][0]['date'];
        if (DateFunctions.day_dif(cell_date, 'today') < 0 && type === 'before') {
          return true;
        }
      } else if (col_ind === this.timeline_data[0].length - 1) {
        let cell_date = this.timeline_data[0][col_ind][0]['date'];
        let previous_date = this.timeline_data[0][col_ind - 1][0]['date'];
        if (DateFunctions.day_dif(previous_date, 'today') > 0 && DateFunctions.day_dif(cell_date, 'today') < 0 && type === 'before') {
          return true;
        }
        if (DateFunctions.day_dif(cell_date, 'today') > 0 && type === 'after') {
          return true;
        }
      } else {
        if (this.timeline_data[0].length >= 3 && this.timeline_data[0][col_ind][0] && this.timeline_data[0][col_ind][0]['date']) {
          let cell_date = this.timeline_data[0][col_ind][0]['date'];
          let previous_date = this.timeline_data[0][col_ind - 1][0]['date'];
          let next_date = this.timeline_data[0][col_ind + 1][0]['date'];
          if (DateFunctions.day_dif(previous_date, 'today') > 0 && DateFunctions.day_dif(cell_date, 'today') < 0 && type === 'before') {
            return true;
          }
        }
      }
      return false;
    },
    f_calculateYearStyle: function (col_ind) {
      let style = 'position: sticky; top: 0; white-space: nowrap; text-align: center; border-bottom: solid 1px #c3d8d8; background-color: #ececec; min-width: 200px; ';
      if (col_ind === 0) {
        return style + 'border-right: solid 1px #c3d8d8; ';
      } else {
        // If we did not arrive to the last index yet.
        if (col_ind !== this.timeline_data[0].length - 1) {
          if (this.timeline_data[0][col_ind][0] && this.timeline_data[0][col_ind][0]['date']) {
            let this_date = this.timeline_data[0][col_ind][0]['date'];
            let next_date = this.timeline_data[0][col_ind + 1][0]['date'];
            if (this_date.split('-')[0] === next_date.split('-')[0]) {
              return style;
            } else {
              return style + 'border-right: solid 1px #c3d8d8; ';
            }
          }
        }
      }
      return style + 'border-right: solid 1px #c3d8d8; ';
    },
    f_calculateMonthStyle: function (col_ind) {
      let style = 'position: sticky; top: 0; white-space: nowrap; text-align: center; border-bottom: solid 1px #c3d8d8; background-color: #ececec; min-width: 200px; ';
      if (col_ind === 0) {
        return style + 'border-right: solid 1px #c3d8d8; ';
      } else {
        // If we did not arrive to the last index yet.
        if (col_ind !== this.timeline_data[0].length - 1) {
          if (this.timeline_data[0][col_ind][0] && this.timeline_data[0][col_ind][0]['date']) {
            let this_date = this.timeline_data[0][col_ind][0]['date'];
            let next_date = this.timeline_data[0][col_ind + 1][0]['date'];
            if (this_date.split('-')[1] === next_date.split('-')[1]) {
              return style;
            } else {
              return style + 'border-right: solid 1px #c3d8d8; ';
            }
          }
        }
      }
      return style + 'border-right: solid 1px #c3d8d8; ';
    },
    f_showThisChars: function (text, count) {
      let str = '';
      if (text) {
        text = text.toString();
        let x = text.length > count ? count : text.length;
        for (let i = 0; i < x; i++) {
          if (text[i]) {
            str += text[i];
          } else {
            str += '.';
          }
        }
        if (text.length > count) {
          str += '...';
        }
      }
      return str;
    },
    f_getWdmAndWdmr: function (cell_data) {
      let data = {
        'type': cell_data.type,
        'patient_id': this.patient.id,
        'wdm': {
          'key': cell_data.wdm_key,
          'bucket': cell_data.bucket
        },
        'wdmr': {
          'key': cell_data.value,
          'bucket': 'wisdom',
          'auto': cell_data.auto ? 'yes' : 'no'
        }
      };
      if (cell_data.treatment) {
        data['wdmr']['treatment'] = cell_data.treatment
      }
      WisdomDataService.get_wdmr_and_its_wdm(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            // console.log(resp.data.result);
            this.d_wdmrData.option_data = resp.data.result.wdm;
            this.d_wdmrData.data = resp.data.result.wdmr;
            this.d_showWdmModal = true;
          } else {
            alert('error ', resp.data.message);
          }
        });
    },
    f_fullScreenMode: function () {
      if (this.d_fullScreen) {
        let height = window.innerHeight - 33;
        return 'overflow-y: auto; height: ' + height.toString() + 'px;';
      } else {
        return 'overflow-y: auto; height: 500px;';
      }
    },
    f_valueStyle: function (col_ind, x, row) {
      let border = '';
      if (col_ind === row.length - 1) {
        border = 'border-right: solid 1px #c1c7cc;';
      }
      if (x['label'] || x['value']) {
        return 'border-radius: 5px 5px; margin: 3px; cursor:pointer; padding: 3px; border-right: solid 1px #c1c7cc; background-color: ' + row[0][0]['background_color'] + '; ' + border;
      } else {
        return 'border-radius: 5px 5px; margin: 3px; cursor:pointer; padding: 3px;' + border;
      }

    },
    f_calculateCellStyle: function (col_ind, col, row) {
      let x = '';
      if (col_ind === row.length - 1) {
        x = 'border-right: solid 1px #c1c7cc;';
      }
      if (col_ind === 0) {
        return 'position: sticky; left: 0; padding: 3px; border-right: solid 1px #c1c7cc; background-color: ' + row[0][0]['background_color'];
      } else {
        let cell_date = this.timeline_data[0][col_ind][0]['date'];
        if (DateFunctions.day_dif(cell_date, 'today') === 0) {
          return 'padding: 3px; border-right: solid 1px #c1c7cc; background-color: #88bcbb;';
        } else {
          let is_future = false;
          if (this.timeline_data[0][col_ind] && this.timeline_data[0][col_ind][0] && this.timeline_data[0][col_ind][0]['date']) {
            is_future = DateFunctions.day_dif(cell_date, 'today') < 0 ? true : false;
          }
          if (is_future) {
            return 'padding: 3px; border-right: solid 1px #c1c7cc; background-color: #76efef33;';
          }
        }
      }
    },
    DateFormat: function (date, type = false) {
      if (date) {
        if (!type) {
          if (date.indexOf('T') !== -1) {
            return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm");
          } else {
            return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
          }
        } else if (type === 'year') {
          return date.split('-')[0];
        } else if (type === 'month') {
          let months = {
            '01': { 'translation': { 'en': 'january', 'tr': 'ocak' } },
            '02': { 'translation': { 'en': 'february', 'tr': 'şubat' } },
            '03': { 'translation': { 'en': 'march', 'tr': 'mart' } },
            '04': { 'translation': { 'en': 'april', 'tr': 'nisan' } },
            '05': { 'translation': { 'en': 'may', 'tr': 'mayıs' } },
            '06': { 'translation': { 'en': 'june', 'tr': 'haziran' } },
            '07': { 'translation': { 'en': 'july', 'tr': 'temmuz' } },
            '08': { 'translation': { 'en': 'august', 'tr': 'ağustos' } },
            '09': { 'translation': { 'en': 'september', 'tr': 'eylül' } },
            '10': { 'translation': { 'en': 'october', 'tr': 'ekim' } },
            '11': { 'translation': { 'en': 'november', 'tr': 'kasım' } },
            '12': { 'translation': { 'en': 'december', 'tr': 'aralık' } }
          };
          // console.log(date.split('-')[1]);
          let m = date.split('-')[1];
          m = m.length === 1 ? '0' + m : m;
          return months[m].translation.tr;
        }
      } else {
        return "";
      }
    }
  },
  watch: {
    'timeline_data': function () {
      this.f_createDateAdjustData();
    }
  }
};

</script>

<style type="text/css">
.cell-class:hover {
  border: solid 1px rgb(233, 253, 233);
  box-shadow: 2px 2px rgb(233, 253, 233);
}

.wdmr-class:hover {
  box-shadow: 0px 0px 4px 4px rgb(233, 253, 233);
}

.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

