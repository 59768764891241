<template>
  <div style="padding-left: 15px; padding-right: 15px; font-size: 12px;">
    <b-card class="card-accent-primary" header-tag="header" v-if="only_protocol">
      <b-row>
        <b-col cols="6">
          <b-button variant="success" @click="print()"><i class="fa fa-print"></i>Yazdır</b-button>
        </b-col>
        <b-col cols="6">
          <b-form-checkbox class="pull-right" :plain="true" v-model="detailed_view">Detaylı görünüm</b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>
    <b-card ref="report_here" header-tag="header">
      <template v-if="view_protocol === 'macro' || view_protocol === 'micro'">
        <b-row>
          <b-col cols="12">
            <logo-view :header_type="'chemotherapy_protocol_scheme'" :view_mode="'normal'"></logo-view>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <patient-data :p_protocolName="d_protocolName" :p_treatment="treatment_data" :p_createdByName="d_protocolCreatorName" :patient_data="patient_data"></patient-data>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col cols="12">
            <span style="font-size: 15px"><strong>Tanı:</strong> {{ treatment_data.diagnosis.translation.tr }}</span>
          </b-col>
        </b-row> -->
        <!-- <b-row>
          <b-col cols="12">
            <span style="font-size: 15px"><strong>Tedavi Tipi:</strong> {{ treatment_data.reason.label }}</span>
          </b-col>
        </b-row> -->
        <!-- <b-row>
          <b-col cols="12">
            <span style="font-size: 15px"><strong>Tedavi Başlama Tarihi:</strong> {{ treatment_data.started_at }}</span>
          </b-col>
        </b-row> -->
        <b-row v-if="treatment_data.indication_approval && treatment_data.indication_approval.status === 'yes'">
          <b-col cols="2">
            <strong>Endikasyon Dışı:</strong>
          </b-col>
          <b-col cols="10">
            <b> Endikasyon dışı onay durumu </b> :
            <span> Endikasyon dışı onam alındıktan sonra uygulanacaktır. </span>
            <template v-if="treatment_data.indication_approval.started_at && treatment_data.indication_approval.ending_at">
              &nbsp [ <i> Başlama tarihi = {{ DateFormat(treatment_data.indication_approval.started_at) }} - Bitiş tarihi = {{ date_dif_today(treatment_data.indication_approval.ending_at) }} </i> ]
            </template>
            <br>
            <span v-if="treatment_data.indication_approval.note"> Not = {{ treatment_data.indication_approval.note }} </span>
          </b-col>
        </b-row>
        <b-row v-if="treatment_data.insurance_data && Object.keys(treatment_data.insurance_data).length > 0">
          <b-col cols="2">
            <strong>Sut bilgileri</strong>
          </b-col>
          <b-col cols="10">
            <div v-for="(ins, ins_ind) in treatment_data.insurance_data">
              &nbsp <b> {{ ins_ind }} : </b> {{ ins.rules }}
              <template v-if="ins.others_rules && ins.others_rules.length > 0">
                <br>
                <span v-for="(other, other_ind) in ins.others_rules"> ( {{ other }} ) </span>
              </template>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="treatment_data.notes && treatment_data.notes.length > 0">
          <b-col sm="12" md="2" lg="2">
            <strong>Protokol Notları</strong>
          </b-col>
          <b-col sm="12" md="10" lg="10">
            <b-row v-for="note, note_ind in treatment_data.notes" :key="'treatment_data.notes_1_' + note_ind">
              <b-col sm="12" md="6" lg="6">
                <b v-if="note.user">{{ all_users[note.user].first_name + ' ' + all_users[note.user].last_name }} {{note.date_at | show_time}}</b>
              </b-col>
              <b-col sm="12" md="6" lg="6">{{note.note}}</b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="background-color: #00ffbd; border-bottom: solid 1px;">
          <b-col :cols="view_protocol === 'macro' ? '2' : '3'">
            <strong>İlaç Adı</strong>
          </b-col>
          <b-col :cols="view_protocol === 'macro' ? '2' : '3'">
            <strong>Doz</strong>
          </b-col>
          <b-col cols="4" v-if="view_protocol === 'macro'">
            <strong>Uygulama</strong>
          </b-col>
          <b-col :cols="view_protocol === 'macro' ? '2' : '3'">
            <strong>Gün</strong>
          </b-col>
          <b-col :cols="view_protocol === 'macro' ? '2' : '3'">
            <strong>Period-Siklus</strong>
          </b-col>
        </b-row>
        <template v-for="(drug, drg_ind) in viewer_data">
          <template v-for="(before, before_ind) in drug.before">
            <treatment-protocol-viewer-show :drug="before" :main_drug="drug" :drug_type="'before'" :detailed_view="detailed_view" :view_protocol="view_protocol"></treatment-protocol-viewer-show>
            <template v-for="(mix, mix_ind) in before.drug_mix">
              <treatment-protocol-viewer-show :drug="mix" :main_drug="before" :drug_type="'mix'" :detailed_view="detailed_view" :view_protocol="view_protocol"></treatment-protocol-viewer-show>
            </template>
          </template>
          <template v-for="(during, during_ind) in drug.during">
            <treatment-protocol-viewer-show :drug="during" :main_drug="drug" :drug_type="'during'" :detailed_view="detailed_view" :view_protocol="view_protocol"></treatment-protocol-viewer-show>
            <template v-for="(mix, mix_ind) in during.drug_mix">
              <treatment-protocol-viewer-show :drug="mix" :main_drug="during" :drug_type="'mix'" :detailed_view="detailed_view" :view_protocol="view_protocol"></treatment-protocol-viewer-show>
            </template>
          </template>
          <treatment-protocol-viewer-show :drug="drug" :drug_type="'drug'" :detailed_view="detailed_view" :view_protocol="view_protocol"></treatment-protocol-viewer-show>
          <template v-for="(mix, mix_ind) in drug.drug_mix">
            <treatment-protocol-viewer-show :drug="mix" :main_drug="drug" :drug_type="'mix'" :detailed_view="detailed_view" :view_protocol="view_protocol"></treatment-protocol-viewer-show>
          </template>
          <template v-for="(after, after_ind) in drug.after">
            <treatment-protocol-viewer-show :drug="after" :main_drug="drug" :drug_type="'after'" :detailed_view="detailed_view" :view_protocol="view_protocol"></treatment-protocol-viewer-show>
            <template v-for="(mix, mix_ind) in after.drug_mix">
              <treatment-protocol-viewer-show :drug="mix" :main_drug="after" :drug_type="'mix'" :detailed_view="detailed_view" :view_protocol="view_protocol"></treatment-protocol-viewer-show>
            </template>
          </template>
        </template>
      </template>
      <template v-else-if="view_protocol === 'protocol_name'">
        <div slot="header" class="text-center">
          <strong>Protokol</strong>
        </div>
        <b-row>
          <b-col cols="12">
            <strong>Hasta: </strong> {{ w_presentation ? 'Hasta Ad Soyad' : patient_data.firstname + ' ' + patient_data.lastname }} / {{ patient_data.sex === '0' ?  $t('wdm16.9588') : $t('wdm16.4260') }} / {{ w_presentation ? 'Yaş' : patient_data.age }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <strong>WisdomEra Dosya No: </strong> {{ w_presentation ? 'Dosya No' : patient_data.record_id }} 
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <strong>HBYS Dosya No: </strong> <template v-if="w_presentation">HBYS Dosya No</template>
              <template v-else-if="patient_data.hospitals[user.hospital_group] && patient_data.hospitals[user.hospital_group][user.hospital_id] && patient_data.hospitals[user.hospital_group][user.hospital_id].data && patient_data.hospitals[user.hospital_group][user.hospital_id].data.id">{{ patient_data.hospitals[user.hospital_group][user.hospital_id].data.id }}</template>
              <template v-else> HBYS Kaydı Bulunamadı</template>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <strong>Protokol Adı: </strong>{{ treatment_data.name }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <strong>Tanı:</strong> {{ treatment_data.diagnosis.label }}
          </b-col>
        </b-row>
        <b-row v-if="treatment_data.notes && treatment_data.notes.length > 0">
          <b-col sm="12" md="2" lg="2">
            <strong>Protokol Notları</strong>
          </b-col>
          <b-col sm="12" md="10" lg="10">
            <b-row v-for="note, note_ind in treatment_data.notes" :key="'treatment_data.notes_1_' + note_ind">
              <b-col sm="12" md="6" lg="6">
                <b v-if="note.user">{{ all_users[note.user].first_name + ' ' + all_users[note.user].last_name }} {{note.date_at | show_time}}</b>
              </b-col>
              <b-col sm="12" md="10" lg="6">{{note.note}}</b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
      <template v-if="view_protocol === 'micro'" style="border: solid 1px;">
        <b-row style="background-color: #00ffbd; border-bottom: solid 1px;">
          <b-col cols="12">
            <strong>İlaç uygulamaları</strong>
          </b-col>
        </b-row>
        <template v-for="(drug, drg_ind) in viewer_data">
          <template v-for="(before, before_ind) in drug.before">
            <treatment-protocol-viewer-show :drug="before" :main_drug="drug" :drug_type="'before'" :detailed_view="detailed_view" :view_protocol="view_protocol" :show_application="true"></treatment-protocol-viewer-show>
            <template v-for="(mix, mix_ind) in before.drug_mix">
              <treatment-protocol-viewer-show :drug="mix" :main_drug="before" :drug_type="'mix'" :detailed_view="detailed_view" :view_protocol="view_protocol" :show_application="true"></treatment-protocol-viewer-show>
            </template>
          </template>
          <template v-for="(during, during_ind) in drug.during">
            <treatment-protocol-viewer-show :drug="during" :main_drug="drug" :drug_type="'during'" :detailed_view="detailed_view" :view_protocol="view_protocol" :show_application="true"></treatment-protocol-viewer-show>
            <template v-for="(mix, mix_ind) in during.drug_mix">
              <treatment-protocol-viewer-show :drug="mix" :main_drug="during" :drug_type="'mix'" :detailed_view="detailed_view" :view_protocol="view_protocol" :show_application="true"></treatment-protocol-viewer-show>
            </template>
          </template>
          <treatment-protocol-viewer-show :drug="drug" :drug_type="'drug'" :detailed_view="detailed_view" :view_protocol="view_protocol" :show_application="true"></treatment-protocol-viewer-show>
          <template v-for="(mix, mix_ind) in drug.drug_mix">
            <treatment-protocol-viewer-show :drug="mix" :main_drug="drug" :drug_type="'mix'" :detailed_view="detailed_view" :view_protocol="view_protocol" :show_application="true"></treatment-protocol-viewer-show>
          </template>
          <template v-for="(after, after_ind) in drug.after">
            <treatment-protocol-viewer-show :drug="after" :main_drug="drug" :drug_type="'after'" :detailed_view="detailed_view" :view_protocol="view_protocol" :show_application="true"></treatment-protocol-viewer-show>
            <template v-for="(mix, mix_ind) in after.drug_mix">
              <treatment-protocol-viewer-show :drug="mix" :main_drug="after" :drug_type="'mix'" :detailed_view="detailed_view" :view_protocol="view_protocol" :show_application="true"></treatment-protocol-viewer-show>
            </template>
          </template>
        </template>
      </template>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { ListingNumbers } from '@/services/public/functions';
import { show_unit_calculate } from '../../filters/index';
import MedicineService from '@/services/medicines';
import TreatmentProtocolViewerShow from '@/components/widgets/TreatmentProtocolViewerShow';
import PatientData from '@/components/widgets/PatientData';
import LogoView from '@/components/widgets/LogoView';
import moment from 'moment';
import GlobalService from '@/services/global';

// /* global $ */

export default {
  name: 'TreatmentProtocolViewer',
  created() {
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.user = JSON.parse(localStorage.getItem('user'));
    let all_users = JSON.parse(localStorage.getItem("all_users"));
    for (let i in all_users) {
      this.all_users[all_users[i].username] = all_users[i];
    }
    let data = {};
    data.patient_id = this.patient_data.id;
    if (this.treatment_data.medicine_list && this.treatment_data.medicine_list.length > 0) {
      GlobalService.get_multiple_cb_documents({ 'key_list': this.treatment_data.medicine_list, 'bucket': 'wisdom' })
        .then(resp => {
          for (let meta_id in resp.data.result) {
            if (!this.medicine_data.data[resp.data.result[meta_id].drug.value]) {
              this.medicine_data.data[resp.data.result[meta_id].drug.value] = [];
            }
            this.medicine_data.data[resp.data.result[meta_id].drug.value].push(resp.data.result[meta_id]);
          }
          this.create_viewer_data();
        });
    } else {
      this.create_viewer_data();
    }
  },
  mounted() {},
  computed: mapGetters({
    lang: 'lang'
  }),
  props: {
    patient_data: {
      type: Object,
      required: false,
      default: {}
    },
    view_protocol: {
      type: String,
      required: false,
      default: 'macro'
    },
    treatment_data: {
      type: Object,
      required: true
    },
    only_protocol: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      all_users: {},
      viewer_data: {},
      detailed_view: false,
      hospital_data: {},
      types: ['before', 'after', 'during'],
      unit_options: require('@/options/unit').options,
      fluid_options: [],
      options: [],
      computed_name: '',
      user: {},
      medicine_data: { data: {} },
      ListingNumbers: ListingNumbers,
      d_protocolName: '',
      d_protocolCreatorName: '',
      w_presentation: false
    };
  },
  methods: {
    get_from_all_users: function (username) {
      for (let i in this.all_users) {
        if (this.all_users[i].username === username) {
          return this.all_users[i].group + ' ' + this.all_users[i].first_name + ' ' + this.all_users[i].last_name;
        }
      }
    },
    create_viewer_data() {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'döküm hazırlanıyor' } });
      for (let drug_key in this.treatment_data.protocol_cycles.drugs) {
        let drg_period_list = {};
        let drg_day_list = {};
        let drg_dosage_object = {};
        let drg_dosage_form_object = {};
        let drg_frequency_object = {};
        let drg_duration_object = {};
        let drg_planned_dosage_object = {};
        let drg_fluid_object = {};
        let drg_fluid_value_object = {};
        let prem_types = ['before', 'during', 'after'];
        let prem_objects = { 'before': {}, 'during': {}, 'after': {} };
        this.viewer_data[drug_key] = {};
        let drug_data = this.treatment_data.protocol_cycles.drugs[drug_key];
        if (drug_data.type && drug_data.type === 'drug') {
          if (this.d_protocolName === '') {
            this.d_protocolName += drug_data.drug.label;
          } else {
            this.d_protocolName += ', ' + drug_data.drug.label;
          }
        }
        this.d_protocolCreatorName = this.get_from_all_users(this.treatment_data.created_by);
        this.viewer_data[drug_key].drug = drug_data.drug;
        for (let cycle_ind in drug_data.cycles) {
          if (!drg_period_list[drug_data.cycles[cycle_ind].period]) {
            drg_period_list[drug_data.cycles[cycle_ind].period] = [];
          }
          drg_period_list[drug_data.cycles[cycle_ind].period].push(parseInt(cycle_ind) + 1);
          let drg_tmp_day_list = [];
          for (let day_ind in drug_data.cycles[cycle_ind].days) {
            let day_data = drug_data.cycles[cycle_ind].days[day_ind];
            drg_tmp_day_list.push(day_data.day);
            this.dosage_func(day_data.data, drg_dosage_object, parseInt(cycle_ind) + 1, day_data.day);
            this.obj_func(day_data.data.planned_dosage + ' ' + show_unit_calculate(day_data.data.unit.value), drg_planned_dosage_object, parseInt(cycle_ind) + 1, day_data.day);
            let duration_text = day_data.data.duration.toString();

            if (day_data.data.dosage_form.value === '13') {
              duration_text += ' gün';
            }
            this.obj_func(duration_text, drg_duration_object, parseInt(cycle_ind) + 1, day_data.day);
            this.obj_func(day_data.data.fluid_value, drg_fluid_value_object, parseInt(cycle_ind) + 1, day_data.day);
            this.label_value_func(day_data.data, 'fluid', drg_fluid_object, parseInt(cycle_ind) + 1, day_data.day);
            this.label_value_func(day_data.data, 'dosage_form', drg_dosage_form_object, parseInt(cycle_ind) + 1, day_data.day);
            this.label_value_func(day_data.data, 'frequency', drg_frequency_object, parseInt(cycle_ind) + 1, day_data.day);

            for (let pr_ind in prem_types) {
              let pr = prem_types[pr_ind];
              for (let prem_ind in day_data.data[pr]) {
                let prem_data = day_data.data[pr][prem_ind];
                if (!prem_objects[pr][prem_ind]) {
                  prem_objects[pr][prem_ind] = { 'drug': {}, 'dosage': {}, 'dosage_form': {}, 'frequency': {}, 'duration': {}, 'planned_dosage': {}, 'fluid': {}, 'fluid_value': {}, 'day': {}, 'drug_mix': {} };
                }
                prem_objects[pr][prem_ind].drug = prem_data.drug;
                this.dosage_func(prem_data, prem_objects[pr][prem_ind].dosage, parseInt(cycle_ind) + 1, day_data.day);
                this.obj_func(prem_data.planned_dosage + ' ' + show_unit_calculate(prem_data.unit.value), prem_objects[pr][prem_ind].planned_dosage, parseInt(cycle_ind) + 1, day_data.day);
                this.obj_func(prem_data.duration, prem_objects[pr][prem_ind].duration, parseInt(cycle_ind) + 1, day_data.day);
                this.obj_func(prem_data.fluid_value, prem_objects[pr][prem_ind].fluid_value, parseInt(cycle_ind) + 1, day_data.day);
                this.label_value_func(prem_data, 'fluid', prem_objects[pr][prem_ind].fluid, parseInt(cycle_ind) + 1, day_data.day);
                this.label_value_func(prem_data, 'dosage_form', prem_objects[pr][prem_ind].dosage_form, parseInt(cycle_ind) + 1, day_data.day);
                this.label_value_func(prem_data, 'frequency', prem_objects[pr][prem_ind].frequency, parseInt(cycle_ind) + 1, day_data.day);
                this.obj_func(prem_data.day[0], prem_objects[pr][prem_ind].day, parseInt(cycle_ind) + 1, day_data.day);

                for (let mix_ind in prem_data.drug_mix) {
                  let mix_data = prem_data.drug_mix[mix_ind];
                  if (!prem_objects[pr][prem_ind].drug_mix[mix_data.drug.value]) {
                    prem_objects[pr][prem_ind].drug_mix[mix_data.drug.value] = { 'drug': mix_data.drug, 'dosage': {}, 'planned_dosage': {} };
                  }
                  this.dosage_func(mix_data, prem_objects[pr][prem_ind].drug_mix[mix_data.drug.value].dosage, parseInt(cycle_ind) + 1, day_data.day);
                  this.obj_func(mix_data.planned_dosage + ' ' + show_unit_calculate(mix_data.unit.value), prem_objects[pr][prem_ind].drug_mix[mix_data.drug.value].planned_dosage, parseInt(cycle_ind) + 1, day_data.day);
                }
              }
            }
            if (!this.viewer_data[drug_key].drug_mix) {
              this.viewer_data[drug_key].drug_mix = {};
            }
            for (let mix_ind in day_data.data.drug_mix) {
              let mix_data = day_data.data.drug_mix[mix_ind];
              if (!this.viewer_data[drug_key].drug_mix[mix_data.drug.value]) {
                this.viewer_data[drug_key].drug_mix[mix_data.drug.value] = { 'drug': mix_data.drug, 'dosage': {}, 'planned_dosage': {} };
              }
              this.dosage_func(mix_data, this.viewer_data[drug_key].drug_mix[mix_data.drug.value].dosage, parseInt(cycle_ind) + 1, day_data.day);
              this.obj_func(mix_data.planned_dosage + ' ' + show_unit_calculate(mix_data.unit.value), this.viewer_data[drug_key].drug_mix[mix_data.drug.value].planned_dosage, parseInt(cycle_ind) + 1, day_data.day);
            }
          }
          let day_caption = ListingNumbers.calculate(drg_tmp_day_list, 'together');
          if (!drg_day_list[day_caption]) {
            drg_day_list[day_caption] = [];
          }
          drg_day_list[day_caption].push(parseInt(cycle_ind) + 1);
        }
        for (let pr_ind in prem_types) {
          this.viewer_data[drug_key][prem_types[pr_ind]] = prem_objects[prem_types[pr_ind]];
        }
        this.viewer_data[drug_key].dosage = drg_dosage_object;
        this.viewer_data[drug_key].duration = drg_duration_object;
        this.viewer_data[drug_key].fluid = drg_fluid_object;
        this.viewer_data[drug_key].fluid_value = drg_fluid_value_object;
        this.viewer_data[drug_key].planned_dosage = drg_planned_dosage_object;
        this.viewer_data[drug_key].dosage_form = drg_dosage_form_object;
        this.viewer_data[drug_key].frequency = drg_frequency_object;
        this.viewer_data[drug_key].daycyc = this.calculate_cycle_period_day(drg_day_list, 'day');
        this.viewer_data[drug_key].percyc = this.calculate_cycle_period_day(drg_period_list, 'period');
      }
      if (this.medicine_data && this.medicine_data.data) {
        for (let drug_key in this.medicine_data.data) {
          for (let drug_index in this.medicine_data.data[drug_key]) {
            if (this.medicine_data.data[drug_key][drug_index].cycles.length > 0) {
              let drg = 'med_' + (parseInt(drug_index) + 1).toString() + '-' + drug_key;
              this.viewer_data[drg] = {};
              this.viewer_data[drg].drug = this.medicine_data.data[drug_key][drug_index].drug;
              let drg_dosage_object = {};
              let drg_dosage_form_object = {};
              let drg_planned_dosage_object = {};
              let drg_frequency_object = {};
              let drg_period_list = {};
              let drg_day_list = {};
              for (let cycle_ind in this.medicine_data.data[drug_key][drug_index].cycles) {
                let cycle_data = this.medicine_data.data[drug_key][drug_index].cycles[cycle_ind];
                let day_key = ListingNumbers.calculate(cycle_data.day, 'together');
                if (!drg_day_list[day_key]) {
                  drg_day_list[day_key] = [];
                }
                drg_day_list[day_key].push(parseInt(cycle_ind) + 1);
                this.dosage_func(cycle_data, drg_dosage_object, parseInt(cycle_ind) + 1, day_key);
                this.obj_func(cycle_data.planned_dosage + ' ' + show_unit_calculate(cycle_data.unit.value), drg_planned_dosage_object, parseInt(cycle_ind) + 1, day_key);
                this.label_value_func(cycle_data, 'frequency', drg_frequency_object, parseInt(cycle_ind) + 1, day_key);
                this.label_value_func(this.medicine_data.data[drug_key][drug_index], 'dosage_form', drg_dosage_form_object, parseInt(cycle_ind) + 1, day_key);

                if (!drg_period_list[cycle_data.period]) {
                  drg_period_list[cycle_data.period] = [];
                }
                drg_period_list[cycle_data.period].push(parseInt(cycle_ind) + 1);
              }
              this.viewer_data[drg].daycyc = this.calculate_cycle_period_day(drg_day_list, 'day');
              this.viewer_data[drg].percyc = this.calculate_cycle_period_day(drg_period_list, 'period');
              this.viewer_data[drg].dosage = drg_dosage_object;
              this.viewer_data[drg].planned_dosage = drg_planned_dosage_object;
              this.viewer_data[drg].frequency = drg_frequency_object;
              this.viewer_data[drg].dosage_form = drg_dosage_form_object;
            } else {
              let drg = 'med_' + (parseInt(drug_index) + 1).toString() + '-' + drug_key;
              let med_data = this.medicine_data.data[drug_key][drug_index];
              this.viewer_data[drg] = {};
              this.viewer_data[drg].med_without_cycles = true;
              this.viewer_data[drg].drug = med_data.drug;
              this.viewer_data[drg].daycyc = ListingNumbers.calculate(med_data.day, 'together');
              this.viewer_data[drg].percyc = med_data.repeat + ' siklus ' + med_data.period + ' günde bir';
              this.viewer_data[drg].dosage = med_data.dosage + med_data.unit.label;
              this.viewer_data[drg].planned_dosage = med_data.planned_dosage + show_unit_calculate(med_data.unit.value);
              this.viewer_data[drg].frequency = med_data.frequency.label;
              this.viewer_data[drg].dosage_form = med_data.dosage_form.label;
            }
          }
        }
      }

      this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
      this.$forceUpdate();
    },
    print: function() {
      let x = new Date();
      let now_date = moment(x.toString()).format('DD/MM/YYYY HH:mm');
      let w = window.open('', '_blank', 'height=500,width=800,toolbar=0,location=0,menubar=0');
      let pageHtml = htmlTemplate
        .replace('##HEADER##', 'Tedavi raporu')
        .replace('##BODY##', this.$refs.report_here.innerHTML)
        .replace('##FOOTER##', 'Çıktıyı Alan: ' + this.user.first_name + ' ' + this.user.last_name + '<br />Çıktı Tarihi: ' + now_date + '<br />Bu protokol sağlıkta yapay zeka sistemi WisdomEra (www.wisdomera.io) ile hazırlanmıştır.');
      w.document.write(pageHtml);
      w.document.close();
      w.onload = function() {
        w.focus();
        w.print();
      };
    },
    write_with_sorting(drug_key, data_type, array_list) {
      if (array_list.length === 1) {
        this.viewer_data[drug_key][data_type] = array_list[0];
      } else {
        array_list.sort();
        this.viewer_data[drug_key][data_type] = array_list[0] + '-' + array_list[array_list.length - 1];
      }
    },
    label_value_func(data, data_type, obj, cycle, day) {
      if (data[data_type]) {
        if (!obj[data[data_type].value]) {
          obj[data[data_type].value] = { 'label': data[data_type].label, 'data': {} };
        }
        if (!obj[data[data_type].value].data[cycle]) {
          obj[data[data_type].value].data[cycle] = [];
        }
        obj[data[data_type].value].data[cycle].push(day);
      }
    },
    array_list_func(data, array_list) {
      if (array_list.indexOf(data) === -1) {
        array_list.push(data);
      }
    },
    obj_func(data, obj, cycle, day) {
      if (!obj[data]) {
        obj[data] = {};
      }
      if (!obj[data][cycle]) {
        obj[data][cycle] = [];
      }
      obj[data][cycle].push(day);
    },
    dosage_func(data, drg_dosage_object, cycle, day) {
      let dosage_text = '';
      if (data.unit.value === 'auc') {
        dosage_text = data.unit.label + '=' + data.dosage;
      } else {
        dosage_text = data.dosage + ' ' + data.unit.label;
      }
      if (!drg_dosage_object[dosage_text]) {
        drg_dosage_object[dosage_text] = {};
      }
      if (!drg_dosage_object[dosage_text][cycle]) {
        drg_dosage_object[dosage_text][cycle] = [];
      }
      drg_dosage_object[dosage_text][cycle].push(day);
    },
    calculate_cycle_period_day: function(data, data_type) {
      if (data_type === 'day') {
        if (Object.keys(data).length === 1) {
          return Object.keys(data)[0];
        } else {
          let text = '';
          let day_list = Object.keys(data);
          for (let i in day_list) {
            let day_key = day_list[i];
            text += ListingNumbers.calculate(data[day_key], 'together') + '. sikluslarda ';
            text += day_key + '. günler';
            if (parseInt(i) !== (day_list.length - 1)) {
              text += ' ve ';
            }
          }
          return text;
        }
      } else if (data_type === 'period') {
        let text = '';
        let period_list = Object.keys(data);
        for (let i in period_list) {
          let period_key = period_list[i];
          text += ListingNumbers.calculate(data[period_key], 'together') + '. sikluslarda ';
          text += period_key + ' günde bir';
          if (parseInt(i) !== (period_list.length - 1)) {
            text += ' ve ';
          }
        }
        return text;
      }
    },
    date_dif_today: function(dt) {
      let date = new Date(dt);
      let dateMsec = date.getTime();
      let today = new Date();
      today.setHours(8, 0, 0, 0);
      let todayMsec = today.getTime();
      let msecPerDay = 86400000;
      let day_dif = Math.round((dateMsec / msecPerDay) - (todayMsec / msecPerDay));
      if (day_dif === 0) {
        return moment(date).format('DD/MM/YYYY') + ' (' + this.$t('wdm16.6218') + ')';
      } else if (day_dif === 1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6207') + ')';
      } else if (day_dif > 1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6227') + ')';
      } else if (day_dif === -1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6208') + ')';
      } else if (day_dif < -1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6215') + ')';
      }
    },
    DateFormat: function(date) {
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
    }
  },
  watch: {},
  components: {
    TreatmentProtocolViewerShow,
    PatientData,
    LogoView
  }
};

const htmlTemplate = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <title></title>
  <style type="text/css">
        @page {
        size: A4;
        margin: 10mm 8mm 10mm 8mm;
      }
    @media print {
      .no_wrap_line {
          width: 100%;
          display: inline-block;
        }
      .col-1 {width:8%;  float:left;}
        .col-2 {width:16%; float:left;}
        .col-3 {width:25%; float:left;}
        .col-4 {width:33%; float:left;}
        .col-5 {width:42%; float:left;}
        .col-6 {width:50%; float:left;}
        .col-7 {width:58%; float:left;}
        .col-8 {width:66%; float:left;}
        .col-9 {width:75%; float:left;}
        .col-10{width:83%; float:left;}
        .col-11{width:92%; float:left;}
        .col-12{width:100%; float:left;}

      html, body {
        width: 210mm;
        background: white!important;
        height: 297mm;
      }
      .page-header {
        
      }
      .page-footer {
        position: fixed;
        bottom: 0;
      }
      .page-body {
      font-size: 12px;
      width: 100%;
      height: 95%;
      }
    }
    .page-footer {
      font-size: 8px;
      position: fixed;
      bottom: 0;
    }
    table, td, tr {
      border: 1px solid black;
    }
    .page-body {
      font-size: 12px;
      width: 100%;
      height: 95%;
    }

    ##CSS##
  </style>

</head>
<body>
  <div class="page-header">
    ##HEADER##
  </div>
  <div class="page-body">
    ##BODY##
  </div>
  <div class="page-footer">
    ##FOOTER##
  </div>
</body>
</html>
`;

</script>
