<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group label="Toksisite tarihi" label-for="toxicity_date" :label-cols="3" :horizontal="true" validated>
          <b-form-input type="date" id="toxicity_date" :max="moment(new Date()).format('YYYY-MM-DD')" v-model="data.date" required></b-form-input>
          <b-form-invalid-feedback>Toksisite tarihi giriniz</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Toksisite grubu" :horizontal="true" validated>
          <v-select ref="toxicity_group" name="toxicity_group" v-model="data.group" :on-change="drug_toxicity_group_select" :options="drug_toxicity_group"></v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Toksisite" :horizontal="true" validated>
          <v-select name="toxicity" v-model="data.toxicity" ref="data.toxicity" :on-change="drug_toxicity_select" :options="drug_toxicity_options"></v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Toksisite grade seçimi" label-for="toxicity_grade" :horizontal="true" :label-cols="3">
          <b-form-radio v-for="(grade, grade_ind) in drug_toxicity_grades" :key="'drug_toxicity_grades_' + grade_ind" v-model="data.grade" name="some-radios" :value="grade">{{ grade.label }}</b-form-radio>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Karar" :label-cols="3" :horizontal="true">
          <b-form-checkbox-group id="checkbox-group-2" v-model="data.process" name="process" stacked>
            <b-form-checkbox value="Removing drug from the protocol">İlaç protokolden çıkarıldı</b-form-checkbox>
            <b-form-checkbox value="Dose reduction">Doz azaltıldı</b-form-checkbox>
            <b-form-checkbox value="Changing the brand form of the drug">İlacın markası değiştirildi</b-form-checkbox>
            <b-form-checkbox value="Medication">Toksisiteye yönelik ilaç uygulandı</b-form-checkbox>
            <b-form-checkbox value="Consultation">Konsültasyon yapıldı</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Not" label-for="toxicity_note" :horizontal="true">
          <b-form-textarea id="textarea" v-model="data.note" placeholder="Lütfen notunuzu yazınız..." rows="3" max-rows="6"></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import moment from 'moment';
import { mapGetters } from 'vuex';
import OptionService from '@/services/option';
import { tr } from '../../../node_modules/flatpickr/dist/l10n/tr';
export default {
  name: 'Toxicity',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    vSelect
  },
  created: function () {
    this.drug_toxicity_group = OptionService.get_toxicity_local_storage('toxicity_group_list');
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      moment: moment,
      datepicker_langs: { tr: tr, en: {} },
      drug_toxicity_group: [],
      drug_toxicity_options: [],
      drug_toxicity_grades: []
    };
  },
  methods: {
    drug_toxicity_select: function (toxicity) {
      this.data.toxicity = toxicity;
      this.drug_toxicity_grades = toxicity.grades;
    },
    drug_toxicity_group_select: function (group) {
      this.drug_toxicity_grades = [];
      if (group) {
        this.drug_toxicity_options = OptionService.get_toxicity_local_storage('toxicity_list', group.value);
      }
    }
  }
};

</script>

