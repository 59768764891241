var info = {
  input_dosage: {
    chars: {value: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'], validation: 'required', info: 'chars_input_dosage'},
    len: {value: 5, validation: 'required', info: 'len_input_dosage'},
    max: {value: 'none', validation: 'none', info: 'max_input_dosage'},
    min: {value: 0.1, validation: 'required', info: 'min_input_dosage'},
    bef_aft: {value: 30, validation: 'information', info: 'bef_aft_input_dosage'},
    decimal: {value: 1, validation: 'information', info: 'decimal_input_dosage'}
  },
  calculated_dosage: {
    chars: {value: 'none', validation: 'none', info: 'chars_calculated_dosage'},
    len: {value: 'none', validation: 'none', info: 'len_calculated_dosage'},
    max: {value: 'none', validation: 'none', info: 'max_calculated_dosage'},
    min: {value: 0.1, validation: 'required', info: 'min_calculated_dosage'},
    bef_aft: {value: 'none', validation: 'none', info: 'bef_aft_calculated_dosage'},
    decimal: {value: 'none', validation: 'none', info: 'decimal_calculated_dosage'}
  },
  input_period: {
    chars: {value: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'], validation: 'required', info: 'chars_input_period'},
    len: {value: 3, validation: 'required', info: 'len_input_period'},
    max: {value: 'none', validation: 'none', info: 'max_input_period'},
    min: {value: 'none', validation: 'none', info: 'min_input_period'},
    bef_aft: {value: 30, validation: 'information', info: 'bef_aft_input_period'},
    decimal: {value: 0, validation: 'information', info: 'decimal_input_period'}
  },
  input_repeat: {
    chars: {value: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'], validation: 'required', info: 'chars_input_repeat'},
    len: {value: 2, validation: 'required', info: 'len_input_repeat'},
    max: {value: 'none', validation: 'none', info: 'max_input_repeat'},
    min: {value: 'none', validation: 'none', info: 'min_input_repeat'},
    bef_aft: {value: 30, validation: 'information', info: 'bef_aft_input_repeat'},
    decimal: {value: 0, validation: 'information', info: 'decimal_input_repeat'}
  },
  input_delay: {
    chars: {value: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'], validation: 'required', info: 'chars_input_delay'},
    len: {value: 2, validation: 'required', info: 'len_input_delay'},
    max: {value: 'none', validation: 'none', info: 'max_input_delay'},
    min: {value: 'none', validation: 'none', info: 'min_input_delay'},
    bef_aft: {value: 30, validation: 'information', info: 'bef_aft_input_delay'},
    decimal: {value: 0, validation: 'information', info: 'decimal_input_delay'}
  },
  input_planned_dosage: {
    chars: {value: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'], validation: 'required', info: 'chars_input_planned_dosage'},
    len: {value: 5, validation: 'required', info: 'len_input_planned_dosage'},
    max: {value: 'none', validation: 'none', info: 'max_input_planned_dosage'},
    min: {value: 0.1, validation: 'required', info: 'min_input_planned_dosage'},
    bef_aft: {value: 30, validation: 'information', info: 'bef_aft_input_planned_dosage'},
    decimal: {value: 1, validation: 'information', info: 'decimal_input_planned_dosage'}
  },
  input_duration_wait: {
    chars: {value: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'], validation: 'required', info: 'chars_input_duration_wait'},
    len: {value: 4, validation: 'information', info: 'len_input_duration_wait'},
    max: {value: 'none', validation: 'none', info: 'max_input_duration_wait'},
    min: {value: 'none', validation: 'none', info: 'min_input_duration_wait'},
    bef_aft: {value: 100, validation: 'information', info: 'bef_aft_input_duration_wait'},
    decimal: {value: 1, validation: 'information', info: 'decimal_input_duration_wait'}
  },
  input_volume: {
    chars: {value: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'], validation: 'required', info: 'chars_input_volume'},
    len: {value: 4, validation: 'required', info: 'len_input_volume'},
    max: {value: 'none', validation: 'information', info: 'max_input_volume'},
    min: {value: 'none', validation: 'information', info: 'min_input_volume'},
    bef_aft: {value: 50, validation: 'information', info: 'bef_aft_input_volume'},
    decimal: {value: 1, validation: 'information', info: 'decimal_input_volume'}
  },
  input_duration: {
    chars: {value: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'], validation: 'required', info: 'chars_input_duration'},
    len: {value: 4, validation: 'required', info: 'len_input_duration'},
    max: {value: 'none', validation: 'information', info: 'max_input_duration'},
    min: {value: 'none', validation: 'information', info: 'min_input_duration'},
    bef_aft: {value: 50, validation: 'information', info: 'bef_aft_input_duration'},
    decimal: {value: 1, validation: 'information', info: 'decimal_input_duration'}
  },
  input_minutes_ago_or_later: {
    chars: {value: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'], validation: 'required', info: 'chars_input_minutes_ago_or_later'},
    len: {value: 4, validation: 'required', info: 'len_input_minutes_ago_or_later'},
    max: {value: 'none', validation: 'information', info: 'max_input_minutes_ago_or_later'},
    min: {value: 'none', validation: 'information', info: 'min_input_minutes_ago_or_later'},
    bef_aft: {value: 50, validation: 'information', info: 'bef_aft_input_minutes_ago_or_later'},
    decimal: {value: 1, validation: 'information', info: 'decimal_input_minutes_ago_or_later'}
  },
  select_unit: {
    selection: {value: '', validation: 'required', info: 'select_unit'}
  },
  select_administration: {
    selection: {value: '', validation: 'required', info: 'select_administration'}
  },
  select_frequency: {
    selection: {value: '', validation: 'required', info: 'select_frequency'}
  },
  select_prem_drug_days: {
    selection: {value: '', validation: 'required', info: 'select_prem_drug_days'}
  },
  select_note_drug_days: {
    selection: {value: '', validation: 'required', info: 'select_note_drug_days'}
  },
  select_note_days: {
    selection: {value: '', validation: 'required', info: 'select_note_days'}
  },
  select_lab_drug_days: {
    selection: {value: '', validation: 'required', info: 'select_lab_drug_days'}
  },
  select_lab_days: {
    selection: {value: '', validation: 'required', info: 'select_lab_days'}
  },
  select_msg_drug_days: {
    selection: {value: '', validation: 'required', info: 'select_msg_drug_days'}
  },
  select_msg_days: {
    selection: {value: '', validation: 'required', info: 'select_msg_days'}
  },
  select_prem_days: {
    selection: {value: '', validation: 'required', info: 'select_prem_days'}
  },
  select_drug_days: {
    selection: {value: '', validation: 'required', info: 'select_drug_days'}
  },
  select_the_drug: {
    selection: {value: '', validation: 'required', info: 'select_the_drug'}
  },
  select_lab_tests: {
    selection: {value: '', validation: 'required', info: 'select_lab_tests'}
  },
  text_note_drug: {
    text: {value: '', validation: 'required', info: 'text_note_drug'}
  },
  text_msg_drug: {
    text: {value: '', validation: 'required', info: 'text_msg_drug'}
  },
  select_protocol_group: {
    selection: {value: '', validation: 'required', info: 'select_protocol_group'}
  },
  input_protocol_name: {
    input: {value: '', validation: 'required', info: 'input_protocol_name'}
  },
  select_visibility: {
    selection: {value: '', validation: 'required', info: 'select_visibility'}
  },
  select_diagnosis: {
    selection: {value: '', validation: 'required', info: 'select_diagnosis'}
  },
  select_chemotherapy_type: {
    selection: {value: '', validation: 'required', info: 'select_chemotherapy_type'}
  },
  select_therapy_unit: {
    selection: {value: '', validation: 'required', info: 'select_therapy_unit'}
  },
  select_treatment_starting_date: {
    selection: {value: '', validation: 'required', info: 'select_treatment_starting_date'}
  },
  select_protocol_visibility: {
    selection: {value: '', validation: 'required', info: 'select_protocol_visibility'}
  },
  date_weight_height: {
    dif: {value: '', days: 30, validation: 'information', info: 'dif_date_weight_height'} // dikkat buradaki days kaldıırlacak value'ye taşınacak. bmigfrwidgetta düzenleme yaparken.
  },
  date_new_weight_height: {
    general: {value: '', validation: 'required', info: 'general_date_new_weight_height'},
    bef_aft: {value: 30, validation: 'information', info: 'dif_date_new_weight_height'}
  },
  input_weight_kg: {
    general: {value: '', validation: 'required', info: 'general_input_weight_kg'},
    chars: {value: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'], validation: 'required', info: 'chars_input_weight_kg'},
    len: {value: 3, validation: 'required', info: 'len_input_weight_kg'},
    max: {value: 120, validation: 'information', info: 'max_input_weight_kg'},
    min: {value: 15, validation: 'information', info: 'min_input_weight_kg'},
    bef_aft: {value: 10, validation: 'information', info: 'bef_aft_input_weight_kg'},
    decimal: {value: 0, validation: 'required', info: 'decimal_input_weight_kg'}
  },
  input_weight_lbs: {
    general: {value: '', validation: 'required', info: 'general_input_weight_lbs'},
    chars: {value: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'], validation: 'required', info: 'chars_input_weight_lbs'},
    len: {value: 3, validation: 'required', info: 'len_input_weight_lbs'},
    max: {value: 264, validation: 'information', info: 'max_input_weight_lbs'},
    min: {value: 15, validation: 'information', info: 'min_input_weight_lbs'},
    bef_aft: {value: 10, validation: 'information', info: 'bef_aft_input_weight_lbs'},
    decimal: {value: 0, validation: 'required', info: 'decimal_input_weight_lbs'}
  },
  input_length_cm: {
    general: {value: '', validation: 'required', info: 'general_input_length_cm'},
    chars: {value: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'], validation: 'required', info: 'chars_input_length_cm'},
    len: {value: 3, validation: 'required', info: 'len_input_length_cm'},
    max: {value: 210, validation: 'information', info: 'max_input_length_cm'},
    min: {value: 0, validation: 'required', info: 'min_input_length_cm'},
    bef_aft: {value: 5, validation: 'information', info: 'bef_aft_input_length_cm'},
    decimal: {value: 0, validation: 'required', info: 'decimal_input_length_cm'}
  },
  input_length_inch: {
    general: {value: '', validation: 'required', info: 'general_input_length_inch'},
    chars: {value: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'], validation: 'required', info: 'chars_input_length_inch'},
    len: {value: 3, validation: 'required', info: 'len_input_length_inch'},
    max: {value: 85, validation: 'required', info: 'max_input_length_inch'},
    min: {value: 0, validation: 'required', info: 'min_input_length_inch'},
    bef_aft: {value: 5, validation: 'information', info: 'bef_aft_input_length_inch'},
    decimal: {value: 0, validation: 'required', info: 'decimal_input_length_inch'}
  },
  date_creatinine: {
    dif: {value: '', days: 30, validation: 'information', info: 'dif_date_creatinine'} // dikkat buradaki days kaldıırlacak value'ye taşınacak. bmigfrwidgetta düzenleme yaparken.
  },
  input_lab: {
    chars: {value: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'], validation: 'required', info: 'chars_input_lab'},
    decimal: {value: 2, validation: 'required', info: 'decimal_input_lab'}
  },
  date_laboratory: {
    general: {value: '', validation: 'required', info: 'general_date_laboratory'}
  }
};
// dikkat value mutlaka olacak. Ayrıcac infoyu ayrı tututuk dil dosyasından variable_langs içinde. Çünkü dil dosyası büyüdükçe sayfa kalabalıklaşacak.
export { info as options };
