<template>
  <div>
    <b-card :class="[is_date_today(p_wdmr11or12List[p_componentType][p_wdmr11or12_ind].date) ? 'bg-secondary' : 'bg-light']" style="margin-bottom: 5px;" no-body>
      <b-card-header header-text-variant="dark" class="p-1">
        <b-row>
          <b-col sm="1" md="1">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '32')">?</b-badge>
            <b-dropdown variant="primary" no-caret>
              <template slot="button-content">
                <i class="icon-settings"></i>
              </template>
              <b-dropdown-item v-if="!p_patients_chemo_list && user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 && user.permissions_result.indexOf('oncology_treatment_date-adjust') !== -1)" @click="f_showChangeDate()"> Tarih öteleme </b-dropdown-item>
              <b-dropdown-item v-if="!p_patients_chemo_list && user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1 || account_list[0].special_permission.indexOf('administrator') !== -1)" @click="f_resetAllDrugsStatusOnDay(p_wdmr11or12List[p_componentType][p_wdmr11or12_ind].list)"> Bugüne ait tüm onay durumlarını "henüz işlem yapılmadı" olarak güncelle (wisdom)</b-dropdown-item>
              <b-dropdown-item v-if="p_patients_chemo_list" @click="f_bringPatient(patient_data.id)"> Hastaya Git </b-dropdown-item>
              <b-dropdown-item @click="f_showDrugReportingModal(p_wdmr11or12List[p_componentType][p_wdmr11or12_ind])"> Yazdır </b-dropdown-item>
              <b-dropdown-item v-if="!p_patients_chemo_list" @click="f_showDayEditModal()"> Düzenleme ekranı </b-dropdown-item>
              <b-dropdown-item v-if="!p_patients_chemo_list" @click="f_showAddCycleModal()"> Siklus arttırma </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col sm="7" md="7" v-b-toggle="'patient_' + patient_data.id + 'p_wdmr11or12_ind-' + p_wdmr11or12_ind + '_' + p_componentType" style="cursor: pointer;">
            <!-- <b-col v-if="!p_patients_chemo_list" sm="7" md="7" v-b-toggle="'p_wdmr11or12_ind-' + p_wdmr11or12_ind" style="cursor: pointer;"> -->
            <patient-drug-day-naming :p_componentType="p_componentType" :mother_children="mother_children" :p_wdmr11or12_ind="p_wdmr11or12_ind" :p_wdmr11or12List="p_wdmr11or12List" :p_patients_chemo_list="p_patients_chemo_list"></patient-drug-day-naming>
          </b-col>
          <b-col sm="4">
            <b-row>
              <b-col cols="12" v-if="f_patientDrugButtonIfControl(p_wdmr11or12List[p_componentType][p_wdmr11or12_ind].list)">
                <patient-drug-button :p_optionData="p_optionData" :p_componentType="p_componentType" :p_external_patient="patient_data" :p_orderOperation="d_orderOperation" :p_wdmr11or12_ind="p_wdmr11or12_ind" :p_wdmr11or12List="p_wdmr11or12List" :mother_children="mother_children"></patient-drug-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-header>
    </b-card>
    <b-collapse :id="'patient_' + patient_data.id + 'p_wdmr11or12_ind-' + p_wdmr11or12_ind + '_' + p_componentType">
      <template v-for="(wdmr_data, wdmr_data_ind) in p_wdmr11or12List[p_componentType][p_wdmr11or12_ind].list">
        <patient-drug-day-show :p_optionData="p_optionData" :p_componentType="p_componentType" :p_button_date="p_wdmr11or12List[p_componentType][p_wdmr11or12_ind].date" :p_wdmr11or12_ind="p_wdmr11or12_ind" :p_wdmr11or12List="p_wdmr11or12List" :p_external_patient="patient_data" :p_orderOperation="d_orderOperation" :mother_children="mother_children" :show_more_drug_details="show_more_drug_details" :p_wdmr_data="wdmr_data" :p_wdmr_data_ind="wdmr_data_ind"></patient-drug-day-show>
      </template>
    </b-collapse>
    <modal v-if="d_showDrugReportingModal" @close="d_showDrugReportingModal = false" :width="'1500'">
      <h3 slot="header">
        Tedavi Detayı Yazdırma Ekranı
        <img v-if="patient_data.sex === '0'" src="../../../static/img/avatars/man.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.3em; padding: 2px;" />
        <img v-else src="../../../static/img/avatars/woman.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.3em; padding: 2px;" /> {{ w_presentation ? 'Hasta Ad Soyad' : patient_data.firstname.toLocaleUpperCase('tr') + ' ' + patient_data.lastname.toLocaleUpperCase('tr') }}
      </h3>
      <div slot="body">
        <patient-drug-reporting-modal :p_createdBy="d_reportingData.created_by" :p_dateList="d_reportingData.date_list" :p_drugDateList="d_reportingData.drugs_by_date" :p_patientData="patient_data"></patient-drug-reporting-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showDrugReportingModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <b-modal title="Tarih Öteleme Ekranı" v-model="d_showChangeDateModal" centered @ok="f_saveDateAdjust()">
      <div>
        <b-row>
          <b-col cols="2" style="padding-right: 1px">
            Yeni Tarih:
          </b-col>
          <b-col cols="6" style="padding-right: 1px">
            <datepicker :config="{minDate: p_wdmr11or12List[p_componentType][p_wdmr11or12_ind].date}" class="form-control" style="background-color: transparent;" name="delay_data_new_date" v-model="d_dateAdjustPrepare.date" @input="f_updateDatepickerAndNumber('number')"></datepicker>
          </b-col>
          <b-col cols="4" style="padding-left: 1px">
            <b-form-input type="number" class="form-control-warning" @keydown="InputControl.input_filter($event, 'delay')" @input="f_updateDatepickerAndNumber('datepicker')" min="0" v-model="d_dateAdjustPrepare.date_interval"></b-form-input>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <modal v-if="d_showDayEditModal" @close="d_showDayEditModal = false" :width="'1000'">
      <h3 slot="header">
        Doz Düzenleme Ekranı
      </h3>
      <div slot="body">
        <table class="dosageAdjustTable">
          <tr class="dosageAdjustTr">
            <th class="dosageAdjustTh">Sonraki Günleri Düzenle</th>
            <th class="dosageAdjustTh">İlaç</th>
            <th class="dosageAdjustTh">Mevcut Doz</th>
            <th class="dosageAdjustTh">Yeni Doz</th>
          </tr>
          <template v-for="(wdm11or12, wdm11or12_ind) in copy_wdmr11or12">
            <tr class="dosageAdjustTr" :style="f_calculateRowStyle(wdm11or12_ind)">
              <td class="dosageAdjustTd">
                <b-form-checkbox :disabled="['no_operation_yet', 'doctor_5'].indexOf(wdm11or12.last_status) === -1" v-model="wdm11or12.concurrent_edit" :value="1" :unchecked-value="0">{{ $t('wdm16.7249') }}</b-form-checkbox>
              </td>
              <td class="dosageAdjustTd">
                {{ wdm11or12.drug.val.label }}
              </td>
              <td class="dosageAdjustTd">
                {{ wdm11or12.planned_dosage + ' ' + wdm11or12.unit }}
              </td>
              <td class="dosageAdjustTd">
                <b-form-input :disabled="['no_operation_yet', 'doctor_5'].indexOf(wdm11or12.last_status) === -1" v-model="wdm11or12.new_dosage" type="number"></b-form-input>
              </td>
            </tr>
          </template>
        </table>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" @click="f_saveDayEdit()">{{ $t('wdm16.4196') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showDayEditModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showCycleAddModal" @close="d_showCycleAddModal = false" :width="'1000'">
      <h3 slot="header">
        Doz Düzenleme Ekranı
      </h3>
      <div slot="body">
        <patient-drug-cycle-add :p_cycleAddData="d_cycleAddData"></patient-drug-cycle-add>
      </div>
      <div slot="footer">
        <!-- <button type="button" class="btn btn-success" @click="f_saveDayEdit()">{{ $t('wdm16.4196') }}</button> -->
        <button type="button" class="btn btn-danger" @click="d_showCycleAddModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Datepicker from 'wisdom-vue-datepicker';
import DrugService from '@/services/drug';
import GlobalService from '@/services/global';
import moment from 'moment';
import PatientService from '@/services/patient';
import WdmService from '@/services/wdm';
import WisdomDataService from "@/services/wisdom_data";
import { DateFunctions } from "@/services/public/date_functions";
import { InputControl } from '@/services/public/functions';
import { mapGetters } from 'vuex';
import {
  default as BmiGfrWidget
} from "@/components/widgets/BmiGfrWidget";
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as PatientDrugButton
} from "@/components/widgets/PatientDrugButton";
import {
  default as PatientDrugChangeDateModal
} from "@/components/widgets/PatientDrugChangeDateModal";
import {
  default as PatientDrugDayNaming
} from "@/components/widgets/PatientDrugDayNaming";
import {
  default as PatientDrugDayShow
} from "@/components/widgets/PatientDrugDayShow";
import {
  default as PatientDrugReportingModal
} from "@/components/widgets/PatientDrugReportingModal";
import {
  default as WisdomDataModal
} from "@/components/widgets/WisdomDataModal";
import {
  default as WisdomDataShow
} from "@/components/widgets/WisdomDataShow";
import {
  default as PatientDrugCycleAdd
} from "@/components/widgets/PatientDrugCycleAdd";

// import Vue from 'vue';

// /* global $ */

export default {
  name: 'PatientDrugDay',
  computed: mapGetters({
    StoreModal: 'modal',
    account_list: 'account_list',
    StoreLoading: 'loading',
    lang: 'lang',
    help: 'help',
    patient: 'patient_selected'
  }),
  props: {
    p_wdmr11or12List: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    p_wdmr11or12: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    p_optionData: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    mother_children: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    p_wdmr11or12_ind: {
      type: Number,
      required: false
    },
    p_patients_chemo_list: {
      type: Boolean,
      required: false
    },
    p_external_patient: {
      type: Object,
      required: false
    },
    p_selectedWdmr10: {
      type: Object,
      required: false,
      default () {
        return {
          'id': '',
          'index': '',
          'status': { 'value': '', 'label': '' }
        }
      }
    },
    show_more_drug_details: {
      type: Object,
      required: false
    },
    p_componentType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      InputControl: InputControl,
      w_presentation: false,
      user: {},
      patient_data: {},
      change: "0",
      view_mode: "table",
      bmi_gfr_change: { status: false },
      d_wdmr10List: [],
      d_wdm10: {},
      d_showWdm11List: true,
      d_showDrugReportingModal: false,
      d_showChangeDateModal: false,
      d_showDayEditModal: false,
      d_showCycleAddModal: false,
      d_selectedWdm10_ind: '',
      d_reportingData: {},
      d_drugChangeDateData: {},
      d_dateLimitData: {},
      d_changeContinuously: { 'status': 0 },
      d_wdmr11EditData: {},
      copy_wdmr11or12: [],
      d_selected_copy_wdmr11or12Index: '',
      d_wdmrDateAdjustData: {},
      d_dateAdjustPrepare: { 'date': '', 'date_interval': 0 },
      d_orderOperation: { 'treatment_order_data': {} },
      d_cycleAddData: {'wdmr10_list': [], 'show_modal': 1, 'patient_id': ''}
    };
  },
  created () {
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.p_external_patient) {
      this.patient_data = JSON.parse(JSON.stringify(this.p_external_patient));
    } else {
      this.patient_data = JSON.parse(JSON.stringify(this.patient));
    }
    // this.f_calculateOptionData();
    // this.copy_wdmr11or12 = JSON.parse(JSON.stringify(this.p_wdmr11or12List[this.p_wdmr11or12_ind]));
  },
  mounted () {
    // this.$options.sockets.onmessage = function (message) {
    //   let socket_data = JSON.parse(message.data);
    //   // console.log(socket_data);
    //   if (socket_data.type && ['wdmr11_or_wdmr12_transfer'].indexOf(socket_data.type) !== -1) {
    //     if (this.patient_data.id === socket_data.data.patient_id) {
    //       if (socket_data.data.date === this.p_wdmr11or12List[p_wdmr11or12_ind].date) {
    //         this.f_updateWdmr11or12FromSocket(socket_data.data.wdmr11or12list);
    //       }
    //     }
    //   }
    // };
  },
  methods: {
    f_bringPatient: function (patient_id) {
      this.$router.push({ path: '/patient/patient_follow', query: { patient_id: patient_id } });
    },
    f_showAddCycleModal: function () {
      this.d_cycleAddData = {'wdmr10_list': [], 'show_modal': 1, 'patient_id': ''};
      this.d_cycleAddData.patient_id = this.patient_data.id;
      let data = {'wdmr11_key_list': [], 'patient_id': this.patient_data.id};
      for (let i in this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list) {
        let wdmr_data = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i];
        if (wdmr_data.type === 'wdm12') {
          data.wdmr11_key_list.push(wdmr_data.parent_key);
        } else {
          data.wdmr11_key_list.push(wdmr_data.type + '_' + wdmr_data.id);
        }
      }
      DrugService.get_wdmr10_from_child(data).then(resp => {
        console.log(resp.data);
        this.d_cycleAddData.wdmr10_list = resp.data.result;
        this.d_showCycleAddModal = true;
      });
    },
    f_calculateRowStyle: function(row_ind) {
      if (row_ind % 2 === 0) {
        return '';
      } else {
        return 'background-color: #ececec;';
      }
    },
    f_updateWdmr11or12FromSocket: function (new_data) {
      let tmp_list = [];
      let current_wdmr12_parent_list = []
      let new_wdmr12_parent_list = []
      let new_wdmr12_list = []
      let new_wdmr11_list = []
      for (let i in new_data) {
        if (new_data[i].type === 'wdm12') {
          new_wdmr12_parent_list.push(new_data[i].parent_key);
          new_wdmr12_list.push('wdm12_' + new_data[i].id);
        } else if (new_data[i].type === 'wdm11') {
          new_wdmr11_list.push('wdm11_' + new_data[i].id);
        }
        tmp_list.push(new_data[i]);
      }
      for (let k in this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list) {
        let old_data = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[k];
        let old_key = old_data.type + '_' + old_data.id;
        if (old_data.type === 'wdm12') {
          if (new_wdmr12_list.indexOf(old_key) !== -1 || new_wdmr11_list.indexOf(old_data.parent_key) !== -1) {
            continue;
          }
        } else if (old_data.type === 'wdm11') {
          if (new_wdmr12_parent_list.indexOf(old_key) !== -1 || new_wdmr11_list.indexOf(old_key) !== -1) {
            continue;
          }
        }
        tmp_list.push(old_data);
      }
      this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list = JSON.parse(JSON.stringify(tmp_list));
      this.f_motherChildren('update_naming');
    },
    f_updateDatepickerAndNumber: function (aim) {
      if (aim === 'number') {
        this.d_dateAdjustPrepare.date_interval = DateFunctions.day_dif(this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].date, this.d_dateAdjustPrepare.date);
      } else if (aim === 'datepicker') {
        this.d_dateAdjustPrepare.date = moment(this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].date).add(this.d_dateAdjustPrepare.date_interval, 'days').format('YYYY-MM-DD');
      }
      this.$forceUpdate();
    },
    f_saveDateAdjust: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Tarih düzenleme işlemi gerçekleştiriliyor.' } });
      let data = { 'list': [] };
      for (let i = this.p_wdmr11or12_ind; i < this.p_wdmr11or12List[this.p_componentType].length; i++) {
        for (let k in this.p_wdmr11or12List[this.p_componentType][i].list) {
          let wdm_data = this.p_wdmr11or12List[this.p_componentType][i].list[k];
          data.list.push({ 'wdmr_key': wdm_data.type + '_' + wdm_data.id, 'date_interval': this.d_dateAdjustPrepare.date_interval });
        }
      }
      if (data.list.length > 0) {
        WdmService.date_adjust(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              // this.f_motherChildren('change_status');
              this.d_showChangeDateModal = false;
              this.$store.commit('Loading', { 'status': false, 'data': {} });
            } else {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              this.d_showChangeDateModal = false;
              alert('error ', resp.data.message);
            }
          });
      } else {
        this.d_showChangeDateModal = false;
        this.$store.commit('Loading', { 'status': false, 'data': {} });
      }
    },
    f_showChangeDate: function () {
      this.d_dateAdjustPrepare = { 'date': this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].date, 'date_interval': 0 }
      this.d_showChangeDateModal = true;
    },
    f_saveDayEdit: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt işlemi gerçekleştiriliyor' } });
      let data = {
        'patient_id': this.patient_data.id,
        'wdmr_list': this.copy_wdmr11or12
      };
      DrugService.dosage_adjust(data).then(resp => {
        // console.log(resp.data);
        if (resp.data.status === "success") {
          let tmp_list = JSON.parse(JSON.stringify(this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list));
          for (let k in resp.data.result.wdmr_list) {
            for (let m in tmp_list) {
              if (tmp_list[m].id === resp.data.result.wdmr_list[k].id) {
                // console.log(tmp_list[m].id);
                // console.log(resp.data.result.wdmr_list[k].id);
                tmp_list[m] = resp.data.result.wdmr_list[k];
              }
            }
          }
          this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list = JSON.parse(JSON.stringify(tmp_list));
          this.f_motherChildren('update_naming');
          // this.f_motherChildren('change_status');
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          this.d_showDayEditModal = false;
        } else {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          alert('error: ', resp.data.message);
        }
      });
    },
    f_showDayEditModal: function () {
      this.d_selected_copy_wdmr11or12Index = '';
      this.f_createCopyForDayEdit();
    },
    f_createCopyForDayEdit: function () {
      this.copy_wdmr11or12 = [];
      for (let i in this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list) {
        let wdmr_data = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i];
        if (wdmr_data.data.general.drug_prem_mix.val.value === 'drug') {
          this.copy_wdmr11or12.push({
            'wdmr_connection': wdmr_data.wdmr_connection,
            'drug': wdmr_data.data.general.drug,
            'planned_dosage': wdmr_data.data.general.planned_dosage.val,
            'new_dosage': wdmr_data.data.general.planned_dosage.val,
            'concurrent_edit': 0,
            'unit': wdmr_data.data.general.dosage.unit.label,
            'last_status': wdmr_data.data.status.status_log.list[wdmr_data.data.status.status_log.list.length - 1].status_log_status.val.value,
            'key': wdmr_data.type + '_' + wdmr_data.id
          });
        }
      }
      this.d_showDayEditModal = true;
    },
    f_showDrugReportingModal: function (wdmr_list) {
      this.d_reportingData = { 'date_list': [], 'drugs_by_date': {}, 'created_by': '' };
      this.d_reportingData.date_list = [wdmr_list.date];
      this.d_reportingData.drugs_by_date[wdmr_list.date] = wdmr_list.list;
      for (let i in wdmr_list.list) {
        if (!this.d_reportingData.created_by) {
          this.d_reportingData.created_by = wdmr_list.list[i].created_by;
          break;
        }
      }
      this.d_showDrugReportingModal = true;
    },
    f_patientDrugButtonIfControl: function (wdmr_list) {
      if (this.p_selectedWdmr10.status.value === 'terminated') {
        return false;
      } else {
        return true;
      }
    },
    DeleteDrugsInThisDay: function (day_ind) {
      if (confirm('Bugündeki ilaçlar silinecektir. Onaylıyor musunuz?')) {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İlaçlar siliniyor' } });
        TreatmentService.delete_drugs_in_this_day(this.patient_data.id, this.treatment_data.id, { 'day_ind': day_ind })
          .then(resp => {
            this.update_treatment_data(resp.data.result);
          });
      }
    },
    ProtocolDayEdit: function (day_ind) {
      this.calculated_days_of_one_day = {};
      this.treatment_data_edit = {};
      this.treatment_data_edit = JSON.parse(JSON.stringify(this.treatment_data));
      this.sort_days_list_of_one_day = [];
      this.sort_days_list_of_one_day.push(day_ind);
      this.calculated_days_of_one_day[day_ind] = JSON.parse(JSON.stringify(this.treatment_data.date_list[day_ind]));
      this.treatment_data_day_edit = JSON.parse(JSON.stringify(this.treatment_data));
      this.show_day_edit_dosage_modal = true;
    },
    is_dosage_changes_eligible (day_ind) {
      return true;
      if (this.treatment_data.status === 'terminated') {
        return false;
      }
      let is_there_drug = false;
      for (let drg_key_dose_change in this.treatment_data.date_list[day_ind]) {
        let cycle_no = this.treatment_data.date_list[day_ind][drg_key_dose_change].cycle_index;
        let day_no = this.treatment_data.date_list[day_ind][drg_key_dose_change].day_index;
        let day_data_dose_change = this.treatment_data.protocol_cycles.drugs[drg_key_dose_change].cycles[cycle_no].days[day_no].data;
        if (['no_operation_yet', 'doctor_5', 'doctor_6'].indexOf(day_data_dose_change.approvals.status) === -1) { // ilaç ile ilgili onay işlemi yapıldıysa
          return false;
        }
        if (this.treatment_data.date_list[day_ind][drg_key_dose_change].type === 'drug') { // sadece premedikasyon içeren günlerde doz değişimi butonunu göstermiyoruz.
          is_there_drug = true;
        }
      }
      if (!is_there_drug) {
        return false;
      }
      return true;
    },
    PrintDayDrugs: function (day_ind) {
      this.calculated_days_of_one_day = {};
      this.sort_days_list_of_one_day = [];
      this.sort_days_list_of_one_day.push(day_ind);
      this.calculated_days_of_one_day[day_ind] = this.treatment_data.date_list[day_ind];
      this.show_report_day = true;
    },
    f_resetAllDrugsStatusOnDay: function (wdmr_list) {
      let modal_data = { 'type': 'confirm', 'text': 'Bugüne ait tüm onay işlemlerini silmek istediğinize emin misiniz? Bu işlem geri alınamaz! Mevcut wdm12 bağlantıları silinerek wdm11 ler güncellenir', 'centered': true, 'title': 'Bugüne ait tüm onay işlemlerini silmek istediğinize emin misiniz', 'confirm': false, 'function_name': 'f_resetAllDrugsStatusOnDayConfirmed', 'arguments': [wdmr_list, this.p_wdmr11or12_ind] };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_resetAllDrugsStatusOnDayConfirmed: function (wdmr_list, wdmr11or12_ind) {
      if (wdmr11or12_ind === this.p_wdmr11or12_ind) {
        let data = {};
        data.patient_id = this.patient_data.id;
        data.wdmr_key_list = [];
        for (let i in wdmr_list) {
          data.wdmr_key_list.push(wdmr_list[i].type + '_' + wdmr_list[i].id);
        }
        data.new_status = { 'value': 'no_operation_yet', 'label': 'Henüz işlem yapılmadı.' };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Onay işlemleri siliniyor' } });
        DrugService.reset_all_drugs_status_on_day(data)
          .then(resp => {
            this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list = resp.data.result.wdmr11or12list;
            this.f_motherChildren('update_naming');
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            // this.f_motherChildren('change_status');
          });
      }
    },
    DateAdjust: function (day_ind) {
      this.doctor_delay_data.date = day_ind;
      this.doctor_delay_data.new_date = this.doctor_delay_data.date;
      this.doctor_delay_data.offset = 0;
      this.doctor_delay_data.calculated_day_drugs = this.treatment_data.date_list[day_ind];
      this.doctor_delay_data.change_type = 'next_all_days_in_all_next_cycles';
      this.show_doctor_delay_modal = true;
    },
    is_date_today: function (dt) {
      let date = new Date(dt);
      date.setHours(0, 0, 0, 0);
      let dateMsec = date.getTime();
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let todayMsec = today.getTime();
      let msecPerDay = 86400000;
      let day_dif = Math.round((dateMsec / msecPerDay) - (todayMsec / msecPerDay));
      if (day_dif === 0) {
        return true;
      } else {
        return false;
      }
    },
    DateFormat: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
    },
    f_motherChildren: function (op) {
      if (this.mother_children[op] === 0) {
        this.mother_children[op] = 1;
      } else {
        this.mother_children[op] = 0;
      }
    }
  },
  watch: {
    // 'p_wdmr11or12_ind': function () {
    //   this.copy_wdmr11or12 = JSON.parse(JSON.stringify(this.p_wdmr11or12List[this.p_wdmr11or12_ind]));
    // },
    'd_cycleAddData.show_modal': function () {
      if (!this.d_cycleAddData.show_modal) {
        this.d_showCycleAddModal = false;
      }
    },
    'mother_children.forceUpdate': function () {
      this.$forceUpdate();
    },
    'mother_children.change_status': function () {
      this.$forceUpdate();
    },
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_resetAllDrugsStatusOnDayConfirmed') {
          if (this.StoreModal.data.confirm) {
            this.f_resetAllDrugsStatusOnDayConfirmed(this.StoreModal.data.arguments[0], this.StoreModal.data.arguments[1]);
          }
        }
      }
    }
  },
  components: {
    Datepicker,
    BmiGfrWidget,
    Modal,
    PatientDrugButton,
    PatientDrugChangeDateModal,
    PatientDrugCycleAdd,
    PatientDrugDayNaming,
    PatientDrugDayShow,
    PatientDrugReportingModal,
    WisdomDataModal,
    WisdomDataShow
  }
};

</script>

<style>
.dosageAdjustTable {
  border: solid 1px; width: 100%;

}
.dosageAdjustTr {
  border: solid 1px;
  
}
.dosageAdjustTd {
  border: solid 1px;
  
}
.dosageAdjustTh {
  border: solid 1px; text-align: center; background-color: #ececec;
}
</style>