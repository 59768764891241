import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  save_laboratory_order (data) {
    return Vue.http.post(API_BASE_URL + 'patient_order/save_laboratory_order', data);
  },
  save_radiology_order (data) {
    return Vue.http.post(API_BASE_URL + 'patient_order/save_radiology_order', data);
  }
};
