<template>
  <div>
    <div class="col-12" style="background-color: rgb(77, 212, 106); text-align: center;">
      <strong> {{ drug.name }} {{ drug_type !== 'drug' ? '(' + main_drug_name + ' ' + drug_type + ')' : '' }} </strong>
    </div>
    <table class="col-12">
      <thead>
        <tr style="background-color: rgb(190, 223, 197); text-align: center; font-weight: bold;">
          <th style="width: 15%;"> İlaç </th>
          <th colspan="2" style="width: 80%;"> Ürün </th>
          <th style="width: 3%;"> Adet </th>
          <th style="width: 2%;"> </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowspan="3">
            <b> {{ drug.name }} </b>
            <br>
            <div>
              (Verilecek Doz: {{ drug.planned_dosage }} {{ drug.unit | show_unit_calculate}})
            </div>
            <div>
              <template v-if="d_selectedProtocolNo">
                <!-- <template v-if="f_atLeastOneBrandInStock(drug.brands)"> -->
                <template v-if="f_atLeastOneBrandMatch(drug.brands)">
                  <b-form-checkbox v-model="drug.pharmacy_status" :value="1" :unchecked-value="0">Eczane İstemi</b-form-checkbox>
                </template>
                <template v-else>
                  <small style="color: red;">Bu üründen stokta görünmüyor.</small>
                </template>
              </template>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="padding: 5px;">
            <v-select ref="selected_brand" name="selected_brand" v-model="drug.selected_brand" :options="drug.brands">
              <template slot="option" slot-scope="option">
                <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;" :disabled="true">
                  <template v-if="d_selectedProtocolNo && option.stock">
                    <span :style="option.stock.count ? 'color: green' : 'color: red'"> [ stok = {{ option.stock.count }} ] </span>
                  </template>
                  <span> {{ option.label }} </span>
                </div>
              </template>
            </v-select>
          </td>
          <td style="padding: 5px;">
            <input v-model="drug_count" type="number" name="count" :id="dy_ind + drug_key + 'none' + 'drug' + 'none'" value="1">
          </td>
          <td style="text-align: right; padding-right: 10px;">
            <button type="button" class="btn btn-success" @click="add_barcode(dy_ind, drug_key, 'none', 'drug', 'none')"><i class="fa fa-plus fa-1x"></i></button>
          </td>
        </tr>
        <tr style="border-bottom: solid 1px black;">
          <td colspan="4">
            <div v-for="(brand, brand_ind) in drug.brand_count" style="background-color: #f1ffc1; width: 100%;">
              <b-button variant="danger" @click="delete_barcode(brand_ind, false)"><i class="fa fa-minus fa-1x"></i></b-button>
              {{ brand.label }} <small> ( {{ brand_ind }} )</small>
              <template v-if="f_calculateBrandStock(brand_ind, drug.brands)">
                <strong style="color: #0089ff;"> [ Stok:  {{ f_calculateBrandStock(brand_ind, drug.brands) }} ] </strong>
              </template>
              <template v-else>
                <strong style="color: red;"> [ Stok: yok ] </strong>
              </template>
              <strong style="color: blue;"> [Adet: {{ brand.count }} ] </strong>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <template v-if="drug.fluid !== undefined">
      <div class="col-12" style="background-color: rgb(77, 212, 106); text-align: center;">
        <strong> {{ drug.name }} {{ drug_type !== 'drug' ? '(' + main_drug_name + ' ' + drug_type + ' mayi)' : '(mayi)' }} </strong>
      </div>
      <table class="col-12">
        <thead>
          <tr style="background-color: rgb(190, 223, 197); text-align: center; font-weight: bold;">
            <th style="width: 15%;"> İlaç </th>
            <th colspan="2" style="width: 80%;"> Ürün </th>
            <th style="width: 3%;"> Adet </th>
            <th style="width: 2%;"> </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="3">
              <b> {{ drug.fluid.name }} </b>
              <br>
              <div>
                ( Uygulanacak Hacim = {{ drug.fluid.fluid_value }} ml )
              </div>
              <div>
                <template v-if="d_selectedProtocolNo">
                  <template v-if="f_atLeastOneBrandMatch(drug.fluid.brands)">
                    <b-form-checkbox v-model="drug.fluid.pharmacy_status" :value="1" :unchecked-value="0">Eczane İstemi</b-form-checkbox>
                  </template>
                  <template v-else>
                    <small style="color: red;">Bu üründen stokta görünmüyor.</small>
                  </template>
                </template>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="padding: 5px;">
              <v-select ref="selected_brand" name="selected_brand" v-model="drug.fluid.selected_brand" :options="drug.fluid.brands">
                <template slot="option" slot-scope="option">
                  <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                    <template v-if="d_selectedProtocolNo && option.stock">
                      <span :style="option.stock.count ? 'color: green' : 'color: red'"> [ stok = {{ option.stock.count }} ] </span>
                    </template>
                    <span> {{ option.label }} </span>
                  </div>
                </template>
              </v-select>
            </td>
            <td style="padding: 5px;">
              <input v-model="fluid_count" type="number" name="count" :id="'fluid' + dy_ind + drug_key + 'none' + 'drug' + 'none'" value="1">
            </td>
            <td style="text-align: right; padding-right: 10px;">
              <button type="button" class="btn btn-success" @click="add_barcode(dy_ind, drug_key, 'none', 'drug', 'none', true)"><i class="fa fa-plus fa-1x"></i></button>
            </td>
          </tr>
          <tr style="border-bottom: solid 1px black;">
            <td colspan="4">
              <div v-for="(brand, brand_ind) in drug.fluid.brand_count" style="background-color: #f1ffc1; width: 100%;">
                <b-button variant="danger" @click="delete_barcode(brand_ind, true)"><i class="fa fa-minus fa-1x"></i></b-button>
                {{ brand.label }} <small> ( {{ brand_ind }} )</small>
                <template v-if="f_calculateBrandStock(brand_ind, drug.fluid.brands)">
                  <strong style="color: #0089ff;"> [ Stok:  {{ f_calculateBrandStock(brand_ind, drug.fluid.brands) }} ] </strong>
                </template>
                <template v-else>
                  <strong style="color: red;"> [ Stok: yok ] </strong>
                </template>
                <strong style="color: blue;"> [Adet: {{ brand.count }} ] </strong>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <!--mixxxxxxxxxxxxxxxx-->
  </div>
</template>

<script>
import moment from 'moment';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';

export default {
  name: 'PatientDrugBrandSelectionDetail',
  computed: {
    ...mapGetters({
      patient: 'patient_selected'
    })
  },
  props: {
    treatment_order_data: {
      type: Object,
      required: false
    },
    hims_protocol_data: {
      type: Object,
      required: false
    },
    patient_data: {
      type: Object,
      required: false
    },
    drug: {
      type: Object,
      required: false
    },
    drug_key: {
      type: String,
      required: false
    },
    d_selectedProtocolNo: {
      type: String,
      required: false
    },
    dy_ind: {
      type: String,
      required: false
    },
    drug_type: {
      type: String,
      required: false
    },
    main_drug_name: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      moment: moment,
      drug_count: 0,
      fluid_count: 0
    };
  },
  created () {},
  mounted () {},
  methods: {
    f_calculateBrandStock: function (barcode_no, brand_list) {
      for (let b in brand_list) {
        if (brand_list[b].value === barcode_no) {
          if (brand_list[b].stock && brand_list[b].stock.count !== undefined) {
            return brand_list[b].stock.count;
          }
        }
      }
      return false;
    },
    f_selectThisProtocol: function (open_pro_ind) {
      this.SelectedProtocolInd === open_pro_ind ? this.SelectedProtocolInd = '' : this.SelectedProtocolInd = open_pro_ind;
      if (this.SelectedProtocolInd !== '') {
        this.hims_protocol_data.selected_protocol = this.patient_data.open_protocol_list.result[open_pro_ind];
        this.d_selectedProtocolNo = this.patient_data.open_protocol_list.result[open_pro_ind].protocol_no;
        this.f_changePharmacyStatus(1);
      } else {
        this.hims_protocol_data.selected_protocol = {};
        this.d_selectedProtocolNo = '';
        this.f_changePharmacyStatus(0);
      }
    },
    f_changePharmacyStatus: function (status) {
      // status = 0, 1
      for (let day in this.treatment_order_data.days) {
        for (let drug_key in this.treatment_order_data.days[day]) {
          // console.log('drug_key', drug_key);
          // herhangi bir brand stoğu var ise o zaman check 1 olabilir.
          this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key], status);
          if (this.treatment_order_data.days[day][drug_key]['drug_mix']) {
            for (let mx in this.treatment_order_data.days[day][drug_key]['drug_mix']) {
              // console.log('mx', mx);
              this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key]['drug_mix'][mx], status);
            }
          }
          if (this.treatment_order_data.days[day][drug_key].fluid) {
            this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key].fluid, status);
          }
          let prem_types = ['before', 'after', 'during'];
          for (let p in prem_types) {
            for (let k in this.treatment_order_data.days[day][drug_key][prem_types[p]]) {
              // console.log('prem ind', k);
              this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key][prem_types[p]][k], status);
              if (this.treatment_order_data.days[day][drug_key][prem_types[p]][k].fluid) {
                this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key][prem_types[p]][k].fluid, status);
              }
              if (this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix']) {
                for (let mx in this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix']) {
                  // console.log('mix ', mx);
                  this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix'][mx], status);
                }
              }
            }
          }
        }
      }
    },
    f_controlBrandStock: function (data, status) {
      // status = 1 ise
      if (status && data.brands) {
        if (this.f_atLeastOneBrandMatch(data.brands)) {
          data['pharmacy_status'] = 1;
        } else {
          data['pharmacy_status'] = 0;
        }
      } else {
        data['pharmacy_status'] = 0;
      }
    },
    f_atLeastOneBrandInStock: function (brand_list) {
      for (let b in brand_list) {
        if (brand_list[b].stock && brand_list[b].stock.count) {
          return true;
        }
      }
      return false;
    },
    f_atLeastOneBrandMatch: function (brand_list) {
      for (let b in brand_list) {
        if (brand_list[b].stock !== undefined) {
          return true;
        }
      }
      return false;
    },
    f_calculateStyleProtocolType (type_id) {
      if (type_id === 0) {
        return 'color: red; font-weight: bold;';
      } else if (type_id === 1) {
        return 'color: green; font-weight: bold;';
      } else if (type_id === 2) {
        return 'color: #b3246c; font-weight: bold;';
      } else if (type_id === 3) {
        return 'color: red; font-weight: bold;';
      } else if (type_id === 4) {
        return 'color: blue; font-weight: bold;';
      } else if (type_id === 5) {
        return 'color: black; font-weight: bold;';
      }
    },
    CalculateProtocolType: function (type_id) {
      if (type_id === 0) {
        return 'Diğer';
      } else if (type_id === 1) {
        return 'Poliklinik';
      } else if (type_id === 2) {
        return 'Yatan Hasta';
      } else if (type_id === 3) {
        return 'Acil';
      } else if (type_id === 4) {
        return 'Günübirlik';
      } else if (type_id === 5) {
        return 'TempInpatient';
      }
    },
    add_barcode: function (dy_ind, drug_key, prem_ind, type, mix_ind, fluid = false) {
      if (fluid) {
        console.log(this.drug);
        if (!this.drug.fluid.selected_brand || this.fluid_count === 0) {
          alert('Lütfen öncelikle ürün seçiminizi yapınız.');
          return;
        }
        let barcode_no = this.drug.fluid.selected_brand.value;
        if (this.d_selectedProtocolNo) {
          if (this.drug.fluid.selected_brand.stock !== undefined) {
            if (!this.drug.fluid.barcode_list) {
              this.drug.fluid.barcode_list = [];
            }
            if (this.drug.fluid.barcode_list.indexOf(barcode_no) === -1) {
              this.drug.fluid.barcode_list.push(barcode_no);
            }
            if (!this.drug.fluid.brand_count) {
              this.drug.fluid.brand_count = {};
            }
            this.drug.fluid.brand_count[barcode_no] = {};
            this.drug.fluid.brand_count[barcode_no].count = this.fluid_count;
            this.drug.fluid.brand_count[barcode_no].label = this.drug.fluid.selected_brand.label;
          } else {
            alert('Bu ürün wisdomera ile hbys arasında eşleştirme yapılmamıştır. Eşleştirme işlemi devam etmektedir. Tamamladığında eczaneye gönderebilirsiniz.');
          }
        } else {
          if (!this.drug.fluid.barcode_list) {
            this.drug.fluid.barcode_list = [];
          }
          if (this.drug.fluid.barcode_list.indexOf(barcode_no) === -1) {
            this.drug.fluid.barcode_list.push(barcode_no);
          }
          if (!this.drug.fluid.brand_count) {
            this.drug.fluid.brand_count = {};
          }
          this.drug.fluid.brand_count[barcode_no] = {};
          this.drug.fluid.brand_count[barcode_no].count = this.fluid_count;
          this.drug.fluid.brand_count[barcode_no].label = this.drug.fluid.selected_brand.label;
        }
        this.$forceUpdate();
      } else {
        if (!this.drug.selected_brand || this.drug_count === 0) {
          alert('Lütfen öncelikle ürün seçiminizi yapınız.');
          return;
        }
        let barcode_no = this.drug.selected_brand.value;
        if (this.d_selectedProtocolNo) {
          if (this.drug.selected_brand.stock !== undefined) {
            if (!this.drug.barcode_list) {
              this.drug.barcode_list = [];
            }
            if (this.drug.barcode_list.indexOf(barcode_no) === -1) {
              this.drug.barcode_list.push(barcode_no);
            }
            if (!this.drug.brand_count) {
              this.drug.brand_count = {};
            }
            this.drug.brand_count[barcode_no] = {};
            this.drug.brand_count[barcode_no].count = this.drug_count;
            this.drug.brand_count[barcode_no].label = this.drug.selected_brand.label;
          } else {
            alert('Bu ürün wisdomera ile hbys arasında eşleştirme yapılmamıştır. Eşleştirme işlemi devam etmektedir. Tamamladığında eczaneye gönderebilirsiniz.');
          }
        } else {
          if (!this.drug.barcode_list) {
            this.drug.barcode_list = [];
          }
          if (this.drug.barcode_list.indexOf(barcode_no) === -1) {
            this.drug.barcode_list.push(barcode_no);
          }
          if (!this.drug.brand_count) {
            this.drug.brand_count = {};
          }
          this.drug.brand_count[barcode_no] = {};
          this.drug.brand_count[barcode_no].count = this.drug_count;
          this.drug.brand_count[barcode_no].label = this.drug.selected_brand.label;
        }
        this.$forceUpdate();
      }
    },
    delete_barcode: function (brand_ind, fluid = false) {
      if (fluid) {
        this.$delete(this.drug.fluid.brand_count, brand_ind);
        this.drug.fluid.barcode_list.splice(this.drug.fluid.barcode_list.indexOf(brand_ind), 1);
      } else {
        this.$delete(this.drug.brand_count, brand_ind);
        this.drug.barcode_list.splice(this.drug.barcode_list.indexOf(brand_ind), 1);
      }
      this.$forceUpdate();
    }
  },
  watch: {},
  components: {
    vSelect
  }
};

</script>

<style type="text/css">
td,
th {}

</style>

