<template>
  <div>
    <b-card class="card-accent-secondary" style="margin-bottom: 5px;" no-body>
      <b-card-header header-text-variant="dark" class="p-1">
        <b-row>
          <b-col cols="12">
            <strong>Siklus İşlemleri</strong>
            <!-- <b-button class="pull-right" @click="f_reset()" variant="danger">Reset</b-button> -->
          </b-col>
        </b-row>
      </b-card-header>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Kaç Siklus Eklenecek" :label-cols="3" label-for="d_newCycleCount">
            <b-form-input v-model="d_newCycleCount" name="d_newCycleCount" id="d_newCycleCount" type="number" :min="1" :max="20"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <template v-for="(wdmr10_data, wdmr10_data_ind) in p_cycleAddData.wdmr10_list">
            <b-card :key="'wdmr10_data_' + wdmr10_data_ind" class="card-accent-secondary">
              <b-card-header header-text-variant="dark" class="p-1">
                <b-row>
                  <b-col cols="12">
                    <strong>{{ wdmr10_data.label }}</strong>
                  </b-col>
                </b-row>
              </b-card-header>
              <template v-for="(wdmr_connection, wdmr_connection_ind) in d_preparedData[wdmr10_data.type + '_' + wdmr10_data.id]">
                <b-row :key="'wdmr_connection_' + wdmr_connection_ind" @click="wdmr_connection.will_add_cycle ? wdmr_connection.will_add_cycle = 0 : wdmr_connection.will_add_cycle = 1; $forceUpdate()">
                  <b-col cols="2" :style="wdmr_connection.will_add_cycle ? 'background-color: #4dbd74; cursor: pointer;' : 'cursor: pointer;'">
                    <li v-if="wdmr_connection.will_add_cycle" class="fa fa-check"> Siklus Ekle</li>
                    <li v-else class="fa fa-times"> Siklus Ekleme</li>
                  </b-col>
                  <b-col cols="10" :style="wdmr_connection.will_add_cycle ? 'background-color: #4dbd74; cursor: pointer;' : 'cursor: pointer;'">{{ wdmr_connection.label }}</b-col>
                </b-row>
              </template>
            </b-card>
          </template>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button block variant="success" @click="f_addCyclesToWdmr10()"> KAYDET</b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { show_unit_calculate } from '../../filters/index';
import WisdomDataService from "@/services/wisdom_data";

export default {
  name: 'PatientDrugCycleAdd',
  mounted () {
    let user = localStorage.getItem('user');
    this.user = JSON.parse(user);
  },
  computed: {},
  props: {
    p_cycleAddData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      d_preparedData: {},
      d_newCycleCount: 1
    };
  },
  created () {
    this.f_prepareWorkingData();
  },
  methods: {
    f_prepareWorkingData: function () {
      this.d_preparedData = {};
      for (let i in this.p_cycleAddData.wdmr10_list) {
        let wdmr10_data = this.p_cycleAddData.wdmr10_list[i];
        let wdmr10_key = wdmr10_data.type + '_' + wdmr10_data.id;
        this.d_preparedData[wdmr10_key] = {};
        for (let k in wdmr10_data.data.general.drug_list.list) {
          let drug_data = wdmr10_data.data.general.drug_list.list[k];
          if (drug_data.detailed_info.val.value === 'yes' && drug_data.wdmr_connection && drug_data.wdmr_connection.val !== '') {
            let drug_label = this.f_createDrugInfo(drug_data);
            this.d_preparedData[wdmr10_key][drug_data.wdmr_connection.val] = { 'will_add_cycle': 0, 'label': drug_label };
          }
        }
      }
    },
    f_createDrugInfo: function (drug_data) {
      let txt = '';
      txt += drug_data.drug.val.label;
      txt += ', ';
      txt += drug_data.dosage.val;
      txt += ' ';
      txt += show_unit_calculate(drug_data.dosage.unit.value);
      return txt;
    },
    f_addCyclesToWdmr10: function () {
      if (this.d_newCycleCount === '' || parseInt(this.d_newCycleCount) <= 0 || parseInt(this.d_newCycleCount) > 20) {
        alert('Yeni eklenecek siklus sayısı 1 ile 20 arasında olmalıdır');
      } else {
        let data = {};
        data.patient_id = this.p_cycleAddData.patient_id;
        data.new_cycle_count = parseInt(this.d_newCycleCount);
        data.data = {};
        for (let wdmr10_key in this.d_preparedData) {
          let tmp_data = [];
          for (let connection_id in this.d_preparedData[wdmr10_key]) {
            if (this.d_preparedData[wdmr10_key][connection_id].will_add_cycle) {
              tmp_data.push(connection_id);
            }
          }
          if (tmp_data.length > 0) {
            data.data[wdmr10_key] = tmp_data;
          }
        }
        if (Object.keys(data.data).length === 0) {
          alert('Hiç ilaç seçmediniz');
        } else {
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Sikluslar Ekleniyor' } });
          WisdomDataService.add_new_cycles_to_wdmr10(data)
            .then(resp => {
              this.p_cycleAddData.show_modal = false;
              this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            });
        }
      }
    }
  },
  watch: {},
  components: {}
};

</script>

<style type="text/css">


</style>

