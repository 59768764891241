<template>
  <div class="no_wrap_line">
    <b-row v-if="!show_application" class="no_wrap_line" style="border-bottom: solid 1px;">
      <b-col :cols="view_protocol === 'macro' ? '2' : '3'">
        <strong>{{ drug.drug.label }}</strong>
        <template v-if="main_drug">
          <template v-if="drug_type !== 'mix'">
            <br>( {{ main_drug.drug.label }} {{ drug_type + '_prem' }} )
          </template>
          <template v-else>
            <br>( {{ main_drug.drug.label }} {{ $t('wdm16.4028') }} )
          </template>
        </template>
      </b-col>
      <b-col :cols="view_protocol === 'macro' ? '2' : '3'">
        <b-row>
          <b-col cols="12" v-if="!drug.med_without_cycles">
            <template v-if="detailed_view">
              <strong>Doz: </strong>
              <template v-for="(x, x_ind, order) in drug.dosage">
                <strong>{{ x_ind }}:</strong>
                <template v-for="(cyc, cyc_ind, cyc_ord) in x">
                  <span style="font-style: italic;">
                  s{{ cyc_ind }}g{{ ListingNumbers.calculate(cyc, 'together') }}{{(parseInt(cyc_ord) + 1) !== Object.keys(x).length ? '; ' : '' }}
                </span>
                </template>
              </template>
            </template>
            <template v-else>
              {{ Object.keys(drug.dosage).join(' - ') }}
            </template>
          </b-col>
          <b-col cols="12" v-else>
            {{ drug.dosage }}
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" v-if="!drug.med_without_cycles">
            <template v-if="detailed_view">
              <strong>Planlanan Doz: </strong>
              <template v-for="(x, x_ind, order) in drug.planned_dosage">
                <strong>{{ x_ind }}:</strong>
                <template v-for="(cyc, cyc_ind, cyc_ord) in x">
                  s{{ cyc_ind }}g{{ ListingNumbers.calculate(cyc, 'together') }}{{(parseInt(cyc_ord) + 1) !== Object.keys(x).length ? '; ' : '' }}
                </template>
              </template>
            </template>
            <template v-else>
              ( {{ Object.keys(drug.planned_dosage).join(' - ') }} )
            </template>
          </b-col>
          <b-col cols="12" v-else>
            ( {{ drug.planned_dosage }} )
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4" v-if="view_protocol === 'macro'">
        <b-row v-if="drug.dosage_form && (drug.dosage_form['12'] || drug.dosage_form['13']) && drug.fluid && Object.keys(drug.fluid).length > 0">
          <b-col cols="12">
            <template v-if="detailed_view">
              <strong>Mayi: </strong>
              <template v-for="(x, x_ind, order) in drug.fluid">
                {{ x.label }}:
                <template v-for="(cyc, cyc_ind, cyc_ord) in x.data">
                  s{{ cyc_ind }}g{{ ListingNumbers.calculate(cyc, 'together') }}{{(parseInt(cyc_ord) + 1) !== Object.keys(x.data).length ? '; ' : '' }}
                </template>
              </template>
            </template>
            <template v-else>
              <strong>Mayi:</strong>
              <template v-for="(x, x_ind, order) in drug.fluid">
                {{ x.label }}
                <template v-if="(parseInt(order) + 1) !== Object.keys(drug.fluid).length"> - </template>
              </template>
            </template>
          </b-col>
        </b-row>
        <b-row v-if="drug.dosage_form && (drug.dosage_form['12'] || drug.dosage_form['13']) && drug.fluid && Object.keys(drug.fluid).length > 0">
          <b-col cols="12">
            <template v-if="detailed_view">
              <strong>Volüm: </strong>
              <template v-for="(x, x_ind, order) in drug.fluid_value">
                {{ x_ind }} cc:
                <template v-for="(cyc, cyc_ind, cyc_ord) in x">
                  s{{ cyc_ind }}g{{ ListingNumbers.calculate(cyc, 'together') }}{{(parseInt(cyc_ord) + 1) !== Object.keys(x).length ? '; ' : '' }}
                </template>
              </template>
            </template>
            <template v-else>
              <strong>Volüm:</strong> {{ Object.keys(drug.fluid_value).join(' - ') }} cc
            </template>
          </b-col>
        </b-row>
        <template v-if="!drug.med_without_cycles">
          <b-row v-if="drug.dosage_form && (drug.dosage_form['12'] || drug.dosage_form['13'] || drug.dosage_form['26'])">
            <b-col cols="12" v-if="drug.dosage_form['26']">
              <strong>Süre:</strong> 0-5 dk
            </b-col>
            <b-col cols="12" v-else>
              <template v-if="detailed_view">
                <strong>Süre: </strong>
                <template v-for="(x, x_ind, order) in drug.duration">
                  {{ x_ind }} {{ drug.dosage_form['12'] ? ' dk' : ' gün' }}:
                  <template v-for="(cyc, cyc_ind, cyc_ord) in x">
                    s{{ cyc_ind }}g{{ ListingNumbers.calculate(cyc, 'together') }}{{(parseInt(cyc_ord) + 1) !== Object.keys(x).length ? '; ' : '' }}
                  </template>
                </template>
              </template>
              <template v-else>
                <strong>Süre:</strong> {{ Object.keys(drug.duration).join(' - ') }} {{ drug.dosage_form['12'] ? ' dk' : ' gün' }}
              </template>
            </b-col>
          </b-row>
        </template>
        <b-row v-if="drug.dosage_form">
          <b-col cols="12" v-if="!drug.med_without_cycles">
            <template v-if="detailed_view">
              <strong>Uygulama: </strong>
              <template v-for="(x, x_ind, order) in drug.dosage_form">
                {{ x.label }}:
                <template v-for="(cyc, cyc_ind, cyc_ord) in x.data">
                  s{{ cyc_ind }}g{{ ListingNumbers.calculate(cyc, 'together') }}{{(parseInt(cyc_ord) + 1) !== Object.keys(x.data).length ? '; ' : '' }}
                </template>
              </template>
            </template>
            <template v-else>
              <strong>Uygulama:</strong>
              <template v-for="(x, x_ind, order) in drug.dosage_form">
                {{ x.label }}
                <template v-if="(parseInt(order) + 1) !== Object.keys(drug.dosage_form).length"> - </template>
              </template>
            </template>
          </b-col>
          <b-col cols="12" v-else>
            <strong>Uygulama: </strong>{{ drug.dosage_form }}
          </b-col>
        </b-row>
        <b-row v-if="drug.frequency">
          <b-col cols="12" v-if="!drug.med_without_cycles">
            <template v-if="detailed_view">
              <strong>Sıklık: </strong>
              <template v-for="(x, x_ind, order) in drug.frequency">
                {{ x.label }}:
                <template v-for="(cyc, cyc_ind, cyc_ord) in x.data">
                  s{{ cyc_ind }}g{{ ListingNumbers.calculate(cyc, 'together') }}{{(parseInt(cyc_ord) + 1) !== Object.keys(x.data).length ? '; ' : '' }}
                </template>
              </template>
            </template>
            <template v-else>
              <strong>Sıklık:</strong>
              <template v-for="(x, x_ind, order) in drug.frequency">
                {{ x.label }}
                <template v-if="(parseInt(order) + 1) !== Object.keys(drug.frequency).length"> - </template>
              </template>
            </template>
          </b-col>
          <b-col cols="12" v-else>
            <strong>Sıklık: </strong>{{ drug.frequency }}
          </b-col>
        </b-row>
      </b-col>
      <b-col :cols="view_protocol === 'macro' ? '2' : '3'" v-if="drug_type === 'drug' && drug.daycyc">
        {{ drug.daycyc }}
      </b-col>
      <b-col :cols="view_protocol === 'macro' ? '2' : '3'" v-if="drug_type === 'drug' && drug.percyc">
        {{ drug.percyc }}
      </b-col>
      <b-col :cols="view_protocol === 'macro' ? '4' : '6'" v-if="['drug', 'mix'].indexOf(drug_type) === -1" class="text-center">
        <template v-if="detailed_view">
          <strong>Uygulama günleri: </strong>
          <template v-for="(x, x_ind, order) in drug.day">
            <template v-if="parseInt(x_ind) === 0">
              aynı gün:
            </template>
            <template v-else>
              {{ x_ind }} gün {{ drug_type + '_prem' }}:
            </template>
            <template v-for="(cyc, cyc_ind, cyc_ord) in x">
              s{{ cyc_ind }}g{{ ListingNumbers.calculate(cyc, 'together') }}{{(parseInt(cyc_ord) + 1) !== Object.keys(x).length ? '; ' : '' }}
            </template>
          </template>
        </template>
        <template v-else>
          <strong>Uygulama günleri:</strong>
          <template v-for="(x, x_ind, order) in drug.day">
            <template v-if="parseInt(x_ind) === 0">
              aynı gün
            </template>
            <template v-else>
              {{ x_ind }} gün {{ drug_type + '_prem' }}
            </template>
            <template v-if="(parseInt(order) + 1) !== Object.keys(drug.day).length"> - </template>
          </template>
        </template>
      </b-col>
    </b-row>
    <b-row style="border-bottom: solid 1px;" v-if="show_application">
      <b-col cols="12" v-if="!drug.med_without_cycles">
        <template v-if="drug.dosage_form && (drug.dosage_form['12'] || drug.dosage_form['13'])">
          <strong> -> </strong> {{ get_caption(drug.fluid) }} içinde <strong>{{ drug.drug.label }} </strong>, {{ Object.keys(drug.duration).join('-') }}, {{ get_caption(drug.dosage_form) }}, {{ get_caption(drug.frequency) }}
        </template>
        <template v-else>
          <strong> -> </strong> <strong>{{ drug.drug.label }}</strong>,
          <template v-if="drug_type !== 'mix'"> {{ get_caption(drug.dosage_form) }}, {{ get_caption(drug.frequency) }} </template>
        </template>
        <template v-if="main_drug">
          <template v-if="drug_type !== 'mix'">
            ( {{ main_drug.drug.label }} {{ drug_type + '_prem' }} )
          </template>
          <template v-else>
            ( {{ main_drug.drug.label }} {{ $t('wdm16.4028') }} )
          </template>
        </template>
      </b-col>
      <b-col cols="12" v-else>
        <strong> -> </strong> <strong>{{ drug.drug.label }}</strong>, {{ drug.frequency }} {{ drug.dosage_form }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ListingNumbers } from '@/services/public/functions';

// /* global $ */

export default {
  name: 'TreatmentProtocolViewerShow',
  created () {},
  mounted () {},
  computed: mapGetters({
    lang: 'lang'
  }),
  props: {
    drug: {
      type: Object,
      required: true
    },
    main_drug: {
      type: Object,
      required: false
    },
    drug_type: {
      type: String,
      required: true
    },
    detailed_view: {
      type: Boolean,
      required: true
    },
    show_application: {
      type: Boolean,
      required: false
    },
    view_protocol: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      ListingNumbers: ListingNumbers
    };
  },
  methods: {
    get_caption (data) {
      let arr = [];
      for (let i in data) {
        if (arr.indexOf(data[i].label) === -1) {
          arr.push(data[i].label);
        }
      }
      return arr.join('-');
    }
  },
  watch: {},
  components: {}
};

</script>

