<template>
  <div>
    <div v-if="report_icon !== 'not report'" class="row">
      <div class="col-10"></div>
      <div class="col-1">
        <i class="fa fa-print" @click="print" style="font-size: 22px;"></i>
      </div>
    </div>
    <div ref="report_here">
      <div class="col-12" v-if="view_protocol === 'macro'">
        <table>
          <thead>
            <tr style="background-color: #19b9e9;">
              <th colspan="8">
                <strong>Protokol</strong>
              </th>
            </tr>
            <tr>
              <td colspan="8">
                <strong> {{data.name}} </strong>
              </td>
            </tr>
            <tr>
              <td colspan="8" style="text-align: left;">
                <b>{{ $t('wdm16.4206') }}</b> : {{data.protocol_group}}
                <br>
                <b>{{ $t('wdm16.4024') }}</b> : {{data.scope}}
                <br>
                <b>{{ $t('wdm16.3930') }}</b> : {{data.protocol_reference}}
                <br>
                <b>{{ $t('wdm16.1116') }}</b> : {{ diagnosis.label }}
                <br>
                <b>{{ $t('wdm16.4041') }}</b> :
                <span style="padding: 10px; width: 70%; color: green;" v-if="user.settings && user.settings.favorite_protocols && user.settings.favorite_protocols.indexOf(data.id) !== -1">
                    <strong>Favori protokolleri listende var</strong>
                    <button type="button" class="btn-primary" @click="delete_favorite(data.id)">
                      Favori protokollerimden çıkar
                    </button>
                  </span>
                <span style="padding: 10px; color: red;" v-else>
                    <strong>Favori protokolleri listende yok </strong>
                    <button type="button" class="btn-success" @click="add_favorite(data.id)">
                      Favori protokolüm yap
                    </button>
                  </span>
                <br>
                <template v-if="insurance_data && Object.keys(insurance_data).length > 0">
                  <b>Sut bilgileri </b> :
                  <br>
                  <div v-for="(ins, ins_ind) in insurance_data">
                    &nbsp <b> {{ ins_ind }} : </b> {{ ins.rules }}
                    <template v-if="ins.others_rules.length > 0">
                      <br>
                      <span v-for="(other, other_ind) in ins.others_rules">
                        ( {{ other }} )
                      </span>
                    </template>
                  </div>
                </template>
                <template v-if="protocol_notes && protocol_notes.length > 0">
                  <b>{{ $t('wdm16.3765') }}</b> :
                  <span v-for="(nt, nt_ind) in protocol_notes">
                       {{nt.user}} : {{nt.note}}
                     </span>
                  <br>
                </template>
                <template v-else-if="protocol_one_note">
                  <b>{{ $t('wdm16.3765') }}</b> : {{ protocol_one_note }}
                </template>
                <template v-if="insurance_report && insurance_report.date">
                  <b>Rapor</b> : Başlama tarihi = {{ DateFormat(insurance_report.started_at) }} ; Bitiş tarihi = {{ DateFormat(insurance_report.ending_at) }}
                  <br>
                </template>
                <template v-if="indication_approval && indication_approval.status === 'yes'">
                  <b> Endikasyon dışı onay durumu </b> :
                  <span> Endikasyon dışı onam alındıktan sonra uygulanacaktır. </span>
                  <template v-if="indication_approval.started_at && indication_approval.ending_at">
                    &nbsp [ <i> Başlama tarihi = {{ DateFormat(indication_approval.started_at) }} - Bitiş tarihi = {{ date_dif_today(indication_approval.ending_at) }} </i> ]
                  </template>
                  <br>
                  <span v-if="indication_approval.note"> Not =  {{ indication_approval.note }} </span>
                </template>
              </td>
            </tr>
            <tr style="background-color: #d9f0e5;">
              <th style="border: solid 1px black;">{{ $t('wdm16.4231') }}</th>
              <th style="width: 10%; border: solid 1px black;">{{ $t('wdm16.4244') }}</th>
              <th style="width: 30%; border: solid 1px black;">{{ $t('wdm16.3993') }}</th>
              <th style="border: solid 1px black;">{{ $t('wdm16.1178') }}</th>
              <th style="border: solid 1px black;">{{ $t('wdm16.4557') }}</th>
              <th style="border: solid 1px black;">{{ $t('wdm16.4164') }}</th>
              <th style="border: solid 1px black;">{{ $t('wdm16.4259') }}</th>
              <th style="border: solid 1px black;">{{ $t('wdm16.4560') }}</th>
            </tr>
          </thead>
          <tbody v-for="(drug, drug_index) in data.drugs">
            <tr style="border-bottom: solid 1px black;">
              <td colspan="8" style="background-color: rgb(49, 187, 187); padding: 5px;">
                <strong v-if="drug.drug"> {{drug_index + 1 }} -) {{ drug.drug.label }}  </strong>
                <strong v-else style="color: red;">Lütfen ilac seçimini yapınız</strong>
              </td>
            </tr>
            <tr v-for="pr in drug.premedications.laboratory.list" style="background-color: rgb(135, 229, 229);">
              <td> {{ $t('wdm16.3912') }} </td>
              <td> <span v-for="test in pr.tests"> {{ test }} </span> </td>
              <td>
                {{ $t('wdm16.4159') }}
                <div v-for="dy in pr.drug_days">
                  {{ dy === 0 ? $t('wdm16.3984') : dy + '. ' + $t('wdm16.1246') }}
                </div>
              </td>
              <td>
                <div v-for="dy in pr.day">
                  {{ dy === 0 ? $t('wdm16.3984') : dy + ' ' + $t('wdm16.1246') }}
                  <br> ( {{ $t('wdm16.' + variable_langs_id_list.general[pr.type]) }} )
                </div>
              </td>
              <td colspan="4"> {{ pr.note }} </td>
            </tr>
            <tr v-for="pr in drug.premedications.message_to_patient.list" style="background-color: rgb(135, 229, 229);">
              <td> {{ $t('wdm16.3945') }} </td>
              <td> </td>
              <td>
                {{ $t('wdm16.4159') }}
                <div v-for="dy in pr.drug_days">
                  {{ dy === 0 ? $t('wdm16.3984') : dy + '. ' + $t('wdm16.1246') }}
                </div>
              </td>
              <td>
                <div v-for="dy in pr.day">
                  {{ dy === 0 ? $t('wdm16.3984') : dy + ' ' + $t('wdm16.1246') }}
                  <br> ( {{ $t('wdm16.' + variable_langs_id_list.general[pr.type]) }} )
                </div>
              </td>
              <td colspan="4"> {{ pr.message }} </td>
            </tr>
            <tr v-for="pr in drug.premedications.note.list" style="background-color: rgb(135, 229, 229);">
              <td> {{ $t('wdm16.3938') }} </td>
              <td> </td>
              <td>
                {{ $t('wdm16.4159') }}
                <div v-for="dy in pr.drug_days">
                  {{ dy === 0 ? $t('wdm16.3984') : dy + '. ' + $t('wdm16.1246') }}
                </div>
              </td>
              <td>
                <div v-for="dy in pr.day">
                  {{ dy === 0 ? $t('wdm16.3984') : dy + ' ' + $t('wdm16.1246') }}
                  <br> ( {{ $t('wdm16.' + variable_langs_id_list.general[pr.type]) }} )
                </div>
              </td>
              <td colspan="4"> {{ pr.note }} </td>
            </tr>
            <template v-for="(before, i) in drug.before">
              <tr class="table-before">
                <td>
                  <b v-if="before.drug">{{ before.drug.label }}</b>
                  <b v-else style="color: red;">Lütfen ilac seçimini yapınız</b>
                </td>
                <td>
                  {{before.dosage}} {{ before.unit.value | show_unit }}
                  <span v-if="patient !== 'not patient report'">
                          <br> ( {{before.planned_dosage}} {{ before.unit.value | show_unit_calculate }} )
                        </span>
                </td>
                <td> {{ $t('wdm16.4158') }}
                  <br> <b> {{ $t('wdm16.1136') }} = </b> <span v-if="before.frequency">  {{ before.frequency.label }} </span>
                  <br>
                  <span v-for="day in before.day">
                          {{ day === 0 ? $t('wdm16.3984') : day + ' ' +  $t('wdm16.6172') }} |
                        </span>
                </td>
                <td>
                  <ul style="list-style: none;padding: 0px!important;">
                    <li v-for="day in before.drug_days">
                      {{ day }}
                    </li>
                  </ul>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{before.drug_note}}</td>
              </tr>
              <tr v-for="(mx, mx_index) in before.drug_mix" class="table-before">
                <td>
                  <b v-if="mx.drug">{{mx.drug.label}}</b>
                  <b v-else style="color: red;">Lütfen ilac seçimini yapınız</b>
                </td>
                <td>
                  {{mx.dosage}} {{mx.unit.value | show_unit}}
                  <span v-if="patient !== 'not patient report'">
                          <br> ( {{mx.planned_dosage}} {{ mx.unit.value | show_unit_calculate }} )
                        </span>
                </td>
                <td>
                  <span v-if="before.drug"> {{ before.drug.label }} {{ $t('wdm16.4028') }} </span>
                  <span v-else style="color: red;">Lütfen ilac seçimini yapınız</span>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{mx.drug_note}}</td>
              </tr>
            </template>
            <template v-for="(during, i) in drug.during">
              <tr class="table-during">
                <td>
                  <b v-if="during.drug">{{ during.drug.label }}</b>
                  <b v-else style="color: red;">Lütfen ilac seçimini yapınız</b>
                </td>
                <td>
                  {{during.dosage}} {{ during.unit.value | show_unit }}
                  <span v-if="patient !== 'not patient report'">
                          <br> ( {{ during.planned_dosage}} {{ during.unit.value | show_unit_calculate }} )
                        </span>
                </td>
                <td> {{ $t('wdm16.4241') }}
                  <br> <b> {{ $t('wdm16.1136') }} = </b> <span v-if="during.frequency">  {{ during.frequency.label }} </span>
                  <br>
                  <span v-for="day in during.day">
                          {{ day === 0 ? $t('wdm16.3984') : day + ' ' +  $t('wdm16.6172') }} |
                        </span>
                </td>
                <td>
                  <ul style="list-style: none;padding: 0px!important;">
                    <li v-for="day in during.drug_days">
                      {{ day }}
                    </li>
                  </ul>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{during.drug_note}}</td>
              </tr>
              <tr v-for="(mx, mx_index) in during.drug_mix" class="table-during">
                <td>
                  <b v-if="mx.drug">{{mx.drug.label}}</b>
                  <b v-else style="color: red;">Lütfen ilac seçimini yapınız</b>
                </td>
                <td>
                  {{mx.dosage}} {{mx.unit.value | show_unit}}
                  <span v-if="patient !== 'not patient report'">
                          <br> ( {{mx.planned_dosage}} {{ mx.unit.value | show_unit_calculate }} )
                        </span>
                </td>
                <td>
                  <span v-if="during.drug"> {{ during.drug.label }} {{ $t('wdm16.4028') }} </span>
                  <span v-else style="color: red;">Lütfen ilac seçimini yapınız</span>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{mx.drug_note}}</td>
              </tr>
            </template>
            <tr class="table-drug">
              <td>
                <b v-if="drug.drug"> {{drug.drug.label}} </b>
                <b v-else style="color: red;">Lütfen ilac seçimini yapınız</b>
              </td>
              <td>
                {{drug.dosage}} {{drug.unit.value | show_unit }}
                <span v-if="patient !== 'not patient report'">
                          <br> ( {{drug.planned_dosage}} {{ drug.unit.value | show_unit_calculate }} )
                        </span>
              </td>
              <td>
                <span v-if="drug.fluid"> <b> {{ $t('wdm16.4031') }} = </b>  {{drug.fluid.label}} <br>  </span>
                <b> {{ $t('wdm16.4221') }} = </b> {{drug.duration}} {{ $t('wdm16.4397') }}
                <br>
                <b> {{ $t('wdm16.4126') }} = </b> {{drug.dosage_form.value | show_dosage_filter}}
                <br>
                <b> {{ $t('wdm16.1136') }} = </b> <span v-if="drug.frequency">  {{ drug.frequency.label }} </span>
              </td>
              <td style="text-align: center; vertical-align: middle;">
                <ul style="list-style: none;padding: 0px!important;">
                  <li v-for="day in drug.day">{{day}}</li>
                </ul>
              </td>
              <td style="text-align: center; vertical-align: middle;">{{drug.period}}</td>
              <td style="text-align: center; vertical-align: middle;">{{drug.repeat}}</td>
              <td style="text-align: center; vertical-align: middle;">{{drug.delay}}</td>
              <td>{{drug.drug_note}}</td>
            </tr>
            <tr v-for="(mx, mx_index) in drug.drug_mix" class="table-drug">
              <td>
                <b v-if="mx.drug">{{mx.drug.label}}</b>
                <b v-else style="color: red;">Lütfen ilac seçimini yapınız</b>
              </td>
              <td>{{mx.dosage}} {{mx.unit.value | show_unit}} </td>
              <td>
                <span v-if="drug.drug"> {{ drug.drug.label }} {{ $t('wdm16.4028') }} </span>
                <span v-else style="color: red;">Lütfen ilac seçimini yapınız</span>
                <span v-if="patient !== 'not patient report'">
                          <br> ( {{mx.planned_dosage}} {{ mx.unit.value | show_unit_calculate }} )
                        </span>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{mx.drug_note}}</td>
            </tr>
            <template v-for="(after, i) in drug.after">
              <tr class="table-after">
                <td>
                  <b v-if="after.drug">{{ after.drug.label }}</b>
                  <b v-else style="color: red;">Lütfen ilac seçimini yapınız</b>
                </td>
                <td>
                  {{after.dosage}} {{ after.unit.value | show_unit }}
                  <span v-if="patient !== 'not patient report'">
                          <br> ( {{after.planned_dosage}} {{ after.unit.value | show_unit_calculate }} )
                        </span>
                </td>
                <td> {{ $t('wdm16.3816') }}
                  <br> <b> {{ $t('wdm16.1136') }} = </b> <span v-if="after.frequency">  {{ after.frequency.label }} </span>
                  <br>
                  <span v-for="day in after.day">
                          {{ day === 0 ? $t('wdm16.3984') : day + ' ' +  $t('wdm16.6204') }} |
                        </span>
                </td>
                <td>
                  <ul style="list-style: none;padding: 0px!important;">
                    <li v-for="day in after.drug_days">
                      {{ day }}
                    </li>
                  </ul>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{after.drug_note}}</td>
              </tr>
              <tr v-for="(mx, mx_index) in after.drug_mix" class="table-after">
                <td>
                  <b v-if="mx.drug">{{mx.drug.label}}</b>
                  <b v-else style="color: red;">Lütfen ilac seçimini yapınız</b>
                </td>
                <td>
                  {{mx.dosage}} {{mx.unit.value | show_unit}}
                  <span v-if="patient !== 'not patient report'">
                          <br> ( {{mx.planned_dosage}} {{ mx.unit.value | show_unit_calculate }} )
                        </span>
                </td>
                <td>
                  <span v-if="after.drug"> {{ after.drug.label }} {{ $t('wdm16.4028') }} </span>
                  <span v-else style="color: red;"> Lütfen ilac seçimini yapınız </span>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{mx.drug_note}}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="col-12" v-if="view_protocol === 'protocol_name'">
        <table>
          <tbody style="border: solid 1px black;">
            <tr style="background-color: #19b9e9;">
              <th colspan="8">
                <strong>Protokol</strong>
              </th>
            </tr>
            <tr>
              <td colspan="8">
                <strong> {{data.name}} </strong>
                <br>
                <b>{{ $t('wdm16.1116') }}</b> : {{ diagnosis.label }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- radar radar radar radar radar radar radar radar radar radar radar radar -->
      <div class="col-12" v-if="view_protocol === 'micro'">
        <table style="border: solid 1px black;">
          <thead>
            <tr style="background-color: #19b9e9;">
              <th colspan="4">
                <strong>Protokol</strong>
              </th>
            </tr>
            <tr style="background-color: #19b9e9;">
              <td colspan="4">
                <strong> {{data.name}} </strong>
              </td>
            </tr>
            <tr style="background-color: #d9f0e5;">
              <th style="border: solid 1px black;">{{ $t('wdm16.4231') }}</th>
              <th style="width: 10%; border: solid 1px black;">{{ $t('wdm16.4244') }}</th>
              <th style="border: solid 1px black;">{{ $t('wdm16.1178') }}</th>
              <th style="border: solid 1px black;">{{ $t('wdm16.4557') }}</th>
            </tr>
          </thead>
          <tbody v-for="(drug, drug_index) in data.drugs" v-if="drug.type !== 'protocol_premedication'">
            <tr class="table-drug">
              <td>
                <b v-if="drug.drug"> {{drug.drug.label}} </b>
                <b v-else style="color: red;">Lütfen ilac seçimini yapınız</b>
              </td>
              <td>
                {{drug.dosage}} {{drug.unit.value | show_unit }}
                <span v-if="patient !== 'not patient report'">
                          <br> ( {{drug.planned_dosage}} {{ drug.unit.value | show_unit_calculate }} )
                        </span>
              </td>
              <td style="text-align: center; vertical-align: middle;">
                {{ drug.day.join(', ') }}
              </td>
              <td style="text-align: center; vertical-align: middle;">{{drug.period}}</td>
            </tr>
            <tr v-for="(mx, mx_index) in drug.drug_mix" class="table-drug">
              <td>
                <b v-if="mx.drug">{{mx.drug.label}}</b>
                <b v-else style="color: red;">Lütfen ilac seçimini yapınız</b>
              </td>
              <td>{{mx.dosage}} {{mx.unit.value | show_unit}} </td>
              <td>
                <span v-if="drug.drug"> {{ drug.drug.label }} {{ $t('wdm16.4028') }} </span>
                <span v-else style="color: red;">Lütfen ilac seçimini yapınız</span>
                <span v-if="patient !== 'not patient report'">
                          <br> ( {{mx.planned_dosage}} {{ mx.unit.value | show_unit_calculate }} )
                        </span>
              </td>
              <td></td>
            </tr>
          </tbody>
          <tbody>
            <tr style="background-color: #19b9e9;">
              <th colspan="4">
                <strong>Premedikasyon ve antineoplastik ilaç uygulamaları</strong>
              </th>
            </tr>
          </tbody>
          <tbody v-for="(drug, drug_index) in data.drugs">
            <template v-for="(before, i) in drug.before">
              <tr class="table-before">
                <td colspan="4">
                  <span v-if="before.fluid"> {{ before.fluid.label }} </span> içinde
                  <b v-if="before.drug">{{ before.drug.label }}</b>
                  <span v-if="before.drug_mix.length > 0">
                          ( karıştırılarak verilecek ilaçlar = 
                          <span v-for="(mx, mx_index) in before.drug_mix">
                            <b v-if="mx.drug"> {{ mx.drug.label }} </b>
                          </span> )
                  </span>
                  <span v-if="before.duration"> {{ before.duration }} dakikada uygulanacak </span>
                  <span v-if="before.frequency"> {{ before.frequency.label }} </span>
                </td>
              </tr>
            </template>
            <template v-for="(during, i) in drug.during">
              <tr class="table-during">
                <td colspan="4">
                  <span v-if="during.fluid"> {{ during.fluid.label }} içinde </span>
                  <b v-if="during.drug">{{ during.drug.label }}</b>
                  <span v-if="during.drug_mix.length > 0">
                          ( karıştırılarak verilecek ilaçlar = 
                          <span v-for="(mx, mx_index) in during.drug_mix">
                            <b v-if="mx.drug"> {{ mx.drug.label }} </b>
                          </span> )
                  </span>
                  <span v-if="during.duration"> {{ during.duration }} dakikada uygulanacak </span>
                  <span v-if="during.frequency"> {{ during.frequency.label }} </span>
                </td>
              </tr>
            </template>
            <tr class="table-drug">
              <td colspan="4">
                <span v-if="drug.fluid">  {{drug.fluid.label}} içinde </span>
                <b v-if="drug.drug"> {{drug.drug.label}}, </b>
                <span v-if="drug.drug_mix.length > 0">
                          ( karıştırılarak verilecek ilaçlar = 
                          <span v-for="(mx, mx_index) in drug.drug_mix">
                            <b v-if="mx.drug"> {{ mx.drug.label }} </b>
                          </span> )
                </span>
                <span v-if="drug.duration && drug.dosage_form.value === '12'"> {{ parseInt(drug.duration) > 60 ? ((parseInt(drug.duration) / 60).toFixed(1)).toString() + ' saatte ' : (drug.duration).toString() + ' dakikada ' }} , </span>
                <span v-if="drug.duration && drug.dosage_form.value === '13'"> {{ drug.duration }} günde , </span>
                <span v-if="drug.dosage_form"> {{ drug.dosage_form.label }}, </span>
                <span v-if="drug.frequency">  {{ drug.frequency.label }} </span>
              </td>
            </tr>
            <template v-for="(after, i) in drug.after">
              <tr class="table-after">
                <td colspan="4">
                  <span v-if="after.fluid"> {{ after.fluid.label }} içinde </span>
                  <b v-if="after.drug">{{ after.drug.label }}</b>
                  <span v-if="after.drug_mix.length > 0">
                          ( karıştırılarak verilecek ilaçlar = 
                          <span v-for="(mx, mx_index) in after.drug_mix">
                            <b v-if="mx.drug"> {{ mx.drug.label }} </b>
                          </span> )
                  </span>
                  <span v-if="after.duration"> {{ after.duration }} dakikada uygulanacak </span>
                  <span v-if="after.frequency"> {{ after.frequency.label }} </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import Vue from 'vue';
import OptionService from '@/services/option';
import Cleave from 'vue-cleave';
import vSelect from 'vue-select';
import moment from 'moment';
import ProtocolService from '@/services/public/protocol';

// /* global $ */

export default {
  name: 'ProtocolViewer',
  created () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted () {
    let user_list = [];
    user_list.push(this.user.username);
    this.get_user_favorite_protocols(user_list, 'active_user');
  },
  computed: mapGetters({
    lang: 'lang'
  }),
  props: {
    pains: {
      type: Object,
      required: false,
      default: () => {}
    },
    patient_data: {
      type: Object,
      required: false,
      default: {}
    },
    patient: {
      type: String,
      required: false,
      default: 'not patient report'
    },
    view_protocol: {
      type: String,
      required: false,
      default: 'macro'
    },
    protocol_one_note: {
      type: String,
      required: false
    },
    diagnosis: {
      type: Object,
      required: false
    },
    insurance_data: {
      type: Object,
      required: false
    },
    insurance_report: {
      type: Object,
      required: false
    },
    indication_approval: {
      type: Object,
      required: false
    },
    protocol_notes: {
      type: Array,
      required: false
    },
    report_icon: {
      type: String,
      required: false,
      default: 'not report'
    },
    data: {
      type: Object,
      required: false,
      default: { drugs: [{ day: [], before: [{}], after: [{}] }] }
    },
    mode: String
  },
  data () {
    return {
      hospital_data: {},
      types: ['before', 'after', 'during'],
      unit_options: require('@/options/unit').options,
      variable_langs_id_list: require('@/options/variable_langs_id_list').options,
      fluid_options: [],
      options: [],
      computed_name: '',
      user: {}
    };
  },
  methods: {
    get_user_favorite_protocols: function (user_list, which_users) {
      ProtocolService.get_favorite_protocols(user_list)
        .then(resp => {
          if (which_users === 'active_user' && resp.data.result) {
            this.user.settings = resp.data.result[this.user.username];
            this.$forceUpdate();
          }
        });
    },
    add_favorite: function (uuid) {
      ProtocolService.make_my_favorite_protocol(uuid)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.user.settings = resp.data.result;
            this.$forceUpdate();
          }
        });
    },
    delete_favorite: function (uuid) {
      ProtocolService.delete_favorite(uuid)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.user.settings = resp.data.result;
            this.$forceUpdate();
          }
        });
    },
    date_dif_today: function (dt) {
      let date = new Date(dt);
      let dateMsec = date.getTime();
      let today = new Date();
      today.setHours(8, 0, 0, 0);
      let todayMsec = today.getTime();
      let msecPerDay = 86400000;
      let day_dif = Math.round((dateMsec / msecPerDay) - (todayMsec / msecPerDay));
      if (day_dif === 0) {
        return moment(date).format('DD/MM/YYYY') + ' (' + this.$t('wdm16.6218') + ')';
      } else if (day_dif === 1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6207') + ')';
      } else if (day_dif > 1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6227') + ')';
      } else if (day_dif === -1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6208') + ')';
      } else if (day_dif < -1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6215') + ')';
      }
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
    },
    print: function () {
      let w = window.open('', '_blank', 'height=500,width=800,toolbar=0,location=0,menubar=0');
      let pageHtml = htmlTemplate
        .replace('##HEADER##', '')
        .replace('##BODY##', this.$refs.report_here.innerHTML)
        .replace('##FOOTER##', 'Bu protokol sağlıkta yapay zeka sistemi WisdomEra (www.wisdomera.io) ile hazırlanmıştır.');
      w.document.write(pageHtml);
      w.document.close();
      w.onload = function () {
        w.focus();
        w.print();
      };
    },
    compute_name: function (oldname) {
      return oldname;
      // let name = '';
      // for (var i = 0; i < this.data.drugs.length; i++) {
      //   if (this.data.drugs[i] !== undefined && this.data.drugs[i].drug !== undefined) {
      //     name += this.data.drugs[i].drug.value;
      //     if (this.data.drugs[i].day.length) {
      //       name += '(D' + this.data.drugs[i].day.join(',D') + '), ';
      //     }
      //   }
      // }
      // this.computed_name = name;
      // return name;
    }
  },
  watch: {
    lang: function (nlang) {}

  },
  components: {
    Cleave,
    vSelect
  }
};

const htmlTemplate = `
<!DOCTYPE html>
<html>
<head>
  <title></title>
  <style type="text/css">
    @page {
      size: A4;
      margin: 10mm 8mm 15mm 8mm;
    }
    @media print {
      html, body {
        width: 210mm;
        background: white!important;
        height: 297mm;
      }
      .page-header {
        
      }
      .page-footer {
        position: fixed;
        bottom: 0;
      }
    }
    .page-footer {
      position: fixed;
      bottom: 0;
    }
    table, td, tr {
      border: solid 3px black;
    }
    .page-body {
      font-size: 12px;
      width: 100%;
      height: 100%;
    }
    ##CSS##
  </style>
  <link href="static/css/font-awesome.min.css" rel="stylesheet">
  <link href="static/css/simple-line-icons.css" rel="stylesheet">
  <link href="static/css/ol3.css" rel="stylesheet">
  <link href="static/css/style.css" rel="stylesheet">
</head>
<body>
  <div class="page-header">
    ##HEADER##
  </div>
  <div class="page-body">
    ##BODY##
  </div>
  <div class="page-footer">
    ##FOOTER##
  </div>
</body>
</html>
`;

</script>

<style>
.table-before {
  background-color: rgb(158, 238, 158);
  border-bottom: solid 1px black;
}

.table-during {
  border-bottom: solid 1px black;
  background-color: rgb(246, 195, 117);
}

.table-after {
  border-bottom: solid 1px black;
  background-color: rgb(230, 230, 147);
}

.table-drug {
  border-bottom: solid 1px black;
  background-color: rgb(157, 221, 221);
}

</style>

