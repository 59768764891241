<template>
  <div>
    <!-- doctor approve mode -->
    <b-row v-if="if_buttons.control(drug_key, cycle_index, day_index, type, prem_index, approvals_index, 'doctor_approval' + is_all_drugs, day_ind, treatment_data, user, selected_days)">
      <b-col sm="12" md="12">
        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '38')">?</b-badge>
        <b-dropdown variant="primary" size="sm" style="width: 100%; padding: 3px;">
          <template slot="button-content">
            {{ $t('wdm16.4072') }} ({{ type }})
          </template>
          <template v-if="type !== 'drug' || drug_key === 'none' || (type === 'drug' && treatment_data['protocol_cycles']['drugs'][drug_key]['drug']['value'] !== 'radiotherapy')">
            <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_doctor-approval-0') !== -1" @click="days_approval_all_drugs(day_ind, 'doctor_0', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
              {{ $t('wdm16.6139') }}
            </b-dropdown-item>
            <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_doctor-approval-1') !== -1" @click="days_approval_all_drugs(day_ind, 'doctor_1', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
              {{ $t('wdm16.6140') }}
            </b-dropdown-item>
            <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_doctor-approval-2') !== -1" @click="days_approval_all_drugs(day_ind, 'doctor_2', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
              {{ $t('wdm16.6141') }}
            </b-dropdown-item>
            <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_doctor-approval-3') !== -1 && ((day_ind !== 'none' && DateFunctions.day_dif(day_ind, 'today') >= 0) || (day_ind === 'none' && calculate_selected_days()))" @click="days_approval_all_drugs(day_ind, 'doctor_3', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
              {{ $t('wdm16.6142') }}
            </b-dropdown-item>
            <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_doctor-approval-4') !== -1 && ((day_ind !== 'none' && DateFunctions.day_dif(day_ind, 'today') >= 0) || (day_ind === 'none' && calculate_selected_days()))" @click="days_approval_all_drugs(day_ind, 'doctor_4', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
              {{ $t('wdm16.6135') }}
            </b-dropdown-item>
            <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_doctor-approval-5') !== -1 && if_buttons.control(drug_key, cycle_index, day_index, type, prem_index, approvals_index, 'doctor_5' + is_all_drugs, day_ind, treatment_data, user)" @click="days_approval_all_drugs(day_ind, 'doctor_5', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
              {{ $t('wdm16.6136') }}
            </b-dropdown-item>
            <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_doctor-approval-6') !== -1" @click="days_approval_all_drugs(day_ind, 'doctor_6', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
              {{ $t('wdm16.6137') }}
            </b-dropdown-item>
            <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_doctor-approval-7') !== -1 && ((day_ind !== 'none' && DateFunctions.day_dif(day_ind, 'today') >= 0) || (day_ind === 'none' && calculate_selected_days()))" @click="days_approval_all_drugs(day_ind, 'doctor_7', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
              {{ $t('wdm16.6138') }}
            </b-dropdown-item>
            <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_doctor-approval-8') !== -1" @click="days_approval_all_drugs(day_ind, 'doctor_8', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
              {{ $t('wdm16.6127') }}
            </b-dropdown-item>
          </template>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_doctor-approval-9') !== -1 && !is_all_drugs && if_buttons.control(drug_key, cycle_index, day_index, type, prem_index, approvals_index, 'doctor_9', day_ind, treatment_data, user)" @click="days_approval_all_drugs(day_ind, 'doctor_9', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6134') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <!-- Medication request brands -->
    <b-row v-if="if_buttons.control(drug_key, cycle_index, day_index, type, prem_index, approvals_index, 'nurse_approval' + is_all_drugs, day_ind, treatment_data, user) && DateFunctions.day_dif(day_ind, 'today') >= 0">
      <b-col sm="12" md="12">
        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '39')">?</b-badge>
        <b-dropdown variant="primary" size="sm" style="width: 100%; padding: 3px;">
          <template slot="button-content">
            {{ $t('wdm16.4587') }} ({{ type }})
          </template>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_nurse-approval-0') !== -1" @click="days_nurse_approval(day_ind, 'nurse_0', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6144') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_nurse-approval-1') !== -1" @click="days_approval_all_drugs(day_ind, 'nurse_1', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6143') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_nurse-approval-2') !== -1" @click="days_brands_edit(day_ind, 'nurse_2', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6145') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <!-- Pharmacy approve -->
    <b-row v-if="if_buttons.control(drug_key, cycle_index, day_index, type, prem_index, approvals_index, 'pharmacy_approval' + is_all_drugs, day_ind, treatment_data, user)">
      <b-col sm="12" md="12">
        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '40')">?</b-badge>
        <b-dropdown variant="primary" size="sm" style="width: 100%; padding: 3px;">
          <template slot="button-content">
            {{ $t('wdm16.3962') }} ({{ type }})
          </template>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_pharmacy-approval-0') !== -1" @click="days_approval_all_drugs(day_ind, 'pharmacy_0', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.3929') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_pharmacy-approval-1') !== -1" @click="days_approval_all_drugs(day_ind, 'pharmacy_1', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.4150') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_pharmacy-approval-2') !== -1" @click="days_approval_all_drugs(day_ind, 'pharmacy_2', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6118') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_pharmacy-approval-3') !== -1" @click="days_approval_all_drugs(day_ind, 'pharmacy_3', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6119') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_pharmacy-approval-4') !== -1" @click="days_approval_all_drugs(day_ind, 'pharmacy_4', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6117') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <!-- preparation -->
    <b-row v-if="if_buttons.control(drug_key, cycle_index, day_index, type, prem_index, approvals_index, 'preparation_approval' + is_all_drugs, day_ind, treatment_data, user)">
      <b-col sm="12" md="12">
        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '41')">?</b-badge>
        <b-dropdown variant="primary" size="sm" style="width: 100%; padding: 3px;">
          <template slot="button-content"> {{ $t('wdm16.4573') }} ({{ type }}) </template>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_preparation-approval-0') !== -1" @click="days_approval_all_drugs(day_ind, 'preparation_0', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6151') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_preparation-approval-1') !== -1" @click="days_approval_all_drugs(day_ind, 'preparation_1', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6150') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_preparation-approval-2') !== -1" @click="days_approval_all_drugs(day_ind, 'preparation_2', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.3804') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_preparation-approval-3') !== -1" @click="days_approval_all_drugs(day_ind, 'preparation_3', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6149') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_preparation-approval-4') !== -1" @click="days_approval_all_drugs(day_ind, 'preparation_4', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6148') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <!-- #################### patient-drug match ####################### -->
    <b-row v-if="if_buttons.control(drug_key, cycle_index, day_index, type, prem_index, approvals_index, 'match_approval' + is_all_drugs, day_ind, treatment_data, user)">
      <b-col sm="12" md="12">
        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '42')">?</b-badge>
        <b-dropdown variant="primary" size="sm" style="width: 100%; padding: 3px;">
          <template slot="button-content"> {{ $t('wdm16.3983') }} ({{ type }}) </template>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_match-approval-0') !== -1" @click="days_approval_all_drugs(day_ind, 'match_0', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6125') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_match-approval-1') !== -1" @click="days_approval_all_drugs(day_ind, 'match_1', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6124') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <!-- #################### Administration ####################### -->
    <b-row v-if="if_buttons.control(drug_key, cycle_index, day_index, type, prem_index, approvals_index, 'administration_approval' + is_all_drugs, day_ind, treatment_data, user)">
      <b-col sm="12" md="12">
        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '43')">?</b-badge>
        <b-dropdown variant="primary" size="sm" style="width: 100%; padding: 3px;">
          <template slot="button-content"> {{ $t('wdm16.3829') }} ({{ type }}) </template>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_administration-approval-0') !== -1" @click="days_approval_all_drugs(day_ind, 'administration_0', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6126') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_administration-approval-1') !== -1 &&!is_all_drugs" @click="administration_1_function(day_ind, 'administration_1', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6121') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_administration-approval-2') !== -1" @click="days_approval_all_drugs(day_ind, 'administration_2', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6123') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_administration-approval-3') !== -1" @click="days_approval_all_drugs(day_ind, 'administration_3', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6122') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('oncology_treatment_administration-approval-4') !== -1" @click="days_approval_all_drugs(day_ind, 'administration_4', type, drug_key, cycle_index, day_index, prem_index, approvals_index)">
            {{ $t('wdm16.6147') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <modal v-if="show_nurse_modal" @close="show_nurse_modal = false" :width="'1000'">
      <div slot="header">
        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '44')">?</b-badge>
        <h3> {{ $t('wdm16.3985') }} </h3>
      </div>
      <div slot="body">
        <brand-selection :treatment_order_data="treatment_order_data" :treatment_data="treatment_data" :patient_data="patient_data" :hims_protocol_data="hims_protocol_data"></brand-selection>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-primary" @click="days_approval_all_drugs(treatment_order_data.dy_no, treatment_order_data.status_id, treatment_order_data.type, treatment_order_data.drug_key)">
          {{ $t('wdm16.4155') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="show_nurse_modal = false">{{ $t('wdm16.3748') }}</button>
      </div>
    </modal>
    <modal v-if="show_administration_1_modal" @close="show_administration_1_modal = false">
      <div slot="header">
        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '45')">?</b-badge>
        <h3> Tamamı uygulanamayan ilaç bilgisi </h3>
      </div>
      <div slot="body">
        <div class="form-group row">
          <div class="col-sm-3">
            <strong>İlaç</strong>
          </div>
          <div v-if="administration_1_data.type === 'drug'" class="col-sm-9">
            {{ treatment_data.protocol_cycles.drugs[administration_1_data.drug_key].drug.label }}
          </div>
          <div v-else class="col-sm-9">
            {{ treatment_data.protocol_cycles.drugs[administration_1_data.drug_key].cycles[administration_1_data.cycle_index].days[administration_1_data.day_index].data[administration_1_data.type][administration_1_data.prem_key].drug.label }}
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <strong>Planlanan ilaç dozu</strong>
          </div>
          <div v-if="administration_1_data.type === 'drug'" class="col-sm-9">
            {{ treatment_data.protocol_cycles.drugs[administration_1_data.drug_key].cycles[administration_1_data.cycle_index].days[administration_1_data.day_index].data.planned_dosage }}
          </div>
          <div v-else class="col-sm-9">
            {{ treatment_data.protocol_cycles.drugs[administration_1_data.drug_key].cycles[administration_1_data.cycle_index].days[administration_1_data.day_index].data[administration_1_data.type][administration_1_data.prem_key].planned_dosage }}
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <strong>Uygulanan ilaç dozu</strong>
          </div>
          <div class="col-sm-9">
            <input type="number" :max="administration_1_data.type === 'drug' ? treatment_data.protocol_cycles.drugs[administration_1_data.drug_key].cycles[administration_1_data.cycle_index].days[administration_1_data.day_index].data.planned_dosage : treatment_data.protocol_cycles.drugs[administration_1_data.drug_key].cycles[administration_1_data.cycle_index].days[administration_1_data.day_index].data[administration_1_data.type][administration_1_data.prem_key].planned_dosage" name="administration_1_data.given_dosage" v-model="administration_1_data.given_dosage">
          </div>
        </div>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-primary" @click="days_approval_all_drugs(administration_1_data.dy_no, administration_1_data.status_id, administration_1_data.type, administration_1_data.drug_key, administration_1_data.cycle_index, administration_1_data.day_index, administration_1_data.prem_key, administration_1_data.prem_day); show_administration_1_modal = false;">
          Kaydet
        </button>
        <button type="button" class="btn btn-danger" @click="show_administration_1_modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showPatientLastLaboratory" @close="d_showPatientLastLaboratory = false" :width="'1000'">
      <div slot="header"> <i class="fa fa-eyedropper"></i> Hasta Son Laboratuar Verileri </div>
      <div slot="body">
        <last-laboratory :patient_data="patient_data" :show_mode="'anormal'"></last-laboratory>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_showPatientLastLaboratory = false">{{ $t('wdm16.4053') }}</b-button>
        <b-button variant="success" @click="CompleteDaysApproval()">Tedavi Onay İşlemine Devam Et</b-button>
      </div>
    </modal>
    <modal v-if="d_showPharmacy" @close="d_showPharmacy = false" :width="'1500'">
      <div slot="header"> <i class="fa fa-eyedropper"></i> Hasta Eczane Ekranı </div>
      <div slot="body">
        <pharmacy-widget ref="pharmacy_widget" :patient_data="patient_data" :hims_protocol_data="hims_protocol_data" :is_chemotherapy_approval="true"></pharmacy-widget>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_showPharmacy = false">{{ $t('wdm16.4053') }}</b-button>
        <b-button variant="success" @click="CompleteDaysApprovalFromPharmacyPage()">Tedavi Onay İşlemine Devam Et</b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import PharmacyService from '@/services/pharmacy';
import {
  default as PharmacyWidget
} from '@/components/widgets/PharmacyWidget';
import { show_unit_calculate } from '../../filters/index';
import { mapGetters } from 'vuex';
import TreatmentService from '@/services/treatment';
import {
  default as BrandSelection
} from './BrandSelection';
import { DateFunctions } from '@/services/public/date_functions';
import { if_buttons } from '@/services/public/functions';
import {
  default as Modal
} from '@/components/widgets/Modal';
import {
  default as LastLaboratory
} from '@/components/widgets/LastLaboratory';
import store from '@/store';
import moment from 'moment';

export default {
  name: 'TreatmentCycleDrugButtons',
  computed: {
    ...mapGetters({
      lang: 'lang',
      help: 'help',
      StoreModal: 'modal',
      StoreTest: 'test'
    })
  },
  props: {
    selected_days: {
      type: Array,
      required: false
    },
    is_all_drugs: {
      type: String,
      required: false,
      default: ''
    },
    drug_key: {
      type: String,
      required: true
    },
    cycle_index: {
      type: String,
      required: true
    },
    day_index: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    prem_index: {
      type: String,
      required: true
    },
    approvals_index: {
      type: String,
      required: true
    },
    day_ind: {
      type: String,
      required: true
    },
    treatment_data: {
      type: Object,
      required: true
    },
    patient_data: {
      type: Object,
      required: true,
      default: {}
    },
    children_mother: {
      type: Object,
      required: false
    }
  },
  data () {
    return { // variables
      hims_protocol_data: { 'pharmacy_list': {}, 'selected_protocol': {} },
      d_selectedHimsProtocolData: '',
      d_showPharmacy: false,
      d_showPatientLastLaboratory: false,
      days_approval_data: '',
      premDaysApprovalData: '',
      store: store,
      if_buttons: if_buttons,
      DateFunctions: DateFunctions,
      rule_results: { rule_ignore: [], list: [], status: '' },
      types: ['before', 'during', 'after'],
      drug_edit_mode: [],
      show_nurse_modal: false,
      show_administration_1_modal: false,
      administration_1_data: {},
      nurse_approval_data: {},
      nurse_approval_day_data: {},
      treatment_order_data: {},
      treatment_order_data_save: {},
      variable_langs_id_list: require('@/options/variable_langs_id_list').options,
      WebserviceProtocolInsuranceData: require('@/options/webservice').ProtocolInsuranceData,
      user: {},
      hospital_data: {},
      textOfPremedicationOfDoctorApproval: '',
      mlpcare_insurance_data: {
        'pain_condition': '',
        'allergy': '',
        'finding': '',
        'history_drug': '',
        'story': '',
        'history': '',
        'family_history': '',
        'symptom': '',
        'symptom_start_date': '',
        'pre_diagnosis': '',
        'main_diagnosis': '',
        'care_plan': '',
        'discharged_plan': '',
        'history_disease': '',
        'history_surgery': '',
        'history_habit': '',
        'blood_pressure': '',
        'weight': '',
        'height': '',
        'temperature': '',
        'pulse': '',
        'oxygen_saturation': '',
        'respiratory_rate': ''
      },
      d_pharmacyFormat: {
        'material_name': '',
        'starting_date': '',
        'material_id': '',
        'unit_id': '',
        'hungry_full': '',
        'usage_type': '',
        'infusion_speed': '',
        'speed_measure': '',
        'dose_period': '',
        'dose_quantity': '',
        'doctor_note': '',
        'req_quantity_from_chemist': '',
      },
      usage_type_wisdom_mlpcare_match: {
        '1': '7',
        '2': '7',
        '3': '33',
        '4': '7',
        '5': '35',
        '6': '28',
        '7': '34',
        '8': '18',
        '9': '5',
        '10': '4',
        '11': '27',
        '12': '1',
        '13': '1',
        '14': '20',
        '15': '17',
        '16': '26',
        '17': '24',
        '18': '22',
        '19': '16',
        '20': '24',
        '21': '23',
        '22': '11',
        '23': '6',
        '24': '11',
        '25': '18',
        '26': '6',
        '27': '6'
      },
      unit_match_with_mlpcare: {
        'ULS': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'mu': 768,
          'mu_m2': 768,
          'mu_kg': 768,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'FLR': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'mu': 768,
          'mu_m2': 768,
          'mu_kg': 768,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'GOZ': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'mu': 768,
          'mu_m2': 768,
          'mu_kg': 768,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'IZT': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'mu': 768,
          'mu_m2': 768,
          'mu_kg': 768,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'BHI': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'mu': 768,
          'mu_m2': 768,
          'mu_kg': 768,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'BAH': {
          'adet': 1,
          'ampul': 683,
          'auc': 715,
          'cc': 9,
          'drop': 720,
          'gr': 716,
          'mcg': 717,
          'mcg_m2': 717,
          'mcg_kg': 717,
          'mg': 715,
          'mg_kg': 715,
          'mg_m2': 715,
          'unit_m2': 715,
          'ml': 9,
          'mu': 713,
          'mu_m2': 713,
          'mu_kg': 713,
          'scale': 687,
          'tablet': 682,
          'unite': 719
        },
        'BUR': {
          'adet': 1,
          'ampul': 683,
          'auc': 700,
          'cc': 9,
          'drop': 693,
          'gr': 689,
          'mcg': 696,
          'mcg_m2': 696,
          'mcg_kg': 696,
          'mg': 700,
          'mg_kg': 700,
          'mg_m2': 700,
          'unit_m2': 700,
          'ml': 9,
          'scale': 687,
          'tablet': 682,
          'unite': 705
        },
        'GZU': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'GEB': {
          'adet': 1,
          'ampul': 683,
          'auc': 769,
          'cc': 690,
          'drop': 773,
          'gr': 770,
          'mcg': 771,
          'mcg_m2': 771,
          'mcg_kg': 771,
          'mg': 769,
          'mg_kg': 769,
          'mg_m2': 769,
          'unit_m2': 769,
          'ml': 690,
          'scale': 687,
          'tablet': 682,
          'unite': 772
        },
        'CAN': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 770,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'BTH': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'PEN': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        }
      },
      d_doctor6CalculatedDays: {},
      d_doctor6Days: [],
      d_doctor6Check: false
    };
  },
  beforeCreate: function () {},
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.hospital_data = JSON.parse(localStorage.getItem('hospital_data'));
  },
  mounted: function () {},
  methods: {
    f_calculateDoctor6All: function (dy_no, drug_key) {
      this.d_doctor6Check = false;
      this.d_doctor6CalculatedDays = {};
      this.d_doctor6Days = [];
      for (let drg_date in this.treatment_data.date_list) {
        if (this.treatment_data.date_list[drg_date][drug_key]) {
          this.d_doctor6CalculatedDays[drg_date] = {};
          this.d_doctor6CalculatedDays[drg_date][drug_key] = this.treatment_data.date_list[drg_date][drug_key];
          this.d_doctor6CalculatedDays[drg_date][drug_key].before = [];
          this.d_doctor6CalculatedDays[drg_date][drug_key].during = [];
          this.d_doctor6CalculatedDays[drg_date][drug_key].after = [];
        }
      }
      for (let c_day in this.d_doctor6CalculatedDays) {
        if (c_day > dy_no && this.d_doctor6Days.indexOf(c_day) === -1) {
          // console.log(c_day);
          this.d_doctor6Check = true;
          this.d_doctor6Days.push(c_day);
        }
      }
    },
    calculate_selected_days: function () {
      if (this.selected_days && this.selected_days.length > 0) {
        for (let dt in this.selected_days) {
          if (DateFunctions.day_dif(this.selected_days[dt], 'today') < 0) {
            return false;
          }
        }
        return true;
      } else {
        return true;
      }
    },
    active_func: function (name, param) {
      switch (name) {
        case 'days_approval_all_drugs':
          this.days_approval_all_drugs(param[0], param[1], param[2], param[3], param[4], param[5], param[6], param[7], param[8]);
          break;
      }
    },
    administration_1_function: function (dy_no, status_id, type, drug_key = 'none', cycle_index = '', day_index = '', prem_key = '', prem_day = '') {
      this.administration_1_data = { 'dy_no': dy_no, 'status_id': status_id, 'type': type, 'drug_key': drug_key, 'cycle_index': cycle_index, 'day_index': day_index, 'prem_key': prem_key, 'prem_day': prem_day, 'given_dosage': '' };
      if (type === 'drug') {
        this.administration_1_data.given_dosage = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.planned_dosage;
      } else {
        this.administration_1_data.given_dosage = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_key].planned_dosage;
      }
      this.show_administration_1_modal = true;
    },
    IsTherePremedicationOnDoctorApproval: function () {
      this.premDaysApprovalData = JSON.parse(JSON.stringify(this.days_approval_data));
      let status_id = this.premDaysApprovalData.status_id;
      this.textOfPremedicationOfDoctorApproval = '';
      for (let x in this.premDaysApprovalData.days) {
        let day_no = this.premDaysApprovalData.days[x];
        for (let drg in this.premDaysApprovalData.calculated_days[day_no]) {
          let drug_name = this.treatment_data.protocol_cycles.drugs[drg].drug.label + ':\n';
          let premedication_general_text = '';
          let cycle_index = this.premDaysApprovalData.calculated_days[day_no][drg].cycle_index;
          let day_index = this.premDaysApprovalData.calculated_days[day_no][drg].day_index;
          for (let pr in this.types) {
            let prem_caption = this.types[pr] + ' premedikasyonları:\n';
            let premedication_text = '';
            let premList = [];
            for (let prem in this.premDaysApprovalData.calculated_days[day_no][drg][this.types[pr]]) {
              let prem_index = this.premDaysApprovalData.calculated_days[day_no][drg][this.types[pr]][prem].prem_index;
              let premApprovalDataStatus = '';
              if (this.treatment_data.protocol_cycles.drugs[drg].cycles[cycle_index].days[day_index].data[this.types[pr]][prem_index].approvals[0]) {
                premApprovalDataStatus = this.treatment_data.protocol_cycles.drugs[drg].cycles[cycle_index].days[day_index].data[this.types[pr]][prem_index].approvals[0].status;
              }
              // console.log('status_id::::', status_id);
              // console.log('premApprovalDataStatus::::', premApprovalDataStatus);
              if (
                (['doctor_0', 'doctor_1', 'doctor_2', 'doctor_3', 'doctor_4', 'doctor_7', 'doctor_8'].indexOf(status_id) !== -1 && ['doctor_5', 'doctor_6', 'no_operation_yet'].indexOf(premApprovalDataStatus) !== -1) ||
                (['doctor_5'].indexOf(status_id) !== -1 && ['doctor_0', 'doctor_1', 'doctor_2', 'doctor_3', 'doctor_4', 'doctor_7', 'doctor_8'].indexOf(premApprovalDataStatus) !== -1) ||
                (['doctor_6'].indexOf(status_id) !== -1 && ['doctor_0', 'doctor_1', 'doctor_2', 'doctor_3', 'doctor_4', 'doctor_5', 'doctor_7', 'doctor_8', 'no_operation_yet'].indexOf(premApprovalDataStatus) !== -1)
              ) {
                // bir kere bile pozitif bir durum tespit edilirse type prem olarak düzenliyoruz.
                this.premDaysApprovalData.type = 'prem';
                premList.push(this.premDaysApprovalData.calculated_days[day_no][drg][this.types[pr]][prem]);
                premedication_text += this.treatment_data.protocol_cycles.drugs[drg].cycles[cycle_index].days[day_index].data[this.types[pr]][prem_index].drug.label + ' ' +
                  this.treatment_data.protocol_cycles.drugs[drg].cycles[cycle_index].days[day_index].data[this.types[pr]][prem_index].dosage + ' ' +
                  show_unit_calculate(this.treatment_data.protocol_cycles.drugs[drg].cycles[cycle_index].days[day_index].data[this.types[pr]][prem_index].unit.value) + '\n';
              }
            }
            this.premDaysApprovalData.calculated_days[day_no][drg][this.types[pr]] = premList;
            if (premedication_text) {
              premedication_general_text += prem_caption + premedication_text;
            }
          }
          if (premedication_general_text) {
            this.textOfPremedicationOfDoctorApproval = drug_name + premedication_general_text;
          }
        }
      }
      if (this.textOfPremedicationOfDoctorApproval) {
        this.textOfPremedicationOfDoctorApproval = 'Yapılan işlem => ' +
          this.$t('wdm16.' + this.variable_langs_id_list.approval_choices[status_id]) + '\n' +
          this.textOfPremedicationOfDoctorApproval +
          'İŞLEM BU İLAÇ PREMEDİKASYONLARINA UYGULANSIN MI?';
      }
    },
    f_lookForAnormalTest: function (test) {
      if (test.val) {
        if (test.clb !== undefined && test.clb !== '' && test.clb !== null) {
          if (test.val < test.clb) {
            return true;
          }
        }
        if (test.cub !== undefined && test.cub !== '' && test.cub !== null) {
          if (test.val > test.cub) {
            return true;
          }
        }
        if (test.ub !== undefined && test.ub !== '' && test.ub !== null) {
          if (test.val > test.ub) {
            return true;
          }
        }
        if (test.lb !== undefined && test.lb !== '' && test.lb !== null) {
          if (test.val < test.lb) {
            return true;
          }
        }
        return false;
      }
    },
    f_controlPharmacyStatus: function () {
      // status = 0, 1
      let det = false;
      this.hims_protocol_data.pharmacy_list = {};
      for (let day in this.treatment_order_data.days) {
        for (let drug_key in this.treatment_order_data.days[day]) {
          if (this.treatment_order_data.days[day][drug_key]['pharmacy_status']) {
            det = true;
            this.f_createPharmacyData(day, this.treatment_order_data.days[day][drug_key]);
          }
          if (this.treatment_order_data.days[day][drug_key].fluid && this.treatment_order_data.days[day][drug_key].fluid['pharmacy_status']) {
            det = true;
            this.f_createPharmacyData(day, this.treatment_order_data.days[day][drug_key].fluid, 'yes');
          }
          if (this.treatment_order_data.days[day][drug_key]['drug_mix']) {
            for (let mx in this.treatment_order_data.days[day][drug_key]['drug_mix']) {
              if (this.treatment_order_data.days[day][drug_key]['drug_mix'][mx]['pharmacy_status']) {
                det = true;
                this.f_createPharmacyData(day, this.treatment_order_data.days[day][drug_key]['drug_mix'][mx]);
              }
            }
          }
          let prem_types = ['before', 'after', 'during'];
          for (let p in prem_types) {
            for (let k in this.treatment_order_data.days[day][drug_key][prem_types[p]]) {
              if (this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['pharmacy_status']) {
                det = true;
                this.f_createPharmacyData(day, this.treatment_order_data.days[day][drug_key][prem_types[p]][k]);
              }
              if (this.treatment_order_data.days[day][drug_key][prem_types[p]][k].fluid && this.treatment_order_data.days[day][drug_key][prem_types[p]][k].fluid['pharmacy_status']) {
                this.f_createPharmacyData(day, this.treatment_order_data.days[day][drug_key][prem_types[p]][k].fluid, 'yes');
              }
              if (this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix']) {
                for (let mx in this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix']) {
                  if (this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix'][mx]['pharmacy_status']) {
                    det = true;
                    this.f_createPharmacyData(day, this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix'][mx]);
                  }
                }
              }
            }
          }
        }
      }
      return det;
    },
    f_createPharmacyData: function (day, data, is_fluid = 'no') {
      if (data['brand_count']) {
        for (let b in data['brand_count']) {
          let x = JSON.parse(JSON.stringify(this.d_pharmacyFormat));
          x['material_name'] = data['brand_count'][b].label;
          // x['material_name'] = data.selected_brand ? data.selected_brand.label : '';
          x['starting_date'] = day + 'T16:00:00.000';
          x['material_id'] = this.d_calculateMaterialId(b, data.brands);
          if (is_fluid === 'yes') {
            x['unit_id'] = this.unit_match_with_mlpcare[this.user.hospital_id]['adet'];
          } else {
            x['unit_id'] = data.unit && data.unit.value && this.unit_match_with_mlpcare[this.user.hospital_id][data.unit.value] ? this.unit_match_with_mlpcare[this.user.hospital_id][data.unit.value] : this.unit_match_with_mlpcare[this.user.hospital_id]['mg'];
          }
          x['hungry_full'] = 'F';
          x['usage_type'] = data.dosage_form && data.dosage_form.value && this.usage_type_wisdom_mlpcare_match[data.dosage_form.value] ? this.usage_type_wisdom_mlpcare_match[data.dosage_form.value] : '1';
          x['dose_period'] = data.frequency && data.frequency.value && data.frequency.value.split('x')[0] ? parseInt(parseInt(data.frequency.value.split('x')[0])) : 1;
          if (is_fluid === 'yes') {
            x['dose_quantity'] = parseFloat(data['brand_count'][b].count).toFixed(2).toString();
            // x['dose_quantity'] = '1';
          } else {
            let dose_quantity = '1';
            if (data['brand_count'][b].ph_dosage) {
              dose_quantity = parseFloat(data['brand_count'][b].ph_dosage).toFixed(2).toString();
            } else {
              if (data.given_dosage) {
                dose_quantity = parseFloat(data.given_dosage).toFixed(2).toString();
              }
            }
            x['dose_quantity'] = dose_quantity;
          }
          if (data.infusion_speed) {
            x['infusion_speed'] = data.infusion_speed.toString();
          } else {
            x['infusion_speed'] = '0';
          }
          x['speed_measure'] = data.speed_measure;
          x['doctor_note'] = '';
          x['req_quantity_from_chemist'] = parseInt(data['brand_count'][b].count);
          if (!this.hims_protocol_data.pharmacy_list[day]) {
            this.hims_protocol_data.pharmacy_list[day] = { 'list': [] };
          }
          let det = false;
          for (let d in this.hims_protocol_data.pharmacy_list) {
            for (let i in this.hims_protocol_data.pharmacy_list[d].list) {
              if (this.hims_protocol_data.pharmacy_list[d].list[i].material_id === x.material_id) {
                det = true;
                this.hims_protocol_data.pharmacy_list[day]['list'][i]['dose_quantity'] = (parseFloat(this.hims_protocol_data.pharmacy_list[day]['list'][i]['dose_quantity']) + parseFloat(x['dose_quantity'])).toFixed(2).toString();
                this.hims_protocol_data.pharmacy_list[day]['list'][i]['req_quantity_from_chemist'] += x['req_quantity_from_chemist'];
              }
            }
          }
          if (!det) {
            this.hims_protocol_data.pharmacy_list[day]['list'].push(x);
          }
        }
      }
    },
    d_calculateMaterialId: function (barcode_no, brand_list) {
      if (barcode_no && brand_list) {
        for (let b in brand_list) {
          if (brand_list[b].value.toString() === barcode_no.toString()) {
            return brand_list[b].stock.id;
          }
        }
      }
      return '';
    },
    days_approval_all_drugs: function (dy_no, status_id, type, drug_key = 'none', cycle_index = '', day_index = '', prem_key = '', prem_day = '') {
      // dy_no calculated_days'den gelen no, type ya drug ya da prem,
      // ürün seçimi ekranında ilaçlar seçildi ve işlem onaylanıyor ise status_id = nurse_0
      if (status_id === 'nurse_0') {
        let det = false;
        for (let dy in this.treatment_order_data.days) {
          for (let drg in this.treatment_order_data.days[dy]) {
            if (this.treatment_order_data.days[dy][drg].type === 'drug') {
              if (this.treatment_order_data.days[dy][drg].barcode_list !== undefined && this.treatment_order_data.days[dy][drg].barcode_list.length === 0) {
                det = true;
                break;
              }
              for (let mx in this.treatment_order_data.days[dy][drg].drug_mix) {
                if (this.treatment_order_data.days[dy][drg].drug_mix[mx].barcode_list.length === 0) {
                  det = true;
                }
              }
            } else {
              for (let typ in this.types) {
                for (let prem in this.treatment_order_data.days[dy][drg][this.types[typ]]) {
                  if (this.treatment_order_data.days[dy][drg][this.types[typ]][prem].barcode_list.length === 0) {
                    det = true;
                    break;
                  }
                  for (let mx in this.treatment_order_data.days[dy][drg][this.types[typ]][prem].drug_mix) {
                    if (this.treatment_order_data.days[dy][drg][this.types[typ]][prem].drug_mix[mx].barcode_list.length === 0) {
                      det = true;
                      break;
                    }
                  }
                  if (det) {
                    break;
                  }
                }
                if (det) {
                  break;
                }
              }
            }
            if (det) {
              break;
            }
          }
          if (det) {
            break;
          }
        }
        if (det) {
          alert('Lütfen ürünlerin seçimlerini tamamlayınız.');
          return;
        }
      }
      let data = { rule_ignore: this.rule_results.rule_ignore, active_func_name: 'days_approval_all_drugs', active_func_parameters: [dy_no, status_id, type, drug_key, cycle_index, day_index, prem_key, prem_day] };
      if (drug_key === 'none') { // tek ilaç onayı yapılmıyor ve gün onayı yapılıyorsa.
        data.calculated_days = this.treatment_data.date_list;
      } else { // tek ilaç onayı yapılıyorsa
        data['calculated_days'] = {};
        data.calculated_days[dy_no] = {};
        data.calculated_days[dy_no][drug_key] = JSON.parse(JSON.stringify(this.treatment_data.date_list[dy_no][drug_key]));
        if (type === 'drug') {
          data.calculated_days[dy_no][drug_key].before = [];
          data.calculated_days[dy_no][drug_key].during = [];
          data.calculated_days[dy_no][drug_key].after = [];
        } else { // before during after
          for (let i in this.types) {
            if (this.types[i] !== type) {
              data.calculated_days[dy_no][drug_key][this.types[i]] = [];
            }
          }
        }
      }
      if (status_id === 'doctor_6' && drug_key !== 'none') {
        this.f_calculateDoctor6All(dy_no, drug_key);
      }
      data['days'] = []; // daha sonra çoklu gün de gönderebilmek için dize olarak oluşturuldu.
      data['status_id'] = status_id;
      data['type'] = type; // drug, prem (çoklu premedikasyon seçimi yapıldıysa before, after, during içinde olabilir), before, after, during
      if (status_id.split('_')[0] === 'doctor') {
        data.calculation_data = {};
        // laboratory data
        if (this.patient_data.last_laboratory['2']) {
          data.calculation_data.lab_date = this.patient_data.last_laboratory['2'].date;
          data.calculation_data.crea = this.patient_data.last_laboratory['2'].val;
          data.calculation_data.gfr = this.patient_data.last_laboratory['2'].gfr;
          data.calculation_data.selected_gfr_formule = this.patient_data.last_laboratory['2'].selected_gfr_formule;
          data.calculation_data.selected_weight_for_gfr = this.patient_data.last_laboratory['2'].selected_weight_for_gfr;
        }
        // bmi data
        if (this.patient_data.last_bmi_data !== 'none') {
          data.calculation_data.bmi_date = this.patient_data.last_bmi_data.date;
          data.calculation_data.bsa = this.patient_data.last_bmi_data.bsa;
          data.calculation_data.length = this.patient_data.last_bmi_data.length;
          data.calculation_data.weight = this.patient_data.last_bmi_data.weight;
          data.calculation_data.selected_weight_for_bsa = this.patient_data.last_bmi_data.selected_weight_for_bsa;
        }
      } else if (status_id.split('_')[0] === 'nurse') {
        data.nurse = this.treatment_order_data;
      }
      let not_today = false;
      if (this.selected_days && this.selected_days.length > 0) {
        data.days = this.selected_days;
        for (let i in this.selected_days) {
          if (DateFunctions.day_dif(this.selected_days[i], 'today') !== 0) {
            not_today = true;
            break;
          }
        }
      } else {
        if (DateFunctions.day_dif(dy_no, 'today') !== 0) {
          not_today = true;
        }
        data.days.push(dy_no);
      }
      if (status_id === 'administration_1') {
        data.given_dosage = this.administration_1_data.given_dosage;
      }
      this.days_approval_data = data;
      //
      this.textOfPremedicationOfDoctorApproval = '';
      if (['doctor_0', 'doctor_1', 'doctor_2', 'doctor_3', 'doctor_4', 'doctor_5', 'doctor_6', 'doctor_7', 'doctor_8'].indexOf(status_id) !== -1 && type === 'drug') {
        this.IsTherePremedicationOnDoctorApproval();
      }
      this.days_approval_data.webservice = { 'protocol_list': [], 'hospitalGroup': this.hospital_data.hospital_group, 'hospitalCode': this.hospital_data.hospital_id, 'sending_json_data': {}, 'sending': 0, 'patientNo': '' };
      if (this.WebserviceProtocolInsuranceData[this.hospital_data.hospital_group]) {
        for (let y in this.WebserviceProtocolInsuranceData[this.hospital_data.hospital_group]['parameter_order']) {
          let hims_param = this.WebserviceProtocolInsuranceData[this.hospital_data.hospital_group]['parameter_order'][y];
          this.days_approval_data.webservice.sending_json_data[hims_param] = '';
        }
      }
      this.days_approval_data.webservice.sending_json_data.symptom_start_date = new Date().toISOString();
      this.days_approval_data.webservice.sending_json_data.pre_diagnosis = this.treatment_data.diagnosis.value;
      this.days_approval_data.webservice.sending_json_data.main_diagnosis = this.treatment_data.diagnosis.value;
      // this.createwebserviceText(status_id);
      // Preparing the text list to send HBYS system
      // hsatanın webservice bağlantısı ve açık protokollerinden Tıbbi Onkoloji var mı kontrol ediliyor.

      // bu if sorgusu alttaki gönderimi kapsadığı zaman sadece güncel protokole bilgi gönderme işlemi yapılıyor
      // if (DateFunctions.day_dif(dy_no, 'today') <= 0) {}

      if (['administration_0', 'administration_1', 'administration_2', 'administration_3', 'doctor_7'].indexOf(status_id) !== -1) {
        if (this.patient_data.hospitals && this.patient_data.hospitals[this.hospital_data.hospital_group] && this.patient_data.hospitals[this.hospital_data.hospital_group][this.hospital_data.hospital_id] && this.patient_data.hospitals[this.hospital_data.hospital_group][this.hospital_data.hospital_id].webservice === 1) {
          this.days_approval_data.webservice.patientNo = this.patient_data.hospitals[this.hospital_data.hospital_group][this.hospital_data.hospital_id].data.id;
          this.createwebserviceText(status_id);
        }
      }
      //
      let settings_info = '';
      if (['doctor_0', 'doctor_1', 'doctor_2', 'doctor_8'].indexOf(status_id) !== -1) {
        if (this.user.settings && this.user.settings.oncologyModule && this.user.settings.oncologyModule.use === '1') {
          if (this.user.settings.oncologyModule.settings.drugApprovalDosage && this.user.settings.oncologyModule.settings.drugApprovalDosage.use === '1') {
            for (let dy in this.days_approval_data.days) {
              for (let drug_key in this.days_approval_data.calculated_days[this.days_approval_data.days[dy]]) {
                let cycle_index = this.days_approval_data.calculated_days[this.days_approval_data.days[dy]][drug_key].cycle_index;
                let day_index = this.days_approval_data.calculated_days[this.days_approval_data.days[dy]][drug_key].day_index;
                let drug_data = this.treatment_data.protocol_cycles.drugs[drug_key].drug;
                let day_data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data;
                if (this.days_approval_data.calculated_days[this.days_approval_data.days[dy]][drug_key].type === 'drug') {
                  let drug_value = drug_data.value;
                  if (this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value]) {
                    if (this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].max && this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].max.use === '1') {
                      if (parseFloat(this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].max.val) < parseFloat(day_data.planned_dosage)) {
                        settings_info += drug_data.label + ' => Maximum dikkat değeri = ' + this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].max.val + ' | Belirlediğiniz değer = ' + day_data.planned_dosage + '\n';
                      }
                    }
                    if (this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].min && this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].min.use === '1') {
                      if (parseFloat(this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].min.val) > parseFloat(day_data.planned_dosage)) {
                        settings_info += drug_data.label + ' => Minimum dikkat değeri = ' + this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].max.val + ' | Belirlediğiniz değer = ' + day_data.planned_dosage + '\n';
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (['doctor_0', 'doctor_1', 'doctor_2', 'doctor_8'].indexOf(status_id) !== -1) {
        for (let lab_ind in this.patient_data.last_laboratory) {
          if (this.f_lookForAnormalTest(this.patient_data.last_laboratory[lab_ind])) {
            this.d_showPatientLastLaboratory = true;
          }
        }
      }
      if (status_id === 'nurse_0') {
        if (this.hims_protocol_data.selected_protocol && this.hims_protocol_data.selected_protocol.protocol_no) {
          if (this.f_controlPharmacyStatus()) {
            let msg = '';
            if (not_today) {
              msg += 'İşlem yaptığınız gün bugüne ait değildir, bu sebeple eczaneden ilaç çekim işlemi yapamayacaksınız.\n';
            }
            if (msg) {
              let modal_data = { 'type': 'confirm', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Tedavi Onay İşlemi Hakkında', 'function_name': 'CompleteDaysApproval', 'confirm': false };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            } else {
              this.d_showPharmacy = true;
            }
            return;
          }
        }
      }
      // laboratuar uyarısı olduğunda öncelik laboratuar bilgisinin gösgterilmesinde. Zaten veri hazırlanmış oluyor. uygun ise completedaysapproval ile devam ediyor
      if (!this.d_showPatientLastLaboratory) {
        let msg = '';
        msg += settings_info;
        if (not_today) {
          msg += 'İşlem yaptığınız gün bugüne ait değildir.\n';
        }
        if (msg) {
          let modal_data = { 'type': 'confirm', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Tedavi Onay İşlemi Hakkında', 'function_name': 'CompleteDaysApproval', 'confirm': false };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        } else {
          this.CompleteDaysApproval();
        }
      }
    },
    createwebserviceText: function (status_id) {
      // console.log('createwebserviceText status_id :::', status_id);
      let str = '';
      let drug_operation = this.$t('wdm16.' + this.variable_langs_id_list.approval_choices[status_id]);
      for (let i in this.days_approval_data.days) {
        let day_index = this.days_approval_data.days[i];
        // console.log(day_index);
        // console.log(DateFunctions.day_dif(day_index, 'today'));
        // alttaki if alttaki for döngüsünü kapsarsa eski tarihlere data oluşmaz
        // if (DateFunctions.day_dif(day_index, 'today') <= 0) {}
        for (let drug_key in this.days_approval_data.calculated_days[day_index]) {
          let calc_day_drug_data = this.days_approval_data.calculated_days[day_index][drug_key];
          let calc_cyc_ind = calc_day_drug_data.cycle_index;
          let calc_day_ind = calc_day_drug_data.day_index;
          let drug_label = this.treatment_data.protocol_cycles.drugs[drug_key].drug.label;
          let drug_data =  this.treatment_data.protocol_cycles.drugs[drug_key].cycles[calc_cyc_ind].days[calc_day_ind].data;
          let drug_planned_dosage = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[calc_cyc_ind].days[calc_day_ind].data.planned_dosage;
          let drug_unit = show_unit_calculate(this.treatment_data.protocol_cycles.drugs[drug_key].cycles[calc_cyc_ind].days[calc_day_ind].data.unit.value);
          drug_unit = drug_unit.replace('  ', '');
          let drug_day = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[calc_cyc_ind].days[calc_day_ind].day.toString();
          if (calc_day_drug_data.type === 'drug') {
            str += drug_label + ' ' + drug_planned_dosage + drug_unit + ' ';
            if (drug_data['dosage_form']['value'].toString() === '26') {
              str += '0-5 dakikada ';
            } else {
              if (drug_data['duration'] && drug_data['duration'] > 0) {
                if (drug_data['dosage_form']['value'].toString() === '13') {
                  str += drug_data['duration'].toString() + ' günde ';
                } else {
                  str += drug_data['duration'].toString() + ' dakikada ';
                }
              }
            }
            if (drug_data['dosage_form'] && drug_data['dosage_form']['label']) {
              str += drug_data['dosage_form']['label'].toLocaleLowerCase('tr').toString() + ' ';
            }
            str += (parseInt(calc_cyc_ind) + 1).toString() + '.siklus ' + drug_day + '.gün, ';
          }
        }

      }
      if (str) {
        this.days_approval_data.webservice.sending = 1;
        // str = moment(new Date().toISOString()).format('YYYY-MM-DD HH:mm') + ' => ' + str + '=> [ ' + drug_operation + ' ]';
        str = str + '> [ ' + drug_operation + ' ]';
        this.days_approval_data.webservice.sending_json_data.care_plan = str;
      }
    },
    CompleteDaysApprovalFromPharmacyPage: function () {
      let d_pharmacyList = this.$refs.pharmacy_widget.d_pharmacyList;
      let d_selectedProtocolData = this.$refs.pharmacy_widget.d_selectedProtocolData;
      let sending_ph_list = {};
      for (let dt in d_pharmacyList) {
        if (!d_selectedProtocolData.recorded_pharmacy_list[dt] || (d_selectedProtocolData.recorded_pharmacy_list[dt] && JSON.stringify(d_selectedProtocolData.recorded_pharmacy_list[dt]) !== JSON.stringify(d_pharmacyList[dt]))) {
          sending_ph_list[dt] = d_pharmacyList[dt];
        }
      }
      let data = {
          'pharmacy_list': sending_ph_list,
          'processID': d_selectedProtocolData.process_id,
          'resourceId': d_selectedProtocolData.resource_id,
          'protocolNo': d_selectedProtocolData.protocol_no,
          'hospitalCode': this.user.hospital_id,
          'hospitalGroup': this.user.hospital_group,
          'patient_id': this.patient_data.id
        }
        // console.log(data);
        // return;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İlaç order kayıt işlemi yapılıyor. Lütfen bekleyiniz.' } });
      PharmacyService.save_pharmacy_order(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.CompleteDaysApproval();
          } else {
            let txt = 'Eczane gönderim işleminde hata oluştu. Eczane istemi yapmadan devam etmek istiyor musunuz?'
            let modal_data = { 'type': 'confirm', 'text': txt, 'centered': true, 'title': 'Ürün & Eczane Onay İşlemleri Hakkında', 'function_name': 'CompleteDaysApproval', 'confirm': false };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        });
    },
    CompleteDaysApproval: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Onay işleminiz gerçekleştiriliyor.' } });
      TreatmentService.approve_all_drugs_in_selected_days(this.patient_data.id, this.treatment_data.id, this.days_approval_data)
        .then(resp => {
          if (resp.data.rule_results.list.length > 0) {
            this.rule_results = resp.data.rule_results;
          } else { // artık yeni bir rule analiz sonrası gelmiyor ise.
            this.rule_results = { rule_ignore: [], list: [], status: '' };
          }
          if (resp.data.result !== 'none') {
            this.update_treatment_data(resp.data.result);
          }
          this.show_nurse_modal = false;
          this.d_showPharmacy = false;
          this.d_showPatientLastLaboratory = false;
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (this.textOfPremedicationOfDoctorApproval) {
            // console.log(this.textOfPremedicationOfDoctorApproval);
            let modal_data = { 'type': 'confirm', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': this.textOfPremedicationOfDoctorApproval, 'centered': true, 'title': 'Premedikasyon İşlemleri Hakkında', 'function_name': 'ApprovalDrugPremedications', 'confirm': false };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          } else {
            if (this.d_doctor6Check) {
              let modal_data = { 'type': 'confirm', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': 'İlacı uygulanmayacak olarak belirlediniz, sonraki günlere de uygulanmasını ister misiniz?', 'centered': true, 'title': 'Premedikasyon İşlemleri Hakkında', 'function_name': 'f_approveDoctor6All', 'confirm': false };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          }
          this.$forceUpdate();
          // this.days_approval_data = {};
        });
    },
    f_approveDoctor6All: function () {
      this.days_approval_data.calculated_days = JSON.parse(JSON.stringify(this.d_doctor6CalculatedDays));
      this.days_approval_data.days = JSON.parse(JSON.stringify(this.d_doctor6Days));
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Onay işleminiz gerçekleştiriliyor.' } });
      TreatmentService.approve_all_drugs_in_selected_days(this.patient_data.id, this.treatment_data.id, this.days_approval_data)
        .then(resp => {
          if (resp.data.rule_results.list.length > 0) {
            this.rule_results = resp.data.rule_results;
          } else { // artık yeni bir rule analiz sonrası gelmiyor ise.
            this.rule_results = { rule_ignore: [], list: [], status: '' };
          }
          if (resp.data.result !== 'none') {
            this.update_treatment_data(resp.data.result);
          }
          this.d_doctor6Check = false;
          this.$forceUpdate();
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        });
    },
    ApprovalDrugPremedications: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Onay işleminiz gerçekleştiriliyor.' } });
      TreatmentService.approve_all_drugs_in_selected_days(this.patient_data.id, this.treatment_data.id, this.premDaysApprovalData)
        .then(resp => {
          if (resp.data.rule_results.list.length > 0) {
            this.rule_results = resp.data.rule_results;
          } else { // artık yeni bir rule analiz sonrası gelmiyor ise.
            this.rule_results = { rule_ignore: [], list: [], status: '' };
          }
          if (resp.data.result !== 'none') {
            this.update_treatment_data(resp.data.result);
          }
          this.textOfPremedicationOfDoctorApproval = '';
          this.$forceUpdate();
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (this.d_doctor6Check) {
            let modal_data = { 'type': 'confirm', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': 'İlacı uygulanmayacak olarak belirlediniz, sonraki günlere de uygulanmasını ister misiniz?', 'centered': true, 'title': 'Premedikasyon İşlemleri Hakkında', 'function_name': 'f_approveDoctor6All', 'confirm': false };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        });
    },
    update_treatment_data: function (new_treatment_data) {
      for (let i in this.treatment_data) {
        this.treatment_data[i] = new_treatment_data[i];
      }
      if (this.children_mother && this.children_mother.treatment_approval) {
        this.children_mother.treatment_approval = false;
      } else if (this.children_mother && !this.children_mother.treatment_approval) {
        this.children_mother.treatment_approval = true;
      }
    },
    days_nurse_approval: function (dy_no, status_id, type, drug_key = 'none', cycle_index, day_index, prem_key, prem_day, day_dif = 0) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Ürün listesi ve stok bilgileri analiz ediliyor. Lütfen bekleyiniz.' } });
      let patientNo = '';
      try {
        patientNo = this.patient_data['hospitals'][this.user.hospital_group][this.user.hospital_id]['data']['id'];
      } catch {
        patientNo = '';
      }
      let data = {
        'dy_no': dy_no,
        'id': this.treatment_data.id,
        'patientNo': patientNo ? patientNo : '',
        'hospitalGroup': this.user.hospital_group,
        'hospitalCode': this.user.hospital_id
      };
      TreatmentService.get_new_drug_list(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            this.treatment_data.drug_list = JSON.parse(JSON.stringify(resp.data.result['drug_list']));
            let drug_order_list = resp.data.result['drug_order_list']
            this.patient_data.open_protocol_list = { 'result': [] };
            this.patient_data.open_protocol_list.result = resp.data.result['eligible_open_protocol_list'];
            this.treatment_order_data = {};
            this.treatment_order_data.dy_no = dy_no;
            this.treatment_order_data.status_id = status_id;
            this.treatment_order_data.type = type;
            this.treatment_order_data.drug_key = drug_key;
            this.treatment_order_data.days = {};
            if (drug_key === 'none') { // tek ilaç onayı yapılmıyor ve gün onayı yapılıyorsa.
              this.treatment_order_data.days[dy_no] = JSON.parse(JSON.stringify(this.treatment_data.date_list[dy_no]));
            } else { // tek ilaç onayı yapılıyorsa
              this.treatment_order_data.days[dy_no] = {};
              this.treatment_order_data.days[dy_no][drug_key] = JSON.parse(JSON.stringify(this.treatment_data.date_list[dy_no][drug_key]));
              if (type === 'drug') {
                this.treatment_order_data.days[dy_no][drug_key].before = [];
                this.treatment_order_data.days[dy_no][drug_key].during = [];
                this.treatment_order_data.days[dy_no][drug_key].after = [];
              } else { // before during after
                for (let i in this.types) {
                  if (this.types[i] !== type) {
                    this.treatment_order_data.days[dy_no][drug_key][this.types[i]] = [];
                  }
                }
              }
            }
            let data = this.treatment_order_data.days[dy_no];
            for (let drg_key in data) {
              if (this.treatment_data.protocol_cycles.drugs[drg_key].drug.value === 'radiotherapy') {
                this.$delete(data, drg_key);
                continue;
              }
              if (type === 'drug') {
                let cycle_index = data[drg_key].cycle_index;
                let day_index = data[drg_key].day_index;
                data[drg_key].brands = this.treatment_data.drug_list[this.treatment_data.protocol_cycles.drugs[drg_key].drug.value].brands;
                data[drg_key].selected_brand = '';
                data[drg_key].barcode_list = [];
                data[drg_key].brand_count = {};
                let data_2 = this.treatment_data.protocol_cycles.drugs[drg_key].cycles[cycle_index].days[day_index].data;
                data[drg_key].frequency = data_2.frequency;
                data[drg_key].duration = data_2.duration;
                data[drg_key].given_dosage = data_2.given_dosage;
                data[drg_key].note_list = data_2.note_list ? data_2.note_list : '';
                data[drg_key].unit = data_2.unit;
                data[drg_key].dosage_form = data_2.dosage_form;
                data[drg_key].dosage = data_2.dosage;
                data[drg_key].infusion_speed = this.f_calculateInfusionSpeed(data_2);
                data[drg_key].speed_measure = this.f_calculateSpeedMeasure(data_2); // ml/saat, ml/dk, mg/saat, ünite/saat, ünite/dk
                if (data_2.fluid) {
                  data[drg_key].fluid = {};
                  data[drg_key].fluid.brands = this.treatment_data.drug_list[data_2.fluid.value].brands;
                  data[drg_key].fluid.selected_brand = '';
                  data[drg_key].fluid.barcode_list = [];
                  data[drg_key].fluid.brand_count = {};
                  data[drg_key].fluid.name = data_2.fluid;
                  data[drg_key].fluid.fluid_value = data_2.fluid_value;
                  data[drg_key].fluid.infusion_speed = this.f_calculateInfusionSpeed(data_2);
                  data[drg_key].fluid.speed_measure = this.f_calculateSpeedMeasure(data_2); // ml/saat, ml/dk, mg/saat, ünite/saat, ünite/dk
                }
                data[drg_key].drug_mix = {};
                let mix_data = this.treatment_data.protocol_cycles.drugs[drg_key].cycles[cycle_index].days[day_index].data.drug_mix;
                for (let mix in mix_data) {
                  data[drg_key].drug_mix[mix_data[mix].drug.value] = {};
                  let new_data = data[drg_key].drug_mix[mix_data[mix].drug.value];
                  new_data.brands = this.treatment_data.drug_list[mix_data[mix].drug.value].brands;
                  new_data.selected_brand = '';
                  new_data.barcode_list = [];
                  new_data.brand_count = {};
                  new_data.name = mix_data[mix].drug;
                  new_data.unit_value = mix_data[mix].unit.value;
                  new_data.mix_ind = mix;
                  new_data.given_dosage = mix_data[mix].given_dosage;
                  new_data.note_list = mix_data[mix].note_list ? mix_data[mix].note_list : '';
                  new_data.unit = mix_data[mix].unit;
                  new_data.dosage = mix_data[mix].dosage;
                  new_data.infusion_speed = this.f_calculateInfusionSpeed(data_2); // ana ilacın süresini koyduk.
                  new_data.speed_measure = this.f_calculateSpeedMeasure(data_2); // ml/saat, ml/dk, mg/saat, ünite/saat, ünite/dk
                }
              } else { // before after during
                for (let i in this.types) {
                  for (let prem in data[drg_key][this.types[i]]) {
                    let prem_index = data[drg_key][this.types[i]][prem].prem_index;
                    let cycle_index = data[drg_key].cycle_index;
                    let day_index = data[drg_key].day_index;
                    data[drg_key][this.types[i]][prem].count = 1;
                    data[drg_key][this.types[i]][prem].selected_brand = '';
                    data[drg_key][this.types[i]][prem].barcode_list = [];
                    data[drg_key][this.types[i]][prem].brand_count = {};
                    data[drg_key][this.types[i]][prem].brands = {};
                    data[drg_key][this.types[i]][prem].brands = this.treatment_data.drug_list[this.treatment_data.protocol_cycles.drugs[drg_key].cycles[cycle_index].days[day_index].data[this.types[i]][prem_index].drug.value].brands;
                    let data_2 = this.treatment_data.protocol_cycles.drugs[drg_key].cycles[cycle_index].days[day_index].data[this.types[i]][prem_index];
                    data[drg_key][this.types[i]][prem].frequency = data_2.frequency;
                    data[drg_key][this.types[i]][prem].duration = data_2.duration;
                    data[drg_key][this.types[i]][prem].given_dosage = data_2.given_dosage;
                    data[drg_key][this.types[i]][prem].note_list = data_2.note_list ? data_2.note_list : '';
                    data[drg_key][this.types[i]][prem].unit = data_2.unit;
                    data[drg_key][this.types[i]][prem].dosage_form = data_2.dosage_form;
                    data[drg_key][this.types[i]][prem].dosage = data_2.dosage;
                    data[drg_key][this.types[i]][prem].infusion_speed = this.f_calculateInfusionSpeed(data_2);
                    data[drg_key][this.types[i]][prem].speed_measure = this.f_calculateSpeedMeasure(data_2); // ml/saat, ml/dk, mg/saat, ünite/saat, ünite/dk
                    if (data_2.fluid) {
                      data[drg_key][this.types[i]][prem].fluid = {};
                      data[drg_key][this.types[i]][prem].fluid.brands = this.treatment_data.drug_list[data_2.fluid.value].brands;
                      data[drg_key][this.types[i]][prem].fluid.selected_brand = '';
                      data[drg_key][this.types[i]][prem].fluid.barcode_list = [];
                      data[drg_key][this.types[i]][prem].fluid.brand_count = {};
                      data[drg_key][this.types[i]][prem].fluid.name = data_2.fluid;
                      data[drg_key][this.types[i]][prem].fluid.fluid_value = data_2.fluid_value;
                      data[drg_key][this.types[i]][prem].fluid.infusion_speed = this.f_calculateInfusionSpeed(data_2);
                      data[drg_key][this.types[i]][prem].fluid.speed_measure = this.f_calculateSpeedMeasure(data_2); // ml/saat, ml/dk, mg/saat, ünite/saat, ünite/dk
                    }
                    data[drg_key][this.types[i]][prem].drug_mix = {};
                    let mix_data = this.treatment_data.protocol_cycles.drugs[drg_key].cycles[cycle_index].days[day_index].data[this.types[i]][prem_index].drug_mix;
                    for (let mix in mix_data) {
                      data[drg_key][this.types[i]][prem].drug_mix[mix_data[mix].drug.value] = {};
                      let new_data = data[drg_key][this.types[i]][prem].drug_mix[mix_data[mix].drug.value];
                      new_data.count = 1;
                      new_data.brands = {};
                      new_data.brands = this.treatment_data.drug_list[mix_data[mix].drug.value].brands;
                      new_data.selected_brand = '';
                      new_data.barcode_list = [];
                      new_data.brand_count = {};
                      new_data.name = mix_data[mix].drug;
                      new_data.given_dosage = mix_data[mix].given_dosage;
                      new_data.unit_value = mix_data[mix].unit.value;
                      new_data.mix_ind = mix;
                      new_data.given_dosage = mix_data[mix].given_dosage;
                      new_data.note_list = mix_data[mix].note_list ? mix_data[mix].note_list : '';
                      new_data.unit = mix_data[mix].unit;
                      new_data.dosage = mix_data[mix].dosage;
                      new_data.infusion_speed = this.f_calculateInfusionSpeed(data_2); // ana ilacın süresini koyduk.
                      new_data.speed_measure = this.f_calculateSpeedMeasure(data_2); // ml/saat, ml/dk, mg/saat, ünite/saat, ünite/dk
                    }
                  }
                }
              }
            }
            this.treatment_order_data_save[dy_no] = {};
            this.treatment_order_data_save[dy_no] = JSON.parse(JSON.stringify(data));
            // console.log(drug_order_list);
            // SAMI SAMI SAMI (brandselection dosyasında otomatik protokol seçim işlemini aktifleştirmeyi unutma)

            for (let drg_key in drug_order_list) {
              if (this.treatment_order_data.days[dy_no][drg_key]) {
                if (this.treatment_order_data.days[dy_no][drg_key]['brand_count'] && drug_order_list[drg_key] && drug_order_list[drg_key]['brand_count']) {
                  this.treatment_order_data.days[dy_no][drg_key]['brand_count'] = drug_order_list[drg_key]['brand_count'];
                  for (let brand_id in drug_order_list[drg_key]['brand_count']) {
                    this.treatment_order_data.days[dy_no][drg_key]['barcode_list'].push(brand_id);
                  }
                }
                if (drug_order_list[drg_key]['fluid'] && drug_order_list[drg_key]['fluid']['brand_count'] && this.treatment_order_data.days[dy_no][drg_key]['fluid'] && this.treatment_order_data.days[dy_no][drg_key]['fluid']['brand_count']) {
                  this.treatment_order_data.days[dy_no][drg_key]['fluid']['brand_count'] = drug_order_list[drg_key]['fluid']['brand_count'];
                  for (let brand_id in drug_order_list[drg_key]['fluid']['brand_count']) {
                    this.treatment_order_data.days[dy_no][drg_key]['fluid']['barcode_list'].push(brand_id);
                  }
                }
                if (drug_order_list[drg_key]['drug_mix']) {
                  for (let drg_mix_value in drug_order_list[drg_key]['drug_mix']) {
                    if (this.treatment_order_data.days[dy_no][drg_key]['drug_mix'] && drug_order_list[drg_key]['drug_mix'][drg_mix_value]['brand_count'] && this.treatment_order_data.days[dy_no][drg_key]['drug_mix'][drg_mix_value] && this.treatment_order_data.days[dy_no][drg_key]['drug_mix'][drg_mix_value]['brand_count']) {
                      this.treatment_order_data.days[dy_no][drg_key]['drug_mix'][drg_mix_value]['brand_count'] = drug_order_list[drg_key]['drug_mix'][drg_mix_value]['brand_count'];
                      for (let brand_id in drug_order_list[drg_key]['drug_mix'][drg_mix_value]['brand_count']) {
                        this.treatment_order_data.days[dy_no][drg_key]['drug_mix'][drg_mix_value]['barcode_list'].push(brand_id);
                      }
                    }
                  }
                }
                for (let i in this.types) {
                  let pr_type = this.types[i];
                  if (drug_order_list[drg_key][pr_type] && Object.keys(drug_order_list[drg_key][pr_type]).length > 0) {
                    for (let pr_info in drug_order_list[drg_key][pr_type]) {
                      for (let k in this.treatment_order_data.days[dy_no][drg_key][pr_type]) {
                        let prem_data = this.treatment_order_data.days[dy_no][drg_key][pr_type][k];
                        if (drug_order_list[drg_key][pr_type][prem_data['prem_index']] && drug_order_list[drg_key][pr_type][prem_data['prem_index']]['brand_count'] && prem_data['brand_count']) {
                          prem_data['brand_count'] = drug_order_list[drg_key][pr_type][prem_data['prem_index']]['brand_count'];
                          for (let brand_id in drug_order_list[drg_key][pr_type][prem_data['prem_index']]['brand_count']) {
                            prem_data['barcode_list'].push(brand_id);
                          }
                        }
                        if (drug_order_list[drg_key][pr_type][prem_data['prem_index']]['fluid'] && drug_order_list[drg_key][pr_type][prem_data['prem_index']]['fluid']['brand_count'] && prem_data['fluid'] && prem_data['fluid']['brand_count']) {
                          prem_data['fluid']['brand_count'] = drug_order_list[drg_key][pr_type][prem_data['prem_index']]['fluid']['brand_count'];
                          for (let brand_id in drug_order_list[drg_key][pr_type][prem_data['prem_index']]['fluid']['brand_count']) {
                            prem_data['fluid']['barcode_list'].push(brand_id);
                          }
                        }
                        if (drug_order_list[drg_key][pr_type][prem_data['prem_index']]['drug_mix']) {
                          for (let drg_mix_value in drug_order_list[drg_key][pr_type][prem_data['prem_index']]['drug_mix']) {
                            if (prem_data['drug_mix'] && drug_order_list[drg_key][pr_type][prem_data['prem_index']]['drug_mix'][drg_mix_value]['brand_count'] && prem_data['drug_mix'][drg_mix_value] && prem_data['drug_mix'][drg_mix_value]['brand_count']) {
                              prem_data['drug_mix'][drg_mix_value]['brand_count'] = drug_order_list[drg_key][pr_type][prem_data['prem_index']]['drug_mix'][drg_mix_value]['brand_count'];
                              for (let brand_id in drug_order_list[drg_key][pr_type][prem_data['prem_index']]['drug_mix'][drg_mix_value]['brand_count']) {
                                prem_data['drug_mix'][drg_mix_value]['barcode_list'].push(brand_id);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            this.show_nurse_modal = true;
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            let msg = 'Güncel brand listesinin çekimi sırasında hata oluştu, lütfen tekrar deneyin';
            let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Brand Listesi Güncelleme İşlemi Hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        });
    },
    f_calculateInfusionSpeed: function (drug_data) {
      if (drug_data.fluid_value && drug_data.duration) {
        if (Number.isInteger(Math.round(drug_data.fluid_value / (drug_data.duration / 60)))) {
          return Math.round(drug_data.fluid_value / (drug_data.duration / 60));
        }
      } else {
        return '';
      }
    },
    f_calculateSpeedMeasure: function (drug_data) {
      if (drug_data.dosage_form && drug_data.dosage_form.value && ['12', '13'].indexOf(drug_data.dosage_form.value) !== -1) {
        return 'ml/saat';
      } else {
        return '';
      }
    },
    days_brands_edit: function (dy_no, status_id, type, drug_key = 'none', cycle_index, day_index, prem_key, prem_day, day_dif = 0) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Brand listesi güncelleniyor' } });
      let patientNo = '';
      try {
        patientNo = this.patient_data['hospitals'][this.user.hospital_group][this.user.hospital_id]['data']['id'];
      } catch {
        patientNo = '';
      }
      let data = {
        'dy_no': dy_no,
        'id': this.treatment_data.id,
        'patientNo': patientNo ? patientNo : '',
        'hospitalGroup': this.user.hospital_group,
        'hospitalCode': this.user.hospital_id
      };
      TreatmentService.get_new_drug_list(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            this.treatment_data.drug_list = JSON.parse(JSON.stringify(resp.data.result['drug_list']));
            this.patient_data.open_protocol_list = { 'result': [] };
            this.patient_data.open_protocol_list.result = resp.data.result['eligible_open_protocol_list'];
            this.treatment_order_data = {};
            this.treatment_order_data.dy_no = dy_no;
            this.treatment_order_data.status_id = status_id;
            this.treatment_order_data.type = type;
            this.treatment_order_data.drug_key = drug_key;
            this.treatment_order_data.days = {};
            if (drug_key === 'none') { // tek ilaç onayı yapılmıyor ve gün onayı yapılıyorsa.
              this.treatment_order_data.days[dy_no] = JSON.parse(JSON.stringify(this.treatment_data.date_list[dy_no]));
            } else { // tek ilaç onayı yapılıyorsa
              this.treatment_order_data.days[dy_no] = {};
              this.treatment_order_data.days[dy_no][drug_key] = JSON.parse(JSON.stringify(this.treatment_data.date_list[dy_no][drug_key]));
              if (type === 'drug') {
                this.treatment_order_data.days[dy_no][drug_key].before = [];
                this.treatment_order_data.days[dy_no][drug_key].during = [];
                this.treatment_order_data.days[dy_no][drug_key].after = [];
              } else { // before during after
                for (let i in this.types) {
                  if (this.types[i] !== type) {
                    this.treatment_order_data.days[dy_no][drug_key][this.types[i]] = [];
                  }
                }
              }
            }
            let data = this.treatment_order_data.days[dy_no];
            for (let drg_key in data) {
              let cycle_index = data[drg_key].cycle_index;
              let day_index = data[drg_key].day_index;
              if (type === 'drug') {
                let drug_value = this.treatment_data.protocol_cycles.drugs[drg_key].drug.value;
                let data_2 = JSON.parse(JSON.stringify(this.treatment_data.protocol_cycles.drugs[drg_key].cycles[cycle_index].days[day_index].data));
                data[drg_key].brands = JSON.parse(JSON.stringify(this.treatment_data.drug_list[drug_value].brands));
                data[drg_key].selected_brand = '';
                data[drg_key].barcode_list = data_2.barcode_list;
                data[drg_key].brand_count = data_2.brand_count;
                if (data_2.fluid) {
                  data[drg_key].fluid = { brands: JSON.parse(JSON.stringify(this.treatment_data.drug_list[data_2.fluid.value].brands)), selected_brand: '', barcode_list: data_2.fluid_brands.barcode_list, brand_count: data_2.fluid_brands.brand_count, name: data_2.fluid, fluid_value: data_2.fluid_value };
                }
                data[drg_key].drug_mix = {};
                let mix_data = JSON.parse(JSON.stringify(this.treatment_data.protocol_cycles.drugs[drg_key].cycles[cycle_index].days[day_index].data.drug_mix));
                for (let mix in mix_data) {
                  data[drg_key].drug_mix[mix_data[mix].drug.value] = {};
                  let new_data = data[drg_key].drug_mix[mix_data[mix].drug.value];
                  new_data.brands = JSON.parse(JSON.stringify(this.treatment_data.drug_list[mix_data[mix].drug.value].brands));
                  new_data.selected_brand = '';
                  new_data.barcode_list = mix_data[mix].barcode_list;
                  new_data.brand_count = mix_data[mix].brand_count;
                  new_data.name = mix_data[mix].drug;
                  new_data.given_dosage = mix_data[mix].given_dosage;
                  new_data.unit_value = mix_data[mix].unit.value;
                  new_data.mix_ind = mix;
                }
              } else { // before after during
                for (let i in this.types) {
                  for (let prem in data[drg_key][this.types[i]]) {
                    let prem_ind = data[drg_key][this.types[i]][prem].prem_index;
                    let approvals_index = data[drg_key][this.types[i]][prem].approvals_index;
                    let data_2 = JSON.parse(JSON.stringify(this.treatment_data.protocol_cycles.drugs[drg_key].cycles[cycle_index].days[day_index].data[this.types[i]][prem_ind]));
                    let prem_value = data_2.drug.value;
                    data[drg_key][this.types[i]][prem].count = 1;
                    data[drg_key][this.types[i]][prem].selected_brand = '';
                    data[drg_key][this.types[i]][prem].barcode_list = data_2.approvals[approvals_index].barcode_list;
                    data[drg_key][this.types[i]][prem].brand_count = data_2.approvals[approvals_index].brand_count;
                    data[drg_key][this.types[i]][prem].brands = JSON.parse(JSON.stringify(this.treatment_data.drug_list[prem_value].brands));
                    if (data_2.fluid) {
                      data[drg_key][this.types[i]][prem].fluid = {};
                      data[drg_key][this.types[i]][prem].fluid.brands = JSON.parse(JSON.stringify(this.treatment_data.drug_list[data_2.fluid.value].brands));
                      data[drg_key][this.types[i]][prem].fluid.selected_brand = '';
                      data[drg_key][this.types[i]][prem].fluid.barcode_list = data_2.approvals[approvals_index].fluid_brands.barcode_list;
                      data[drg_key][this.types[i]][prem].fluid.brand_count = data_2.approvals[approvals_index].fluid_brands.brand_count;
                      data[drg_key][this.types[i]][prem].fluid.name = data_2.fluid;
                      data[drg_key][this.types[i]][prem].fluid.fluid_value = data_2.fluid_value;
                    }
                    data[drg_key][this.types[i]][prem].drug_mix = {};
                    let mix_data = JSON.parse(JSON.stringify(this.treatment_data.protocol_cycles.drugs[drg_key].cycles[cycle_index].days[day_index].data[this.types[i]][prem_ind].drug_mix));
                    for (let mix in mix_data) {
                      let mix_value = mix_data[mix].drug.value;
                      data[drg_key][this.types[i]][prem].drug_mix[mix_data[mix].drug.value] = {};
                      let new_data = data[drg_key][this.types[i]][prem].drug_mix[mix_data[mix].drug.value];
                      new_data.brands = JSON.parse(JSON.stringify(this.treatment_data.drug_list[mix_value].brands));
                      new_data.selected_brand = '';
                      new_data.barcode_list = mix_data[mix].barcode_list;
                      new_data.brand_count = mix_data[mix].brand_count;
                      new_data.name = mix_data[mix].drug;
                      new_data.given_dosage = mix_data[mix].given_dosage;
                      new_data.unit_value = mix_data[mix].unit.value;
                      new_data.mix_ind = mix;
                    }
                  }
                }
              }
            }
            this.treatment_order_data_save[dy_no] = {};
            this.treatment_order_data_save[dy_no] = JSON.parse(JSON.stringify(data));
            this.show_nurse_modal = true;

          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            let msg = 'Güncel brand listesinin çekimi sırasında hata oluştu, lütfen tekrar deneyin';
            let modal_data = { 'type': 'alert', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Brand Listesi Güncelleme İşlemi Hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        });
    },
    nurse_approval (drug_key, cycle_index, day_index, prem_key, day_data, type, prem_day) {
      if (type === 'drug') {
        this.treatment_order_data.drugs = {};
        this.treatment_order_data.drugs[drug_key] = this.treatment_data.drug_list[day_data.drug.value];
        this.treatment_order_data.drugs[drug_key]['amount'] = day_data.given_dosage;
        this.treatment_order_data.drugs[day_data.fluid.value] = this.treatment_data.drug_list[day_data.fluid.value];
        this.treatment_order_data.drugs[day_data.fluid.value]['amount'] = day_data.fluid_value + ' ml';
        for (let dmix in day_data.drug_mix) {
          this.treatment_order_data.drugs[day_data.drug_mix[dmix].drug.value] = this.treatment_data.drug_list[day_data.drug_mix[dmix].drug.value];
          this.treatment_order_data.drugs[day_data.drug_mix[dmix].drug.value]['amount'] = day_data.drug_mix[dmix].given_dosage;
        }
      } else {
        this.treatment_order_data.drugs = {};
        this.treatment_order_data.drugs[prem_key] = this.treatment_data.drug_list[day_data.drug.value];
        this.treatment_order_data.drugs[prem_key]['amount'] = day_data.given_dosage;
        this.treatment_order_data.drugs[day_data.fluid.value] = this.treatment_data.drug_list[day_data.fluid.value];
        this.treatment_order_data.drugs[day_data.fluid.value]['amount'] = day_data.fluid_value + ' ml';
        for (let dmix in day_data.drug_mix) {
          this.treatment_order_data.drugs[day_data.drug_mix[dmix].drug.value] = this.treatment_data.drug_list[day_data.drug_mix[dmix].drug.value];
          this.treatment_order_data.drugs[day_data.drug_mix[dmix].drug.value]['amount'] = day_data.drug_mix[dmix].given_dosage;
        }
      }
      this.nurse_approval_data.drug_key = drug_key;
      this.nurse_approval_data['prem_key'] = prem_key;
      this.nurse_approval_data['prem_day'] = prem_day;
      this.nurse_approval_data['type'] = type;
      this.nurse_approval_data.day_index = day_index;
      this.nurse_approval_data.cycle_index = cycle_index;
      this.nurse_approval_day_data = day_data;
      this.nurse_approval_day_data.orders = {};
      this.show_nurse_modal = true;
    },
    nurse_approval_save () {
      this.nurse_approval_day_data.nurse_note = this.nurse_approval.note;
      this.nurse_approval_day_data.drug_key = this.nurse_approval_data.drug_key;
      this.nurse_approval_day_data.day_index = this.nurse_approval_data.day_index;
      this.nurse_approval_day_data.cycle_index = this.nurse_approval_data.cycle_index;
      this.nurse_approval_day_data['prem_key'] = this.nurse_approval_data.prem_key;
      this.nurse_approval_day_data['prem_day'] = this.nurse_approval_data.prem_day;
      this.nurse_approval_day_data['type'] = this.nurse_approval_data.type;
      this.nurse_approval_day_data.orders = this.treatment_order_data_save;
      if (this.nurse_approval_day_data.orders.barcode.length > 0) {
        TreatmentService.order_save(this.patient_data.id, this.treatment_data.id, this.nurse_approval_day_data)
          .then(resp => {
            this.show_nurse_modal = false;
            this.update_treatment_data(resp.data.result);
          });
      } else {
        alert('Please choose drug for order');
      }
    },
    cancel_order_treatment (drug_key, cycle_index, day_index, prem_key, type, prem_day) {
      if (confirm('Do you want to cancel the drug appprovement.')) {
        let cancelling_data = {};
        cancelling_data['prem_key'] = prem_key;
        cancelling_data['prem_day'] = prem_day;
        cancelling_data['drug_key'] = drug_key;
        cancelling_data['cycle_index'] = cycle_index;
        cancelling_data['day_index'] = day_index;
        cancelling_data['type'] = type;
        TreatmentService.cancel_order_treatment(this.patient_data.id, this.treatment_data.id, cancelling_data)
          .then(resp => {
            this.update_treatment_data(resp.data.result);
          });
      }
    }
  },
  filters: {},
  watch: {
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'CompleteDaysApproval' && this.days_approval_data.days) {
          if (this.StoreModal.data.confirm) {
            // console.log('store modal', this.StoreModal.show);
            this.CompleteDaysApproval();
          }
        } else if (!this.StoreModal.show && this.StoreModal.data.function_name === 'ApprovalDrugPremedications') {
          if (this.StoreModal.data.confirm && this.textOfPremedicationOfDoctorApproval) {
            this.ApprovalDrugPremedications();
          }
        } else if (!this.StoreModal.show && this.StoreModal.data.function_name === 'f_approveDoctor6All') {
          if (this.StoreModal.data.confirm && this.d_doctor6Check) {
            this.f_approveDoctor6All();
          }
        }
      }
    }
  },
  components: {
    Modal,
    BrandSelection,
    LastLaboratory,
    PharmacyWidget
  },
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  }
};

</script>

<style>
.bg-danger-tratment {
  color: #fff !important;
  background-color: #f86c6b !important;
  cursor: not-allowed;
}

.treatment-actions-btns {
  width: 44px;
}

.tratmnet-logs-header {
  background: #cfd8dc;
  padding: 5px;
}

.tratmnet-logs-sub {
  background: #cfd8dc;
  padding: 5px;
  padding-left: 30px;
  border-top: 1px solid #21a8d8;
}

.treatment-log-sub-title {
  margin: 5px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background: red;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 18px;
  color: #fff;
  line-height: 22px;
  text-align: center;
  background: #21a8d8;
}

,
.toxicilty-lists {
  background: rgb(236, 239, 242);
  border-radius: 5px;
  padding: 5px;
}

.treatment-active {
  background-color: #b3ffcc !important;
}

.treatment-waiting {
  background-color: #ccf2ff !important;
}

.treatment-finished {
  background-color: #ff8080 !important;
}

.table-drug {
  background-color: #e6ffff !important;
}

#table-scroll {
  overflow: auto;
}

</style>

