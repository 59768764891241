<template>
  <div>
    <b-card header-tag="header" style="min-height: 750px;">
      <b-row>
        <b-col cols="12">
          <b-card bg-variant="primary" style="margin-bottom: 0px; background-color: #3c9ca5!important;" no-body>
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <i class="fa fa-file-text-o"></i> Hasta İstem Ekranı
              <b-button :disabled="f_calculateDisabledSaveButton()" variant="warning" size="md" class="pull-right" @click="f_savePatientOrder()"><i class="fa fa-save"></i> istemleri tamamla </b-button>
            </b-card-header>
            <b-row style="padding: 3px;">
              <b-col cols="12">
                <template v-if="d_showProtocols && p_patient && p_patient.open_protocol_list && p_patient.open_protocol_list.result">
                  <template v-for="(open_pro, open_pro_ind) in p_patient.open_protocol_list.result">
                    <b-row v-if="user_hims_branch_id && user_hims_branch_id === open_pro.branch_id" :key="'open_pro_' + open_pro_ind" style="padding: 5px; margin: 0px; border-bottom: solid 1px #ccbdbd;">
                      <b-col cols="12">
                        <div :style="SelectedProtocolInd === open_pro_ind ? 'background-color: #b0e8f5; cursor: pointer; color: black;' : 'cursor: pointer; color: black;'" @click="f_selectThisProtocol(open_pro_ind)">
                          <b-form-checkbox :checked="SelectedProtocolInd === open_pro_ind" style="display: inline;"></b-form-checkbox>
                          <i class="fa fa-hand-o-right" style="color: green;"></i> {{ open_pro.department_name }}
                          <i class="fa fa-user"></i> {{ open_pro.doctor }}
                          <i class="fa fa-key"></i> {{ open_pro.protocol_no }}
                          <i class="fa fa-calendar-o"></i> ({{ moment(open_pro.open_date).format('DD/MM/YYYY HH:mm') }})
                          <span :style="f_calculateStyleProtocolType(open_pro.type_id)">
                              [{{ f_calculateProtocolType(open_pro.type_id) }}]
                            </span>
                        </div>
                      </b-col>
                    </b-row>
                  </template>
                </template>
              </b-col>
            </b-row>
            <b-row style="padding: 3px;">
              <b-col sm="12" md="4">
                <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                  <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                    <i class="fa fa-search"></i> Grup Listesi
                  </b-card-header>
                  <b-row>
                    <b-col cols="12">
                      <b-form-input id="d_searchTextTestGroup" placeholder="Grup Adıyla Arama" type="text" v-model="d_searchTextTestGroup"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <div style="overflow-y: auto; height: 500px; overflow-x: hidden;">
                        <b-table :filter="d_searchTextTestGroup" :filter-included-fields="['name']" head-variant="dark" striped responsive stacked="sm" bordered borderless small hover :items="d_testGroupList" :fields="d_testGroupTableFields" select-mode="single" selectable selectedVariant="success" @row-dblclicked="f_filterGroups" @row-selected="">
                          <template v-slot:cell(row_no)="row">
                            {{ row.index + 1 }}
                          </template>
                        </b-table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col sm="12" md="4" style="border-radius: 15px">
                <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                  <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                    <i class="fa fa-database"></i> İstem Listesi
                  </b-card-header>
                  <b-row>
                    <b-col cols="12">
                      <b-form-input id="d_searchTextTestInfo" placeholder="Test Adıyla Arama" type="text" v-model="d_searchTextTestInfo"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <div style="overflow-y: auto; height: 500px; overflow-x: hidden;">
                        <b-table :filter="d_searchTextTestInfo" :filter-included-fields="['name']" head-variant="dark" striped responsive stacked="sm" bordered borderless small hover :items="d_showingInfoList" :fields="d_testInfoTableFields" select-mode="single" selectable selectedVariant="success" @row-dblclicked="f_addRemoveFromAdded">
                          <template v-slot:cell(row_no)="row">
                            {{ row.index + 1 }}
                          </template>
                        </b-table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col sm="12" md="4">
                <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                  <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                    <i class="fa fa-asterisk"></i> Eklenmişler
                  </b-card-header>
                  <b-row>
                    <b-col cols="12">
                      <b-form-input id="d_searchTextTestAdded" placeholder="Grup Adıyla Arama" type="text" v-model="d_searchTextTestAdded"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <div style="overflow-y: auto; height: 500px; overflow-x: hidden;">
                        <b-table :filter="d_searchTextTestAdded" :filter-included-fields="['name']" head-variant="dark" striped responsive stacked="sm" bordered borderless small hover :items="d_addedInfoList" :fields="d_addedTestTableFields" select-mode="single" selectable selectedVariant="success" @row-dblclicked="f_addRemoveFromAdded">
                          <template v-slot:cell(row_no)="row">
                            {{ row.index + 1 }}
                          </template>
                        </b-table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <!-- <b-card-footer footer-bg-variant="primary" header-text-variant="dark" class="p-1" style="background-color: #5db5bd!important;">
              <b-row>
                <b-col sm="12" md="4"></b-col>
                <b-col sm="12" md="4">
                  <b-button variant="warning" size="md" style="width: 100%;" @click="f_add()"><i class="fa fa-cloud-download"></i> ekle </b-button>
                </b-col>
                <b-col sm="12" md="4">
                </b-col>
              </b-row>
            </b-card-footer> -->
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Datepicker from 'wisdom-vue-datepicker';
import vSelect from 'vue-select';
import WebService from '@/services/webservice';
import PatientOrderService from '@/services/patient_order';
import { DateFunctions } from '@/services/public/date_functions';
import moment from 'moment';
export default {
  name: 'PatientOrderWidget',
  computed: mapGetters({
    StoreModal: 'modal',
    lang: 'lang'
  }),
  components: {
    Datepicker,
    vSelect
  },
  props: {
    p_orderType: {
      type: String,
      required: true
    },
    p_patient: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      moment: moment,
      user: {},
      d_searchTextTestGroup: '',
      d_searchTextTestInfo: '',
      d_searchTextTestAdded: '',
      SelectedProtocolInd: '',
      d_selectedProtocolNo: '',
      d_selectedProtocolData: {},
      d_testGroupList: [],
      d_allTestInfoList: [],
      d_testGroupTableFields: [{
        key: "name",
        label: "Grup Adı"
      }],
      d_testInfoTableFields: [{
        key: "name",
        label: "İstem Adı"
      }],
      d_addedTestTableFields: [{
        key: "name",
        label: "Gönderilecek İstem"
      }],
      user_hims_branch_id: '',
      d_groupedInfoList: {},
      d_showingInfoList: [],
      d_addedInfoList: [],
      d_showProtocols: true
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    for (let i in this.user.position_department_title) {
      if (this.user.position_department_title[i].active && this.user.position_department_title[i].department.hims_branch_id) {
        this.user_hims_branch_id = parseInt(this.user.position_department_title[i].department.hims_branch_id);
        break;
      }
    }
    if (this.p_orderType === 'laboratory') {
      this.f_getLabTestGroupsAndTestInfos();
    } else if (this.p_orderType === 'radiology') {
      this.f_getRadTestGroupsAndTestInfos();
    }
  },
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_calculateDisabledSaveButton: function () {
      if (this.d_addedInfoList.length === 0) {
        return true;
      }
      if (this.d_selectedProtocolNo === '') {
        return true;
      }
    },
    f_savePatientOrder: function () {
      let modal_data = { 'type': 'confirm', 'text': 'Seçilen istemler HBYS ye gönderilsin mi?', 'centered': true, 'title': 'İstem Yapılsın mı?', 'confirm': false, 'function_name': 'f_savePatientOrderConfirmed', 'arguments': [] };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_savePatientOrderConfirmed: function () {
      if (this.p_orderType === 'laboratory') {
        this.f_saveLaboratoryOrder();
      } else if (this.p_orderType === 'radiology') {
        this.f_saveRadiologyOrder();
      }
    },
    f_saveLaboratoryOrder: function () {
      let strings = '';
      for (let i in this.d_addedInfoList) {
        strings += this.d_addedInfoList[i].id.toString() + ',';
      }
      let data = {
        'model': { 'testIds': strings.slice(0, -1) },
        'processID': this.d_selectedProtocolData.process_id,
        'resourceId': this.d_selectedProtocolData.resource_id,
        'protocolNo': this.d_selectedProtocolData.protocol_no,
        'isHighPriority': 'false',
        'explanation': 'WisdomEra Test Request',
        'hospitalCode': this.user.hospital_id,
        'hospitalGroup': this.user.hospital_group
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İstem yapılıyor.' } });
      PatientOrderService.save_laboratory_order(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_saveRadiologyOrder: function () {
      let strings = '';
      for (let i in this.d_addedInfoList) {
        strings += this.d_addedInfoList[i].id.toString() + ',';
      }
      let data = {
        'model': { 'testIds': strings.slice(0, -1) },
        'processID': this.d_selectedProtocolData.process_id,
        'resourceId': this.d_selectedProtocolData.resource_id,
        'protocolNo': this.d_selectedProtocolData.protocol_no,
        'isPregnant': 'false',
        'isHighPriority': 'false',
        'explanation': 'WisdomEra Test Request',
        'transferPreviousClinicalNote': 'false',
        'hospitalCode': this.user.hospital_id,
        'hospitalGroup': this.user.hospital_group
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İstem yapılıyor.' } });
      PatientOrderService.save_radiology_order(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_showTrueFalse: function () {
      this.d_showProtocols = false;
      setTimeout(function () {
        this.d_showProtocols = true;
        this.$forceUpdate();
      }.bind(this), 100)
    },
    f_selectThisProtocol: function (open_pro_ind) {
      this.SelectedProtocolInd === open_pro_ind ? this.SelectedProtocolInd = '' : this.SelectedProtocolInd = open_pro_ind;
      if (this.SelectedProtocolInd !== '') {
        this.d_selectedProtocolData = JSON.parse(JSON.stringify(this.p_patient.open_protocol_list.result[open_pro_ind]));
        this.d_selectedProtocolNo = this.p_patient.open_protocol_list.result[open_pro_ind].protocol_no;
      } else {
        this.f_showTrueFalse();
        this.d_selectedProtocolData = {};
        this.d_selectedProtocolNo = '';
      }
    },
    f_getRadTestGroupsAndTestInfos: function () {
      let query = 'hospitalGroup=' + this.user.hospital_group;
      query += '&hospitalCode=' + this.user.hospital_id;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Radyoloji test grupları getiriliyor' } });
      WebService.get_hims_radiology_test_groups(query)
        .then(resp => {
          this.d_testGroupList = resp.data.result;
          query = 'testGroupId=0';
          query += '&hospitalGroup=' + this.user.hospital_group;
          query += '&hospitalCode=' + this.user.hospital_id;
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Radyoloji test bilgileri getiriliyor' } });
          WebService.get_hims_radiology_test_infos(query)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              this.d_allTestInfoList = resp.data.result;
              this.d_showingInfoList = JSON.parse(JSON.stringify(this.d_allTestInfoList));
              for (let i in this.d_allTestInfoList) {
                if (!this.d_groupedInfoList[this.d_allTestInfoList[i].exam_group_id.toString()]) {
                  this.d_groupedInfoList[this.d_allTestInfoList[i].exam_group_id.toString()] = [];
                }
                this.d_groupedInfoList[this.d_allTestInfoList[i].exam_group_id.toString()].push(this.d_allTestInfoList[i]);
              }
            });
        });
    },
    f_getLabTestGroupsAndTestInfos: function () {
      let query = 'hospitalGroup=' + this.user.hospital_group;
      query += '&hospitalCode=' + this.user.hospital_id;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Laboratuar test grupları getiriliyor' } });
      WebService.get_hims_laboratory_test_groups(query)
        .then(resp => {
          this.d_testGroupList = resp.data.result;
          query = 'laboratoryId=0&testGroupId=0';
          query += '&hospitalGroup=' + this.user.hospital_group;
          query += '&hospitalCode=' + this.user.hospital_id;
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Laboratuar test bilgileri getiriliyor' } });
          WebService.get_hims_laboratory_test_infos(query)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              this.d_allTestInfoList = resp.data.result;
              this.d_showingInfoList = JSON.parse(JSON.stringify(this.d_allTestInfoList));
              for (let i in this.d_allTestInfoList) {
                if (!this.d_groupedInfoList[this.d_allTestInfoList[i].group_id.toString()]) {
                  this.d_groupedInfoList[this.d_allTestInfoList[i].group_id.toString()] = [];
                }
                this.d_groupedInfoList[this.d_allTestInfoList[i].group_id.toString()].push(this.d_allTestInfoList[i]);
              }
            });
        });
    },
    f_filterGroups: function (row) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Testler filtreleniyor' } });
      this.d_showingInfoList = [];
      if (this.p_orderType === 'laboratory') {
        if (row.real_id.toString() === '0') {
          this.d_showingInfoList = JSON.parse(JSON.stringify(this.d_allTestInfoList));
        } else {
          if (this.d_groupedInfoList[row.real_id.toString()]) {
            this.d_showingInfoList = JSON.parse(JSON.stringify(this.d_groupedInfoList[row.real_id.toString()]));
          }
        }
      } else if (this.p_orderType === 'radiology') {
        if (row.id.toString() === '0') {
          this.d_showingInfoList = JSON.parse(JSON.stringify(this.d_allTestInfoList));
        } else {
          if (this.d_groupedInfoList[row.id.toString()]) {
            this.d_showingInfoList = JSON.parse(JSON.stringify(this.d_groupedInfoList[row.id.toString()]));
          }
        }
      }
      this.$store.commit('Loading', { 'status': false, 'data': {} });
      this.$forceUpdate();
    },
    f_addRemoveFromAdded: function (row) {
      let det = false;
      let index = ''
      for (let i in this.d_addedInfoList) {
        if (row.id === this.d_addedInfoList[i].id) {
          det = true;
          index = i;
          break;
        }
      }
      if (det) {
        this.d_addedInfoList.splice(index, 1);
      } else {
        this.d_addedInfoList.push(row);
      }
      this.$forceUpdate();
    },
    f_calculateStyleProtocolType (type_id) {
      if (type_id === 0) {
        return 'color: red; font-weight: bold;';
      } else if (type_id === 1) {
        return 'color: green; font-weight: bold;';
      } else if (type_id === 2) {
        return 'color: #b3246c; font-weight: bold;';
      } else if (type_id === 3) {
        return 'color: red; font-weight: bold;';
      } else if (type_id === 4) {
        return 'color: blue; font-weight: bold;';
      } else if (type_id === 5) {
        return 'color: black; font-weight: bold;';
      }
    },
    f_calculateProtocolType: function (type_id) {
      if (type_id === 0) {
        return 'Diğer';
      } else if (type_id === 1) {
        return 'Poliklinik';
      } else if (type_id === 2) {
        return 'Yatan Hasta';
      } else if (type_id === 3) {
        return 'Acil';
      } else if (type_id === 4) {
        return 'Günübirlik';
      } else if (type_id === 5) {
        return 'TempInpatient';
      }
    }
  },
  watch: {
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_savePatientOrderConfirmed') {
          if (this.StoreModal.data.confirm) {
            this.f_savePatientOrderConfirmed();
          }
        }
      }
    }
  }
};

</script>

<style></style>

