var
  ProtocolInsuranceData = {
    'MLPCARE': {
      'parameter_order': [
        'symptom_start_date',
        'pre_diagnosis',
        'main_diagnosis',
        'care_plan',
        'story',
        'allergy',
        'history',
        'discharged_plan',
        'family_history',
        'finding',
        'symptom',
        'history_disease',
        'history_drug',
        'history_habit',
        'history_surgery',
        'oxygen_saturation',
        'pain_condition',
        'pulse',
        'blood_pressure',
        'temperature',
        'respiratory_rate',
        'weight',
        'height'
      ],
      'parameter_list': {
        'symptom_start_date': { 'selected': '', 'name': { 'label': 'Semptom Başlama Tarihi' }, 'text_list': [], 'type': 'one', 'div_tag': 'date', 'default': 'today', 'required': 1 },
        'allergy': { 'selected': '', 'name': { 'label': 'Allerji' }, 'text_list': [], 'type': 'many', 'div_tag': 'textarea', 'default': '', 'required': 0 },
        'blood_pressure': { 'selected': '', 'name': { 'label': 'Kan Basıncı' }, 'text_list': [], 'type': 'one', 'div_tag': 'text', 'default': '', 'required': 0 },
        'care_plan': { 'selected': '', 'name': { 'label': 'Bakım Planı' }, 'text_list': [], 'type': 'many', 'div_tag': 'textarea', 'default': '-', 'required': 1 },
        'discharged_plan': { 'selected': '', 'name': { 'label': 'Taburcu Planı' }, 'text_list': [], 'type': 'many', 'div_tag': 'textarea', 'default': '', 'required': 0 },
        'family_history': { 'selected': '', 'name': { 'label': 'Aile Hikayesi' }, 'text_list': [], 'type': 'many', 'div_tag': 'textarea', 'default': '', 'required': 0 },
        'finding': { 'selected': '', 'name': { 'label': 'Bulgular' }, 'text_list': [], 'type': 'many', 'div_tag': 'textarea', 'default': 'yok', 'required': 1 },
        'height': { 'selected': '', 'name': { 'label': 'Boy' }, 'text_list': [], 'type': 'one', 'div_tag': 'text', 'default': 'x', 'required': 0 },
        'history': { 'selected': '', 'name': { 'label': 'Özgeçmiş' }, 'text_list': [], 'type': 'many', 'div_tag': 'textarea', 'default': '-', 'required': 0 },
        'history_disease': { 'selected': '', 'name': { 'label': 'Hastalık Geçmişi' }, 'text_list': [], 'type': 'many', 'div_tag': 'textarea', 'default': 'yok', 'required': 0 },
        'history_drug': { 'selected': '', 'name': { 'label': 'İlaç Kullanımı' }, 'text_list': [], 'type': 'many', 'div_tag': 'textarea', 'default': '-', 'required': 0 },
        'history_habit': { 'selected': '', 'name': { 'label': 'Bağımlılık' }, 'text_list': [], 'type': 'many', 'div_tag': 'textarea', 'default': 'yok', 'required': 0 },
        'history_surgery': { 'selected': '', 'name': { 'label': 'Cerrahi Hikayesi' }, 'text_list': [], 'type': 'many', 'div_tag': 'textarea', 'default': 'yok', 'required': 0 },
        'main_diagnosis': { 'selected': '', 'name': { 'label': 'Ana Tanı' }, 'text_list': [], 'type': 'many', 'div_tag': '', 'default': 'user_will_fill', 'required': 1 },
        'oxygen_saturation': { 'selected': '', 'name': { 'label': 'Oksijen Satürasyonu' }, 'text_list': [], 'type': 'one', 'div_tag': 'text', 'default': '', 'required': 0 },
        'pain_condition': { 'selected': '', 'name': { 'label': 'Ağrı Durumu' }, 'text_list': [], 'type': 'one', 'div_tag': 'text', 'default': '', 'required': 0 },
        'pre_diagnosis': { 'selected': '', 'name': { 'label': 'Ön Tanı' }, 'text_list': [], 'type': 'many', 'div_tag': '', 'default': 'user_will_fill', 'required': 1 },
        'pulse': { 'selected': '', 'name': { 'label': 'Nabız' }, 'text_list': [], 'type': 'one', 'div_tag': 'text', 'default': '', 'required': 0 },
        'story': { 'selected': '', 'name': { 'label': 'Hikaye' }, 'text_list': [], 'type': 'many', 'div_tag': 'textarea', 'default': '-', 'required': 0 },
        'symptom': { 'selected': '', 'name': { 'label': 'Semptomlar' }, 'text_list': [], 'type': 'many', 'div_tag': 'textarea', 'default': 'yok', 'required': 0 },
        'temperature': { 'selected': '', 'name': { 'label': 'Ateş' }, 'text_list': [], 'type': 'one', 'div_tag': 'text', 'default': '', 'required': 0 },
        'weight': { 'selected': '', 'name': { 'label': 'Kilo' }, 'text_list': [], 'type': 'one', 'div_tag': 'text', 'default': '', 'required': 0 },
        'respiratory_rate': { 'selected': '', 'name': { 'label': 'Solunum Sayısı' }, 'text_list': [], 'type': 'one', 'div_tag': 'text', 'default': '', 'required': 0 }
      }
    }
  }
export { ProtocolInsuranceData };
