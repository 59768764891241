<template>
  <div style="padding: 10px; font-size: 20px;">
    <div class="row">
      <div class="col-12" v-for="(info, info_ind) in data.info" style="border-bottom: solid 1px black; font-size: 14px; padding: 10px;">
        *** {{ info }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ConfirmForm',
  mounted () {},
  computed: {
    ...mapGetters({
      patient: 'patient_selected'
    })
  },
  props: {
    data: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
    };
  },
  created () {},
  methods: {},
  watch: {},
  components: {}
};
</script>

<style type="text/css">
</style>
