<template>
  <div>
    <template v-for="(prem, prem_ind) in drug[prem_type]">
      <template v-if="prem.brands !== undefined">
        <div class="col-12" style="background-color: rgb(77, 212, 106); text-align: center;">
          <strong> {{ treatment_data.protocol_cycles.drugs[drug_key].drug.label }} premedikasyon ({{ prem_type }}) </strong>
        </div>
        <table class="col-12">
          <thead>
            <tr style="background-color: rgb(190, 223, 197); text-align: center; font-weight: bold;">
              <th style="width: 15%;"> İlaç </th>
              <th colspan="2" style="width: 80%;"> Ürünler </th>
              <th style="width: 3%;"> Sayı </th>
              <th style="width: 2%;"> </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowspan="3">
                <b> {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug.cycle_index].days[drug.day_index].data[prem_type][prem.prem_index].drug.label }}  </b>
                <br>
                <div>
                  (Verilecek Doz: = {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug.cycle_index].days[drug.day_index].data[prem_type][prem.prem_index].given_dosage }} {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug.cycle_index].days[drug.day_index].data[prem_type][prem.prem_index].unit.value | show_unit_calculate }} )
                </div>
                <div>
                  <template v-if="d_selectedProtocolNo">
                    <template v-if="f_atLeastOneBrandMatch(prem.brands)">
                      <b-form-checkbox v-model="prem.pharmacy_status" :value="1" :unchecked-value="0">Eczane İstemi</b-form-checkbox>
                    </template>
                    <template v-else>
                      <small style="color: red;">Bu üründen stokta görünmüyor.</small>
                    </template>
                  </template>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" style="padding: 5px;">
                <v-select ref="selected_brand" name="selected_brand" v-model="prem.selected_brand" :options="prem.brands">
                  <template slot="option" slot-scope="option">
                    <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                      <template v-if="d_selectedProtocolNo && option.stock">
                        <span :style="option.stock.count ? 'color: green' : 'color: red'"> [ stok = {{ option.stock.count }} ] </span>
                      </template>
                      <span> {{ option.label }} </span>
                    </div>
                  </template>
                </v-select>
              </td>
              <td style="padding: 5px;">
                <input type="number" name="count" :id="dy_ind + drug_key + prem_ind + prem_type + 'none'" value="1">
              </td>
              <td style="text-align: right; padding-right: 10px;">
                <button type="button" class="btn btn-success" @click="add_barcode(dy_ind, drug_key, prem_ind, prem_type, 'none')"><i class="fa fa-plus fa-1x"></i></button>
              </td>
            </tr>
            <tr style="border-bottom: solid 1px black;">
              <td colspan="4">
                <div v-for="(brand, brand_ind) in prem.brand_count" style="background-color: #f1ffc1; width: 100%;">
                  <b-button variant="danger" @click="delete_barcode(dy_ind, drug_key, prem_ind, prem_type, 'none', brand_ind)"><i class="fa fa-minus fa-1x"></i></b-button>
                  {{ brand.label }} <small> ( {{ brand_ind }} )</small>
                  <template v-if="f_calculateBrandStock(brand_ind, prem.brands)">
                    <strong style="color: #0089ff;"> [ Stok:  {{ f_calculateBrandStock(brand_ind, prem.brands) }} ] </strong>
                  </template>
                  <template v-else>
                    <strong style="color: red;"> [ Stok: yok ] </strong>
                  </template>
                  <strong style="color: blue;"> [ Adet: {{ brand.count }} ] </strong>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <template v-if="prem.fluid !== undefined">
          <div class="col-12" style="background-color: rgb(77, 212, 106);">
            <strong> {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug.cycle_index].days[drug.day_index].data[prem_type][prem.prem_index].drug.label }} ( mayi ) </strong>
          </div>
          <table class="col-12">
            <thead>
              <tr style="background-color: rgb(190, 223, 197); text-align: center; font-weight: bold;">
                <th style="width: 15%;"> İlaç </th>
                <th colspan="2" style="width: 80%;"> Ürünler </th>
                <th style="width: 3%;"> Sayı </th>
                <th style="width: 2%;"> </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="3">
                  <b> {{ prem.fluid.name.label }} </b>
                  <br>
                  <div> ( Uygulanacak Hacim {{ prem.fluid.fluid_value }} ml ) </div>
                  <div>
                    <template v-if="d_selectedProtocolNo">
                      <template v-if="f_atLeastOneBrandMatch(prem.fluid.brands)">
                        <b-form-checkbox v-model="prem.fluid.pharmacy_status" :value="1" :unchecked-value="0">Eczane İstemi</b-form-checkbox>
                      </template>
                      <template v-else>
                        <small style="color: red;">Bu üründen stokta görünmüyor.</small>
                      </template>
                    </template>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="padding: 5px;">
                  <v-select ref="selected_brand" name="selected_brand" v-model="prem.fluid.selected_brand" :options="prem.fluid.brands">
                    <template slot="option" slot-scope="option">
                      <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                        <template v-if="d_selectedProtocolNo && option.stock">
                          <span :style="option.stock.count ? 'color: green' : 'color: red'"> [ stok = {{ option.stock.count }} ] </span>
                        </template>
                        <span> {{ option.label }} </span>
                      </div>
                    </template>
                  </v-select>
                </td>
                <td style="padding: 5px;">
                  <input type="number" name="count" :id="'fluid' + dy_ind + drug_key + prem_ind + prem_type + 'none'" value="1">
                </td>
                <td style="text-align: right; padding-right: 10px;">
                  <button type="button" class="btn btn-success" @click="add_barcode(dy_ind, drug_key, prem_ind, prem_type, 'none', true)"><i class="fa fa-plus fa-1x"></i></button>
                </td>
              </tr>
              <tr style="border-bottom: solid 1px black;">
                <td colspan="4">
                  <div v-for="(brand, brand_ind) in prem.fluid.brand_count" style="background-color: #f1ffc1; width: 100%;">
                    <b-button variant="danger" @click="delete_barcode(dy_ind, drug_key, prem_ind, prem_type, 'none', brand_ind, true)"><i class="fa fa-minus fa-1x"></i></b-button>
                    {{ brand.label }} <small> ( {{ brand_ind }} )</small>
                    <template v-if="f_calculateBrandStock(brand_ind, prem.fluid.brands)">
                      <strong style="color: #0089ff;"> [ Stok:  {{ f_calculateBrandStock(brand_ind, prem.fluid.brands) }} ] </strong>
                    </template>
                    <template v-else>
                      <strong style="color: red;"> [ Stok: yok ] </strong>
                    </template>
                    <strong style="color: blue;"> [ Adet: {{ brand.count }} ] </strong>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <!--mixxxxxxxxxxxxxxxx-->
        <template v-for="(mix, mix_ind) in prem.drug_mix">
          <div class="col-12" style="background-color: rgb(77, 212, 106); text-align: center;">
            <strong> {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug.cycle_index].days[drug.day_index].data[prem_type][prem.prem_index].drug.label }} karışım ilacı
              </strong>
          </div>
          <table class="col-12">
            <thead>
              <tr style="background-color: rgb(190, 223, 197); text-align: center; font-weight: bold;">
                <th style="width: 15%;"> İlaç </th>
                <th colspan="2" style="width: 80%;"> Ürün </th>
                <th style="width: 3%;"> Sayı </th>
                <th style="width: 2%;"> </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="3">
                  <b> {{ mix.name.label }}  </b>
                  <br>
                  <div>
                    (Given dosage = {{ mix.given_dosage }} {{ mix.unit_value | show_unit_calculate }} )
                  </div>
                  <div>
                    <template v-if="d_selectedProtocolNo">
                      <template v-if="f_atLeastOneBrandMatch(mix.brands)">
                        <b-form-checkbox v-model="mix.pharmacy_status" :value="1" :unchecked-value="0">Eczane İstemi</b-form-checkbox>
                      </template>
                      <template v-else>
                        <small style="color: red;">Bu üründen stokta görünmüyor.</small>
                      </template>
                    </template>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="padding: 5px;">
                  <v-select ref="selected_brand" name="selected_brand" v-model="mix.selected_brand" :options="mix.brands">
                    <template slot="option" slot-scope="option">
                      <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                        <template v-if="d_selectedProtocolNo && option.stock">
                          <span :style="option.stock.count ? 'color: green' : 'color: red'"> [ stok = {{ option.stock.count }} ] </span>
                        </template>
                        <span> {{ option.label }} </span>
                      </div>
                    </template>
                  </v-select>
                </td>
                <td style="padding: 5px;">
                  <input type="number" name="count" :id="dy_ind + drug_key + prem_ind + prem_type + mix_ind" value="1">
                </td>
                <td style="text-align: right; padding-right: 10px;">
                  <button type="button" class="btn btn-success" @click="add_barcode(dy_ind, drug_key, prem_ind, prem_type, mix_ind)"><i class="fa fa-plus fa-1x"></i></button>
                </td>
              </tr>
              <tr style="border-bottom: solid 1px black;">
                <td colspan="4">
                  <div v-for="(brand, brand_ind) in mix.brand_count" style="background-color: #f1ffc1; width: 100%;">
                    <b-button variant="danger" @click="delete_barcode(dy_ind, drug_key, prem_ind, prem_type, mix_ind, brand_ind)"><i class="fa fa-minus fa-1x"></i></b-button>
                    {{ brand.label }} <small> ( {{ brand_ind }} )</small>
                    <template v-if="f_calculateBrandStock(brand_ind, mix.brands)">
                      <strong style="color: #0089ff;"> [ Stok:  {{ f_calculateBrandStock(brand_ind, mix.brands) }} ] </strong>
                    </template>
                    <template v-else>
                      <strong style="color: red;"> [ Stok: yok ] </strong>
                    </template>
                    <strong style="color: blue;"> [ Adet: {{ brand.count }} ] </strong>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';

export default {
  name: 'BrandSelectionPremedication',
  mounted () {},
  computed: {
    ...mapGetters({
      patient: 'patient_selected'
    })
  },
  props: {
    treatment_order_data: {
      type: Object,
      required: true
    },
    treatment_data: {
      type: Object,
      required: true
    },
    drug: {
      type: Object,
      required: true
    },
    drug_key: {
      type: String,
      required: true
    },
    prem_type: {
      type: String,
      required: true
    },
    dy_ind: {
      type: String,
      required: true
    },
    d_selectedProtocolNo: {
      type: String,
      required: true
    }
  },
  data () {
    return {};
  },
  created () {},
  methods: {
    f_calculateBrandStock: function (barcode_no, brand_list) {
      for (let b in brand_list) {
        if (brand_list[b].value === barcode_no) {
          if (brand_list[b].stock && brand_list[b].stock.count !== undefined) {
            return brand_list[b].stock.count;
          }
        }
      }
      return false;
    },
    f_atLeastOneBrandInStock: function (brand_list) {
      for (let b in brand_list) {
        if (brand_list[b].stock && brand_list[b].stock.count) {
          return true;
        }
      }
      return false;
    },
    f_atLeastOneBrandMatch: function (brand_list) {
      for (let b in brand_list) {
        if (brand_list[b].stock !== undefined) {
          return true;
        }
      }
      return false;
    },
    add_barcode: function (dy_ind, drug_key, prem_ind, type, mix_ind, fluid = false) {
      let id_no = '';
      if (fluid) {
        id_no = 'fluid' + dy_ind.toString() + drug_key + prem_ind.toString() + type + mix_ind;
      } else {
        id_no = dy_ind.toString() + drug_key + prem_ind.toString() + type + mix_ind;
      }
      let count = document.getElementById(id_no).value;
      let data = {};
      if (type === 'drug') {
        if (mix_ind === 'none') {
          if (fluid) {
            data = this.treatment_order_data.days[dy_ind][drug_key].fluid;
          } else {
            data = this.treatment_order_data.days[dy_ind][drug_key];
          }
        } else {
          data = this.treatment_order_data.days[dy_ind][drug_key].drug_mix[mix_ind];
        }
      } else { // before after during
        if (mix_ind === 'none') {
          if (fluid) {
            data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind].fluid;
          } else {
            data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind];
          }
        } else {
          data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind].drug_mix[mix_ind];
        }
      }
      if (!data.selected_brand) {
        alert('Lütfen öncelikle ürün seçiminizi yapınız.');
        return;
      }
      let barcode_no = data.selected_brand.value;
      if (this.d_selectedProtocolNo) {
        if (data.selected_brand.stock !== undefined) {
          data.barcode_list.push(barcode_no);
          data.brand_count[barcode_no] = {};
          data.brand_count[barcode_no].count = count;
          data.brand_count[barcode_no].label = data.selected_brand.label;
        } else {
          alert('Bu ürün wisdomera ile hbys arasında eşleştirme yapılmamıştır. Eşleştirme işlemi devam etmektedir. Tamamladığında eczaneye gönderebilirsiniz.');
        }
      } else {
        data.barcode_list.push(barcode_no);
        data.brand_count[barcode_no] = {};
        data.brand_count[barcode_no].count = count;
        data.brand_count[barcode_no].label = data.selected_brand.label;
      }
      this.$forceUpdate();
    },
    delete_barcode: function (dy_ind, drug_key, prem_ind, type, mix_ind, brand_ind, fluid = false) {
      if (type === 'drug') {
        if (mix_ind === 'none') {
          if (fluid) {
            let data = this.treatment_order_data.days[dy_ind][drug_key].fluid;
            data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
            this.$delete(data.brand_count, brand_ind);
          } else {
            let data = this.treatment_order_data.days[dy_ind][drug_key];
            data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
            this.$delete(data.brand_count, brand_ind);
          }
        } else { // drug mix
          let data = this.treatment_order_data.days[dy_ind][drug_key].drug_mix[mix_ind];
          data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
          this.$delete(data.brand_count, brand_ind);
        }
      } else {
        if (mix_ind === 'none') {
          if (fluid) {
            let data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind].fluid;
            data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
            this.$delete(data.brand_count, brand_ind);
          } else {
            let data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind];
            data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
            this.$delete(data.brand_count, brand_ind);
          }
        } else {
          let data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind].drug_mix[mix_ind];
          data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
          this.$delete(data.brand_count, brand_ind);
        }
      }
      this.$forceUpdate();
    }
  },
  watch: {},
  components: {
    vSelect
  }
};

</script>

<style type="text/css">
td,
th {}

</style>

