<template>
  <div>
    <div class="form-group row">
      <div class="col-sm-6">
        <template v-if="show_more_drug_details.check">
          <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '46')">?</b-badge>
          <div class="form-group row">
            <div class="col-sm-12">
              <strong>
                {{ p_wdmr_data_ind + 1 }} - 
                {{ d_namingData.drug }} 
              </strong>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-3"> <strong> {{ $t('wdm16.3837') }} </strong> </div>
            <div class="col-sm-9"> {{ d_namingData.dosage }} {{ d_namingData.unit || show_unit }} </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-3"> <strong> {{ $t('wdm16.4000') }} </strong> </div>
            <div class="col-sm-9"> {{ d_namingData.calculated_dosage }} {{ d_namingData.unit | show_unit_calculate }} </div>
          </div>
          <div class="form-group row" v-if="p_wdmr_data.type === 'wdm11'">
            <div class="col-sm-3"> <strong> {{ $t('wdm16.4071') }} </strong> </div>
            <div class="col-sm-9">
              {{ d_namingData.planned_dosage }} {{ d_namingData.unit | show_unit_calculate }}
              <span style="color: red;"> {{ CalculateDosageChange(d_namingData.planned_dosage, d_namingData.calculated_dosage) }} </span>
            </div>
          </div>
          <div class="form-group row" v-else>
            <div class="col-sm-3"> <strong> {{ $t('wdm16.3946') }} </strong> </div>
            <div class="col-sm-9"> {{ d_namingData.given_dosage }} {{ d_namingData.unit | show_unit_calculate }} </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-3"> <strong> {{ $t('wdm16.4126') }} </strong> </div>
            <div class="col-sm-9"> {{ d_namingData.administration_type }} </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-3"> <strong> {{ $t('wdm16.1136') }} </strong> </div>
            <div class="col-sm-9"> {{ d_namingData.frequency }} </div>
          </div>
          <template v-if="d_namingData.fluid">
            <div class="form-group row">
              <div class="col-sm-3"> <strong> {{ $t('wdm16.4031') }} </strong> </div>
              <div class="col-sm-9"> {{ d_namingData.fluid }} </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-3"> <strong> {{ $t('wdm16.4357') }} </strong> </div>
              <div class="col-sm-9"> {{ d_namingData.fluid_volume }} ml </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-3"> <strong> {{ $t('wdm16.4221') }} </strong> </div>
              <div class="col-sm-9"> {{ d_namingData.duration }} {{ $t('wdm16.3980') }} </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-3"> <strong> {{ $t('wdm16.4059') }} </strong> </div>
              <div class="col-sm-9">
                <span v-if="Number.isInteger(Math.round(d_namingData.fluid_volume / (d_namingData.duration/60)))">
              {{ Math.round(d_namingData.fluid_volume / (d_namingData.duration/60)) }} cc/saat
            </span>
                <span v-if="Number.isInteger(Math.round((( d_namingData.fluid_volume / (d_namingData.duration/60)) * 33) / 100))">
              ( {{ Math.round((( d_namingData.fluid_volume / (d_namingData.duration/60)) * 33) / 100) }} {{ $t('wdm16.4134') }} )
            </span>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-3"> <strong> {{ $t('wdm16.3791') }} </strong> </div>
              <div class="col-sm-9"> {{ d_namingData.wait_after_administration }} {{ $t('wdm16.3980') }} </div>
            </div>
            <template v-if="d_namingData.dosage_changes && d_namingData.dosage_changes.data.percent">
              <div class="form-group row">
                <div class="col-sm-3"><b>{{ d_namingData.drug }} {{ $t('wdm16.4121') }}</b> </div>
                <div class="col-sm-9"> {{ d_namingData.given_dosage }} {{ d_namingData.unit || show_unit_calculate }} </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  {{ $t('wdm16.4208') }} {{ d_namingData.dosage_changes.data.percent }}
                  <br> {{ $t('wdm16.3754') }} {{ (d_namingData.dosage_changes.data.new_planned_dosage - d_namingData.dosage_changes.data.old_planned_dosage).toFixed(1) }} {{ d_namingData.dosage_changes.data.new_unit.value | show_unit_calculate }}
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3"> <strong> {{ $t('wdm16.3963') }} </strong> </div>
                <div class="col-sm-9">
                  <div v-for="(reason, reason_ind) in d_namingData.drug_mix[mix_ind].dosage_changes.reasons">
                    {{ (reason_ind + 1) + '- '+ reason }}
                  </div>
                </div>
              </div>
            </template>
          </template>
        </template>
        <template v-else>
          <div class="form-group row">
            <div class="col-sm-12">
              <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '47')">?</b-badge>
              <strong>
                {{ p_wdmr_data_ind + 1 }} -
                {{ d_namingData.drug }}
                <span v-if="p_wdmr_data.type === 'wdm11'">{{ d_namingData.planned_dosage }}<span style="color: red;"> {{ CalculateDosageChange(d_namingData.planned_dosage, d_namingData.calculated_dosage) }} </span>
                </span>
                <span v-else>{{ d_namingData.given_dosage }}</span>
                {{ d_namingData.unit | show_unit_calculate }} {{ d_namingData.frequency }}

              </strong>
              <br> (
              <span v-if="d_namingData.fluid"> {{ d_namingData.fluid }}; </span>
              <span v-if="parseInt(d_namingData.fluid_volume) > 0">{{ $t('wdm16.4357') }} = {{ d_namingData.fluid_volume }} ml; </span>
              <span v-if="parseInt(d_namingData.duration) > 0">{{ $t('wdm16.4221') }} = {{ d_namingData.duration }} {{ $t('wdm16.3980') }};</span>
              <span v-if="Number.isInteger(Math.round(d_namingData.fluid_volume / (d_namingData.duration/60)))">
                  {{ Math.round(d_namingData.fluid_volume / (d_namingData.duration/60)) }} {{ $t('wdm16.3735') }}; 
                </span>
              <span v-if="Number.isInteger(Math.round((( d_namingData.fluid_volume / (d_namingData.duration/60)) * 33) / 100))">
                  ( {{ Math.round((( d_namingData.fluid_volume / (d_namingData.duration/60)) * 33) / 100) }} {{ $t('wdm16.4134') }} );
                </span> {{ d_namingData.administration_type }}
              <span v-if="d_namingData.starting_time">; Başlama saati = {{ TimeFormat(d_namingData.starting_time) }} </span>
              <span v-if="d_namingData.ending_time"> Bitiş saati = {{ TimeFormat(d_namingData.ending_time) }} </span> )
            </div>
          </div>
        </template>
      </div>
      <div class="col-sm-2">
        <!-- TODO bireysel buton hataları kaldırıldığında aktifleştirilecek -->
        <template v-if="f_patientDrugButtonIfControl(p_wdmr11or12List[p_componentType][p_wdmr11or12_ind].list[p_wdmr_data_ind])">
          <patient-drug-button :p_optionData="p_optionData" :p_componentType="p_componentType" :p_external_patient="patient_data" :p_orderOperation="p_orderOperation" :p_wdmr11or12_ind="p_wdmr11or12_ind" :p_wdmr11or12List="p_wdmr11or12List" :p_wdmr_data_ind="p_wdmr_data_ind" :mother_children="mother_children"></patient-drug-button>
        </template>
        <br>
        <template v-if="show_administration_time">
          <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '48')">?</b-badge>
          <strong>Başlama saati</strong>
          <datepicker style="background-color: transparent;" :config="{ enableTime: true, maxDate:'today', locale: datepicker_langs[lang]}" class="form-control" v-model="d_namingData.starting_time" name="starting_time"></datepicker>
          <br>
          <strong>Bitiş saati</strong>
          <datepicker style="background-color: transparent;" :config="{ enableTime: true, maxDate:'today', locale: datepicker_langs[lang]}" class="form-control" v-model="d_namingData.ending_time" name="ending_time"></datepicker>
          <div class="pull-right" style="padding: 2px;">
            <button class="btn" style="background-color: rgb(157, 221, 221);" title="Saati kaydet" type="button" @click="protocol_edit_save(drug_key, cycle_index, day_index, drug_type, 'none', 'none')">
              <a class="fa fa-save fa-1x"> Saati kaydet </a>
            </button>
          </div>
        </template>
      </div>
      <div class="col-sm-4">
        <!-- <div class="row">
          <div class="col-sm-8">
            <b>{{ $t('wdm16.3866') }}</b>
          </div>
          <div class="col-sm-4">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '49')">?</b-badge>
            <b-dropdown variant="primary" size="lg" no-caret>
              <template slot="button-content">
                <i class="icon-wrench"></i>
              </template>
              <b-dropdown-item @click="show_add_note(drug_key, cycle_index, day_index, prem_index, drug_type, approvals_index, 'none')"> {{ $t('wdm16.4034') }} </b-dropdown-item>
              <b-dropdown-item v-if="user.permissions_result && user.permissions_result.indexOf('add_toxicity') !== -1" @click="show_toxicity_add(drug_key, cycle_index, day_index, prem_index, drug_type, approvals_index)">{{ $t('wdm16.4091') }}</b-dropdown-item>
            </b-dropdown>
          </div>
        </div> -->
        <div v-if="!show_all_status">
          <div>
            <div>
              <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '50')">?</b-badge>
              <strong style="padding-left: 3px;">{{ $t('wdm16.4213') }}</strong>
            </div>
            <div>
              <div style="padding: 5px;">
                <!-- {{ calculate_last_status }} -->
                {{ d_namingData.last_log_status }}
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '51')">?</b-badge>
          <div>
            <div><strong style="padding-left: 3px;">{{ $t('wdm16.4213') }}</strong></div>
            <div>
              <div style="padding: 5px;">
                {{ d_namingData.last_log_status }}
              </div>
            </div>
          </div>
          <div>
            <div><strong style="padding-left: 3px;"> Tüm İşlemler</strong></div>
            <div>
              <div style="padding: 5px;">
                <div v-for="(log, log_ind) in d_namingData.log_list">
                  <strong><i>{{ log.date | show_time }} </i></strong> {{ log.user }} {{ log.status }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: center" v-if="(d_namingData.note_list.length > 0)">
          <b>{{ $t('wdm16.3758') }}</b>
        </div>
        <div v-for="(note, note_ind) in d_namingData.note_list">
          <div class="btn-group" role="group">
            <a class="fa fa-trash fa-2x" @click="drug_note_delete(note_ind)"></a>
            <a class="fa fa-edit fa-2x" @click="show_note_edit(note_ind)"></a>
          </div>
          {{ note.date | show_time }} {{ note.user }} {{ note.note }}
        </div>
      </div>
    </div>
    <modal v-if="show_add_premedication_to_drug_modal" @close="show_add_premedication_to_drug_modal = false" :width="'1000'">
      <div slot="header">
        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '52')">?</b-badge>
        <h3>İlaç premedikasyonu ekle</h3>
      </div>
      <div slot="body">
        <div class="row form-group">
          <div class="col-sm-12">
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input type="checkbox" v-model="premedication_data.concurrent"> Bu premedikasyonu ilacın sonraki siklus ve günlerine de ekle
              </label>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-3">
            <strong>Premedikasyon tipi</strong>
          </div>
          <div class="col-sm-6">
            <select v-model="premedication_data.type" class="form-control" @change="create_premedication_data()">
              <option value="before">Ön premedikasyon</option>
              <option value="after">Sonra premedikasyon</option>
              <option value="during">Sırasında premedikasyon</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-6"><strong>Premedikasyon ilaç detayları</strong></div>
        </div>
        <div class="row form-group">
          <div class="col-sm-12">
            <treatment-add-edit-drug :drug_data="premedication_data.treatment_data.selected_protocol.drugs[0][premedication_data.type][0]" :drug_type="premedication_data.type" :main_drug="premedication_data.treatment_data.selected_protocol.drugs[0]" :drg_ind="0" :prem_ind="'0'" :pro_inf_list="premedication_data.pro_inf_list" :treatment_data="premedication_data.treatment_data" :patient_data="patient_data" :premedication_add="true"></treatment-add-edit-drug>
          </div>
        </div>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" @click="confirm_data_func()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-secondary" @click="show_add_premedication_to_drug_modal = false">{{ $t('wdm16.4077') }}</button>
      </div>
    </modal>
    <modal v-if="confirm_modal" @close="confirm_modal = false">
      <h3 slot="header"> Analiz ekranı </h3>
      <div slot="body">
        <confirm-form :data="confirm_data"></confirm-form>
      </div>
      <div slot="footer">
        <button v-if="confirm_data.permission" type="button" class="btn btn-success" @click="save_add_premedication()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="confirm_modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="show_add_note_modal" @close="show_add_note_modal = false" :width="'1000'">
      <div slot="header">
        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '53')">?</b-badge>
        <h3>{{ $t('wdm16.3968') }}</h3>
      </div>
      <div slot="body">
        <div class="form-group row">
          <label for="port" class="col-2 col-form-label">Not Tipi</label>
          <div class="col-10">
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-radio" type="radio" name="type" v-model="add_note_data.note_type" value="drug"> İlaç notu
              </label>
            </div>
            <br>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-radio" type="radio" name="type" v-model="add_note_data.note_type" value="before"> İlaç öncesi
              </label>
            </div>
            <br>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-radio" type="radio" name="type" v-model="add_note_data.note_type" value="during"> İlaç sırasında
              </label>
            </div>
            <br>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-radio" type="radio" name="type" v-model="add_note_data.note_type" value="after"> İlaç sonrasında
              </label>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="port" class="col-2 col-form-label">Not</label>
          <div class="col-10">
            <textarea class="form-control" v-model="add_note_data.note"></textarea>
          </div>
        </div>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-primary" v-if="!add_note_data.note_ind" @click="add_note_save()">{{ $t('wdm16.3959') }}</button>
        <button type="button" class="btn btn-primary" v-if="add_note_data.note_ind" @click="add_note_save()">{{ $t('wdm16.3845') }} </button>
        <button type="button" class="btn btn-secondary" @click="show_add_note_modal = false">{{ $t('wdm16.4077') }}</button>
      </div>
    </modal>
    <!-- TOXICITY MODAL -->
    <modal v-if="show_drug_toxicity_modal" @close="show_drug_toxicity_modal = false">
      <h3 slot="header">
        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '54')">?</b-badge>
          {{ $t('wdm16.3749') }}
        </h3>
      <div slot="body">
        <toxicity :data="drug_toxicity_data"></toxicity>
      </div>
      <div slot="footer">
        <button v-if="!drug_toxicity_data.t_id" type="button" class="btn btn-primary" @click="drug_toxicity_save()">{{ $t('wdm16.3839') }}</button>
        <button v-if="drug_toxicity_data.t_id" type="button" class="btn btn-primary" @click="drug_toxicity_save(drug_toxicity_data.t_id)">{{ $t('wdm16.3833') }}</button>
        <button type="button" class="btn btn-secondary" @click="show_drug_toxicity_modal = false">{{ $t('wdm16.4045') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  default as TreatmentCycleDrugButtons
} from './TreatmentCycleDrugButtons';
import {
  default as Toxicity
} from './Toxicity';
import {
  default as TreatmentAddEditDrug
} from './TreatmentAddEditDrug';
import {
  default as ConfirmForm
} from './ConfirmForm';
import { mapGetters } from 'vuex';
import TreatmentService from '@/services/treatment';
import { DosageCalculator, if_buttons } from '@/services/public/functions';
import {
  default as PatientDrugButton
} from "@/components/widgets/PatientDrugButton";
import {
  default as Modal
} from '@/components/widgets/Modal';
import vSelect from 'vue-select';
import Datepicker from 'wisdom-vue-datepicker';
import { tr } from '../../../node_modules/flatpickr/dist/l10n/tr';
import moment from 'moment';
import OptionService from '@/services/option';
import store from '@/store';

export default {
  name: 'PatientDrugDayShow',
  computed: {
    ...mapGetters({
      StoreModal: 'modal',
      patient: 'patient_selected',
      lang: 'lang',
      help: 'help'
    }),
    calculate_last_status: function () {
      if (this.drug_type === 'drug') {
        return this.$t('wdm16.' + this.variable_langs_id_list.approval_choices[this.d_namingData.last_log_status]);
      } else {
        return this.$t('wdm16.' + this.variable_langs_id_list.approval_choices[this.d_namingData.approvals[this.approvals_index].status]);
      }
    },
    CalculateDrugStyle: function () {
      if (this.drug_type === 'drug') {
        return 'background-color: #c3f5f5;';
      } else if (this.drug_type === 'before') {
        return 'background-color: #00ffd5;';
      } else if (this.drug_type === 'during') {
        return 'background-color: #00ffd5;';
      } else if (this.drug_type === 'after') {
        return 'background-color: #00ffd5;';
      }
    }
  },
  props: {
    p_wdmr11or12List: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    p_wdmr11or12_ind: {
      type: Number,
      required: false
    },
    p_optionData: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    p_button_date: {
      type: String,
      required: false
    },
    p_external_patient: {
      type: Object,
      required: false
    },
    mother_children: {
      type: Object,
      required: true
    },
    p_orderOperation: {
      type: Object,
      required: true
    },
    p_wdmr11or12: {
      type: Object,
      required: false
    },
    p_wdmr_data: {
      type: Object,
      required: true
    },
    p_wdmr_data_ind: {
      type: Number,
      required: true,
      default: -1
    },
    show_more_drug_details: {
      type: Object,
      required: false
    },
    drug_type: {
      type: String,
      required: false
    },
    day_ind: {
      type: String,
      required: false
    },
    drug: {
      type: Object,
      required: false
    },
    drug_data: {
      type: Object,
      required: false
    },
    treatment_data: {
      type: Object,
      required: false
    },
    show_all_status: {
      type: Boolean,
      required: false
    },
    show_administration_time: {
      type: Boolean,
      required: false
    },
    children_mother: {
      type: Object,
      required: false
    },
    drug_key: {
      type: String,
      required: false
    },
    cycle_index: {
      type: Number,
      required: false
    },
    day_index: {
      type: Number,
      required: false
    },
    prem_index: {
      type: String,
      required: false
    },
    approvals_index: {
      type: String,
      required: false
    },
    drug_order: {
      type: Number,
      required: false
    },
    p_componentType: {
      type: String,
      required: true
    }
  },
  data () {
    return { // variables
      variable_langs_id_list: require('@/options/variable_langs_id_list').options,
      store: store,
      d_drugNoteDeleteQuery: '',
      moment: moment,
      approvals: { 'doctor': { 'status': 'waiting for doctor approval', 'data': [] }, 'nurse': { 'status': 'waiting for medication request', 'data': [] }, 'pharmacy': { 'status': 'waiting for pharmacy approval', 'data': [] }, 'preparation': { 'status': 'waiting for medication preparation', 'data': [] }, 'match': { 'status': 'waiting for correct matching of the patient and the drug', 'data': [] }, 'administration': { 'status': 'Medication is ready and waiting for administration', 'data': [] }, 'status': 'no_operation_yet', 'drug_permission': 'doctor' },
      premedication_data: { type: 'before', concurrent: false, pro_inf_list: { drugs: {}, others: {} }, treatment_data: { selected_protocol: { drugs: [] } } },
      confirm_data: { func_name: '', info: [], permission: true },
      show_add_premedication_to_drug_modal: false,
      calculation_data: {},
      edit_protocol_cycles: {},
      if_buttons: if_buttons,
      datepicker_langs: { tr: tr, en: {} },
      types: ['before', 'during', 'after'],
      dose_changes: {},
      confirm_modal: false,
      show_nurse_modal: false,
      show_drug_toxicity_modal: false,
      show_add_note_modal: false,
      add_note_data: {},
      drug_toxicity_data: { process: [], note: '' },
      drug_toxicity_selected: {},
      drug_toxicity_group: [],
      drug_toxicity_grades: [],
      drug_toxicity_options: [],
      fluid_options: require('@/options/fluids').options,
      info_options: require('@/options/info').options,
      unit_options: require('@/options/unit').options,
      frequency: require('@/options/frequency').options,
      user: {},
      reason_options: [{ 'value': 'intolerability', 'caption': 'İntolerabilite' },
        { 'value': 'low_performance', 'caption': 'Düşük performans' },
        { 'value': 'patient_preference', 'caption': 'Hasta tercihi' }
      ],
      selected_reason: '',
      all_users: {},
      toxicities_in_protocol: [],
      d_namingData: { 'cycle': '', 'day': '', 'drug': '', 'given_dosage': '', 'planned_dosage': '', 'unit': '', 'last_log_user': '', 'last_log_status': '', 'log_list': [], 'note_list': [], 'fluid': '', 'fluid_volume': '',  'frequency': '', 'administration_type': '', 'duration': '', 'wait_after_administration': ''}
    };
  },
  beforeCreate: function () {},
  created: function () {
    if (this.p_external_patient) {
      this.patient_data = JSON.parse(JSON.stringify(this.p_external_patient));
    } else {
      this.patient_data = JSON.parse(JSON.stringify(this.patient));
    }
    this.user = JSON.parse(localStorage.getItem('user'));
    let all_users = JSON.parse(localStorage.getItem('all_users'));
    for (let i in all_users) {
      this.all_users[all_users[i].username] = all_users[i];
    }
    this.f_createNamingData();
  },
  mounted: function () {},
  methods: {
    f_createNamingData: function () {
      try {
        this.d_namingData['cycle'] = this.p_wdmr_data.data.general.repeat.val;
      } catch (err) {}

      try {
        this.d_namingData['day'] = Object.keys(this.p_wdmr_data.data.general.selected_days)[0];
      } catch (err) {}

      try {
        this.d_namingData['drug'] = this.p_wdmr_data.data.general.drug.val.label;
      } catch (err) {}

      try {
        this.d_namingData['given_dosage'] = this.p_wdmr_data.data.general.given_dosage.val;
      } catch (err) {}

      try {
        this.d_namingData['planned_dosage'] = this.p_wdmr_data.data.general.planned_dosage.val;
      } catch (err) {}

      try {
        this.d_namingData['unit'] = this.p_wdmr_data.data.general.dosage.unit.value;
      } catch (err) {}

      try {
        this.d_namingData['last_log_user'] = this.p_wdmr_data.data.status.status_log.list[this.p_wdmr_data.data.status.status_log.list.length - 1].status_log_user.val.label;
      } catch (err) {}

      try {
        this.d_namingData['last_log_status'] = this.p_wdmr_data.data.status.status_log.list[this.p_wdmr_data.data.status.status_log.list.length - 1].status_log_status.val.label;
      } catch (err) {
        this.d_namingData['last_log_status'] = ' Henüz İşlem Yapılmadı ';
      }

      try {
        this.d_namingData['fluid'] = this.p_wdmr_data.data.general.fluid.val.label;
      } catch (err) {}

      try {
        this.d_namingData['fluid_volume'] = this.p_wdmr_data.data.general.fluid_volume.val;
      } catch (err) {}

      try {
        this.d_namingData['frequency'] = this.p_wdmr_data.data.general.frequency.val.label;
      } catch (err) {}

      try {
        this.d_namingData['administration_type'] = this.p_wdmr_data.data.general.administration_type.val.label;
      } catch (err) {}

      try {
        this.d_namingData['duration'] = this.p_wdmr_data.data.general.duration.val;
      } catch (err) {}

      try {
        this.d_namingData['wait_after_administration'] = this.p_wdmr_data.data.general.wait_after_administration.val;
      } catch (err) {}

      try {
        for (let i in this.p_wdmr_data.data.status.status_log.list) {
          let x = this.p_wdmr_data.data.status.status_log.list[i];
          this.d_namingData['log_list'].push({'status': x.status_log_status.val.label, 'user': x.status_log_user.val.label, 'date': x.status_log_date.val });
        }
      } catch (err) {}
      this.$forceUpdate();
    },
    f_patientDrugButtonIfControl: function () {
      return true
    },
    save_add_premedication: function () {
      this.show_add_premedication_to_drug_modal = false;
      this.confirm_modal = false;
      this.premedication_data.treatment_data.selected_protocol.drugs[0][this.premedication_data.type][0];
      this.treatment_data.protocol_cycles.drugs[this.drug_key].cycles[this.cycle_index].days[this.day_index];
      let prem_ind = this.create_premedication_index();
      let data = JSON.parse(JSON.stringify(this.treatment_data));
      let prepared_data = this.prepare_premedication_drug();
      if (this.premedication_data.concurrent) {
        for (let cyc_ind in data.protocol_cycles.drugs[this.drug_key].cycles) {
          for (let dy_ind in data.protocol_cycles.drugs[this.drug_key].cycles[cyc_ind].days) {
            if ((parseInt(cyc_ind) === parseInt(this.cycle_index)) && (parseInt(dy_ind) >= parseInt(this.day_index))) {
              // işlem yapılacak
              data.protocol_cycles.drugs[this.drug_key].cycles[cyc_ind].days[dy_ind].data[this.premedication_data.type][prem_ind] = prepared_data;
            }
            if (parseInt(cyc_ind) > parseInt(this.cycle_index)) {
              // işlem yapılacak
              data.protocol_cycles.drugs[this.drug_key].cycles[cyc_ind].days[dy_ind].data[this.premedication_data.type][prem_ind] = prepared_data;
            }
          }
        }
      } else {
        data.protocol_cycles.drugs[this.drug_key].cycles[this.cycle_index].days[this.day_index].data[this.premedication_data.type][prem_ind] = prepared_data;
      }
      TreatmentService.add_premedication(this.patient_data.id, this.treatment_data.id, data)
        .then(resp => {
          for (let i in this.treatment_data) {
            this.treatment_data[i] = resp.data.result[i];
          }
          if (this.children_mother && this.children_mother.sort_days_list) {
            this.children_mother.sort_days_list = false;
          } else if (this.children_mother && !this.children_mother.sort_days_list) {
            this.children_mother.sort_days_list = true;
          }
        });
    },
    create_premedication_index: function () {
      let prem_ind_array = [];
      for (let cyc_ind in this.treatment_data.protocol_cycles.drugs[this.drug_key].cycles) {
        for (let dy_ind in this.treatment_data.protocol_cycles.drugs[this.drug_key].cycles[cyc_ind].days) {
          for (let pr_ind in this.treatment_data.protocol_cycles.drugs[this.drug_key].cycles[cyc_ind].days[dy_ind].data[this.premedication_data.type]) {
            if (prem_ind_array.indexOf(pr_ind) === -1) {
              prem_ind_array.push(pr_ind);
            }
          }
        }
      }
      let drg_value = this.premedication_data.treatment_data.selected_protocol.drugs[0][this.premedication_data.type][0].drug.value;
      for (let i = 0; i < 1000; i++) {
        let ind = i.toString() + '-' + drg_value;
        if (prem_ind_array.indexOf(ind) === -1) {
          return ind;
        }
      }
    },
    prepare_premedication_drug: function () {
      let data = this.premedication_data.treatment_data.selected_protocol.drugs[0][this.premedication_data.type][0];
      let today = new Date();
      today = moment(today).toISOString();
      data.toxicity = [];
      data.info_list = { 'input_dosage': {}, 'input_volume': {}, 'input_planned_dosage': {}, 'input_duration': {} };
      data.meal_relation = '';
      data.given_dosage = data.planned_dosage;
      data.dosage_changes = { 'data': {}, 'reasons': [] };
      console.log('data.day :::', data.day);
      let string_day = data.day[0].toString();
      data.approvals = {};
      data.approvals[string_day] = JSON.parse(JSON.stringify(this.approvals));
      data.approvals[string_day]['note_list'] = [];
      if (data.drug_note) {
        data.approvals[string_day].note_list.push({ date_at: today, note: data.drug_note, note_type: 'drug', user: this.user.username });
      }

      // laboratory data
      if (this.patient_data.last_laboratory['2']) {
        this.calculation_data.lab_date = this.patient_data.last_laboratory['2'].date;
        this.calculation_data.crea = this.patient_data.last_laboratory['2'].val;
        this.calculation_data.gfr = this.patient_data.last_laboratory['2'].gfr;
        this.calculation_data.selected_gfr_formule = this.patient_data.last_laboratory['2'].selected_gfr_formule;
        this.calculation_data.selected_weight_for_gfr = this.patient_data.last_laboratory['2'].selected_weight_for_gfr;
      }
      // bmi data
      if (this.patient_data.last_bmi_data !== 'none') {
        this.calculation_data.bmi_date = this.patient_data.last_bmi_data.date;
        this.calculation_data.bsa = this.patient_data.last_bmi_data.bsa;
        this.calculation_data.length = this.patient_data.last_bmi_data.length;
        this.calculation_data.weight = this.patient_data.last_bmi_data.weight;
        this.calculation_data.selected_weight_for_bsa = this.patient_data.last_bmi_data.selected_weight_for_bsa;
      }

      data.calculation_data = this.calculation_data;
      data.approvals[string_day].calculation_data = this.calculation_data;
      data.approvals[string_day].barcode_list = [];
      data.approvals[string_day].brand_count = {};
      data.approvals[string_day].fluid_brands = { 'barcode_list': [], 'brand_count': {} };

      if (data.drug_mix) {
        for (let mx in data.drug_mix) {
          if (data.drug_mix[mx].drug) {
            data.drug_mix[mx].toxicity = [];
            data.drug_mix[mx].barcode_list = [];
            data.drug_mix[mx].brand_count = {};
            data.drug_mix[mx].dosage_changes = { 'data': {}, 'reasons': [] };
            data.drug_mix[mx].given_dosage = data.drug_mix[mx].planned_dosage;
            data.drug_mix[mx].note_list = [];
            data.drug_mix[mx].info_list = { 'input_dosage': {}, 'input_volume': {}, 'input_planned_dosage': {}, 'input_duration': {} };
          }
        }
      }

      return data;
    },
    create_premedication_data: function () {
      this.premedication_data.treatment_data.selected_protocol.drugs[0].drug_mix = [];
      this.premedication_data.treatment_data.selected_protocol.drugs[0].before = [];
      this.premedication_data.treatment_data.selected_protocol.drugs[0].during = [];
      this.premedication_data.treatment_data.selected_protocol.drugs[0].after = [];
      this.premedication_data.treatment_data.selected_protocol.drugs[0][this.premedication_data.type].push({ day: [0], minutes_drug_relation: [], drug_note: '', drug_days: [0], drug_mix: [], unit: { value: 'mg', caption: 'mg' }, frequency: { caption: '1x1', value: '1x1' }, administration_type: { caption: 'İntravenöz', value: '12' }, wait_after_administration: 0, drug_order: 0, dosage: 0, planned_dosage: 0, calculated_dosage: 0, fluid_volume: 0, fluid: '', duration: 0, drug: '' });
      this.calculate_drug_info(0);
    },
    show_add_premedication_to_drug: function () {
      this.premedication_data.treatment_data.selected_protocol.drugs = [];
      this.premedication_data.treatment_data.selected_protocol.drugs.push(JSON.parse(JSON.stringify(this.drug_data)));
      this.premedication_data.treatment_data.selected_protocol.drugs[0].period = 21;
      this.create_premedication_data();
      this.show_add_premedication_to_drug_modal = true;
    },
    calculate_drug_info: function (drg_ind) {
      let data = this.premedication_data.treatment_data.selected_protocol.drugs[drg_ind];
      this.premedication_data.pro_inf_list.drugs[drg_ind] = { info_list: { input_dosage: {}, input_volume: {}, calculated_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_frequency: {}, select_administration: {}, select_unit: {}, input_wait_after_administration: {}, select_drug_days: {}, input_period: {}, input_repeat: {}, input_delay: {}, select_the_drug: {}, select_note_drug_days: {}, select_lab_drug_days: {}, select_msg_drug_days: {}, select_note_days: {}, select_msg_days: {}, select_lab_days: {}, select_lab_tests: {}, text_note_drug: {} }, before: {}, after: {}, during: {}, drug_mix: {} };
      for (let mx in data.drug_mix) {
        this.premedication_data.pro_inf_list.drugs[drg_ind].drug_mix[mx] = { info_list: { input_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_unit: {}, select_the_drug: {} } };
      }
      for (let pr in this.types) {
        for (let pr_ind in data[this.types[pr]]) {
          this.premedication_data.pro_inf_list.drugs[drg_ind][this.types[pr]][pr_ind] = { info_list: { input_dosage: {}, input_volume: {}, calculated_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_frequency: {}, select_administration: {}, select_unit: {}, input_wait_after_administration: {}, select_prem_drug_days: {}, select_prem_days: {}, select_the_drug: {} }, drug_mix: {} };
          for (let mx in data[this.types[pr]][pr_ind].drug_mix) {
            this.premedication_data.pro_inf_list.drugs[drg_ind][this.types[pr]][pr_ind].drug_mix[mx] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_the_drug: {}, select_unit: {} } };
          }
        }
      }
    },
    TimeFormat: function (date) {
      return moment(date).format('h:mm');
    },
    ToxicityControl: function () {
      if (!this.treatment_data.protocol_cycles) {
        return;
      }
      if (this.treatment_data.protocol_cycles.drug_order.length > 0) {
        this.toxicities_in_protocol = [];
        for (let drg_ind in this.treatment_data.protocol_cycles.drugs) {
          for (let cyc_ind in this.treatment_data.protocol_cycles.drugs[drg_ind].cycles) {
            for (let day_ind in this.treatment_data.protocol_cycles.drugs[drg_ind].cycles[cyc_ind].days) {
              let data = this.treatment_data.protocol_cycles.drugs[drg_ind].cycles[cyc_ind].days[day_ind].data;
              let day = this.treatment_data.protocol_cycles.drugs[drg_ind].cycles[cyc_ind].days[day_ind].day;
              if (data.toxicity && data.toxicity.length > 0) {
                this.toxicities_in_protocol.push({ drug: data.drug.label, drg_ind: drg_ind, cyc_ind: cyc_ind, day_ind: day_ind, day: day, tox_data: data.toxicity });
              }
            }
          }
        }
      }
    },
    CalculateDosageChange: function (planned_dosage, calculated_dosage) {
      if (parseInt(planned_dosage) / parseInt(calculated_dosage) > 1.01) {
        return '( + %' + Math.abs(Math.round((parseInt(planned_dosage) / parseInt(calculated_dosage) - 1) * 100)) + ' değişim, hesaplanan doz: ' + parseInt(calculated_dosage) + ' )';
      } else if (parseInt(planned_dosage) / parseInt(calculated_dosage) < 0.99) {
        return '( - %' + Math.abs(Math.round((parseInt(planned_dosage) / parseInt(calculated_dosage) - 1) * 100)) + ' değişim, hesaplanan doz: ' + parseInt(calculated_dosage) + ' )';
      } else {
        return '';
      }
    },
    eger: function (drug_key, cycle_index, day_index, type, prem_ind, prem_day, mix_ind, subject, dy_ind) {
      let types = ['before', 'during', 'after'];
      if (this.treatment_data.status === 'terminated') {
        return false;
      }
      if (subject === 'delete_cycle') { // dikkat ilaçta yada premedikalarda durum gerçekleşirse res false döönüyor.
        let data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days;
        for (let day in data) {
          if (['no_operation_yet', 'doctor_5'].indexOf(data[day].data.last_log_status) === -1) {
            return false;
          }
          for (let i in types) {
            for (let pr_drg in data[day].data[types[i]]) {
              for (let pr_dy in data[day].data[types[i]][pr_drg].day) {
                let x = (data[day].data[types[i]][pr_drg].day[pr_dy]).toString();
                if (['no_operation_yet', 'doctor_5'].indexOf(data[day].data[types[i]][pr_drg].approvals[x].status) === -1) {
                  return false;
                }
              }
            }
          }
        }
      } else if (subject === 'delete_this_day_of_this_cycle') {
        let data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data;
        if (['no_operation_yet', 'doctor_5'].indexOf(data.last_log_status) === -1) {
          return false;
        }
        for (let i in types) {
          for (let pr_drg in data[types[i]]) {
            for (let pr_dy in data[types[i]][pr_drg].day) {
              let x = (data[types[i]][pr_drg].day[pr_dy]).toString();
              if (['no_operation_yet', 'doctor_5'].indexOf(data[types[i]][pr_drg].approvals[x].status) === -1) {
                return false;
              }
            }
          }
        }
      } else if (subject === 'delete_this_day_in_all_cycles') {
        let data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data;
        if (['no_operation_yet', 'doctor_5'].indexOf(data.last_log_status) === -1) {
          return false;
        }
        for (let i in types) {
          for (let pr_drg in data[types[i]]) {
            for (let pr_dy in data[types[i]][pr_drg].day) {
              let x = (data[types[i]][pr_drg].day[pr_dy]).toString();
              if (['no_operation_yet', 'doctor_5'].indexOf(data[types[i]][pr_drg].approvals[x].status) === -1) {
                return false;
              }
            }
          }
        }
      } else if (subject === 'change_day_in_cycle') {
        let data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data;
        if (['no_operation_yet', 'doctor_5'].indexOf(data.last_log_status) === -1) {
          return false;
        }
        for (let i in types) {
          for (let pr_drg in data[types[i]]) {
            for (let pr_dy in data[types[i]][pr_drg].day) {
              let x = (data[types[i]][pr_drg].day[pr_dy]).toString();
              if (['no_operation_yet', 'doctor_5'].indexOf(data[types[i]][pr_drg].approvals[x].status) === -1) {
                return false;
              }
            }
          }
        }
      } else if (subject === 'dosage_adjustment') {
        if (type === 'drug') {
          let drug_status = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.last_log_status;
          if (['no_operation_yet', 'doctor_5'].indexOf(drug_status) === -1) {
            return false;
          }
        } else {
          let data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind];
          for (let pr_dy in data.day) {
            let x = (data.day[pr_dy]).toString();
            if (['no_operation_yet', 'doctor_5'].indexOf(data.approvals[x].status) === -1) {
              return false;
            }
          }
        }
      } else if (subject === 'date_delay_adjustment') {
        let det = false;
        for (let drg in this.treatment_data.date_list[dy_ind]) {
          if (this.treatment_data.date_list[dy_ind][drg].type === 'drug') {
            det = true;
            break;
          }
        }
        if (!det) {
          return false;
        }
      } else if (subject === 'period_change') {
        if (cycle_index !== this.treatment_data.protocol_cycles.drugs[drug_key].cycles.length - 1) {
          let data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index + 1].days;
          for (let day in data) {
            if (['no_operation_yet', 'doctor_5'].indexOf(data[day].data.last_log_status) === -1) {
              return false;
            }
            for (let i in types) {
              for (let pr_drg in data[day].data[types[i]]) {
                for (let pr_dy in data[day].data[types[i]][pr_drg].day) {
                  let x = (data[day].data[types[i]][pr_drg].day[pr_dy]).toString();
                  if (['no_operation_yet', 'doctor_5'].indexOf(data[day].data[types[i]][pr_drg].approvals[x].status) === -1) {
                    return false;
                  }
                }
              }
            }
          }
        }
      } else if (subject === 'planned_given') {
        let pg_data = {};
        if (type === 'drug') {
          pg_data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.approvals;
          if (['administration_0', 'administration_1'].indexOf(pg_data.status) !== -1) {
            return false;
          }
        } else { // before after during
          pg_data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind];
          for (let pr_dy in pg_data.day) {
            let x = pg_data.day[pr_dy].toString();
            if (['administration_0', 'administration_1'].indexOf(pg_data.approvals[x].status) !== -1) {
              return false;
            }
          }
        }
      }
      return true;
    },
    dosage_calculate: function (drug_key, cycle_index, day_index, prem_ind, type, mix_ind) {
      let calculate_data = this.patient_data;
      let data = {};
      if (type === 'drug') {
        if (mix_ind === 'none') {
          data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data;
        } else {
          data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.drug_mix[mix_ind];
        }
      } else { // before during after
        if (mix_ind === 'none') {
          data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind];
        } else {
          data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].drug_mix[mix_ind];
        }
      }
      let unit = data.unit.value;
      let dosage = data.dosage;
      data.calculated_dosage = DosageCalculator.calculate(unit, calculate_data, dosage);
      data.planned_dosage = data.calculated_dosage;
    },
    add_selected_reason: function (drug_key, cycle_index, day_index, type, prem_ind, mix_ind) {
      if (this.selected_reason === null) {
        alert('Please select a reason first.');
        return;
      }
      if (type === 'drug') {
        if (mix_ind === 'none') {
          let data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.dosage_changes.reasons;
          if (data.indexOf(this.selected_reason.value) === -1) {
            data.push(this.selected_reason.value);
          }
        } else {
          let data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.drug_mix[mix_ind].dosage_changes.reasons;
          if (data.indexOf(this.selected_reason.value) === -1) {
            data.push(this.selected_reason.value);
          }
        }
      } else { // before during after
        if (mix_ind === 'none') {
          let data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].dosage_changes.reasons;
          if (data.indexOf(this.selected_reason.value) === -1) {
            data.push(this.selected_reason.value);
          }
        } else {
          let data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].drug_mix[mix_ind].dosage_changes.reasons;
          if (data.indexOf(this.selected_reason.value) === -1) {
            data.push(this.selected_reason.value);
          }
        }
      }
      this.$forceUpdate();
    },
    delete_selected_reason: function (drug_key, cycle_index, day_index, type, prem_ind, mix_ind, reason_ind) {
      if (type === 'drug') {
        if (mix_ind === 'none') {
          let data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.dosage_changes.reasons;
          data.splice(reason_ind, 1);
        } else {
          let data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.drug_mix[mix_ind].dosage_changes.reasons;
          data.splice(reason_ind, 1);
        }
      } else { // before during after
        if (mix_ind === 'none') {
          let data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].dosage_changes.reasons;
          data.splice(reason_ind, 1);
        } else {
          let data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].drug_mix[mix_ind].dosage_changes.reasons;
          data.splice(reason_ind, 1);
        }
      }
    },
    calculate_dosage_change: function (drug_key, cycle_index, day_index) {
      if (this.edit_protocol_cycles.drugs[drug_key].cycles[cycle_index] === undefined) {
        return;
      }
      if (this.edit_protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index] === undefined) {
        return;
      }
      if (this.edit_protocol_cycles.drugs[drug_key].cycles[cycle_index]) {
        if (this.edit_protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index]) {
          let new_dosage = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.planned_dosage;
          let old_dosage = this.edit_protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.planned_dosage;
          let percent = 100 * (new_dosage - old_dosage) / old_dosage;
          return percent;
        }
      }
    },
    calculate_dosage_changes: function (drug_key, cycle_index, day_index, type, prem_ind, mix_ind) {
      if (this.edit_protocol_cycles.drugs[drug_key].cycles[cycle_index] === undefined) {
        return;
      }
      if (this.edit_protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index] === undefined) {
        return;
      }
      let first_planned_dosage = '';
      let second_planned_dosage = '';
      let percent = 0;
      if (type === 'drug') {
        if (mix_ind === 'none') {
          let data_first = this.edit_protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data;
          let data_second = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data;
          if (data_first.dosage_changes.data.percent !== undefined) {
            first_planned_dosage = data_first.dosage_changes.data.old_planned_dosage; // ilk planladığımız ana değer
          } else {
            first_planned_dosage = data_first.planned_dosage; // ilk planladığımız ana değer
          }
          second_planned_dosage = data_second.planned_dosage; // anlık olarak değiştirdiğimiz değeri
          data_second.given_dosage = data_second.planned_dosage;
          percent = (second_planned_dosage - first_planned_dosage) * 100 / first_planned_dosage;
          if (Math.abs(percent) > 1.5) {
            parseFloat(second_planned_dosage) > parseFloat(first_planned_dosage) ? this.dose_changes['type'] = 'increment' : this.dose_changes['type'] = 'decrement';
            this.dose_changes['percent'] = Math.round((second_planned_dosage - first_planned_dosage) * 100 / first_planned_dosage);
            this.dose_changes['old_planned_dosage'] = first_planned_dosage;
            this.dose_changes['new_planned_dosage'] = second_planned_dosage;
            this.dose_changes['old_unit'] = data_second.unit;
            this.dose_changes['old_dosage'] = data_second.dosage;
            this.dose_changes['user'] = data_second.username;
            this.dose_changes['new_unit'] = data_second.unit;
            this.dose_changes['new_dosage'] = data_second.dosage;
            let a = new Date();
            a.setHours(a.getHours() + 3);
            this.dose_changes['date'] = a.toISOString();
            data_second.dosage_changes.data = this.dose_changes;
          } else {
            if (data_second.dosage_changes.data.percent !== undefined) {
              data_second.dosage_changes = { data: {}, reasons: [] };
            }
          }
        } else { // mix
          let data_first = this.edit_protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.drug_mix[mix_ind];
          let data_second = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.drug_mix[mix_ind];
          if (data_first.dosage_changes.data.percent !== undefined) {
            first_planned_dosage = data_first.dosage_changes.data.old_planned_dosage; // ilk planladığımız ana değer
          } else {
            first_planned_dosage = data_first.planned_dosage; // ilk planladığımız ana değer
          }
          second_planned_dosage = data_second.planned_dosage; // anlık olarak değiştirdiğimiz değeri
          data_second.given_dosage = data_second.planned_dosage;
          percent = (second_planned_dosage - first_planned_dosage) * 100 / first_planned_dosage;
          if (Math.abs(percent) > 1.5) {
            parseFloat(second_planned_dosage) > parseFloat(first_planned_dosage) ? this.dose_changes['type'] = 'increment' : this.dose_changes['type'] = 'decrement';
            this.dose_changes['percent'] = Math.round((second_planned_dosage - first_planned_dosage) * 100 / first_planned_dosage);
            this.dose_changes['old_planned_dosage'] = first_planned_dosage;
            this.dose_changes['new_planned_dosage'] = second_planned_dosage;
            this.dose_changes['old_unit'] = data_second.unit;
            this.dose_changes['old_dosage'] = data_second.dosage;
            this.dose_changes['user'] = data_second.username;
            this.dose_changes['new_unit'] = data_second.unit;
            this.dose_changes['new_dosage'] = data_second.dosage;
            let a = new Date();
            a.setHours(a.getHours() + 3);
            this.dose_changes['date'] = a.toISOString();
            data_second.dosage_changes.data = this.dose_changes;
          } else {
            if (data_second.dosage_changes.data.percent !== undefined) {
              data_second.dosage_changes = { data: {}, reasons: [] };
            }
          }
        }
      } else { // before after during
        if (mix_ind === 'none') {
          if (this.edit_protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind] === undefined) {
            return;
          }
          let data_first = this.edit_protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind];
          let data_second = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind];
          if (data_first.dosage_changes.data.percent !== undefined) {
            first_planned_dosage = data_first.dosage_changes.data.old_planned_dosage; // ilk planladığımız ana değer
          } else {
            first_planned_dosage = data_first.planned_dosage; // ilk planladığımız ana değer
          }
          second_planned_dosage = data_second.planned_dosage; // anlık olarak değiştirdiğimiz değeri
          data_second.given_dosage = data_second.planned_dosage;
          percent = (second_planned_dosage - first_planned_dosage) * 100 / first_planned_dosage;
          if (Math.abs(percent) > 1.5) {
            parseFloat(second_planned_dosage) > parseFloat(first_planned_dosage) ? this.dose_changes['type'] = 'increment' : this.dose_changes['type'] = 'decrement';
            this.dose_changes['percent'] = Math.round((second_planned_dosage - first_planned_dosage) * 100 / first_planned_dosage);
            this.dose_changes['old_planned_dosage'] = first_planned_dosage;
            this.dose_changes['new_planned_dosage'] = second_planned_dosage;
            this.dose_changes['old_unit'] = data_second.unit;
            this.dose_changes['old_dosage'] = data_second.dosage;
            this.dose_changes['user'] = data_second.username;
            this.dose_changes['new_unit'] = data_second.unit;
            this.dose_changes['new_dosage'] = data_second.dosage;
            let a = new Date();
            a.setHours(a.getHours() + 3);
            this.dose_changes['date'] = a.toISOString();
            data_second.dosage_changes.data = this.dose_changes;
          } else {
            if (data_second.dosage_changes.data.percent !== undefined) {
              data_second.dosage_changes = { data: {}, reasons: [] };
            }
          }
        } else {
          if (this.edit_protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind] === undefined) {
            return;
          }
          let data_first = this.edit_protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].drug_mix[mix_ind];
          let data_second = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].drug_mix[mix_ind];
          if (data_first.dosage_changes.data.percent !== undefined) {
            first_planned_dosage = data_first.dosage_changes.data.old_planned_dosage; // ilk planladığımız ana değer
          } else {
            first_planned_dosage = data_first.planned_dosage; // ilk planladığımız ana değer
          }
          second_planned_dosage = data_second.planned_dosage; // anlık olarak değiştirdiğimiz değeri
          data_second.given_dosage = data_second.planned_dosage;
          percent = (second_planned_dosage - first_planned_dosage) * 100 / first_planned_dosage;
          if (Math.abs(percent) > 1.5) {
            parseFloat(second_planned_dosage) > parseFloat(first_planned_dosage) ? this.dose_changes['type'] = 'increment' : this.dose_changes['type'] = 'decrement';
            this.dose_changes['percent'] = Math.round((second_planned_dosage - first_planned_dosage) * 100 / first_planned_dosage);
            this.dose_changes['old_planned_dosage'] = first_planned_dosage;
            this.dose_changes['new_planned_dosage'] = second_planned_dosage;
            this.dose_changes['old_unit'] = data_second.unit;
            this.dose_changes['old_dosage'] = data_second.dosage;
            this.dose_changes['user'] = data_second.username;
            this.dose_changes['new_unit'] = data_second.unit;
            this.dose_changes['new_dosage'] = data_second.dosage;
            let a = new Date();
            a.setHours(a.getHours() + 3);
            this.dose_changes['date'] = a.toISOString();
            data_second.dosage_changes.data = this.dose_changes;
          } else {
            if (data_second.dosage_changes.data.percent !== undefined) {
              data_second.dosage_changes = { data: {}, reasons: [] };
            }
          }
        }
      }
    },
    protocol_edit_save: function (drug_key, cycle_index, day_index, type, prem_ind, mix_ind) {
      TreatmentService.protocol_edit_save(this.patient_data.id, this.treatment_data.id, this.treatment_data)
        .then(resp => {
          this.update_treatment_data(resp.data.result);
          alert(this.$t('wdm16.13275'));
        });
    },
    drug_note_delete: function (drug_key, cycle_index, day_index, prem_ind, type, prem_day, mix_ind, note_ind) {
      this.d_drugNoteDeleteQuery = '';
      this.d_drugNoteDeleteQuery = 'drug_key=' + drug_key + '&cycle_index=' + cycle_index + '&day_index=' + day_index + '&prem_ind=' + prem_ind + '&type=' + type + '&prem_day=' + prem_day + '&mix_ind=' + mix_ind + '&note_ind=' + note_ind;
      let modal_data = { 'type': 'confirm', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': 'İlaç Notu Silme İşlemine Emin Misiniz?', 'centered': true, 'title': 'İlaç Notu Silme İşlemi Hakkında', 'function_name': 'drug_note_delete_confirmed', 'confirm': false };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    drug_note_delete_confirmed: function (query) {
      TreatmentService.drug_note_delete(this.patient_data.id, this.treatment_data.id, query)
        .then(resp => {
          this.update_treatment_data(resp.data.result);
        });
    },
    show_add_note: function (drug_key, cycle_index, day_index, prem_ind, type, prem_day, mix_ind) {
      this.add_note_data = { note: '' };
      this.add_note_data.type = type;
      this.add_note_data.drug_key = drug_key;
      this.add_note_data.cycle_index = cycle_index;
      this.add_note_data.day_index = day_index;
      this.add_note_data.prem_ind = prem_ind;
      this.add_note_data.prem_day = prem_day;
      this.add_note_data.mix_ind = mix_ind;
      this.add_note_data.note_type = 'drug';
      this.show_add_note_modal = true;
    },
    show_note_edit: function (drug_key, cycle_index, day_index, prem_ind, type, prem_day, mix_ind, note_ind) {
      this.add_note_data = { note: '' };
      let data = {};
      if (type === 'drug') {
        if (mix_ind === 'none') {
          data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data;
        } else {
          data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.drug_mix[mix_ind];
        }
      } else {
        if (mix_ind === 'none') {
          data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].approvals[prem_day];
        } else {
          data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind].drug_mix[mix_ind];
        }
      }
      this.add_note_data.drug_key = drug_key;
      this.add_note_data.type = type;
      this.add_note_data.cycle_index = cycle_index;
      this.add_note_data.day_index = day_index;
      this.add_note_data.prem_ind = prem_ind;
      this.add_note_data.prem_day = prem_day;
      this.add_note_data.mix_ind = mix_ind;
      this.add_note_data.note_ind = note_ind;
      this.add_note_data.note_type = data.note_list[note_ind].note_type;
      this.add_note_data.note = data.note_list[note_ind].note;
      this.show_add_note_modal = true;
    },
    add_note_save: function () {
      this.show_add_note_modal = false;
      TreatmentService.add_drug_note_in_day(this.patient_data.id, this.treatment_data.id, this.add_note_data)
        .then(resp => {
          this.update_treatment_data(resp.data.result);
        });
    },
    toxicity_delete: function (drug_key, cycle_index, day_index, prem_key, type, toxicity_id, prem_day_ind) {
      this.d_toxicityDeleteQuery = '';
      this.d_toxicityDeleteQuery = 'drug_key=' + drug_key + '&t_id=' + toxicity_id + '&cycle_index=' + cycle_index + '&day_index=' + day_index + '&prem_key=' + prem_key + '&type=' + type + '&prem_day_ind=' + prem_day_ind;
      let modal_data = { 'type': 'confirm', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': 'Toksisite Silme İşlemine Emin Misiniz?', 'centered': true, 'title': 'Toksisite Silme İşlemi Hakkında', 'function_name': 'toxicity_delete_confirmed', 'confirm': false };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    toxicity_delete_confirmed: function (query) {
      TreatmentService.drug_toxicity_delete(this.patient_data.id, this.treatment_data.id, query)
        .then(resp => {
          this.update_treatment_data(resp.data.result);
          this.ToxicityControl();
        });
    },
    update_treatment_data: function (new_treatment_data) {
      for (let i in this.treatment_data) {
        this.treatment_data[i] = new_treatment_data[i];
      }
    },
    drug_toxicity_group_select: function (group) {
      this.drug_toxicity_grades = [];
      if (group) {
        this.drug_toxicity_options = OptionService.get_toxicity_local_storage('toxicity_list', group.value);
      }
    },
    drug_toxicity_select: function (toxicity) {
      this.drug_toxicity_data.toxicity = toxicity;
      this.drug_toxicity_grades = toxicity.grades;
    },
    show_toxicity_add (drug_key, cycle_index, day_index, prem_key, type, prem_day_ind) {
      this.drug_toxicity_group = OptionService.get_toxicity_local_storage('toxicity_group_list');
      this.drug_toxicity_data.group = '';
      this.drug_toxicity_data.drug_key = drug_key;
      this.drug_toxicity_data.cycle_index = cycle_index;
      this.drug_toxicity_data.day_index = day_index;
      this.drug_toxicity_data.prem_day_ind = prem_day_ind;
      this.drug_toxicity_data['type'] = type;
      this.drug_toxicity_data['prem_key'] = prem_key;
      this.show_drug_toxicity_modal = true;
    },
    show_toxicity_edit (drug_key, cycle_index, day_index, prem_key, type, toxicity, prem_day_ind) {
      this.drug_toxicity_group = OptionService.get_toxicity_local_storage('toxicity_group_list');
      this.drug_toxicity_data.group = toxicity.toxicity_group;
      this.drug_toxicity_data.drug_key = drug_key;
      this.drug_toxicity_data.cycle_index = cycle_index;
      this.drug_toxicity_data.day_index = day_index;
      this.drug_toxicity_data.type = type;
      this.drug_toxicity_data.prem_key = prem_key;
      this.drug_toxicity_data.prem_day_ind = prem_day_ind;
      this.show_drug_toxicity_modal = true;
      this.$forceUpdate();
    },
    drug_toxicity_save: function (t_id_edit) {
      if (t_id_edit) {
        this.drug_toxicity_data['t_id_edit'] = t_id_edit;
      }
      if (this.drug_toxicity_data.grade) {
        TreatmentService.drug_toxicity_save(this.patient_data.id, this.treatment_data.id, this.drug_toxicity_data)
          .then(resp => {
            this.drug_toxicity_data = { 'process': [], 'note': '' };
            this.update_treatment_data(resp.data.result);
            this.show_drug_toxicity_modal = false;
            this.ToxicityControl();
          });
        return;
      } else {
        alert('Lütfen zorunlu alanları doldurunuz');
      }
    }
  },
  filters: {},
  watch: {
    'mother_children.forceUpdate': function () {
      this.f_createNamingData();
      this.$forceUpdate();
    },
    'mother_children.update_naming': function () {
      this.f_createNamingData();
    },
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'drug_note_delete_confirmed') {
          if (this.StoreModal.data.confirm && this.d_drugNoteDeleteQuery) {
            this.drug_note_delete_confirmed(this.d_drugNoteDeleteQuery);
          }
        }
      } else if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'toxicity_delete_confirmed') {
          if (this.StoreModal.data.confirm && this.d_toxicityDeleteQuery) {
            this.toxicity_delete_confirmed(this.d_toxicityDeleteQuery);
          }
        }
      }
    },
    lang: function (nlang) {
      for (let i in this.drug_toxicity_group) {
        this.drug_toxicity_group[i].label = this.drug_toxicity_group[i].translation[this.lang];
      }
      for (let i in this.drug_toxicity_grades) {
        this.drug_toxicity_grades[i].label = this.drug_toxicity_grades[i].translation[this.lang];
      }
      for (let i in this.drug_toxicity_options) {
        this.drug_toxicity_options[i].label = this.drug_toxicity_options[i].translation[this.lang];
        for (let k in this.drug_toxicity_options[i].grades) {
          this.drug_toxicity_options[i].grades[k].label = this.drug_toxicity_options[i].grades[k].translation[this.lang];
        }
      }
    }
  },
  components: {
    Toxicity,
    Modal,
    vSelect,
    Datepicker,
    TreatmentCycleDrugButtons,
    TreatmentAddEditDrug,
    PatientDrugButton,
    ConfirmForm
  }
};

</script>

<style>


</style>

