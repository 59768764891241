import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  get_chemo_unit_drug_list (data) {
    return Vue.http.post(API_BASE_URL + 'drug/get_chemo_unit_drug_list', data);
  },
  save_brand_order_data (data) {
    return Vue.http.post(API_BASE_URL + 'drug/save_brand_order_data', data);
  },
  prepare_brand_order_data (data) {
    return Vue.http.post(API_BASE_URL + 'drug/prepare_brand_order_data', data);
  },
  dosage_adjust (data) {
    return Vue.http.post(API_BASE_URL + 'drug/dosage_adjust', data);
  },
  change_status (data) {
    return Vue.http.post(API_BASE_URL + 'drug/change_status', data);
  },
  change_date (data) {
    return Vue.http.post(API_BASE_URL + 'drug/change_date', data);
  },
  reset_all_drugs_status_on_day (data) {
    return Vue.http.post(API_BASE_URL + 'drug/reset_all_drugs_status_on_day', data);
  },
  terminate_wdmr10 (data) {
    return Vue.http.post(API_BASE_URL + 'drug/terminate_wdmr10', data);
  },
  cancel_terminate_wdmr10 (data) {
    return Vue.http.post(API_BASE_URL + 'drug/cancel_terminate_wdmr10', data);
  },
  delete_wdmr10 (data) {
    return Vue.http.post(API_BASE_URL + 'drug/delete_wdmr10', data);
  },
  date_adjust_with_connection (data) {
    return Vue.http.post(API_BASE_URL + 'drug/date_adjust_with_connection', data);
  },
  get_wdmr10_from_child (data) {
    return Vue.http.post(API_BASE_URL + 'drug/get_wdmr10_from_child', data);
  },
  // old services (from treatment)
  save (data) {
    var d_uuid = 'new';
    if (data.id) {
      d_uuid = data.id;
    }
    let url = API_BASE_URL + 'patient/' + data.patient_id + '/chemotherapy/' + d_uuid;
    return Vue.http.put(url, data);
  },
  day_edit_save (data) {
    let url = API_BASE_URL + 'patient/' + data.patient_id + '/day_edit_save/' + data.treatment.id;
    return Vue.http.put(url, data);
  },
  add_new_cycles (data) {
    let url = API_BASE_URL + 'patient/' + data.patient_id + '/chemotherapy/' + data.id + '/add_new_cycles';
    return Vue.http.put(url, data);
  },
  chemo_stock_list (data) {
    return Vue.http.post(API_BASE_URL + 'chemo_stock_list', data);
  },
  chemo_patient_list (data) {
    return Vue.http.post(API_BASE_URL + 'chemo_patient_list', data);
  },
  getOncologyTreatmentList (data) {
    return Vue.http.post(API_BASE_URL + 'getOncologyTreatmentList', data);
  },
  chemo_stat (data) {
    return Vue.http.post(API_BASE_URL + 'chemo_stat', data);
  },
  detailed_approval_list (data) {
    return Vue.http.post(API_BASE_URL + 'chemotherapy/detailed_approval_list', data);
  },
  search (data) {
    return Vue.http.post(API_BASE_URL + 'patient/' + data.patient_id + '/chemotherapy/list');
  },
  get_treatment (puid, uuid) {
    return Vue.http.get(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid);
  },
  get_chemo_list (puid) {
    return Vue.http.get(API_BASE_URL + 'patient/' + puid + '/chemo_list');
  },
  delete (puid, uuid) {
    return Vue.http.delete(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid);
  },
  indication_approval_save (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/indication_approval', data);
  },
  protocol_edit_save (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/edit_data_save', data);
  },
  save_detailed_date (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/save_detailed_date', data);
  },
  add_premedication (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/add_premedication', data);
  },
  add_new_drug_to_active_treatment (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/add_new_drug_to_active_treatment', data);
  },
  cancel_approvement (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/cancel_doctor_approvement', data);
  },
  cancel_day (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/cancel', data);
  },
  open_day (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/open', data);
  },
  order_save (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/order', data);
  },
  cancel_order_treatment (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/cancel_order_treatment', data);
  },
  termination_save (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/terminate', data);
  },
  termination_open_save (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/open-terminate', data);
  },
  drug_termination_save (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/drug-terminate', data);
  },
  drug_toxicity_save (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/drug-toxicity', data);
  },
  drug_toxicity_delete (puid, uuid, query) {
    return Vue.http.delete(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/drug-toxicity?' + query);
  },
  actions_add (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/action', data);
  },
  approve_all_drugs_in_selected_days (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/approve_all_drugs_in_selected_days', data);
  },
  add_drug_note_in_day (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/add_drug_note_in_day', data);
  },
  add_protocol_note (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/add_protocol_note', data);
  },
  delete_protocol_note (puid, uuid, query) {
    return Vue.http.delete(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/delete_protocol_note?' + query);
  },
  delay_day (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/delay_day', data);
  },
  delete_approval_in_this_day (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/delete_approval_in_this_day', data);
  },
  delete_drugs_in_this_day (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/delete_drugs_in_this_day', data);
  },
  protocol_insurance_report (puid, uuid, data) {
    return Vue.http.put(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/insurance_report', data);
  },
  delete_delay_day (puid, uuid, query) {
    return Vue.http.delete(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/delay?' + query);
  },
  drug_note_delete (puid, uuid, query) {
    return Vue.http.delete(API_BASE_URL + 'patient/' + puid + '/chemotherapy/' + uuid + '/drug_note_delete?' + query);
  },
  list (data) {
    return Vue.http.post(API_BASE_URL + 'chemotherapy', data);
  },
  get_new_drug_list (data) {
    return Vue.http.post(API_BASE_URL + 'chemotherapy/get_new_drug_list', data);
  }
};
