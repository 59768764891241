<template>
  <div>
    <b-card class="mb-1" no-body header-tag="header" footer-tag="footer">
      <b-card-header header-bg-variant="success" class="p-1">
        <strong>Fatura Başvuru Protokolü Bilgileri</strong>
        <span style="color: red"> <-- Eczaneye seçtiğiniz ilaçları gönderebilmek için lütfen bir başvuru protokolü seçimi yapınız. --> </span>
      </b-card-header>
      <b-row style="margin: 2px;text-align: center;">
        <b-col cols="12">
          <template v-if="patient_data && patient_data.open_protocol_list && patient_data.open_protocol_list.result && patient_data.open_protocol_list.result.length > 0">
            <b-row v-for="(open_pro, open_pro_ind) in patient_data.open_protocol_list.result" :key="open_pro_ind" style="padding: 5px; margin: 0px; border-bottom: solid 1px #ccbdbd;">
              <b-col cols="12">
                <div :style="SelectedProtocolInd === open_pro_ind ? 'background-color: #b0e8f5; cursor: pointer;' : 'cursor: pointer;'" @click="f_selectThisProtocol(open_pro_ind)">
                  <i class="fa fa-hand-o-right" style="color: green;"></i> {{ open_pro.department_name }}
                  <i class="fa fa-user"></i> {{ open_pro.doctor }}
                  <i class="fa fa-key"></i> {{ open_pro.protocol_no }}
                  <i class="fa fa-calendar-o"></i> ({{ moment(open_pro.open_date).format('DD/MM/YYYY HH:mm') }})
                  <span :style="f_calculateStyleProtocolType(open_pro.type_id)">
                      [{{ CalculateProtocolType(open_pro.type_id) }}]
                    </span>
                </div>
              </b-col>
            </b-row>
          </template>
          <!--           <template v-else>
            <strong style="color: red;">
              Açık başvuru protokolü bulunmadığından ilaçları eczane sistemine gönderilememektedir. İlaçları eczaneye göndermek istiyorsanız lütfen ilgili görevli ile irtibata geçiniz.
            </strong>
          </template> -->
        </b-col>
      </b-row>
      <!-- <b-card-footer footer-bg-variant="secondary" header-text-variant="dark" class="p-1"></b-card-footer> -->
    </b-card>
    <template v-for="(day, dy_ind) in treatment_order_data.days">
      <template v-for="(drug, drug_key) in day">
        <template v-if="drug.before && Object.keys(drug.before).length > 0" v-for="(before, before_key) in drug.before">
          <patient-drug-brand-selection-detail :d_selectedProtocolNo="d_selectedProtocolNo" :patient_data="patient_data" :hims_protocol_data="hims_protocol_data" :treatment_order_data="treatment_order_data" :drug="before" :drug_key="before_key" :dy_ind="dy_ind" :main_drug_name="drug.name" drug_type="before"></patient-drug-brand-selection-detail>
          <template v-if="before.drug_mix && Object.keys(before.drug_mix).length > 0" v-for="(bef_drug_mix, bef_drug_mix_key) in before.drug_mix">
            <patient-drug-brand-selection-detail :d_selectedProtocolNo="d_selectedProtocolNo" :patient_data="patient_data" :hims_protocol_data="hims_protocol_data" :treatment_order_data="treatment_order_data" :drug="bef_drug_mix" :drug_key="bef_drug_mix_key" :dy_ind="dy_ind" :main_drug_name="before.name" drug_type="drug_mix"></patient-drug-brand-selection-detail>
          </template>
        </template>
        <patient-drug-brand-selection-detail :d_selectedProtocolNo="d_selectedProtocolNo" :patient_data="patient_data" :hims_protocol_data="hims_protocol_data" :treatment_order_data="treatment_order_data" :drug="drug" :drug_key="drug_key" :dy_ind="dy_ind" :main_drug_name="drug.name" drug_type="drug"></patient-drug-brand-selection-detail>
        <template v-if="drug.during && Object.keys(drug.during).length > 0" v-for="(during, during_key) in drug.during">
          <patient-drug-brand-selection-detail :d_selectedProtocolNo="d_selectedProtocolNo" :patient_data="patient_data" :hims_protocol_data="hims_protocol_data" :treatment_order_data="treatment_order_data" :drug="during" :drug_key="during_key" :dy_ind="dy_ind" :main_drug_name="drug.name" drug_type="during"></patient-drug-brand-selection-detail>
          <template v-if="during.drug_mix && Object.keys(during.drug_mix).length > 0" v-for="(dur_drug_mix, dur_drug_mix_key) in during.drug_mix">
            <patient-drug-brand-selection-detail :d_selectedProtocolNo="d_selectedProtocolNo" :patient_data="patient_data" :hims_protocol_data="hims_protocol_data" :treatment_order_data="treatment_order_data" :drug="dur_drug_mix" :drug_key="dur_drug_mix_key" :dy_ind="dy_ind" :main_drug_name="during.name" drug_type="drug_mix"></patient-drug-brand-selection-detail>
          </template>
        </template>
        <template v-if="drug.drug_mix && Object.keys(drug.drug_mix).length > 0" v-for="(drug_mix, drug_mix_key) in drug.drug_mix">
          <patient-drug-brand-selection-detail :d_selectedProtocolNo="d_selectedProtocolNo" :patient_data="patient_data" :hims_protocol_data="hims_protocol_data" :treatment_order_data="treatment_order_data" :drug="drug_mix" :drug_key="drug_mix_key" :dy_ind="dy_ind" :main_drug_name="drug.name" drug_type="drug_mix"></patient-drug-brand-selection-detail>
        </template>
        <template v-if="drug.after && Object.keys(drug.after).length > 0" v-for="(after, after_key) in drug.after">
          <patient-drug-brand-selection-detail :d_selectedProtocolNo="d_selectedProtocolNo" :patient_data="patient_data" :hims_protocol_data="hims_protocol_data" :treatment_order_data="treatment_order_data" :drug="after" :drug_key="after_key" :dy_ind="dy_ind" :main_drug_name="drug.name" drug_type="after"></patient-drug-brand-selection-detail>
          <template v-if="after.drug_mix && Object.keys(after.drug_mix).length > 0" v-for="(aft_drug_mix, aft_drug_mix_key) in after.drug_mix">
            <patient-drug-brand-selection-detail :d_selectedProtocolNo="d_selectedProtocolNo" :patient_data="patient_data" :hims_protocol_data="hims_protocol_data" :treatment_order_data="treatment_order_data" :drug="aft_drug_mix" :drug_key="aft_drug_mix_key" :dy_ind="dy_ind" :main_drug_name="after.name" drug_type="drug_mix"></patient-drug-brand-selection-detail>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';
import {
  default as PatientDrugBrandSelectionDetail
} from './PatientDrugBrandSelectionDetail';

export default {
  name: 'PatientDrugBrandSelection',
  computed: {
    ...mapGetters({
      patient: 'patient_selected'
    })
  },
  props: {
    treatment_order_data: {
      type: Object,
      required: false
    },
    hims_protocol_data: {
      type: Object,
      required: false
    },
    patient_data: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      SelectedProtocolInd: '',
      moment: moment,
      d_selectedProtocolNo: ''
    };
  },
  created () {},
  mounted () {
    // SAMI SAMI SAMI (treatmentcycledrugbuttons dosyasında otomatik ilaç doldurmayı aktifleştirmeyi unutma)
    if (this.patient_data.open_protocol_list && this.patient_data.open_protocol_list.result && this.patient_data.open_protocol_list.result.length > 0) {
      this.f_selectThisProtocol(0);
    }
  },
  methods: {
    f_calculateBrandStock: function (barcode_no, brand_list) {
      for (let b in brand_list) {
        if (brand_list[b].value === barcode_no) {
          if (brand_list[b].stock && brand_list[b].stock.count !== undefined) {
            return brand_list[b].stock.count;
          }
        }
      }
      return false;
    },
    f_selectThisProtocol: function (open_pro_ind) {
      this.SelectedProtocolInd === open_pro_ind ? this.SelectedProtocolInd = '' : this.SelectedProtocolInd = open_pro_ind;
      if (this.SelectedProtocolInd !== '') {
        this.hims_protocol_data.selected_protocol = this.patient_data.open_protocol_list.result[open_pro_ind];
        this.d_selectedProtocolNo = this.patient_data.open_protocol_list.result[open_pro_ind].protocol_no;
        this.f_changePharmacyStatus(1);
      } else {
        this.hims_protocol_data.selected_protocol = {};
        this.d_selectedProtocolNo = '';
        this.f_changePharmacyStatus(0);
      }
    },
    f_changePharmacyStatus: function (status) {
      // status = 0, 1
      for (let day in this.treatment_order_data.days) {
        for (let drug_key in this.treatment_order_data.days[day]) {
          // console.log('drug_key', drug_key);
          // herhangi bir brand stoğu var ise o zaman check 1 olabilir.
          this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key], status);
          if (this.treatment_order_data.days[day][drug_key]['drug_mix']) {
            for (let mx in this.treatment_order_data.days[day][drug_key]['drug_mix']) {
              // console.log('mx', mx);
              this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key]['drug_mix'][mx], status);
            }
          }
          if (this.treatment_order_data.days[day][drug_key].fluid) {
            this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key].fluid, status);
          }
          let prem_types = ['before', 'after', 'during'];
          for (let p in prem_types) {
            for (let k in this.treatment_order_data.days[day][drug_key][prem_types[p]]) {
              // console.log('prem ind', k);
              this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key][prem_types[p]][k], status);
              if (this.treatment_order_data.days[day][drug_key][prem_types[p]][k].fluid) {
                this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key][prem_types[p]][k].fluid, status);
              }
              if (this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix']) {
                for (let mx in this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix']) {
                  // console.log('mix ', mx);
                  this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix'][mx], status);
                }
              }
            }
          }
        }
      }
    },
    f_controlBrandStock: function (data, status) {
      // status = 1 ise
      if (status && data.brands) {
        if (this.f_atLeastOneBrandMatch(data.brands)) {
          data['pharmacy_status'] = 1;
        } else {
          data['pharmacy_status'] = 0;
        }
      } else {
        data['pharmacy_status'] = 0;
      }
    },
    f_atLeastOneBrandInStock: function (brand_list) {
      for (let b in brand_list) {
        if (brand_list[b].stock && brand_list[b].stock.count) {
          return true;
        }
      }
      return false;
    },
    f_atLeastOneBrandMatch: function (brand_list) {
      for (let b in brand_list) {
        if (brand_list[b].stock !== undefined) {
          return true;
        }
      }
      return false;
    },
    f_calculateStyleProtocolType (type_id) {
      if (type_id === 0) {
        return 'color: red; font-weight: bold;';
      } else if (type_id === 1) {
        return 'color: green; font-weight: bold;';
      } else if (type_id === 2) {
        return 'color: #b3246c; font-weight: bold;';
      } else if (type_id === 3) {
        return 'color: red; font-weight: bold;';
      } else if (type_id === 4) {
        return 'color: blue; font-weight: bold;';
      } else if (type_id === 5) {
        return 'color: black; font-weight: bold;';
      }
    },
    CalculateProtocolType: function (type_id) {
      if (type_id === 0) {
        return 'Diğer';
      } else if (type_id === 1) {
        return 'Poliklinik';
      } else if (type_id === 2) {
        return 'Yatan Hasta';
      } else if (type_id === 3) {
        return 'Acil';
      } else if (type_id === 4) {
        return 'Günübirlik';
      } else if (type_id === 5) {
        return 'TempInpatient';
      }
    },
    add_barcode: function (dy_ind, drug_key, prem_ind, type, mix_ind, fluid = false) {
      let id_no = '';
      if (fluid) {
        id_no = 'fluid' + dy_ind.toString() + drug_key + prem_ind.toString() + type + mix_ind;
      } else {
        id_no = dy_ind.toString() + drug_key + prem_ind.toString() + type + mix_ind;
      }
      let count = document.getElementById(id_no).value;
      let data = {};
      if (type === 'drug') {
        if (mix_ind === 'none') {
          if (fluid) {
            data = this.treatment_order_data.days[dy_ind][drug_key].fluid;
          } else {
            data = this.treatment_order_data.days[dy_ind][drug_key];
          }
        } else {
          data = this.treatment_order_data.days[dy_ind][drug_key].drug_mix[mix_ind];
        }
      } else { // before after during
        if (mix_ind === 'none') {
          if (fluid) {
            data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind].fluid;
          } else {
            data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind];
          }
        } else {
          data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind].drug_mix[mix_ind];
        }
      }
      if (!data.selected_brand) {
        alert('Lütfen öncelikle ürün seçiminizi yapınız.');
        return;
      }
      let barcode_no = data.selected_brand.value;
      if (this.d_selectedProtocolNo) {
        if (data.selected_brand.stock !== undefined) {
          data.barcode_list.push(barcode_no);
          data.brand_count[barcode_no] = {};
          data.brand_count[barcode_no].count = count;
          data.brand_count[barcode_no].label = data.selected_brand.label;
        } else {
          alert('Bu ürün wisdomera ile hbys arasında eşleştirme yapılmamıştır. Eşleştirme işlemi devam etmektedir. Tamamladığında eczaneye gönderebilirsiniz.');
        }
      } else {
        data.barcode_list.push(barcode_no);
        data.brand_count[barcode_no] = {};
        data.brand_count[barcode_no].count = count;
        data.brand_count[barcode_no].label = data.selected_brand.label;
      }
      this.$forceUpdate();
    },
    delete_barcode: function (dy_ind, drug_key, prem_ind, type, mix_ind, brand_ind, fluid = false) {
      if (type === 'drug') {
        if (mix_ind === 'none') {
          if (fluid) {
            let data = this.treatment_order_data.days[dy_ind][drug_key].fluid;
            data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
            this.$delete(data.brand_count, brand_ind);
          } else {
            let data = this.treatment_order_data.days[dy_ind][drug_key];
            data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
            this.$delete(data.brand_count, brand_ind);
          }
        } else { // drug mix
          let data = this.treatment_order_data.days[dy_ind][drug_key].drug_mix[mix_ind];
          data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
          this.$delete(data.brand_count, brand_ind);
        }
      } else {
        if (mix_ind === 'none') {
          if (fluid) {
            let data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind].fluid;
            data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
            this.$delete(data.brand_count, brand_ind);
          } else {
            let data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind];
            data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
            this.$delete(data.brand_count, brand_ind);
          }
        } else {
          let data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind].drug_mix[mix_ind];
          data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
          this.$delete(data.brand_count, brand_ind);
        }
      }
      this.$forceUpdate();
    }
  },
  watch: {},
  components: {
    vSelect,
    PatientDrugBrandSelectionDetail
  }
};

</script>

<style type="text/css">
td,
th {}

</style>

