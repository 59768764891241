<template>
  <div>
    <template v-for="(drug, drug_ind) in p_wdmr11or12.list">
      <b-row :key="'change_date_drug_row_' + drug_ind">
        <b-col cols="2">
          {{ drug.data.general.drug.val.label }} : {{ drug.type + '_' + drug.id }}
        </b-col>
        <b-col cols="3">
          Eski Tarih: {{ moment(drug.date).format('DD-MM-YYYY') }}
        </b-col>
        <template v-if="drug.tmp_data.is_eligable_backward === 'yes' || drug.tmp_data.is_eligable_forward === 'yes'">
          <b-col cols="3">
            <datepicker class="form-control" style="background-color: transparent;" name="delay_data_new_date" v-model="d_newDateList[drug.data.general.wdmr_connection.val].current" :config="{locale: datepicker_langs[lang], minDate: d_newDateList[drug.data.general.wdmr_connection.val].min, maxDate: d_newDateList[drug.data.general.wdmr_connection.val].max}" @input="f_updateDatepickerAndNumber(drug.date, drug.data.general.wdmr_connection.val, 'number')"></datepicker>
          </b-col>
          <b-col cols="4">
            <b-form-input type="number" :min="p_dateLimitData[drug.data.general.wdmr_connection.val].min" :max="p_dateLimitData[drug.data.general.wdmr_connection.val].max" class="form-control-warning" @keydown="InputControl.input_filter($event, 'delay')" @input="f_updateDatepickerAndNumber(drug.date, drug.data.general.wdmr_connection.val, 'datepicker')" v-model="p_drugChangeDateData[drug.data.general.wdmr_connection.val].change_date_delta"></b-form-input>
          </b-col>
        </template>
        <template v-else>
          <b-col cols="3">
            <datepicker disabled class="form-control" name="delay_data_new_date" v-model="d_newDateList[drug.data.general.wdmr_connection.val].current" :config="{locale: datepicker_langs[lang]}"></datepicker>
          </b-col>
          <b-col cols="4">
            <b-form-input disabled type="number" :min="p_dateLimitData[drug.data.general.wdmr_connection.val].min" :max="p_dateLimitData[drug.data.general.wdmr_connection.val].max" class="form-control-warning" @keydown="InputControl.input_filter($event, 'delay')" @keyup="f_checkDateLimit(drug)" v-model="p_drugChangeDateData[drug.data.general.wdmr_connection.val].change_date_delta"></b-form-input>
          </b-col>
        </template>
      </b-row>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { InputControl } from '@/services/public/functions';
import Datepicker from 'wisdom-vue-datepicker';
import { tr } from '../../../node_modules/flatpickr/dist/l10n/tr';

export default {
  name: 'PatientDrugChangeDateModal',
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      lang: 'lang'
    })
  },
  props: {
    p_wdmr11or12: {
      type: Object,
      required: true
    },
    p_drugChangeDateData: {
      type: Object,
      required: true
    },
    p_dateLimitData: {
      type: Object,
      required: true
    },
    p_changeContinuously: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      InputControl: InputControl,
      moment: moment,
      w_presentation: false,
      datepicker_langs: { tr: tr, en: {} },
      d_newDateList: {}
    };
  },
  created () {
    for (let i in this.p_wdmr11or12.list) {
      if (!this.p_drugChangeDateData[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val]) {
        this.p_drugChangeDateData[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val] = { 'change_date_delta': 0 };
      }
      if (!this.d_newDateList[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val]) {
        this.d_newDateList[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val] = { 'min': '', 'current': moment(this.p_wdmr11or12.list[i].date).format('YYYY-MM-DD'), 'max': '' };
      }
    }
    this.f_updateDateLimitData();
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.all_users = JSON.parse(localStorage.getItem('all_users'));
  },
  mounted () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  methods: {
    f_updateDatepickerAndNumber: function (drug_date, wdmr_connection, aim) {
      console.log('92');
      console.log(drug_date);
      if (aim === 'number') {
        let datepicker = moment(this.d_newDateList[wdmr_connection].current.split('-'));
        let date_drug = moment(drug_date);
        this.p_drugChangeDateData[wdmr_connection].change_date_delta = datepicker.diff(date_drug, 'days');
      } else if (aim === 'datepicker') {
        this.d_newDateList[wdmr_connection].current = moment(drug_date).add(this.p_drugChangeDateData[wdmr_connection].change_date_delta, 'days').format('YYYY-MM-DD');
      }
      this.$forceUpdate();
    },
    f_updateDateLimitData: function () {
      for (let i in this.p_wdmr11or12.list) {
        if (!this.p_dateLimitData[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val]) {
          let min_limit = this.f_calculateChangeDateLimit(this.p_wdmr11or12.list[i].tmp_data, 'min');
          let max_limit = this.f_calculateChangeDateLimit(this.p_wdmr11or12.list[i].tmp_data, 'max');
          if (min_limit !== '') {
            if ((this.d_newDateList[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val].min !== '' && moment(this.p_wdmr11or12.list[i].date).add(min_limit, 'days').format('YYYY-MM-DD') > this.d_newDateList[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val].min) || this.d_newDateList[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val].min === '') {
              this.d_newDateList[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val].min = moment(this.p_wdmr11or12.list[i].date).add(min_limit, 'days').format('YYYY-MM-DD');
            }
          }
          if (max_limit !== '') {
            if ((this.d_newDateList[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val].max !== '' && moment(this.p_wdmr11or12.list[i].date).add(max_limit, 'days').format('YYYY-MM-DD') < this.d_newDateList[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val].max) || this.d_newDateList[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val].max === '') {
              this.d_newDateList[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val].max = moment(this.p_wdmr11or12.list[i].date).add(max_limit, 'days').format('YYYY-MM-DD');
            }
          }
          if (this.p_dateLimitData[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val]) {
            if (min_limit !== '' && (this.p_dateLimitData[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val].min < min_limit || this.p_dateLimitData[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val].min === '')) {
              this.p_dateLimitData[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val].min = min_limit;
            }
            if (max_limit !== '' && (this.p_dateLimitData[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val].max > max_limit || this.p_dateLimitData[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val].max === '')) {
              this.p_dateLimitData[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val].max = max_limit;
            }
          } else {
            this.p_dateLimitData[this.p_wdmr11or12.list[i].data.general.wdmr_connection.val] = { 'min': min_limit, 'max': max_limit }
          }
        }
      }
      this.$forceUpdate();
    },
    f_checkDateLimit: function (drug) {
      if (this.p_dateLimitData[drug.data.general.wdmr_connection.val].min !== '' && parseInt(this.p_drugChangeDateData[drug.data.general.wdmr_connection.val].change_date_delta) < this.p_dateLimitData[drug.data.general.wdmr_connection.val].min) {
        this.p_drugChangeDateData[drug.data.general.wdmr_connection.val].change_date_delta = this.p_dateLimitData[drug.data.general.wdmr_connection.val].min.toString();
      }
      if (this.p_dateLimitData[drug.data.general.wdmr_connection.val].max !== '' && parseInt(this.p_drugChangeDateData[drug.data.general.wdmr_connection.val].change_date_delta) > this.p_dateLimitData[drug.data.general.wdmr_connection.val].max) {
        this.p_drugChangeDateData[drug.data.general.wdmr_connection.val].change_date_delta = this.p_dateLimitData[drug.data.general.wdmr_connection.val].max.toString();
      }
      this.$forceUpdate();
    },
    f_calculateChangeDateLimit: function (tmp_data, change_date_type) {
      if (change_date_type === 'min') {
        if (tmp_data.is_eligable_backward === 'yes') {
          if (this.p_changeContinuously.status) {
            if (tmp_data.backward_all === -1) {
              return '';
            } else {
              return -Math.abs(tmp_data.backward_all)
            }
          } else {
            if (tmp_data.backward_one === -1) {
              return '';
            } else {
              return -Math.abs(tmp_data.backward_one)
            }
          }
        } else {
          return 0;
        }
      } else if (change_date_type === 'max') {
        if (tmp_data.is_eligable_forward === 'yes') {
          if (this.p_changeContinuously.status) {
            if (tmp_data.forward_all === -1) {
              return '';
            } else {
              return tmp_data.forward_all
            }
          } else {
            if (tmp_data.forward_one === -1) {
              return '';
            } else {
              return tmp_data.forward_one
            }
          }
        } else {
          return 0;
        }
      }
    }
  },
  watch: {
    'p_changeContinuously.status': function () {
      this.f_updateDateLimitData();
    }
  },
  components: {
    Datepicker
  }
};

</script>

<style type="text/css"></style>

