import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  search_material (data) {
    return Vue.http.post(API_BASE_URL + 'pharmacy/search_material', data);
  },
  save_pharmacy_order (data) {
    return Vue.http.post(API_BASE_URL + 'pharmacy/save_pharmacy_order', data);
  }
};
