<template>
  <div>
    <b-row v-if="p_wdmr10ShowMode" style="padding: 3px;">
      <b-col cols="12">
        <b-card header-tag="header" class="card-accent-primary" no-body>
          <b-card-header header-bg-variant="primary" class="p-1">
            Hasta Tedavi Listesi
          </b-card-header>
          <b-table v-if="d_showWdmr10Table && d_wdmr10TableData.length > 0" ref="d_wdmr10Table" :tbody-tr-class="wdmr10RowClass" selectable select-mode="single" stacked="sm" hover borderless :fields="p_wdmr10ListTableFiels" selectedVariant="success" :items="d_wdmr10TableData" @row-selected="f_getWdmr10Childs">
            <template v-slot:cell(date)="row">
              {{ DateFormat(row.item.date) }}
            </template>
            <template v-slot:cell(treatment_status)="row">
              {{ row.item.treatment_status.label }}
            </template>
            <template v-slot:cell(actions)="row">
              <template v-if="p_selectedWdmr10 && p_selectedWdmr10.index === row.index">
                <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '20')">?</b-badge>
                <b-dropdown variant="link" size="lg" no-caret>
                  <template slot="button-content">
                    &#x1f50d;
                    <span class="sr-only">İşlem</span>
                  </template>
                  <b-dropdown-item v-if="row.item.treatment_status.value === 'no_operation_yet' && user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('oncology_treatment_treatment-add-edit-view') !== -1)" @click="f_editWdmr10(row.item.id)"> Tedaviyi Düzenle </b-dropdown-item>
                  <b-dropdown-item v-if="row.item.treatment_status.value === 'no_operation_yet' && user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('oncology_treatment_delete-treatment') !== -1)" @click="f_deleteWdmr10(row.item.id)"> Tedaviyi Sil </b-dropdown-item>
                  <b-dropdown-item v-if="['no_operation_yet', 'terminated'].indexOf(row.item.treatment_status.value) === -1 && user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('oncology_treatment_terminate-treatment') !== -1)" @click="f_showTerminateWdmr10()"> Tedaviyi Durdur</b-dropdown-item>
                  <b-dropdown-item v-if="row.item.treatment_status.value === 'terminated' && user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('oncology_treatment_cancel-terminate-treatment') !== -1)" @click="f_cancelTerminateWdmr10()"> Tedaviyi Aktifleştir</b-dropdown-item>
                  <b-dropdown-item @click="f_printWdmr10()">protokolü yazdır</b-dropdown-item>
                </b-dropdown>
              </template>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
          <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
            <i class="fa fa-search"></i> {{ p_componentType }} İlaç Uygulama
          </b-card-header>
          <template v-if="p_showWdmr11or12.x && Object.keys(p_optionData).length > 0" v-for="(wdmr11or12, wdmr11or12_ind) in p_wdmr11or12List[p_componentType]">
            <patient-drug-day :p_componentType="p_componentType" :p_optionData="p_optionData" :key="'wdmr11or12_patientDrugDay_' + wdmr11or12_ind" :show_more_drug_details="show_more_drug_details" :p_wdmr11or12_ind="wdmr11or12_ind" :p_wdmr11or12List="p_wdmr11or12List" :mother_children="mother_children"></patient-drug-day>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <modal v-if="d_showDrugReportingModal" @close="d_showDrugReportingModal = false" :width="'1500'">
      <div slot="header">
        Tedavi Detayı Yazdırma Ekranı
        <img v-if="patient.sex === '0'" src="../../../static/img/avatars/man.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.3em; padding: 2px;" />
        <img v-else src="../../../static/img/avatars/woman.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.3em; padding: 2px;" /> {{ w_presentation ? 'Hasta Ad Soyad' : patient.firstname.toLocaleUpperCase('tr') + ' ' + patient.lastname.toLocaleUpperCase('tr') }}
      </div>
      <div slot="body">
        <patient-drug-reporting-modal :p_createdBy="d_reportingData.created_by" :p_dateList="d_reportingData.date_list" :p_drugDateList="d_reportingData.drugs_by_date" :p_patientData="patient"></patient-drug-reporting-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showDrugReportingModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showTerminationModal" @close="d_showTerminationModal = false" :width="'750'">
      <h3 slot="header">{{ $t('wdm16.3903') }}</h3>
      <div slot="body">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Tedavi sonlandırma tarihi" label-for="date" :label-cols="3" :horizontal="true">
              <b-form-input v-model="d_terminationData.date" type="date" id="date"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-checkbox-group v-model="d_terminationData.reason" label="Tedavi sonlandırma sebebi" label-for="reason" :label-cols="3" stacked>
              <template v-for="(t_reas, t_reas_ind) in d_terminationReasonOptions">
                <b-form-checkbox :key="'d_terminationReasonOptions_' + t_reas_ind" :name="t_reas.value" :value="t_reas">
                  {{ t_reas.label }}
                </b-form-checkbox>
              </template>
            </b-form-checkbox-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Tedavi sonlandırma notu" label-for="note" :label-cols="3" :horizontal="true">
              <b-form-input v-model="d_terminationData.note" type="text" id="note"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" @click="f_terminateWdmr10()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-secondary" @click="d_showTerminationModal = false">{{ $t('wdm16.4197') }}</button>
      </div>
    </modal>
    <modal v-if="d_showWdmrReportingModal" @close="d_showWdmrReportingModal = false" :width="'1500'">
      <div slot="header">
        Rapor Listeleme Ekranı
      </div>
      <div slot="body">
        <wdmr-report :p_wdmrList="d_wdmrReportData.wisdom_data" :p_optionDataObj="d_wdmrReportData.option_data" :p_patientData="patient_data"></wdmr-report>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showWdmrReportingModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import DrugService from '@/services/drug';
import GlobalService from '@/services/global';
import PatientService from '@/services/patient';
import WisdomDataService from "@/services/wisdom_data";
import { mapGetters } from 'vuex';
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as PatientDrugButton
} from "@/components/widgets/PatientDrugButton";
import {
  default as PatientDrugDay
} from "@/components/widgets/PatientDrugDay";
import {
  default as PatientDrugReportingModal
} from "@/components/widgets/PatientDrugReportingModal";
import {
  default as WisdomDataModal
} from "@/components/widgets/WisdomDataModal";
import {
  default as WisdomDataShow
} from "@/components/widgets/WisdomDataShow";

// import Vue from 'vue';
import moment from 'moment';

// /* global $ */

export default {
  name: 'PatientDrug',
  computed: mapGetters({
    account_list: 'account_list',
    StoreModal: 'modal',
    StoreLoading: 'loading',
    lang: 'lang',
    help: 'help',
    patient: 'patient_selected'
  }),
  props: {
    p_showWdmr11or12: {
      type: Object,
      required: true
    },
    p_wdmr10ShowMode: {
      type: Boolean,
      required: true
    },
    p_wdmr11or12List: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    p_wdmr10List: {
      type: Array,
      required: true
    },
    p_optionData: {
      type: Object,
      required: true
    },
    p_patientDrugEditData: {
      type: Object,
      required: true
    },
    p_selectedWdmr10: {
      type: Object,
      required: false
    },
    mother_children: {
      type: Object,
      required: true
    },
    p_componentType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      w_presentation: false,
      user: {},
      patient_data: {},
      p_wdmr10ListTableFiels: [
        { key: "actions", label: "İşlemler" },
        { key: "name", label: "Tedavi Adı", width: "40px" },
        { key: "diagnosis", label: "Başlama Tanısı", width: "40px" },
        { key: "created_by", label: "Hazırlayan" },
        { key: "date", label: "Başlama tarihi" },
        { key: "treatment_status", label: "Son durum" }
      ],
      change: "0",
      view_mode: "table",
      d_wdm10: {},
      d_showNewWdm5: false,
      d_showWdmrReportingModal: false,
      d_wdmrReportData: {},
      show_more_drug_details: { check: false },
      d_wdmr10TableData: [],
      d_showDrugReportingModal: false,
      d_showTerminationModal: false,
      d_showWdmr10Table: false,
      d_reportingData: { 'date_list': [], 'drugs_by_date': {}, 'created_by': '' },
      option_data: {},
      wisdom_data: {},
      data_type: '',
      d_terminationData: { 'date': '', 'reason': [], 'note': '' },
      d_terminationReasonOptions: [],
    };
  },
  created () {
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.user = JSON.parse(localStorage.getItem("user"));
    this.patient_data = JSON.parse(JSON.stringify(this.patient));
    // this.f_calculateOptionData();
  },
  mounted () {
    if (this.p_wdmr10ShowMode) {
      this.f_createWdmr10TableData();
    }
  },
  methods: {
    f_printWdmr10: function () {
      this.d_wdmrReportData = { 'wisdom_data': [], 'option_data': {} };
      let reference_wdm = this.p_wdmr10List[this.p_selectedWdmr10.index].reference_wdm;
      let bucket = '';
      if (reference_wdm.owner_type === 'wisdom') {
        bucket = 'option';
      } else {
        bucket = 'hospital';
      }
      GlobalService.get_one_cb_document({ 'key': reference_wdm.key, 'bucket': bucket })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_wdmrReportData.option_data[reference_wdm.key] = resp.data.result;
            this.d_wdmrReportData.wisdom_data.push(this.p_wdmr10List[this.p_selectedWdmr10.index]);
            this.d_showWdmrReportingModal = true;
          } else {
            alert('error ', resp.data.message);
          }
        });
    },
    f_deleteWdmr10Confirmed: function () {
      console.log('219');
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Tedavi siliniyor' } });
      let data = {};
      data.patient_id = this.patient.id;
      data.wdmr10_id = this.p_selectedWdmr10.id;
      DrugService.delete_wdmr10(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            this.f_motherChildren('update_patient_wdmr');
            this.p_wdmr10List.splice(this.p_selectedWdmr10.index, 1);
            this.p_selectedWdmr10.id = '';
            this.p_selectedWdmr10.index = '';
            this.p_selectedWdmr10.status = { 'label': '', 'value': '' };
            this.f_calculateSelectedWdmr10Status();
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            alert('error: ', resp.data.message);
          }
        });
    },
    f_deleteWdmr10: function () {
      let msg = "Tedaviyi silmek istiyor musunuz?"
      let modal_data = { 'type': 'confirm', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Tedavi silme işlemi hakkında', 'function_name': 'f_deleteWdmr10Confirmed', 'confirm': false, 'arguments': [] };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_cancelTerminateWdmr10: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Tedavi sonlandırılıyor' } });
      let data = {};
      data.patient_id = this.patient.id;
      data.wdmr10_id = this.p_selectedWdmr10.id;
      DrugService.cancel_terminate_wdmr10(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.mother_children.wdmr10_data = resp.data.result.wdmr10_data;
            this.d_showTerminationModal = false;
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            this.f_motherChildren('change_status');
            this.f_calculateSelectedWdmr10Status();
          } else {
            this.d_showTerminationModal = false;
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            alert('error: ', resp.data.message);
          }
        });
    },
    f_terminateWdmr10: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Tedavi sonlandırılıyor' } });
      let data = {};
      data.patient_id = this.patient.id;
      data.wdmr10_id = this.p_selectedWdmr10.id;
      data.termination_data = this.d_terminationData;
      DrugService.terminate_wdmr10(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.mother_children.wdmr10_data = resp.data.result.wdmr10_data;
            this.d_showTerminationModal = false;
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            this.f_motherChildren('change_status');
            this.f_calculateSelectedWdmr10Status();
          } else {
            this.d_showTerminationModal = false;
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            alert('error: ', resp.data.message);
          }
        });
    },
    f_showTerminateWdmr10: function () {
      let reference_wdm = this.p_wdmr10List[this.p_selectedWdmr10.index].reference_wdm;
      let bucket = '';
      if (reference_wdm.owner_type === 'wisdom') {
        bucket = 'option';
      } else {
        bucket = 'hospital';
      }
      GlobalService.get_one_cb_document({ 'key': reference_wdm.key, 'bucket': bucket })
        .then(resp => {
          if (resp.data.status === 'success') {
            console.log(resp.data.result.general.parameters);
            this.d_terminationData = { 'date': '', 'reason': [], 'note': '' };
            this.d_terminationReasonOptions = resp.data.result.general.parameters.termination_reason.options;
            this.d_showTerminationModal = true;
          } else {
            alert('error ', resp.data.message);
          }
        });
    },
    f_editWdmr10: function (wdmr10_id) {
      this.p_patientDrugEditData.status = 1;
      this.p_patientDrugEditData.key = 'wdm10_' + wdmr10_id;
      this.p_patientDrugEditData.data_type = 'wdm10';
    },
    f_showDrugReportingModal: function (wdmr_list) {
      this.d_reportingData = { 'date_list': [], 'drugs_by_date': {}, 'created_by': '' };
      for (let k in this.p_wdmr11or12List[this.p_componentType]) {
        this.d_reportingData.date_list.push(this.p_wdmr11or12List[this.p_componentType][k].date);
        this.d_reportingData.drugs_by_date[this.p_wdmr11or12List[this.p_componentType][k].date] = this.p_wdmr11or12List[this.p_componentType][k].list;
        for (let i in this.p_wdmr11or12List[this.p_componentType][k].list) {
          if (!this.d_reportingData.created_by) {
            this.d_reportingData.created_by = this.p_wdmr11or12List[this.p_componentType][k].list[i].created_by;
            break;
          }
        }
      }
      this.d_showDrugReportingModal = true;
    },
    f_createWdmr10TableData: function () {
      // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veriler hazırlanıyor' } });
      this.d_showWdmr10Table = false;
      this.d_wdmr10TableData = [];

      for (let i in this.p_wdmr10List) {
        // { key: "actions", label: "İşlemler" },
        // { key: "name", label: "Tedavi Adı", width: "40px" },
        // { key: "diagnosis", label: "Başlama Tanısı", width: "40px" },
        // { key: "created_by", label: "Hazırlayan" },
        // { key: "date", label: "Başlama tarihi" },
        // { key: "last_status", label: "Son durum" },
        let wdm10_data = this.p_wdmr10List[i];
        let append_data = { 'name': '', 'diagnosis': '', 'created_by': '', 'date': '', 'last_status': '', 'child_key': [], 'id': '', 'last_status_value': '' };

        try {
          append_data['name'] = wdm10_data.label;
        } catch (err) {}

        try {
          append_data['diagnosis'] = wdm10_data.data.general.diagnosis.val.label;
        } catch (err) {}

        try {
          append_data['created_by'] = wdm10_data.created_by;
        } catch (err) {}

        try {
          append_data['date'] = wdm10_data.date;
        } catch (err) {}

        try {
          append_data['id'] = wdm10_data.id;
        } catch (err) {}

        try {
          append_data['child_key'] = wdm10_data.child_key;
        } catch (err) {}

        try {
          append_data['treatment_status'] = wdm10_data.data.status.status.val;
        } catch (err) {
          append_data['treatment_status'] = { "translation": { "en": "Henüz İşlem Yapılmadı", "tr": "Henüz İşlem Yapılmadı" }, "value": "no_operation_yet", "label": "Henüz İşlem Yapılmadı" };
        }
        this.d_wdmr10TableData.push(append_data);
      }
      setTimeout(function () {
        this.d_showWdmr10Table = true;
        this.f_reGetWdmr10Childs();
        this.$forceUpdate();
      }.bind(this), 50)
    },
    wdmr10RowClass: function (item, type) {
      if (!item || !this.p_selectedWdmr10) return;
      if (this.p_selectedWdmr10.id === item.id) return "table-success";
    },
    DeleteDrugsInThisDay: function (day_ind) {
      if (confirm('Bugündeki ilaçlar silinecektir. Onaylıyor musunuz?')) {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İlaçlar siliniyor' } });
        TreatmentService.delete_drugs_in_this_day(this.patient_data.id, this.treatment_data.id, { 'day_ind': day_ind })
          .then(resp => {
            this.update_treatment_data(resp.data.result);
          });
      }
    },
    ProtocolDayEdit: function (day_ind) {
      this.calculated_days_of_one_day = {};
      this.treatment_data_edit = {};
      this.treatment_data_edit = JSON.parse(JSON.stringify(this.treatment_data));
      this.sort_days_list_of_one_day = [];
      this.sort_days_list_of_one_day.push(day_ind);
      this.calculated_days_of_one_day[day_ind] = JSON.parse(JSON.stringify(this.treatment_data.date_list[day_ind]));
      this.treatment_data_day_edit = JSON.parse(JSON.stringify(this.treatment_data));
      this.show_day_edit_dosage_modal = true;
    },
    is_dosage_changes_eligible (day_ind) {
      return true;
      if (this.treatment_data.status === 'terminated') {
        return false;
      }
      let is_there_drug = false;
      for (let drg_key_dose_change in this.treatment_data.date_list[day_ind]) {
        let cycle_no = this.treatment_data.date_list[day_ind][drg_key_dose_change].cycle_index;
        let day_no = this.treatment_data.date_list[day_ind][drg_key_dose_change].day_index;
        let day_data_dose_change = this.treatment_data.protocol_cycles.drugs[drg_key_dose_change].cycles[cycle_no].days[day_no].data;
        if (['no_operation_yet', 'doctor_5', 'doctor_6'].indexOf(day_data_dose_change.approvals.status) === -1) { // ilaç ile ilgili onay işlemi yapıldıysa
          return false;
        }
        if (this.treatment_data.date_list[day_ind][drg_key_dose_change].type === 'drug') { // sadece premedikasyon içeren günlerde doz değişimi butonunu göstermiyoruz.
          is_there_drug = true;
        }
      }
      if (!is_there_drug) {
        return false;
      }
      return true;
    },
    PrintDayDrugs: function (day_ind) {
      this.calculated_days_of_one_day = {};
      this.sort_days_list_of_one_day = [];
      this.sort_days_list_of_one_day.push(day_ind);
      this.calculated_days_of_one_day[day_ind] = this.treatment_data.date_list[day_ind];
      this.show_report_day = true;
    },
    f_removeApprovalsFromDay: function (day_ind) {
      let modal_data = { 'type': 'confirm', 'text': 'Bugüne ait tüm onay işlemlerini silmek istediğinize emin misiniz', 'centered': true, 'title': 'Bugüne ait tüm onay işlemlerini silmek istediğinize emin misiniz', 'confirm': false, 'function_name': 'f_removeApprovalsFromDayConfirmed', 'arguments': [day_ind] };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_removeApprovalsFromDayConfirmed: function (day_ind) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Onay işlemleri siliniyor' } });
      TreatmentService.delete_approval_in_this_day(this.patient_data.id, this.treatment_data.id, { 'date': day_ind })
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          this.update_treatment_data(resp.data.result);
        });
    },
    DateAdjust: function (day_ind) {
      this.doctor_delay_data.date = day_ind;
      this.doctor_delay_data.new_date = this.doctor_delay_data.date;
      this.doctor_delay_data.offset = 0;
      this.doctor_delay_data.calculated_day_drugs = this.treatment_data.date_list[day_ind];
      this.doctor_delay_data.change_type = 'next_all_days_in_all_next_cycles';
      this.show_doctor_delay_modal = true;
    },
    is_date_today: function (dt) {
      console.log(dt);
      let date = new Date(dt);
      console.log(date);
      date.setHours(0, 0, 0, 0);
      let dateMsec = date.getTime();
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      console.log(today);
      let todayMsec = today.getTime();
      let msecPerDay = 86400000;
      let day_dif = Math.round((dateMsec / msecPerDay) - (todayMsec / msecPerDay));
      console.log(day_dif);
      if (day_dif === 0) {
        console.log('bugün');
        return true;
      } else {
        console.log('değil');
        return false;
      }
    },
    DateFormat: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
    },
    f_getWdmr10Childs: function (item) {
      // this.p_showWdmr11or12.x = false;
      this.p_showWdmr11or12.x = false;
      if (item.length > 0) {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Data getiriliyor' } });
        let wdmr10_child_key = item[0].child_key;
        let wdmr10_id = item[0].id;
        this.p_selectedWdmr10.index = this.f_getWdmr10Index(wdmr10_id);
        this.p_selectedWdmr10.id = item[0].id;
        if (wdmr10_child_key && wdmr10_child_key.length > 0) {
          let data = { 'wdm10_key': 'wdm10_' + wdmr10_id, 'patient_id': this.patient.id };
          WisdomDataService.get_wdmr10_childs(data)
            .then(resp => {
              if (resp.data.status === 'success') {
                // console.log(resp.data);
                this.f_cleanPropArray(resp.data.result.list);
                // this.p_wdmr11or12List = resp.data.result.list;
                // this.p_showWdmr11or12.x = true;
                this.f_calculateOptionData();
              } else {
                this.$store.commit('Loading', { 'status': false, 'data': {} });
                alert('error:' + resp.data.message);
              }
            });
        } else {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          alert('Kayıtlı ilaç listesi olmayan veri veridir.');
        }
      }
    },
    f_calculateOptionData: function () {
      // console.log('236');
      let key_list = [];
      for (let i in this.p_wdmr11or12List[this.p_componentType]) {
        // console.log('239');
        for (let k in this.p_wdmr11or12List[this.p_componentType][i].list) {
          if (key_list.indexOf(this.p_wdmr11or12List[this.p_componentType][i].list[k].reference_wdm.key) === -1 && !this.p_optionData[this.p_wdmr11or12List[this.p_componentType][i].list[k].reference_wdm.key]) {
            key_list.push(this.p_wdmr11or12List[this.p_componentType][i].list[k].reference_wdm.key);
          }
        }
      }
      if (key_list.length > 0) {
        GlobalService.get_multiple_cb_documents({ 'key_list': key_list, 'bucket': 'option' })
          .then(resp => {
            if (resp.data.status === 'success') {
              for (let i in resp.data.result) {
                this.p_optionData[i] = {};
                this.p_optionData[i][resp.data.result[i].parent] = resp.data.result[i];
              }
            }
            setTimeout(function () {
              // this.d_drugMode = 1;
              this.p_showWdmr11or12.x = true;
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              this.$forceUpdate();
            }.bind(this), 50)
          });
      } else {
        setTimeout(function () {
          // this.d_drugMode = 1;
          this.p_showWdmr11or12.x = true;
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          this.$forceUpdate();
        }.bind(this), 50)
      }
    },
    f_cleanPropArray: function (resp_list) {
      if (this.p_wdmr11or12List[this.p_componentType].length > 0) {
        this.p_wdmr11or12List[this.p_componentType].pop();
      }
      if (this.p_wdmr11or12List[this.p_componentType].length > 0) {
        this.f_cleanPropArray(resp_list);
      } else {
        for (let i in resp_list) {
          this.p_wdmr11or12List[this.p_componentType].push(resp_list[i]);
        }
        this.f_calculateOptionData();
      }
    },
    f_reGetWdmr10Childs: function () {
      // this.p_showWdmr11or12.x = false;
      if (this.p_selectedWdmr10 && this.p_selectedWdmr10.id !== '') {
        let data = { 'wdm10_key': 'wdm10_' + this.p_selectedWdmr10.id, 'patient_id': this.patient.id };
        WisdomDataService.get_wdmr10_childs(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              this.f_cleanPropArray(resp.data.result.list);
            } else {
              alert('error:' + resp.data.message);
            }
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          });
      } else {
        this.$store.commit('Loading', { 'status': false, 'data': {} });
      }
    },
    f_getWdmr10Index (wdmr10_id) {
      for (let i in this.d_wdmr10TableData) {
        if (this.d_wdmr10TableData[i].id === wdmr10_id) {
          return parseInt(i);
        }
      }
    },
    f_getPatientRecords: function () {
      this.p_wdmr10List = [];
      let department_filter = { 'filter_with_department': [], 'all_departments': ['wdm10'], 'not_eligible_for_department_relation': [] };
      let search_data = {};
      search_data.department_filter = department_filter;
      search_data.patient_id = this.patient.id;
      search_data.department_list = [];
      search_data.show_last_x_data = 'all';
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Veriler getiriliyor.' } });
      WisdomDataService.get_patient_wdmr(search_data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.p_wdmr10List = resp.data.result.wdmr_list;
            this.d_wdm10 = resp.data.result.wdm_list;
            this.f_createWdmr10TableData();
          } else {
            alert('error:', )
          }
        });
    },
    f_motherChildren: function (op) {
      if (this.mother_children[op] === 0) {
        this.mother_children[op] = 1;
      } else {
        this.mother_children[op] = 0;
      }
    },
    f_calculateSelectedWdmr10Status: function () {
      if (this.p_selectedWdmr10.id) {
        try {
          this.p_selectedWdmr10.status = this.p_wdmr10List[this.p_selectedWdmr10.index].data.status.status.val;
        } catch (err) {
          this.p_selectedWdmr10.status = { "translation": { "en": "Henüz İşlem Yapılmadı", "tr": "Henüz İşlem Yapılmadı" }, "value": "no_operation_yet", "label": "Henüz İşlem Yapılmadı" };
        }
      }
    }
  },
  watch: {
    'p_wdmr10ShowMode': function () {
      if (this.p_wdmr10ShowMode) {
        this.f_createWdmr10TableData();
      }
    },
    'mother_children.forceUpdate': function () {
      this.$forceUpdate();
    },
    'p_selectedWdmr10.id': function () {
      this.f_calculateSelectedWdmr10Status();
    },
    // 'mother_children.update_patient_wdmr': function () {
    //   this.f_createWdmr10TableData();
    // },
    'mother_children.change_status': function () {
      let data = { 'wdm10_key': 'wdm10_' + this.p_selectedWdmr10.id, 'patient_id': this.patient.id };
      // this.p_showWdmr11or12.x = false;
      WisdomDataService.get_wdmr10_childs(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.f_cleanPropArray(resp.data.result.list);
            this.f_motherChildren('update_naming');
            this.f_calculateSelectedWdmr10Status();
            // this.p_showWdmr11or12.x = true;
          } else {
            alert('error:' + resp.data.message);
          }
        });
    },
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_deleteWdmr10Confirmed' && this.StoreModal.data.confirm) {
          this.f_deleteWdmr10Confirmed();
        }
      }
    }
  },
  components: {
    Modal,
    PatientDrugButton,
    PatientDrugDay,
    PatientDrugReportingModal,
    WdmrReport: () =>
      import ('@/components/widgets/WdmrReport'),
    WisdomDataModal,
    WisdomDataShow
  }
};

</script>

<style>


</style>

