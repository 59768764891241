<template>
  <div>
    <b-dropdown variant="primary" size="md" style="width: 75%; float: left;">
      <template slot="button-content"> İlaç İşlemi </template>
      <template v-for="button_option in PatientDrugButtonOptions">
        <b-dropdown-item @click="f_changeStatus(button_option)" v-if="f_buttonPermControl(button_option)"> {{ button_option.status.label }} </b-dropdown-item>
      </template>
    </b-dropdown>
    <modal v-if="d_showNurseModal" @close="d_showNurseModal = false" :width="'1000'">
      <div slot="header">
        <h3> {{ $t('wdm16.3985') }} </h3>
      </div>
      <div slot="body">
        <patient-drug-brand-selection :patient_data="patient_data" :treatment_order_data="treatment_order_data" :hims_protocol_data="hims_protocol_data"></patient-drug-brand-selection>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-primary" @click="days_approval_all_drugs(treatment_order_data.dy_no, treatment_order_data.status_id, treatment_order_data.type, treatment_order_data.drug_key)">
          {{ $t('wdm16.4155') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="d_showNurseModal = false">{{ $t('wdm16.3748') }}</button>
      </div>
    </modal>
    <modal v-if="d_showPharmacyModal" @close="d_showPharmacyModal = false" :width="'1500'">
      <div slot="header"> <i class="fa fa-eyedropper"></i> Hasta Eczane Ekranı </div>
      <div slot="body">
        <pharmacy-widget ref="pharmacy_widget" :patient_data="patient_data" :hims_protocol_data="hims_protocol_data" :is_chemotherapy_approval="true"></pharmacy-widget>
      </div>
      <div slot="footer">
        <b-button variant="danger" @click="d_showPharmacyModal = false">{{ $t('wdm16.4053') }}</b-button>
        <b-button variant="success" @click="f_completeDaysApprovalFromPharmacyPage()">Tedavi Onay İşlemine Devam Et</b-button>
      </div>
    </modal>
    <modal v-if="d_showPatientLastLaboratoryAnormal" @close="d_showPatientLastLaboratoryAnormal = false" :width="'1000'">
      <div slot="header"> <i class="fa fa-eyedropper"></i> Hasta Son Laboratuar Verileri </div>
      <div slot="body">
        <last-laboratory :patient_data="patient_data" :show_mode="'anormal'" :last_x_days="30"></last-laboratory>
      </div>
      <div slot="footer">
        <b-button variant="success" @click="f_changeStatusContinue(d_clickedButton)"> İşleme Devam Et </b-button>
        <b-button variant="danger" @click="d_showPatientLastLaboratoryAnormal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_showApproveWithChangeModal" @close="d_showApproveWithChangeModal = false" :width="'1000'">
      <h3 slot="header">
        Doz Düzenleme Ekranı
      </h3>
      <div slot="body">
        <template v-for="(wdmr_data, wdmr_key) in d_approveWithChangeData.wdmr_data_obj">
          <b-row :key="'d_approveWithChangeData.wdmr_data_obj_' + wdmr_key" @click="d_selectedWdmr11Key = wdmr_key" :style="d_selectedWdmr11Key && d_selectedWdmr11Key === wdmr_key ? 'background-color: #4dbd74; cursor: pointer;' : 'cursor: pointer;'">
            <b-col cols="1">
              <li v-if="d_selectedWdmr11Key && d_selectedWdmr11Key === wdmr_key" class="fa fa-check"> Siklus Ekle</li>
            </b-col>
            <b-col cols="11">
              {{ wdmr_data.label }}
            </b-col>
          </b-row>
        </template>
        <template v-if="d_selectedWdmr11Key">
          <wisdom-data-modal :data="d_approveWithChangeData.wdmr_data_obj[d_selectedWdmr11Key]" :data_type="d_approveWithChangeData.wdmr_data_obj[d_selectedWdmr11Key].type" :option_data="p_optionData[d_approveWithChangeData.wdmr_data_obj[d_selectedWdmr11Key].reference_wdm.key]" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
        </template>
      </div>
      <div slot="footer">
        <b-button variant="success" @click="f_changeStatusFinal(d_approveWithChangeData)"> İşleme Devam Et </b-button>
        <b-button variant="danger" @click="d_showApproveWithChangeModal = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { show_unit_calculate } from '../../filters/index';
import { mapGetters } from 'vuex';
import PharmacyService from '@/services/pharmacy';
import {
  default as LastLaboratory
} from '@/components/widgets/LastLaboratory';
import {
  default as PharmacyWidget
} from '@/components/widgets/PharmacyWidget';
import { DateFunctions } from '@/services/public/date_functions';
import PatientService from '@/services/patient';
import DrugService from '@/services/drug';
import {
  default as WisdomDataModal
} from "@/components/widgets/WisdomDataModal";
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as PatientDrugBrandSelection
} from '@/components/widgets/PatientDrugBrandSelection';

// import Vue from 'vue';
// import moment from 'moment';

// /* global $ */

export default {
  name: 'PatientDrugButton',
  computed: mapGetters({
    StoreModal: 'modal',
    StoreLoading: 'loading',
    lang: 'lang',
    patient: 'patient_selected'
  }),
  props: {
    p_wdmr11or12List: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    p_optionData: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    p_wdmr11or12_ind: {
      type: Number,
      required: false
    },
    p_external_patient: {
      type: Object,
      required: false
    },
    p_wdmr_data_ind: {
      type: Number,
      required: false,
      default: -1
    },
    mother_children: {
      type: Object,
      required: true
    },
    p_orderOperation: {
      type: Object,
      required: true
    },
    p_patientId: {
      type: String,
      required: false
    },
    p_componentType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      d_selectedAnatomySchemaList: [],
      rule_results: { rule_ignore: [], list: [], status: '' },
      hospital_data: {},
      hims_protocol_data: { 'pharmacy_list': {}, 'selected_protocol': {} },
      user: {},
      d_selectedWdmr11Key: '',
      patient_data: {},
      WebserviceProtocolInsuranceData: require('@/options/webservice').ProtocolInsuranceData,
      PatientDrugButtonOptions: require('@/options/PatientDrugButtonOptions').options,
      d_showButtons: true,
      d_showPatientLastLaboratoryAnormal: false,
      d_showApproveWithChangeModal: false,
      d_showNurseModal: false,
      d_showPharmacyModal: false,
      treatment_data: {},
      d_drugList: {},
      d_approveWithChangeData: {},
      d_drugOrderList: {},
      d_clickedButton: {},
      treatment_order_data: {},
      treatment_order_data_save: {},
      types: ['before', 'during', 'after'],
      usage_type_wisdom_mlpcare_match: {
        '1': '7',
        '2': '7',
        '3': '33',
        '4': '7',
        '5': '35',
        '6': '28',
        '7': '34',
        '8': '18',
        '9': '5',
        '10': '4',
        '11': '27',
        '12': '1',
        '13': '1',
        '14': '20',
        '15': '17',
        '16': '26',
        '17': '24',
        '18': '22',
        '19': '16',
        '20': '24',
        '21': '23',
        '22': '11',
        '23': '6',
        '24': '11',
        '25': '18',
        '26': '6',
        '27': '6'
      },
      d_pharmacyFormat: {
        'material_name': '',
        'starting_date': '',
        'material_id': '',
        'unit_id': '',
        'hungry_full': '',
        'usage_type': '',
        'infusion_speed': '',
        'speed_measure': '',
        'dose_period': '',
        'dose_quantity': '',
        'doctor_note': '',
        'req_quantity_from_chemist': '',
      },
      unit_match_with_mlpcare: {
        'ULS': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'mu': 768,
          'mu_m2': 768,
          'mu_kg': 768,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'FLR': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'mu': 768,
          'mu_m2': 768,
          'mu_kg': 768,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'GOZ': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'mu': 768,
          'mu_m2': 768,
          'mu_kg': 768,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'IZT': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'mu': 768,
          'mu_m2': 768,
          'mu_kg': 768,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'BHI': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'mu': 768,
          'mu_m2': 768,
          'mu_kg': 768,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'BAH': {
          'adet': 1,
          'ampul': 683,
          'auc': 715,
          'cc': 9,
          'drop': 720,
          'gr': 716,
          'mcg': 717,
          'mcg_m2': 717,
          'mcg_kg': 717,
          'mg': 715,
          'mg_kg': 715,
          'mg_m2': 715,
          'unit_m2': 715,
          'ml': 9,
          'mu': 713,
          'mu_m2': 713,
          'mu_kg': 713,
          'scale': 687,
          'tablet': 682,
          'unite': 719
        },
        'BUR': {
          'adet': 1,
          'ampul': 683,
          'auc': 700,
          'cc': 9,
          'drop': 693,
          'gr': 689,
          'mcg': 696,
          'mcg_m2': 696,
          'mcg_kg': 696,
          'mg': 700,
          'mg_kg': 700,
          'mg_m2': 700,
          'unit_m2': 700,
          'ml': 9,
          'scale': 687,
          'tablet': 682,
          'unite': 705
        },
        'GZU': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'GEB': {
          'adet': 1,
          'ampul': 683,
          'auc': 769,
          'cc': 690,
          'drop': 773,
          'gr': 770,
          'mcg': 771,
          'mcg_m2': 771,
          'mcg_kg': 771,
          'mg': 769,
          'mg_kg': 769,
          'mg_m2': 769,
          'unit_m2': 769,
          'ml': 690,
          'scale': 687,
          'tablet': 682,
          'unite': 772
        },
        'CAN': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 770,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'BTH': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        },
        'PEN': {
          'adet': 1,
          'ampul': 683,
          'auc': 770,
          'cc': 690,
          'drop': 773,
          'gr': 771,
          'mcg': 772,
          'mcg_m2': 772,
          'mcg_kg': 772,
          'mg': 770,
          'mg_kg': 770,
          'mg_m2': 770,
          'unit_m2': 770,
          'ml': 690,
          'scale': 687,
          'tablet': 682,
          'unite': 766
        }
      }
    };
  },
  created () {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.p_external_patient) {
      this.patient_data = JSON.parse(JSON.stringify(this.p_external_patient));
    } else {
      this.patient_data = JSON.parse(JSON.stringify(this.patient));
    }
    this.hospital_data = JSON.parse(localStorage.getItem('hospital_data'));
  },
  mounted () {},
  methods: {
    f_completeDaysApprovalFromPharmacyPage: function () {
      let d_pharmacyList = this.$refs.pharmacy_widget.d_pharmacyList;
      let d_selectedProtocolData = this.$refs.pharmacy_widget.d_selectedProtocolData;
      let sending_ph_list = {};
      for (let dt in d_pharmacyList) {
        if (!d_selectedProtocolData.recorded_pharmacy_list[dt] || (d_selectedProtocolData.recorded_pharmacy_list[dt] && JSON.stringify(d_selectedProtocolData.recorded_pharmacy_list[dt]) !== JSON.stringify(d_pharmacyList[dt]))) {
          sending_ph_list[dt] = d_pharmacyList[dt];
        }
      }
      let data = {
          'pharmacy_list': sending_ph_list,
          'processID': d_selectedProtocolData.process_id,
          'resourceId': d_selectedProtocolData.resource_id,
          'protocolNo': d_selectedProtocolData.protocol_no,
          'hospitalCode': this.user.hospital_id,
          'hospitalGroup': this.user.hospital_group,
          'patient_id': this.patient_data.id
        }
        // console.log(data);
        // return;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İlaç order kayıt işlemi yapılıyor. Lütfen bekleyiniz.' } });
      PharmacyService.save_pharmacy_order(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.f_completeDaysApproval();
          } else {
            let txt = 'Eczane gönderim işleminde hata oluştu. Eczane istemi yapmadan devam etmek istiyor musunuz?'
            let modal_data = { 'type': 'confirm', 'text': txt, 'centered': true, 'title': 'Ürün & Eczane Onay İşlemleri Hakkında', 'function_name': 'f_completeDaysApproval', 'confirm': false };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        });
    },
    days_approval_all_drugs: function (dy_no, status_id, type, drug_key = 'none', cycle_index = '', day_index = '', prem_key = '', prem_day = '') {
      // dy_no calculated_days'den gelen no, type ya drug ya da prem,
      // ürün seçimi ekranında ilaçlar seçildi ve işlem onaylanıyor ise status_id = nurse_0
      if (status_id === 'nurse_0') {
        let det = false;
        for (let dy in this.treatment_order_data.days) {
          for (let drg in this.treatment_order_data.days[dy]) {
            if (this.treatment_order_data.days[dy][drg].type === 'drug') {
              if (this.treatment_order_data.days[dy][drg].barcode_list !== undefined && this.treatment_order_data.days[dy][drg].barcode_list.length === 0) {
                det = true;
                break;
              }
              for (let mx in this.treatment_order_data.days[dy][drg].drug_mix) {
                if (this.treatment_order_data.days[dy][drg].drug_mix[mx].barcode_list.length === 0) {
                  det = true;
                }
              }
            } else {
              for (let typ in this.types) {
                for (let prem in this.treatment_order_data.days[dy][drg][this.types[typ]]) {
                  if (this.treatment_order_data.days[dy][drg][this.types[typ]][prem].barcode_list.length === 0) {
                    det = true;
                    break;
                  }
                  for (let mx in this.treatment_order_data.days[dy][drg][this.types[typ]][prem].drug_mix) {
                    if (this.treatment_order_data.days[dy][drg][this.types[typ]][prem].drug_mix[mx].barcode_list.length === 0) {
                      det = true;
                      break;
                    }
                  }
                  if (det) {
                    break;
                  }
                }
                if (det) {
                  break;
                }
              }
            }
            if (det) {
              break;
            }
          }
          if (det) {
            break;
          }
        }
        if (det) {
          alert('Lütfen ürünlerin seçimlerini tamamlayınız.');
          return;
        }
      }
      let data = { rule_ignore: this.rule_results.rule_ignore, active_func_name: 'days_approval_all_drugs', active_func_parameters: [dy_no, status_id, type, drug_key, cycle_index, day_index, prem_key, prem_day] };
      if (drug_key === 'none') { // tek ilaç onayı yapılmıyor ve gün onayı yapılıyorsa.
        data.calculated_days = this.treatment_data.date_list;
      } else { // tek ilaç onayı yapılıyorsa
        data['calculated_days'] = {};
        data.calculated_days[dy_no] = {};
        data.calculated_days[dy_no][drug_key] = JSON.parse(JSON.stringify(this.treatment_data.date_list[dy_no][drug_key]));
        if (type === 'drug') {
          data.calculated_days[dy_no][drug_key].before = [];
          data.calculated_days[dy_no][drug_key].during = [];
          data.calculated_days[dy_no][drug_key].after = [];
        } else { // before during after
          for (let i in this.types) {
            if (this.types[i] !== type) {
              data.calculated_days[dy_no][drug_key][this.types[i]] = [];
            }
          }
        }
      }
      if (status_id === 'doctor_6' && drug_key !== 'none') {
        this.f_calculateDoctor6All(dy_no, drug_key);
      }
      data['days'] = []; // daha sonra çoklu gün de gönderebilmek için dize olarak oluşturuldu.
      data['status_id'] = status_id;
      data['type'] = type; // drug, prem (çoklu premedikasyon seçimi yapıldıysa before, after, during içinde olabilir), before, after, during
      if (status_id.split('_')[0] === 'doctor') {
        data.calculation_data = {};
        // laboratory data
        if (this.patient_data.last_laboratory['2']) {
          data.calculation_data.lab_date = this.patient_data.last_laboratory['2'].date;
          data.calculation_data.crea = this.patient_data.last_laboratory['2'].val;
          data.calculation_data.gfr = this.patient_data.last_laboratory['2'].gfr;
          data.calculation_data.selected_gfr_formule = this.patient_data.last_laboratory['2'].selected_gfr_formule;
          data.calculation_data.selected_weight_for_gfr = this.patient_data.last_laboratory['2'].selected_weight_for_gfr;
        }
        // bmi data
        if (this.patient_data.last_bmi_data !== 'none') {
          data.calculation_data.bmi_date = this.patient_data.last_bmi_data.date;
          data.calculation_data.bsa = this.patient_data.last_bmi_data.bsa;
          data.calculation_data.length = this.patient_data.last_bmi_data.length;
          data.calculation_data.weight = this.patient_data.last_bmi_data.weight;
          data.calculation_data.selected_weight_for_bsa = this.patient_data.last_bmi_data.selected_weight_for_bsa;
        }
      } else if (status_id.split('_')[0] === 'nurse') {
        data.nurse = this.treatment_order_data;
      }
      let not_today = false;
      if (this.selected_days && this.selected_days.length > 0) {
        data.days = this.selected_days;
        for (let i in this.selected_days) {
          if (DateFunctions.day_dif(this.selected_days[i], 'today') !== 0) {
            not_today = true;
            break;
          }
        }
      } else {
        if (DateFunctions.day_dif(dy_no, 'today') !== 0) {
          not_today = true;
        }
        data.days.push(dy_no);
      }
      if (status_id === 'administration_1') {
        data.given_dosage = this.administration_1_data.given_dosage;
      }
      this.days_approval_data = data;
      //
      this.textOfPremedicationOfDoctorApproval = '';
      this.days_approval_data.webservice = { 'protocol_list': [], 'hospitalGroup': this.hospital_data.hospital_group, 'hospitalCode': this.hospital_data.hospital_id, 'sending_json_data': {}, 'sending': 0, 'patientNo': '' };
      if (this.WebserviceProtocolInsuranceData[this.hospital_data.hospital_group]) {
        for (let y in this.WebserviceProtocolInsuranceData[this.hospital_data.hospital_group]['parameter_order']) {
          let hims_param = this.WebserviceProtocolInsuranceData[this.hospital_data.hospital_group]['parameter_order'][y];
          this.days_approval_data.webservice.sending_json_data[hims_param] = '';
        }
      }
      this.days_approval_data.webservice.sending_json_data.symptom_start_date = new Date().toISOString();
      // TODO-1
      this.days_approval_data.webservice.sending_json_data.pre_diagnosis = 'C50';
      this.days_approval_data.webservice.sending_json_data.main_diagnosis = 'C50';
      // this.createwebserviceText(status_id);
      // Preparing the text list to send HBYS system
      // hsatanın webservice bağlantısı ve açık protokollerinden Tıbbi Onkoloji var mı kontrol ediliyor.

      // bu if sorgusu alttaki gönderimi kapsadığı zaman sadece güncel protokole bilgi gönderme işlemi yapılıyor
      // if (DateFunctions.day_dif(dy_no, 'today') <= 0) {}

      if (['administration_0', 'administration_1', 'administration_2', 'administration_3', 'doctor_7'].indexOf(status_id) !== -1) {
        if (this.patient_data.hospitals && this.patient_data.hospitals[this.hospital_data.hospital_group] && this.patient_data.hospitals[this.hospital_data.hospital_group][this.hospital_data.hospital_id] && this.patient_data.hospitals[this.hospital_data.hospital_group][this.hospital_data.hospital_id].webservice === 1) {
          this.days_approval_data.webservice.patientNo = this.patient_data.hospitals[this.hospital_data.hospital_group][this.hospital_data.hospital_id].data.id;
          this.createwebserviceText(status_id);
        }
      }
      //
      let settings_info = '';
      if (['doctor_0', 'doctor_1', 'doctor_2', 'doctor_8'].indexOf(status_id) !== -1) {
        if (this.user.settings && this.user.settings.oncologyModule && this.user.settings.oncologyModule.use === '1') {
          if (this.user.settings.oncologyModule.settings.drugApprovalDosage && this.user.settings.oncologyModule.settings.drugApprovalDosage.use === '1') {
            for (let dy in this.days_approval_data.days) {
              for (let drug_key in this.days_approval_data.calculated_days[this.days_approval_data.days[dy]]) {
                let cycle_index = this.days_approval_data.calculated_days[this.days_approval_data.days[dy]][drug_key].cycle_index;
                let day_index = this.days_approval_data.calculated_days[this.days_approval_data.days[dy]][drug_key].day_index;
                let drug_data = this.treatment_data.protocol_cycles.drugs[drug_key].drug;
                let day_data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data;
                if (this.days_approval_data.calculated_days[this.days_approval_data.days[dy]][drug_key].type === 'drug') {
                  let drug_value = drug_data.value;
                  if (this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value]) {
                    if (this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].max && this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].max.use === '1') {
                      if (parseFloat(this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].max.val) < parseFloat(day_data.planned_dosage)) {
                        settings_info += drug_data.label + ' => Maximum dikkat değeri = ' + this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].max.val + ' | Belirlediğiniz değer = ' + day_data.planned_dosage + '\n';
                      }
                    }
                    if (this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].min && this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].min.use === '1') {
                      if (parseFloat(this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].min.val) > parseFloat(day_data.planned_dosage)) {
                        settings_info += drug_data.label + ' => Minimum dikkat değeri = ' + this.user.settings.oncologyModule.settings.drugApprovalDosage.drugs[drug_value].max.val + ' | Belirlediğiniz değer = ' + day_data.planned_dosage + '\n';
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (['doctor_0', 'doctor_1', 'doctor_2', 'doctor_8'].indexOf(status_id) !== -1) {
        for (let lab_ind in this.patient_data.last_laboratory) {
          if (this.f_lookForAnormalTest(this.patient_data.last_laboratory[lab_ind])) {
            this.d_showPatientLastLaboratory = true;
          }
        }
      }
      if (status_id === 'nurse_0') {
        if (this.hims_protocol_data.selected_protocol && this.hims_protocol_data.selected_protocol.protocol_no) {
          if (this.f_controlPharmacyStatus()) {
            let msg = '';
            if (not_today) {
              msg += 'İşlem yaptığınız gün bugüne ait değildir, bu sebeple eczaneden ilaç çekim işlemi yapamayacaksınız.\n';
            }
            if (msg) {
              let modal_data = { 'type': 'confirm', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Tedavi Onay İşlemi Hakkında', 'function_name': 'f_completeDaysApproval', 'confirm': false };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            } else {
              this.d_showPharmacyModal = true;
            }
            return;
          }
        }
      }
      // laboratuar uyarısı olduğunda öncelik laboratuar bilgisinin gösgterilmesinde. Zaten veri hazırlanmış oluyor. uygun ise f_completeDaysApproval ile devam ediyor
      if (!this.d_showPatientLastLaboratory) {
        let msg = '';
        msg += settings_info;
        if (not_today) {
          msg += 'İşlem yaptığınız gün bugüne ait değildir.\n';
        }
        if (msg) {
          let modal_data = { 'type': 'confirm', 'header_bg_variant': 'info', 'header_text_variant': 'dark', 'text': msg, 'centered': true, 'title': 'Tedavi Onay İşlemi Hakkında', 'function_name': 'f_completeDaysApproval', 'confirm': false };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        } else {
          this.f_completeDaysApproval();
        }
      }
    },
    f_completeDaysApproval: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Onay işleminiz gerçekleştiriliyor.' } });
      DrugService.save_brand_order_data(this.treatment_order_data)
        .then(resp => {
          if (this.p_wdmr_data_ind >= 0) {
            this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[this.p_wdmr_data_ind] = resp.data.result.wdmr11or12list[0];
          } else {
            this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list = resp.data.result.wdmr11or12list;
          }
          this.$forceUpdate();
          this.mother_children.wdmr10_data = resp.data.result.wdmr10_data;
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          this.d_showNurseModal = false;
          this.d_showPharmacyModal = false;
          this.f_motherChildren('update_naming');
          this.f_motherChildren('forceUpdate');
        });
    },
    f_controlPharmacyStatus: function () {
      // status = 0, 1
      let det = false;
      this.hims_protocol_data.pharmacy_list = {};
      for (let day in this.treatment_order_data.days) {
        for (let drug_key in this.treatment_order_data.days[day]) {
          if (this.treatment_order_data.days[day][drug_key]['pharmacy_status']) {
            det = true;
            this.f_createPharmacyData(day, this.treatment_order_data.days[day][drug_key]);
          }
          if (this.treatment_order_data.days[day][drug_key].fluid && this.treatment_order_data.days[day][drug_key].fluid['pharmacy_status']) {
            det = true;
            this.f_createPharmacyData(day, this.treatment_order_data.days[day][drug_key].fluid, 'yes');
          }
          if (this.treatment_order_data.days[day][drug_key]['drug_mix']) {
            for (let mx in this.treatment_order_data.days[day][drug_key]['drug_mix']) {
              if (this.treatment_order_data.days[day][drug_key]['drug_mix'][mx]['pharmacy_status']) {
                det = true;
                this.f_createPharmacyData(day, this.treatment_order_data.days[day][drug_key]['drug_mix'][mx]);
              }
            }
          }
          let prem_types = ['before', 'after', 'during'];
          for (let p in prem_types) {
            for (let k in this.treatment_order_data.days[day][drug_key][prem_types[p]]) {
              if (this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['pharmacy_status']) {
                det = true;
                this.f_createPharmacyData(day, this.treatment_order_data.days[day][drug_key][prem_types[p]][k]);
              }
              if (this.treatment_order_data.days[day][drug_key][prem_types[p]][k].fluid && this.treatment_order_data.days[day][drug_key][prem_types[p]][k].fluid['pharmacy_status']) {
                this.f_createPharmacyData(day, this.treatment_order_data.days[day][drug_key][prem_types[p]][k].fluid, 'yes');
              }
              if (this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix']) {
                for (let mx in this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix']) {
                  if (this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix'][mx]['pharmacy_status']) {
                    det = true;
                    this.f_createPharmacyData(day, this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix'][mx]);
                  }
                }
              }
            }
          }
        }
      }
      return det;
    },
    d_calculateMaterialId: function (barcode_no, brand_list) {
      if (barcode_no && brand_list) {
        for (let b in brand_list) {
          if (brand_list[b].value.toString() === barcode_no.toString()) {
            return brand_list[b].stock.id;
          }
        }
      }
      return '';
    },
    f_createPharmacyData: function (day, data, is_fluid = 'no') {
      if (data['brand_count']) {
        for (let b in data['brand_count']) {
          let x = JSON.parse(JSON.stringify(this.d_pharmacyFormat));
          x['material_name'] = data['brand_count'][b].label;
          // x['material_name'] = data.selected_brand ? data.selected_brand.label : '';
          x['starting_date'] = day + 'T16:00:00.000';
          x['material_id'] = this.d_calculateMaterialId(b, data.brands);
          if (is_fluid === 'yes') {
            x['unit_id'] = this.unit_match_with_mlpcare[this.user.hospital_id]['adet'];
          } else {
            x['unit_id'] = data.unit && data.unit.value && this.unit_match_with_mlpcare[this.user.hospital_id][data.unit.value] ? this.unit_match_with_mlpcare[this.user.hospital_id][data.unit.value] : this.unit_match_with_mlpcare[this.user.hospital_id]['mg'];
          }
          x['hungry_full'] = 'F';
          x['usage_type'] = data.dosage_form && data.dosage_form.value && this.usage_type_wisdom_mlpcare_match[data.dosage_form.value] ? this.usage_type_wisdom_mlpcare_match[data.dosage_form.value] : '1';
          x['dose_period'] = data.frequency && data.frequency.value && data.frequency.value.split('x')[0] ? parseInt(parseInt(data.frequency.value.split('x')[0])) : 1;
          if (is_fluid === 'yes') {
            x['dose_quantity'] = parseFloat(data['brand_count'][b].count).toFixed(2).toString();
            // x['dose_quantity'] = '1';
          } else {
            let dose_quantity = '1';
            if (data['brand_count'][b].ph_dosage) {
              dose_quantity = parseFloat(data['brand_count'][b].ph_dosage).toFixed(2).toString();
            } else {
              if (data.given_dosage) {
                dose_quantity = parseFloat(data.given_dosage).toFixed(2).toString();
              }
            }
            x['dose_quantity'] = dose_quantity;
          }
          if (data.infusion_speed) {
            x['infusion_speed'] = data.infusion_speed.toString();
          } else {
            x['infusion_speed'] = '0';
          }
          x['speed_measure'] = data.speed_measure;
          x['doctor_note'] = '';
          x['req_quantity_from_chemist'] = parseInt(data['brand_count'][b].count);
          if (!this.hims_protocol_data.pharmacy_list[day]) {
            this.hims_protocol_data.pharmacy_list[day] = { 'list': [] };
          }
          let det = false;
          for (let d in this.hims_protocol_data.pharmacy_list) {
            for (let i in this.hims_protocol_data.pharmacy_list[d].list) {
              if (this.hims_protocol_data.pharmacy_list[d].list[i].material_id === x.material_id) {
                det = true;
                this.hims_protocol_data.pharmacy_list[day]['list'][i]['dose_quantity'] = (parseFloat(this.hims_protocol_data.pharmacy_list[day]['list'][i]['dose_quantity']) + parseFloat(x['dose_quantity'])).toFixed(2).toString();
                this.hims_protocol_data.pharmacy_list[day]['list'][i]['req_quantity_from_chemist'] += x['req_quantity_from_chemist'];
              }
            }
          }
          if (!det) {
            this.hims_protocol_data.pharmacy_list[day]['list'].push(x);
          }
        }
      }
    },
    f_brandSelectionOperations: function () {
      let dy_no = '';
      let patientNo = '';
      try {
        patientNo = this.patient_data['hospitals'][this.user.hospital_group][this.user.hospital_id]['data']['id'];
      } catch {
        patientNo = '';
      }
      let data = {
        'patient_id': this.patient_data.id,
        'wdmr_key_list': [],
        'dy_no': '',
        'id': this.treatment_data.id,
        'patientNo': patientNo ? patientNo : '',
        'hospitalGroup': this.user.hospital_group,
        'hospitalCode': this.user.hospital_id
      };
      if (this.p_wdmr_data_ind >= 0) {
        data.wdmr_key_list.push(this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[this.p_wdmr_data_ind].type + '_' + this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[this.p_wdmr_data_ind].id);
        if (dy_no === '') {
          dy_no = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[this.p_wdmr_data_ind].date.split('T')[0];
          data.dy_no = dy_no;
        }
      } else {
        for (let i in this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list) {
          data.wdmr_key_list.push(this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].type + '_' + this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].id);
          if (dy_no === '') {
            dy_no = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].date.split('T')[0];
            data.dy_no = dy_no;
          }
        }
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İşleminiz gerçekleştiriliyor' } });
      DrugService.prepare_brand_order_data(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            let tmp_wdmr_list = {}

            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            this.d_drugList = JSON.parse(JSON.stringify(resp.data.result['drug_list']));
            this.d_drugOrderList = JSON.parse(JSON.stringify(resp.data.result['drug_order_list']));
            // alert('error: ' + JSON.stringify(resp.data.result));

            this.treatment_data.drug_list = JSON.parse(JSON.stringify(resp.data.result['drug_list']));
            let drug_order_list = resp.data.result['drug_order_list']
            this.patient_data.open_protocol_list = { 'result': [] };
            this.patient_data.open_protocol_list.result = resp.data.result['eligible_open_protocol_list'];
            this.treatment_order_data = {};
            this.treatment_order_data.dy_no = dy_no;
            this.treatment_order_data.status_id = 'nurse_0';
            this.treatment_order_data.drug_key = 'none';
            this.treatment_order_data.days = {};
            this.treatment_order_data.days[dy_no] = JSON.parse(JSON.stringify(resp.data.result['drug_order_list']));

            let data = this.treatment_order_data.days[dy_no];
            if (this.p_wdmr_data_ind >= 0) {
              let drg_key = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[this.p_wdmr_data_ind].type + '_' + this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[this.p_wdmr_data_ind].id;
              if (this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[this.p_wdmr_data_ind].data.general.drug.val.value === 'radiotherapy') {
                this.$delete(data, drg_key);
              } else {
                tmp_wdmr_list[drg_key] = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[this.p_wdmr_data_ind];
              }
            } else {
              for (let i in this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list) {
                let drg_key = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].type + '_' + this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].id;
                if (this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].data.general.drug.val.value === 'radiotherapy') {
                  this.$delete(data, drg_key);
                } else {
                  tmp_wdmr_list[drg_key] = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i];
                }
              }
            }

            for (let drg_key in data) {
              let drug_data = tmp_wdmr_list[drg_key];

              data[drg_key].brands = this.d_drugList[drug_data.data.general.drug.val.value].brands;
              data[drg_key].selected_brand = '';
              data[drg_key].name = drug_data.data.general.drug.val.label;
              data[drg_key].pharmacy_status = 1;
              data[drg_key].barcode_list = this.d_drugOrderList[drg_key].barcode_list;
              data[drg_key].brand_count = this.d_drugOrderList[drg_key].brand_count;
              data[drg_key].frequency = drug_data.data.general.frequency ? drug_data.data.general.frequency.val.value : '';
              data[drg_key].duration = drug_data.data.general.duration ? drug_data.data.general.duration.val : '';
              data[drg_key].planned_dosage = drug_data.data.general.planned_dosage.val;
              data[drg_key].dosage = drug_data.data.general.planned_dosage.val;
              data[drg_key].unit = drug_data.data.general.dosage.unit.value ? drug_data.data.general.dosage.unit.value : 'mg';
              data[drg_key].dosage_form = this.d_drugOrderList[drg_key].administration ? this.d_drugOrderList[drg_key].administration : '';
              // data[drg_key].note_list = data_2.note_list ? data_2.note_list : '';
              // data[drg_key].infusion_speed = this.f_calculateInfusionSpeed(data_2);
              // data[drg_key].speed_measure = this.f_calculateSpeedMeasure(data_2); // ml/saat, ml/dk, mg/saat, ünite/saat, ünite/dk
              if (drug_data.data.general.fluid && drug_data.data.general.fluid.val && drug_data.data.general.fluid.val !== '') {
                data[drg_key].fluid = {};
                data[drg_key].fluid.brands = this.d_drugList[drug_data.data.general.fluid.val.value].brands;
                data[drg_key].fluid.selected_brand = '';
                data[drg_key].fluid.pharmacy_status = 1;
                data[drg_key].fluid.barcode_list = this.d_drugOrderList[drg_key].fluid.barcode_list;
                data[drg_key].fluid.brand_count = this.d_drugOrderList[drg_key].fluid.brand_count;
                data[drg_key].fluid.name = drug_data.data.general.fluid.val.label;
                data[drg_key].fluid.fluid_value = drug_data.data.general.fluid_volume.val;
                // data[drg_key].fluid.infusion_speed = this.f_calculateInfusionSpeed(data_2);
                // data[drg_key].fluid.speed_measure = this.f_calculateSpeedMeasure(data_2); // ml/saat, ml/dk, mg/saat, ünite/saat, ünite/dk
              }
              if (data[drg_key].drug_mix) {
                for (let drg_mix_key in data[drg_key].drug_mix) {
                  let drg_mix_data = tmp_wdmr_list[drg_mix_key];
                  data[drg_key]['drug_mix'][drg_mix_key].brands = this.d_drugList[drg_mix_data.data.general.drug.val.value].brands;
                  data[drg_key]['drug_mix'][drg_mix_key].selected_brand = '';
                  data[drg_key]['drug_mix'][drg_mix_key].name = drg_mix_data.data.general.drug.val.label;
                  data[drg_key]['drug_mix'][drg_mix_key].pharmacy_status = 1;
                  data[drg_key]['drug_mix'][drg_mix_key].barcode_list = this.d_drugOrderList[drg_key]['drug_mix'][drg_mix_key].barcode_list;
                  data[drg_key]['drug_mix'][drg_mix_key].brand_count = this.d_drugOrderList[drg_key]['drug_mix'][drg_mix_key].brand_count;
                  data[drg_key]['drug_mix'][drg_mix_key].planned_dosage = drg_mix_data.data.general.planned_dosage.val;
                  data[drg_key]['drug_mix'][drg_mix_key].dosage = drg_mix_data.data.general.planned_dosage.val;
                  data[drg_key]['drug_mix'][drg_mix_key].unit = drg_mix_data.data.general.dosage.unit.value ? drg_mix_data.data.general.dosage.unit.value : 'mg';
                }
              }

              // before after during
              for (let i in this.types) {
                for (let prem_key in data[drg_key][this.types[i]]) {
                  let prem_data = tmp_wdmr_list[prem_key];
                  data[drg_key][this.types[i]][prem_key].name = prem_data.data.general.drug.val.label;
                  data[drg_key][this.types[i]][prem_key].selected_brand = '';
                  data[drg_key][this.types[i]][prem_key].barcode_list = this.d_drugOrderList[drg_key][this.types[i]][prem_key].barcode_list;
                  data[drg_key][this.types[i]][prem_key].brand_count = this.d_drugOrderList[drg_key][this.types[i]][prem_key].brand_count;
                  data[drg_key][this.types[i]][prem_key].brands = this.d_drugList[prem_data.data.general.drug.val.value].brands;
                  data[drg_key][this.types[i]][prem_key].frequency = prem_data.data.general.frequency ? prem_data.data.general.frequency.val.value : '';
                  data[drg_key][this.types[i]][prem_key].duration = prem_data.data.general.duration ? prem_data.data.general.duration.val : '';
                  data[drg_key][this.types[i]][prem_key].planned_dosage = prem_data.data.general.planned_dosage.val;
                  data[drg_key][this.types[i]][prem_key].note_list = prem_data.note_list ? prem_data.note_list : '';
                  data[drg_key][this.types[i]][prem_key].unit = prem_data.data.general.dosage.unit.value ? prem_data.data.general.dosage.unit.value : 'mg';
                  data[drg_key][this.types[i]][prem_key].dosage_form = this.d_drugOrderList[drg_key][this.types[i]][prem_key].administration ? this.d_drugOrderList[drg_key][this.types[i]][prem_key].administration : '';
                  data[drg_key][this.types[i]][prem_key].dosage = prem_data.data.general.planned_dosage.val;
                  if (prem_data.data.general.fluid) {
                    data[drg_key][this.types[i]][prem_key].fluid = {};
                    data[drg_key][this.types[i]][prem_key].fluid.brands = this.d_drugList[prem_data.data.general.fluid.val.value].brands;
                    data[drg_key][this.types[i]][prem_key].fluid.selected_brand = '';
                    data[drg_key][this.types[i]][prem_key].fluid.pharmacy_status = 1;
                    data[drg_key][this.types[i]][prem_key].fluid.barcode_list = this.d_drugOrderList[drg_key][this.types[i]][prem_key].fluid.barcode_list;
                    data[drg_key][this.types[i]][prem_key].fluid.brand_count = this.d_drugOrderList[drg_key][this.types[i]][prem_key].fluid.brand_count;
                    data[drg_key][this.types[i]][prem_key].fluid.name = prem_data.data.general.fluid.val.label;
                    data[drg_key][this.types[i]][prem_key].fluid.fluid_value = prem_data.data.general.fluid_volume.val;
                  }
                  if (data[drg_key][this.types[i]][prem_key].drug_mix) {
                    for (let pr_mix_key in data[drg_key][this.types[i]][prem_key].drug_mix) {
                      let pr_mix_data = tmp_wdmr_list[pr_mix_key];
                      data[drg_key][this.types[i]][prem_key].drug_mix[pr_mix_key].brands = this.d_drugList[pr_mix_data.data.general.drug.val.value].brands;
                      data[drg_key][this.types[i]][prem_key].drug_mix[pr_mix_key].selected_brand = '';
                      data[drg_key][this.types[i]][prem_key].drug_mix[pr_mix_key].name = pr_mix_data.data.general.drug.val.label;
                      data[drg_key][this.types[i]][prem_key].drug_mix[pr_mix_key].pharmacy_status = 1;
                      data[drg_key][this.types[i]][prem_key].drug_mix[pr_mix_key].barcode_list = this.d_drugOrderList[drg_key][this.types[i]][prem_key].drug_mix[pr_mix_key].barcode_list;
                      data[drg_key][this.types[i]][prem_key].drug_mix[pr_mix_key].brand_count = this.d_drugOrderList[drg_key][this.types[i]][prem_key].drug_mix[pr_mix_key].brand_count;
                      data[drg_key][this.types[i]][prem_key].drug_mix[pr_mix_key].planned_dosage = pr_mix_data.data.general.planned_dosage.val;
                      data[drg_key][this.types[i]][prem_key].drug_mix[pr_mix_key].dosage = pr_mix_data.data.general.planned_dosage.val;
                      data[drg_key][this.types[i]][prem_key].drug_mix[pr_mix_key].unit = pr_mix_data.data.general.dosage.unit.value ? pr_mix_data.data.general.dosage.unit.value : 'mg';
                    }
                  }
                }
              }
            }
            this.treatment_order_data_save[dy_no] = {};
            this.treatment_order_data_save[dy_no] = JSON.parse(JSON.stringify(data));
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            this.d_showNurseModal = true;
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            alert('error: ', resp.data.message);
          }
        });
    },
    f_checkLastAnormalLab: function (button_option) {
      try {
        let x = this.patient_data.last_laboratory;
        if (x && Object.keys(x).length > 0) {
          for (let lab_ind in x) {
            if (this.f_lookForAnormalTest(x[lab_ind]) && DateFunctions.day_dif(x[lab_ind]['date'], 'today') <= 30) {
              return true;
            }
          }
        }
        return false;
      } catch (err) {
        return false;
      }
    },
    f_lookForAnormalTest: function (test) {
      if (test.val) {
        if (test.clb !== undefined && test.clb !== '' && test.clb !== null) {
          if (test.val < test.clb) {
            return true;
          }
        }
        if (test.cub !== undefined && test.cub !== '' && test.cub !== null) {
          if (test.val > test.cub) {
            return true;
          }
        }
        if (test.ub !== undefined && test.ub !== '' && test.ub !== null) {
          if (test.val > test.ub) {
            return true;
          }
        }
        if (test.lb !== undefined && test.lb !== '' && test.lb !== null) {
          if (test.val < test.lb) {
            return true;
          }
        }
        return false;
      }
    },
    f_showLastLaboratoryAnormal: function (button_option) {
      if (this.patient_data.last_laboratory && Object.keys(this.patient_data.last_laboratory).length > 0) {
        this.d_clickedButton = button_option;
        this.d_showPatientLastLaboratoryAnormal = true;
      } else {
        alert('Laboratuar testi bulunmamaktadır');
      }
    },
    f_changeStatus: function (button_option) {
      // console.log(button_option);
      if (button_option.status.value === 'nurse_0') {
        this.f_brandSelectionOperations();
      } else if (['doctor_0', 'doctor_1', 'doctor_2', 'doctor_8'].indexOf(button_option.status.value) !== -1) {
        // console.log('986');
        let anormal_lab = this.f_checkLastAnormalLab(button_option);
        // console.log(anormal_lab);
        if (anormal_lab) {
          this.f_showLastLaboratoryAnormal(button_option);
        } else {
          this.f_changeStatusContinue(button_option);
        }
      } else if (['doctor_12', 'doctor_13'].indexOf(button_option.status.value) !== -1) {
        this.f_approveWithChange(button_option);
      } else {
        this.f_changeStatusContinue(button_option);
      }
    },
    f_approveWithChange: function (button_option) {
      this.d_approveWithChangeData = {};
      this.d_approveWithChangeData.patient_id = this.patient_data.id;
      this.d_approveWithChangeData.new_status = button_option.status;
      this.d_approveWithChangeData.wdmr_key_list = [];
      this.d_approveWithChangeData.wdmr_data_obj = {};


      if (this.p_wdmr_data_ind >= 0) {
        let wdmr_data = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[this.p_wdmr_data_ind];
        let wdmr_key = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[this.p_wdmr_data_ind].type + '_' + this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[this.p_wdmr_data_ind].id;
        this.d_approveWithChangeData.wdmr_key_list.push(wdmr_key);
        this.d_approveWithChangeData.wdmr_data_obj[wdmr_key] = wdmr_data;
      } else {
        for (let i in this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list) {
          let wdmr_status = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].data.status.status_log.list[this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].data.status.status_log.list.length - 1].status_log_status.val.value;
          let wdmr_data = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i];
          let wdmr_key = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].type + '_' + this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].id;
          if (['doctor_6', 'doctor_14'].indexOf(wdmr_status) === -1) {
            this.d_approveWithChangeData.wdmr_key_list.push(wdmr_key);
            this.d_approveWithChangeData.wdmr_data_obj[wdmr_key] = wdmr_data;
          }
        }
      }
      this.d_selectedWdmr11Key = '';
      this.d_showApproveWithChangeModal = true;
    },
    f_changeStatusContinue: function (button_option) {
      this.d_showPatientLastLaboratoryAnormal = false;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İşleminiz gerçekleştiriliyor' } });
      let data = {};
      data.patient_id = this.patient_data.id;
      data.wdmr_key_list = [];
      if (this.p_wdmr_data_ind >= 0) {
        data.wdmr_key_list.push(this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[this.p_wdmr_data_ind].type + '_' + this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[this.p_wdmr_data_ind].id);
      } else {
        for (let i in this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list) {
          let wdmr_status = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].data.status.status_log.list[this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].data.status.status_log.list.length - 1].status_log_status.val.value;
          if (['doctor_6', 'doctor_14'].indexOf(wdmr_status) === -1) {
            data.wdmr_key_list.push(this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].type + '_' + this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].id);
          }
        }
      }
      data.new_status = button_option.status;

      if (['doctor_3', 'doctor_4', 'doctor_7', 'doctor_9', 'administration_0', 'administration_1'].indexOf(button_option.status.value) !== -1) {
        if (this.patient_data.hospitals && this.patient_data.hospitals[this.hospital_data.hospital_group] && this.patient_data.hospitals[this.hospital_data.hospital_group][this.hospital_data.hospital_id] && this.patient_data.hospitals[this.hospital_data.hospital_group][this.hospital_data.hospital_id].webservice === 1) {
          data.webservice = this.createwebserviceText(data, button_option);
        } else {
          this.f_changeStatusFinal(data);
        }
      } else {
        this.f_changeStatusFinal(data);
      }
    },
    f_changeStatusFinal: function (service_data) {
      this.d_showApproveWithChangeModal = false;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İşleminiz gerçekleştiriliyor' } });
      DrugService.change_status(service_data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.$forceUpdate();
            // if (this.p_wdmr_data_ind >= 0) {
            //   this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[this.p_wdmr_data_ind] = resp.data.result.wdmr11or12list[0];
            // } else {
            //   this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list = resp.data.result.wdmr11or12list;
            // }
            this.f_motherChildren('update_naming');
            this.f_motherChildren('forceUpdate');
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            alert('error: ', resp.data.message);
          }
        });
    },
    createwebserviceText: function (service_data, button_option) {
      PatientService.get_patient_diagnosis_value_list(this.patient_data.id)
        .then(resp => {
          service_data.webservice = { 'protocol_list': [], 'hospitalGroup': this.hospital_data.hospital_group, 'hospitalCode': this.hospital_data.hospital_id, 'sending_json_data': {}, 'sending': 0, 'patientNo': '' };

          if (this.WebserviceProtocolInsuranceData[this.hospital_data.hospital_group]) {
            for (let y in this.WebserviceProtocolInsuranceData[this.hospital_data.hospital_group]['parameter_order']) {
              let hims_param = this.WebserviceProtocolInsuranceData[this.hospital_data.hospital_group]['parameter_order'][y];
              service_data.webservice.sending_json_data[hims_param] = '';
            }
          }
          service_data.webservice.sending_json_data.symptom_start_date = new Date().toISOString();
          service_data.webservice.sending_json_data.pre_diagnosis = resp.data.result.join(', ');
          service_data.webservice.sending_json_data.main_diagnosis = resp.data.result.join(', ');
          service_data.webservice.patientNo = this.patient_data.hospitals[this.hospital_data.hospital_group][this.hospital_data.hospital_id].data.id;
          service_data.webservice.patient_id = this.patient_data.id;
          service_data.webservice.day_list = [];
          service_data.webservice.day_list.push(this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].date);

          let str = '';
          let drug_operation = button_option.status.label;
          if (this.p_wdmr_data_ind >= 0) {
            str += this.f_createStrFromWdmr(this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[this.p_wdmr_data_ind]);
          } else {
            for (let i in this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list) {
              str += this.f_createStrFromWdmr(this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i]);
            }
          }

          if (str) {
            service_data.webservice.sending = 1;
            str = str + '> [ ' + drug_operation + ' ]';
            service_data.webservice.sending_json_data.care_plan = str;
          }
          this.f_changeStatusFinal(service_data);
        });
    },
    f_createStrFromWdmr: function (wdmr_data) {
      let str = '';

      if (wdmr_data.data.general.drug_prem_mix.val.value === 'drug') {
        let cycle_info = false;
        let drug_label = false;
        let drug_planned_dosage = false;
        let drug_unit = false;
        let duration = false;
        let administration_type = false;
        let drug_day = false;

        try {
          cycle_info = wdmr_data.data.general.repeat.val.toString();
        } catch {
          cycle_info = false;
        }
        try {
          drug_label = wdmr_data.data.general.drug.val.label;
        } catch {
          drug_label = false;
        }
        try {
          drug_planned_dosage = wdmr_data.data.general.planned_dosage.val;
        } catch {
          drug_planned_dosage = false;
        }
        try {
          drug_unit = show_unit_calculate(wdmr_data.data.general.dosage.unit.value);
          drug_unit = drug_unit.replace('  ', '');
        } catch {
          drug_unit = false;
        }
        try {
          duration = wdmr_data.data.general.duration.val;
        } catch {
          duration = false;
        }
        try {
          administration_type = wdmr_data.data.general.administration_type.val;
        } catch {
          administration_type = false;
        }
        try {
          drug_day = Object.keys(wdmr_data.data.general.selected_days)[0].toString();
        } catch {
          drug_day = false;
        }

        str += drug_label + ' ' + drug_planned_dosage + drug_unit + ' ';
        if (administration_type['value'].toString() === '26') {
          str += '0-5 dakikada ';
        } else {
          if (duration && duration > 0) {
            if (administration_type['value'].toString() === '13') {
              str += duration.toString() + ' günde ';
            } else {
              str += duration.toString() + ' dakikada ';
            }
          }
        }
        if (administration_type && administration_type['label']) {
          str += administration_type['label'].toLocaleLowerCase('tr').toString() + ' ';
        }
        str += cycle_info + '.siklus ' + drug_day + '.gün, ';
      }
      return str;
    },
    f_buttonPermControl: function (button_option) {
      // wca_start
      // we are checking if a button is viewable or not
      // future days --> only ['doctor_0', 'doctor_1', 'doctor_2', 'doctor_6', 'doctor_8']
      // future days with ['doctor_0', 'doctor_1', 'doctor_2', 'doctor_6', 'doctor_8'] --> only ['doctor_5']
      // 
      // wca_end
      let perm_match_data = { 'no_operation_yet': 'doctor', 'doctor_0': 'doctor_nurse', 'doctor_1': 'doctor_preparation', 'doctor_2': 'doctor_preparation', 'doctor_3': 'doctor', 'doctor_4': 'doctor', 'doctor_5': 'doctor', 'doctor_6': 'doctor', 'doctor_7': 'doctor', 'doctor_8': 'doctor', 'doctor_9': 'doctor', 'doctor_10': 'doctor', 'doctor_11': 'doctor', 'doctor_12': 'doctor', 'doctor_13': 'doctor', 'doctor_14': 'doctor', 'doctor_15': 'doctor', 'doctor_16': 'doctor', 'nurse_0': 'nurse_pharmacy', 'nurse_1': 'doctor_nurse', 'pharmacy_0': 'pharmacy_preparation', 'pharmacy_1': 'nurse_pharmacy', 'pharmacy_2': 'nurse_pharmacy', 'pharmacy_3': 'nurse_pharmacy', 'pharmacy_4': 'nurse_pharmacy', 'preparation_0': 'preparation_match', 'preparation_1': 'preparation_match', 'preparation_2': 'preparation_match', 'match_0': 'match_administration', 'match_1': 'preparation_match', 'administration_0': 'administration', 'administration_1': 'administration', 'administration_2': 'match_administration', 'administration_3': 'match_administration', 'administration_4': 'match_administration' };
      let button_value = button_option.status.value;
      let button_perm = button_option.perm;
      if (this.user.permissions_result.indexOf(button_perm) === -1) {
        return false;
      }
      let status_list = [];
      let wdmr_day = false;
      for (let i in this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list) {
        if (this.p_wdmr_data_ind >= 0 && this.p_wdmr_data_ind !== parseInt(i)) {
          continue;
        }
        if (button_option.model_perm && button_option.model_perm.length > 0 & button_option.model_perm.indexOf(this.p_componentType) === -1) {
          return false;
        }
        let wdmr_status = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].data.status.status_log.list[this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].data.status.status_log.list.length - 1].status_log_status.val.value;
        if (['doctor_6', 'doctor_14'].indexOf(wdmr_status) === -1 && status_list.indexOf(perm_match_data[wdmr_status]) === -1) {
          status_list.push(perm_match_data[wdmr_status]);
          if (status_list.length > 1) {
            return false;
          }
        }
        if (!wdmr_day) {
          wdmr_day = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].date.split('T')[0];
        }
        if (wdmr_status === button_value) {
          return false;
        }
        if (DateFunctions.day_dif(wdmr_day, 'today') > 0) {
          if (['doctor_3', 'doctor_4', 'doctor_5', 'doctor_6', 'doctor_7', 'doctor_9', 'doctor_10', 'doctor_11', 'doctor_12', 'doctor_13', 'doctor_14', 'doctor_15', 'doctor_16'].indexOf(button_value) === -1) {
            return false;
          }
        } else if (DateFunctions.day_dif(wdmr_day, 'today') < 0) {
          if (['doctor_0', 'doctor_1', 'doctor_2', 'doctor_6', 'doctor_8'].indexOf(button_value) === -1 && (['doctor_0', 'doctor_1', 'doctor_2', 'doctor_6', 'doctor_8'].indexOf(wdmr_status) === -1 || (['doctor_0', 'doctor_1', 'doctor_2', 'doctor_6', 'doctor_8'].indexOf(wdmr_status) !== -1 && ['doctor_5'].indexOf(button_value) === -1))) {
            return false;
          }
        }
        // if (this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].is_eligable_nurse === 'no') {
        //   if (['doctor_0', 'doctor_1', 'doctor_2', 'doctor_3', 'doctor_4', 'doctor_5', 'doctor_6', 'doctor_7', 'doctor_8', 'doctor_9'].indexOf(button_value) === -1) {
        //     return false;
        //   }
        // }
        if (['doctor_6', 'doctor_14'].indexOf(wdmr_status) === -1 && button_option.status_perm.indexOf(wdmr_status) === -1) {
          return false;
        }
        if (button_option.needed_drug && button_option.needed_drug.length > 0) {
          let wdmr_drug = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i].data.general.drug.val.value;
          if (button_option.needed_drug.indexOf(wdmr_drug) === -1) {
            return false;
          }
        }
      }
      return true;
    },
    f_motherChildren: function (op) {
      if (this.mother_children[op] === 0) {
        this.mother_children[op] = 1;
      } else {
        this.mother_children[op] = 0;
      }
    }
  },
  watch: {
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_completeDaysApproval' && this.days_approval_data.days) {
          if (this.StoreModal.data.confirm) {
            // console.log('store modal', this.StoreModal.show);
            this.f_completeDaysApproval();
          }
        }
      }
    }
  },
  components: {
    LastLaboratory,
    Modal,
    PatientDrugBrandSelection,
    PharmacyWidget,
    WisdomDataModal
  }
};

</script>

<style>


</style>

