<template>
  <div>
    <b-card no-body>
      <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
        <b-button variant="success" @click="d_showPatientWdmr = true">Hasta Takip Sayfası</b-button>
        <b-button variant="success" class="pull-right" @click="f_addNewWdm()">Bu Türden Veri Kaydet</b-button>
      </b-card-header>
      <b-row style="margin: 0px;">
        <b-col sm="12">
          <template v-for="wdm in d_patientDataList">
            <b-button variant="success" @click="f_wisdomDataEdit(wdm)">{{ wdm.date.split('T')[0] + ' tarihli --> ' + wdm.label }}</b-button>
            <br>
          </template>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="d_showWisdomDataModel" @close="d_showWisdomDataModel = false" :width="'1500'">
      <h3 slot="header">{{ option_data[data_type].name.label }}</h3>
      <div slot="body">
        <wisdom-data-modal :external_patient_data="external_patient_data" ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <b-button variant="success" v-on:click="f_saveWdm()">{{ $t('wdm16.4061') }}</b-button>
        <b-button variant="success" @click="d_showWisdomDataModel = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_showPatientWdmr" @close="d_showPatientWdmr = false" :width="'1500'">
      <h3 slot="header"> {{ p_patientOrganizationTaskData.data.general.selected_patient_name.val }} - Hasta Takip</h3>
      <div slot="body">
        <patient-wdmr :patient="external_patient_data" :p_organizationTask="p_patientOrganizationTaskData"></patient-wdmr>
      </div>
      <div slot="footer">
        <b-button variant="success" @click="d_showPatientWdmr = false">{{ $t('wdm16.4053') }}</b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import WisdomDataService from "@/services/wisdom_data";
import PatientService from '@/services/patient';
import GlobalService from '@/services/global';
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import moment from "moment";
import {
  default as PatientWdmr
} from "@/components/widgets/PatientWdmr";

export default {
  name: "PatientOrganizationTaskData",
  computed: mapGetters({
    lang: "lang",
    help: 'help'
  }),
  props: {
    p_patientOrganizationTaskData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      d_selectedAnatomySchemaList: [],
      user: {},
      d_patientDataList: [],
      option_data: {},
      wisdom_data: {},
      external_patient_data: {},
      data_type: '',
      d_showPatientWdmr: false,
      d_showWisdomDataModel: false
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.f_getExternalPatientData();
    this.f_getPatientOrganizationTaskData();
    this.data_type = this.p_patientOrganizationTaskData.data.general.wdm_type.val.value;
    this.f_fillOptionData();
  },
  mounted () {},
  methods: {
    f_fillOptionData: function () {
      let wdm_version_key = this.p_patientOrganizationTaskData.data.general.wdm_version.val.value;
      let wdm_owner_type = this.p_patientOrganizationTaskData.data.general.wdm_group.val.value;
      let department_value = this.p_patientOrganizationTaskData.data.general.selected_hospital_department.val.value;
      let hospital_group = this.p_patientOrganizationTaskData.data.general.selected_patient_hospital_group.val;
      let hospital_id = this.p_patientOrganizationTaskData.data.general.selected_patient_hospital_id.val;
      let patient_id = this.p_patientOrganizationTaskData.data.general.selected_patient_id.val;

      let service_data = { 'key': '', 'bucket': '' };
      if (wdm_owner_type === 'wisdom') {
        service_data.bucket = 'option'
        service_data.key = wdm_version_key;
      } else if (wdm_owner_type === 'hospital') {
        service_data.bucket = 'hospital'
        service_data.key = wdm_version_key;
      } else if (wdm_owner_type === 'user') {
        service_data.bucket = 'hospital'
        service_data.key = wdm_version_key;
      }
      GlobalService.get_one_cb_document(service_data)
        .then(resp => {
          this.option_data[this.data_type] = resp.data.result;
        });
    },
    f_addNewWdm: function () {
      let pre_data = { 'id': 'new', 'data': {}, 'type': this.data_type, 'reference_wdm': {}, 'parent': this.data_type};
      pre_data['reference_wdm']['version'] = this.option_data[this.data_type]['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data[this.data_type]['owner_type'];
      if (this.option_data[this.data_type]['owner_type'] === 'wisdom') {
        pre_data['reference_wdm']['key'] = 'wdm_wisdom_' + this.data_type + '_v' + this.option_data[this.data_type].version;
      }
      for (let i in this.option_data[this.data_type].param_group.param_order) {
        pre_data['data'][this.option_data[this.data_type].param_group.param_order[i]] = {};
      }
      this.wisdom_data = pre_data;
      this.d_showWisdomDataModel = true;
    },
    f_saveWdmContinue: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Data kaydı yapılıyor.' } });
      
      let wdm_version = this.p_patientOrganizationTaskData.data.general.wdm_version.val.label;
      let wdm_version_key = this.p_patientOrganizationTaskData.data.general.wdm_version.val.value;
      let wdm_owner_type = this.p_patientOrganizationTaskData.data.general.wdm_group.val.value;
      let wdm_type_label = this.p_patientOrganizationTaskData.data.general.wdm_type.val.label;
      let wdm_type_value = this.p_patientOrganizationTaskData.data.general.wdm_type.val.value;
      let department_value = this.p_patientOrganizationTaskData.data.general.selected_hospital_department.val.value;
      let hospital_group = this.p_patientOrganizationTaskData.data.general.selected_patient_hospital_group.val;
      let hospital_id = this.p_patientOrganizationTaskData.data.general.selected_patient_hospital_id.val;
      let patient_id = this.p_patientOrganizationTaskData.data.general.selected_patient_id.val;

      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'delete']);
      let wdmr_name = return_data.name;
      this.wisdom_data['label'] = wdmr_name;
      this.wisdom_data['hospital_group'] = hospital_group;
      this.wisdom_data['hospital_id'] = hospital_id;
      this.wisdom_data['department'] = department_value;
      this.wisdom_data['reference_wdm'] = {
        'owner_type': wdm_owner_type,
        'version': wdm_version,
        'key': wdm_version_key
      };
      this.wisdom_data['organization'] = { 'id': this.p_patientOrganizationTaskData.organization_data.id, 'label': this.p_patientOrganizationTaskData.organization_data.name, 'task_id': this.p_patientOrganizationTaskData.id };
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      let d_uuid = this.wisdom_data.id ? this.wisdom_data.id : '';
      let data = {
        'data': this.wisdom_data
      };
      WisdomDataService.save_wdm(this.p_patientOrganizationTaskData.data.general.selected_patient_id.val, d_uuid, data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          this.d_showWisdomDataModel = false;
          this.f_getPatientOrganizationTaskData();
        });
    },
    f_saveWdm: function () {
      // console.log(this.wisdom_data);
      if (this.option_data[this.wisdom_data.type].date_ability && this.option_data[this.wisdom_data.type].date_ability === 'yes') {
        if (!this.wisdom_data.date) {
          let modal_data = { 'type': 'alert', 'text': "Lütfen tarih giriniz", 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
      }
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['require', 'name']);
      // console.log('return_data: ', return_data);
      // return;
      let wdmr_name = return_data.name;
      this.wisdom_data['label'] = wdmr_name;
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      // Bir wdmr kaydı yapabilmek için en az bir parametre dolu olması gerekiyor.
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      // wdmr kaydı sırasında tüm wdm parametreleri için require kontrolü yapıyor. İlgili parametrede val değeri oluştuysa zaten oraya girilmiş oluyor. İçine girilipte (wisdom-param componenti) dolu olmayan tüm parametreleri kontrol ediyoruz. Örneğin er ihd testini ekledi. Fakat pozitif / negatif değerini doldurmadıysa geçemiyor.
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      this.f_saveWdmContinue();
    },
    f_wisdomDataEdit (wdm) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Döküman hazırlanıyor.' } });
      GlobalService.get_one_cb_document({ 'key': wdm.value, 'bucket': 'wisdom' })
        .then(resp => {
          this.wisdom_data = JSON.parse(JSON.stringify(resp.data.result));
          let service_data = { 'key': '', 'bucket': '' };
          if (this.wisdom_data.reference_wdm.owner_type === 'wisdom') {
            service_data.bucket = 'option'
            service_data.key = 'wdm_wisdom_' + this.wisdom_data.type + '_v' + this.wisdom_data.reference_wdm.version;
          } else if (this.wisdom_data.reference_wdm.owner_type === 'hospital') {
            service_data.bucket = 'hospital'
            service_data.key = 'wdm_hospital_' + this.wisdom_data.type + '_' + this.wisdom_data.hospital_group + '_' + this.wisdom_data.hospital_id + '_v' + this.wisdom_data.reference_wdm.version;
          } else if (this.wisdom_data.reference_wdm.owner_type === 'user') {
            service_data.bucket = 'hospital'
            service_data.key = 'wdm_user_' + this.wisdom_data.type + '_' + '_v' + this.wisdom_data.reference_wdm.version;
          }
          GlobalService.get_one_cb_document(service_data)
            .then(resp => {
              this.option_data[this.data_type] = resp.data.result;
              this.d_showWisdomDataModel = true;
            });
        });
    },
    f_getExternalPatientData: function () {
      let data = {};
      data.patient_id = this.p_patientOrganizationTaskData.data.general.selected_patient_id.val;
      data.hospital_group = this.p_patientOrganizationTaskData.data.general.selected_patient_hospital_group.val;
      data.hospital_id = this.p_patientOrganizationTaskData.data.general.selected_patient_hospital_id.val;
      PatientService.get_organization_task_patient(data)
        .then(resp => {
          this.external_patient_data = JSON.parse(JSON.stringify(resp.data.result));
          this.external_patient_data.selected = true;
        });
    },
    f_getPatientOrganizationTaskData: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Datalar yükleniyor' } });
      let data = {};
      data.patient_id = this.p_patientOrganizationTaskData.data.general.selected_patient_id.val;
      data.organization_id = this.p_patientOrganizationTaskData.organization_data.id;
      data.task_id = this.p_patientOrganizationTaskData.id;
      data.wdm_type = this.p_patientOrganizationTaskData.data.general.wdm_type.val.value;
      PatientService.get_patient_organization_task_data(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (resp.data.status === 'success') {
            this.d_patientDataList = resp.data.result;
          } else {
            this.d_patientDataList = [];
          }
        });
    }
  },
  watch: {},
  components: {
    PatientWdmr,
    WisdomDataModal: () =>
      import ('@/components/widgets/WisdomDataModal'),
    Modal: () =>
      import ('@/components/widgets/Modal')
  }
};

</script>

