<template>
  <div>
    <b-row>
      <b-col cols="10">
        <b-row>
          <b-col cols="4">
            <strong> Protokol bilgisi görünümü </strong>
            <br>
            <div class="radio" style="padding: 10px;">
              <label>
                <input v-model="view_protocol" type="radio" value="protocol_name" style="width: 15px; height: 15px; padding: 3px;"> Protokol adı </label>
              <label>
                <input v-model="view_protocol" type="radio" value="macro" style="width: 15px; height: 15px;"> Makro </label>
              <label>
                <input v-model="view_protocol" type="radio" value="micro" style="width: 15px; height: 15px;"> Mikro </label>
              <label>
                <input v-model="view_protocol" type="radio" value="not_show" style="width: 15px; height: 15px;">Gösterme</label>
            </div>
          </b-col>
          <b-col cols="4">
            <strong> Tedavi görünümü </strong>
            <br>
            <div class="radio" style="padding: 10px;">
              <label>
                <input v-model="view_mode" type="radio" value="macro" style="width: 15px; height: 15px; padding: 3px;"> Makro </label>
              <label>
                <input v-model="view_mode" type="radio" value="normal" style="width: 15px; height: 15px;">Normal </label>
              <label>
                <input v-model="view_mode" type="radio" value="micro" style="width: 15px; height: 15px;">Mikro</label>
            </div>
          </b-col>
          <b-col cols="4">
            <strong> İşlemler görünümü </strong>
            <br>
            <div class="radio" style="padding: 10px;">
              <label>
                <input v-model="show_status" type="radio" value="last" style="width: 15px; height: 15px;"> Son </label>
              <label>
                <input v-model="show_status" type="radio" value="all" style="width: 15px; height: 15px;">Hepsi </label>
              <label>
                <input v-model="show_status" type="radio" value="none" style="width: 15px; height: 15px;"> Gösterme </label>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <div class="col-1">
        <i class="fa fa-print" @click="print" style="font-size:22px;"></i>
      </div>
    </b-row>
    <b-card ref="report_here" header-tag="header">
      <b-row>
        <b-col cols="12">
          <logo-view :header_type="'chemotherapy_protocol_scheme'" :view_mode="'normal'"></logo-view>
        </b-col>
      </b-row>
      <b-row v-if="show_only_patient_data">
        <b-col cols="12">
          <patient-data :patient_data="p_patientData"></patient-data>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <!-- <treatment-protocol-viewer :treatment_data="treatment_data" :view_protocol="view_protocol" :patient_data="patient_data"></treatment-protocol-viewer> -->
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div style="padding-left: 15px; padding-right: 15px; font-size: 12px;">
            <b-card class="card-accent-primary">
              <b-row>
                <b-col cols="12" class="text-right">
                  <strong>Tedaviyi Başlatan:</strong> {{ get_from_all_users(p_createdBy) }}
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" v-if="view_mode ==='macro'">
          <template v-for="date in p_dateList">
            <div>
              <div style="padding-left: 15px; padding-right: 15px;">
                <div>
                  <table style="width: 100%;">
                    <thead>
                      <tr style="background-color: #19b9e952;">
                        <th style="text-align: left;">
                          <b style="padding: 2px;"> {{ calculate_date_of_day_ind(date) }} {{ calculate_drugs_in_the_day(date) }} </b>
                        </th>
                        <th style="width: 20%;"></th>
                      </tr>
                    </thead>
                    <!-- SAMI -->
                    <template v-for="drug, drug_ind in p_drugDateList[date]" style="border: solid 1px black;">
                      <tr :style="'border-bottom: solid 1px black; border-top: solid 1px black;'">
                        <td style="text-align: left; width: 75%;">
                          <strong>{{ drug_ind + 1}} - {{ d_namingData[date][drug_ind].drug }} {{ d_namingData[date][drug_ind].given_dosage !== '' ? d_namingData[date][drug_ind].given_dosage : d_namingData[date][drug_ind].planned_dosage }} {{ d_namingData[date][drug_ind].unit | show_unit_calculate }} {{ d_namingData[date][drug_ind].frequency }}</strong> {{ d_namingData[date][drug_ind].fluid && d_namingData[date][drug_ind].fluid !== '' ? d_namingData[date][drug_ind].fluid + ', ' : '' }} {{ d_namingData[date][drug_ind].fluid_volume && d_namingData[date][drug_ind].fluid_volume !== '' ? 'Hacim:' + d_namingData[date][drug_ind].fluid_volume + ' ml, ' : '' }} {{ d_namingData[date][drug_ind].duration && d_namingData[date][drug_ind].duration !== '' ? d_namingData[date][drug_ind].duration + ' dakika, ' : '' }}{{ d_namingData[date][drug_ind].administration_type && d_namingData[date][drug_ind].administration_type !== '' ? d_namingData[date][drug_ind].administration_type : '' }}
                        </td>
                        <td>
                          <template v-if="d_namingData[date][drug_ind].last_log_status === 'Henüz işlem yapılmadı'">
                            {{ d_namingData[date][drug_ind].last_log_status }}
                          </template>
                          <template v-else>
                            {{ d_namingData[date][drug_ind].last_log_user + ': ' + d_namingData[date][drug_ind].last_log_status }}
                          </template>
                        </td>
                      </tr>
                    </template>
                  </table>
                </div>
              </div>
            </div>
          </template>
        </b-col>
        <b-col cols="12" v-if="view_mode ==='normal'">
          <template v-for="date in p_dateList">
            <div>
              <div style="padding-left: 15px; padding-right: 15px;">
                <div>
                  <table style="width: 100%;">
                    <thead>
                      <tr style="background-color: #19b9e952;">
                        <th style="text-align: left;">
                          <b style="padding: 2px;"> {{ calculate_date_of_day_ind(date) }} {{ calculate_drugs_in_the_day(date) }} </b>
                        </th>
                        <th style="width: 20%;"></th>
                      </tr>
                    </thead>
                    <!-- SAMI -->
                    <template v-for="drug, drug_ind in p_drugDateList[date]" style="border: solid 1px black;">
                      <tr :style="'border-bottom: solid 1px black; border-top: solid 1px black;'">
                        <td style="text-align: left; width: 75%;">
                          <strong>{{ drug_ind + 1}} - {{ d_namingData[date][drug_ind].drug }} {{ d_namingData[date][drug_ind].given_dosage !== '' ? d_namingData[date][drug_ind].given_dosage : d_namingData[date][drug_ind].planned_dosage }} {{ d_namingData[date][drug_ind].unit | show_unit_calculate }} {{ d_namingData[date][drug_ind].frequency }}</strong> {{ d_namingData[date][drug_ind].fluid && d_namingData[date][drug_ind].fluid !== '' ? d_namingData[date][drug_ind].fluid + ', ' : '' }} {{ d_namingData[date][drug_ind].fluid_volume && d_namingData[date][drug_ind].fluid_volume !== '' ? 'Hacim:' + d_namingData[date][drug_ind].fluid_volume + ' ml, ' : '' }} {{ d_namingData[date][drug_ind].duration && d_namingData[date][drug_ind].duration !== '' ? d_namingData[date][drug_ind].duration + ' dakika, ' : '' }}{{ d_namingData[date][drug_ind].administration_type && d_namingData[date][drug_ind].administration_type !== '' ? d_namingData[date][drug_ind].administration_type : '' }}
                        </td>
                        <td>
                          <template v-if="d_namingData[date][drug_ind].last_log_status === 'Henüz işlem yapılmadı'">
                            {{ d_namingData[date][drug_ind].last_log_status }}
                          </template>
                          <template v-else>
                            {{ d_namingData[date][drug_ind].last_log_user + ': ' + d_namingData[date][drug_ind].last_log_status }}
                          </template>
                        </td>
                      </tr>
                    </template>
                  </table>
                </div>
              </div>
            </div>
          </template>
        </b-col>
        <b-col cols="12" v-if="view_mode ==='micro'">
          <template v-for="date in p_dateList">
            <div>
              <div style="padding-left: 15px; padding-right: 15px;">
                <div>
                  <table style="width: 100%;">
                    <thead>
                      <tr style="background-color: #19b9e952;">
                        <th style="text-align: left;">
                          <b style="padding: 2px;"> {{ calculate_date_of_day_ind(date) }} {{ calculate_drugs_in_the_day(date) }} </b>
                        </th>
                        <th style="width: 20%;"></th>
                      </tr>
                    </thead>
                    <template v-for="drug, drug_ind in p_drugDateList[date]" style="border: solid 1px black;">
                      <tr :style="'border-bottom: solid 1px black; border-top: solid 1px black;'">
                        <td style="text-align: left; width: 75%;">
                          <strong>{{ drug_ind + 1}} - {{ d_namingData[date][drug_ind].drug }} {{ d_namingData[date][drug_ind].given_dosage !== '' ? d_namingData[date][drug_ind].given_dosage : d_namingData[date][drug_ind].planned_dosage }} {{ d_namingData[date][drug_ind].unit | show_unit_calculate }} {{ d_namingData[date][drug_ind].frequency }}</strong> {{ d_namingData[date][drug_ind].fluid && d_namingData[date][drug_ind].fluid !== '' ? d_namingData[date][drug_ind].fluid + ', ' : '' }} {{ d_namingData[date][drug_ind].fluid_volume && d_namingData[date][drug_ind].fluid_volume !== '' ? 'Hacim:' + d_namingData[date][drug_ind].fluid_volume + ' ml, ' : '' }} {{ d_namingData[date][drug_ind].duration && d_namingData[date][drug_ind].duration !== '' ? d_namingData[date][drug_ind].duration + ' dakika, ' : '' }}{{ d_namingData[date][drug_ind].administration_type && d_namingData[date][drug_ind].administration_type !== '' ? d_namingData[date][drug_ind].administration_type : '' }}
                        </td>
                        <td>
                          <template v-if="d_namingData[date][drug_ind].last_log_status === 'Henüz işlem yapılmadı'">
                            {{ d_namingData[date][drug_ind].last_log_status }}
                          </template>
                          <template v-else>
                            {{ d_namingData[date][drug_ind].last_log_user + ': ' + d_namingData[date][drug_ind].last_log_status }}
                          </template>
                        </td>
                      </tr>
                    </template>
                  </table>
                </div>
              </div>
            </div>
          </template>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  default as ProtocolViewer
} from '@/components/widgets/ProtocolViewer';
import {
  default as TreatmentProtocolViewer
} from '@/components/widgets/TreatmentProtocolViewer';
import {
  default as LogoView
} from '@/components/widgets/LogoView';
import {
  default as ReportingModalPremedication
} from '@/components/widgets/ReportingModalPremedication';
import {
  default as PatientData
} from '@/components/widgets/PatientData';
import moment from 'moment';

export default {
  name: 'PatientDrugReportingModal',
  mounted () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      lang: 'lang'
    })
  },
  props: {
    p_createdBy: {
      type: String,
      required: true
    },
    p_dateList: {
      type: Array,
      required: false
    },
    p_drugDateList: {
      type: Object,
      required: false
    },
    p_patientData: {
      type: Object,
      required: false
    },
    calculated_days: {
      type: Object,
      required: false
    },
    treatment_data: {
      type: Object,
      required: false
    },
    sort_days_list: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      w_presentation: false,
      singleDoctorOperation: {},
      user: {},
      all_users: [],
      view_mode: 'micro',
      show_only_patient_data: true,
      show_drug_details_with_table_view: false,
      show_status: 'last',
      show_protocol_schema: false,
      view_protocol: 'protocol_name',
      d_namingData: { 'cycle': '', 'day': '', 'drug': '', 'given_dosage': '', 'planned_dosage': '', 'unit': '', 'last_log_user': '', 'last_log_status': '', 'log_list': [], 'note_list': [], 'fluid': '', 'fluid_volume': '', 'frequency': '', 'administration_type': '', 'duration': '', 'wait_after_administration': '' }
    };
  },
  created () {
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.all_users = JSON.parse(localStorage.getItem('all_users'));
    this.checkSingleDoctorOperation();
    this.f_createNamingData();
  },
  methods: {
    f_createNamingData: function () {
      for (let i in this.p_dateList) {
        if (!this.d_namingData[this.p_dateList[i]]) {
          this.d_namingData[this.p_dateList[i]] = [];
        }
        // console.log(this.p_drugDateList);
        for (let k in this.p_drugDateList[this.p_dateList[i]]) {
          let drug_data = this.p_drugDateList[this.p_dateList[i]][k];
          let append_data = {}
          try {
            append_data['cycle'] = drug_data.data.general.repeat.val;
          } catch (err) {}

          try {
            append_data['day'] = Object.keys(drug_data.data.general.selected_days)[0];
          } catch (err) {}

          try {
            append_data['drug'] = drug_data.data.general.drug.val.label;
          } catch (err) {}

          try {
            append_data['given_dosage'] = drug_data.data.general.given_dosage.val;
          } catch (err) {}

          try {
            append_data['planned_dosage'] = drug_data.data.general.planned_dosage.val;
          } catch (err) {}

          try {
            append_data['unit'] = drug_data.data.general.dosage.unit.value;
          } catch (err) {}

          try {
            append_data['last_log_user'] = drug_data.data.status.status_log.list[drug_data.data.status.status_log.list.length - 1].status_log_user.val.label;
          } catch (err) {}

          try {
            append_data['last_log_status'] = drug_data.data.status.status_log.list[drug_data.data.status.status_log.list.length - 1].status_log_status.val.label;
          } catch (err) {
            append_data['last_log_status'] = ' Henüz İşlem Yapılmadı ';
          }

          try {
            append_data['fluid'] = drug_data.data.general.fluid.val.label;
          } catch (err) {}

          try {
            append_data['fluid_volume'] = drug_data.data.general.fluid_volume.val;
          } catch (err) {}

          try {
            append_data['frequency'] = drug_data.data.general.frequency.val.label;
          } catch (err) {}

          try {
            append_data['administration_type'] = drug_data.data.general.administration_type.val.label;
          } catch (err) {}

          try {
            append_data['duration'] = drug_data.data.general.duration.val;
          } catch (err) {}

          try {
            append_data['wait_after_administration'] = drug_data.data.general.wait_after_administration.val;
          } catch (err) {}

          try {
            for (let i in drug_data.data.status.status_log.list) {
              let x = drug_data.data.status.status_log.list[i];
              append_data['log_list'].push({ 'status': x.status_log_status.val.label, 'user': x.status_log_user.val.label, 'date': x.status_log_date.val });
            }
          } catch (err) {}
          this.d_namingData[this.p_dateList[i]].push(append_data);
        }
      }
      this.$forceUpdate();
    },
    f_calculateDrugStatusStyle: function (drug_key, cycle_index, day_index) {
      if (this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.approvals.status === 'doctor_6') {
        return 'text-decoration:line-through;';
      } else {
        return '';
      }
    },
    checkSingleDoctorOperation: function () {
      // TODO 
      for (let i in this.p_dateList) {
        this.singleDoctorOperation[this.p_dateList[i]] = [this.p_createdBy];
      }
      // let prem_types = ['before', 'during', 'after']
      // for (let tmp_day_ind in this.calculated_days) {
      //   this.singleDoctorOperation[tmp_day_ind] = [];
      //   for (let drug_key in this.calculated_days[tmp_day_ind]) {
      //     let cycle_index = this.calculated_days[tmp_day_ind][drug_key].cycle_index;
      //     let day_index = this.calculated_days[tmp_day_ind][drug_key].day_index;
      //     let drug_data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data;
      //     let doctorApprovalData = drug_data.approvals.doctor.data;
      //     if (doctorApprovalData.length > 0 && !this.singleDoctorOperation[tmp_day_ind].includes(doctorApprovalData[doctorApprovalData.length - 1].user)) {
      //       this.singleDoctorOperation[tmp_day_ind].push(doctorApprovalData[doctorApprovalData.length - 1].user);
      //     }
      //     for (let i in prem_types) {
      //       for (let tmp_prem_ind in this.calculated_days[tmp_day_ind][drug_key][prem_types[i]]) {
      //         let prem_index = this.calculated_days[tmp_day_ind][drug_key][prem_types[i]][tmp_prem_ind].prem_index;
      //         let approvals_index = this.calculated_days[tmp_day_ind][drug_key][prem_types[i]][tmp_prem_ind].approvals_index;
      //         let premDoctorApprovalData = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[prem_types[i]][prem_index].approvals[approvals_index].doctor.data;
      //         if (premDoctorApprovalData.length > 0 && !this.singleDoctorOperation[tmp_day_ind].includes(premDoctorApprovalData[premDoctorApprovalData.length - 1].user)) {
      //           this.singleDoctorOperation[tmp_day_ind].push(premDoctorApprovalData[premDoctorApprovalData.length - 1].user);
      //         }
      //       }
      //     }
      //   }
      // }
    },
    getLastOperatorDoctor: function (approvalsData) {
      let totalDoctorOperation = approvalsData.doctor.data.length;
      if (totalDoctorOperation > 0) {
        return this.get_from_all_users(approvalsData.doctor.data[totalDoctorOperation - 1].user);
      }
      return '';
    },
    getLastOperatorUser: function (approvalsData) {
      if (approvalsData.status === 'no_operation_yet') {
        return '';
      }
      let lastStatusType = approvalsData.status.split('_')[0];
      let totalLastOperation = approvalsData[lastStatusType].data.length;
      return this.get_from_all_users(approvalsData[lastStatusType].data[totalLastOperation - 1].user) + ': ';
    },
    get_from_all_users: function (username) {
      for (let i in this.all_users) {
        if (this.all_users[i].username === username) {
          return this.all_users[i].group + ' ' + this.all_users[i].first_name + ' ' + this.all_users[i].last_name;
        }
      }
    },
    TimeFormat: function (date) {
      return moment(date).format('h:mm');
    },
    eger: function (drug_key, cycle_index, day_index, type, prem_ind, prem_day, mix_ind, subject, dy_ind) {
      let res = true;
      if (subject === 'planned_given') {
        let pg_data = {};
        if (type === 'drug') {
          pg_data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data.approvals;
          if (['administration_0'].indexOf(pg_data.status) !== -1) {
            res = false;
            return res;
          }
        } else { // before after during
          pg_data = this.treatment_data.protocol_cycles.drugs[drug_key].cycles[cycle_index].days[day_index].data[type][prem_ind];
          for (let pr_dy in pg_data.day) {
            let x = pg_data.day[pr_dy].toString();
            if (['administration_0'].indexOf(pg_data.approvals[x].status) !== -1) {
              res = false;
              return res;
            }
          }
        }
      }
      return res;
    },
    select_calculated_days: function (next_day_ind) {
      let data = {};
      if (!data[next_day_ind]) {
        data[next_day_ind] = this.calculated_days[next_day_ind];
      }
      return data;
    },
    print: function () {
      let x = new Date();
      let now_date = moment(x.toString()).format('DD/MM/YYYY HH:mm');
      let w = window.open('', '_blank', 'height=500,width=800,toolbar=0,location=0,menubar=0');
      let pageHtml = htmlTemplate
        .replace('##HEADER##', 'Tedavi raporu')
        .replace('##BODY##', this.$refs.report_here.innerHTML)
        .replace('##FOOTER##', 'Çıktıyı Alan: ' + this.user.first_name + ' ' + this.user.last_name + '<br />Çıktı Tarihi: ' + now_date + '<br />Bu protokol sağlıkta yapay zeka sistemi WisdomEra (www.wisdomera.io) ile hazırlanmıştır.');
      w.document.write(pageHtml);
      w.document.close();
      w.onload = function () {
        w.focus();
        w.print();
      };
    },
    calculate_date_of_day_ind: function (day_ind) {
      return moment(day_ind).format('DD/MM/YYYY');
    },
    calculate_drugs_in_the_day: function (date) { // this code is for calculate the accordion captions
      let str = '';
      let cycle_day_list = [];
      let drug_name_list = [];
      let is_there_prem = false;
      for (let i in this.p_drugDateList[date]) {
        let cyc_day_str = '';
        try {
          str += ' (' + this.p_drugDateList[date][i].data.general.repeat.val + '. siklus, ';
          cyc_day_str += ' (' + this.p_drugDateList[date][i].data.general.repeat.val + '. siklus, ';
        } catch (err) {}
        try {
          str += Object.keys(this.p_drugDateList[date][i].data.general.selected_days)[0] + '. gün) ';
          cyc_day_str += Object.keys(this.p_drugDateList[date][i].data.general.selected_days)[0] + '. gün)';
        } catch (err) {}
        try {
          str += this.p_drugDateList[date][i].data.general.drug.val.label;
          if (drug_name_list.indexOf(this.p_drugDateList[date][i].data.general.drug.val.label) === -1) {
            drug_name_list.push(this.p_drugDateList[date][i].data.general.drug.val.label);
          }
        } catch (err) {}
        if (i < this.p_drugDateList[date].length - 1) {
          str += ', ';
        }
        if (cycle_day_list.indexOf(cyc_day_str) === -1) {
          cycle_day_list.push(cyc_day_str);
        }
      }
      if (cycle_day_list.length === 1 && !is_there_prem) {
        str = cycle_day_list[0] + ' [' + drug_name_list.join(', ') + ']';
      }
      return str;
    },
    drug_order_func_calc_days: function (day_index) {
      let data = {};
      for (let drg in this.treatment_data.protocol_cycles.drug_order) {
        let drg_key = this.treatment_data.protocol_cycles.drug_order[drg];
        if (this.calculated_days[day_index][drg_key] !== undefined) {
          data[drg_key] = this.calculated_days[day_index][drg_key];
        }
      }
      let i = 1;
      for (let drug_key in data) {
        for (let pr in data[drug_key].before) {
          data[drug_key].before[pr].order = i;
          i++;
        }
        for (let pr in data[drug_key].during) {
          data[drug_key].during[pr].order = i;
          i++;
        }
        if (data[drug_key].type === 'drug') {
          data[drug_key].order = i;
          i++;
        }
        for (let pr in data[drug_key].after) {
          data[drug_key].after[pr].order = i;
          i++;
        }
      }
      return data;
    }
  },
  watch: {
    show_protocol_schema: function () {
      this.show_protocol_schema ? this.show_only_patient_data = false : this.show_only_patient_data = true;
    }
  },
  components: {
    ProtocolViewer,
    TreatmentProtocolViewer,
    PatientData,
    LogoView,
    ReportingModalPremedication
  }
};

const htmlTemplate = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <title></title>
  <style type="text/css">
        @page {
        size: A4;
        margin: 10mm 8mm 10mm 8mm;
      }
    @media print {
      .drug_line {
          width: 100%;
          display: inline-block;
        }
      .col-1 {width:8%;  float:left;}
        .col-2 {width:16%; float:left;}
        .col-3 {width:25%; float:left;}
        .col-4 {width:33%; float:left;}
        .col-5 {width:42%; float:left;}
        .col-6 {width:50%; float:left;}
        .col-7 {width:58%; float:left;}
        .col-8 {width:66%; float:left;}
        .col-9 {width:75%; float:left;}
        .col-10{width:83%; float:left;}
        .col-11{width:92%; float:left;}
        .col-12{width:100%; float:left;}

      html, body {
        width: 210mm;
        background: white!important;
        height: 297mm;
      }
      .page-header {
        
      }
      .page-footer {
        position: fixed;
        bottom: 0;
      }
      .page-body {
      font-size: 14px;
      width: 100%;
      height: 95%;
      }
    }
    .page-footer {
      font-size: 8px;
      position: fixed;
      bottom: 0;
    }
    .text-right {
      text-align: right;
    }
    table, td, tr {
      border: 1px solid black;
    }
    .page-body {
      font-size: 14px;
      width: 100%;
      height: 95%;
    }

    ##CSS##
  </style>

</head>
<body>
  <div class="page-header">
    ##HEADER##
  </div>
  <div class="page-body">
    ##BODY##
  </div>
  <div class="page-footer">
    ##FOOTER##
  </div>
</body>
</html>
`;

</script>

<style type="text/css"></style>

