var variable_langs_id_list = {
    "approval_choices": {
        "administration_0": "6126",
        "administration_1": "6121",
        "administration_2": "6123",
        "administration_3": "6122",
        "administration_4": "6147",
        "doctor_0": "6139",
        "doctor_1": "6140",
        "doctor_2": "6141",
        "doctor_3": "6142",
        "doctor_4": "6135",
        "doctor_5": "6136",
        "doctor_6": "6137",
        "doctor_7": "6138",
        "doctor_8": "6127",
        "doctor_9": "6134",
        "drug_approving_cancelled": "6120",
        "match_0": "6125",
        "match_1": "6124",
        "no_operation_yet": "6146",
        "nurse_0": "6144",
        "nurse_1": "6143",
        "nurse_2": "6145",
        "pharmacy_0": "3929",
        "pharmacy_1": "4150",
        "pharmacy_2": "6118",
        "pharmacy_3": "6119",
        "pharmacy_4": "6117",
        "preparation_0": "6151",
        "preparation_1": "6150",
        "preparation_2": "3804",
        "preparation_3": "6149",
        "preparation_4": "6148"
    },
    "general": {
        "abnormality": "6232",
        "after": "3855",
        "after_prem": "6209",
        "before": "3876",
        "before_prem": "6198",
        "click_to_close_premedications": "6222",
        "click_to_close_protocol_edit_mode": "6220",
        "click_to_open_protocol_edit_mode": "6234",
        "click_to_preview_and_reporting": "6152",
        "click_to_reset_all_changes": "6156",
        "click_to_save_all_changes": "6216",
        "click_to_show_all_therapy_days_of_this_patient": "6235",
        "click_to_show_from_first_day_to_the_next_day_therapy_of_this_patient": "6201",
        "click_to_show_one_of_the_drugs_of_this_patient": "6154",
        "click_to_show_premedications": "6223",
        "click_to_show_the_last_day_therapy_of_this_patient": "6212",
        "click_to_show_the_next_day_therapy_of_this_patient": "6153",
        "click_to_unselect_all_therapy_days_of_this_patient": "6229",
        "cycle": "4164",
        "day": "1246",
        "day_after": "6204",
        "day_ago": "6208",
        "day_before": "6172",
        "day_during": "6236",
        "day_later": "6207",
        "days_ago": "6215",
        "days_later": "6227",
        "decrease": "9761",
        "do_you_want_to_complete_your_saving_operation": "6170",
        "drug": "48",
        "drug_name": "4257",
        "during": "4035",
        "during_prem": "4241",
        "general": "1118",
        "in_the_same_day": "3984",
        "increase": "9760",
        "lab": "6224",
        "msg": "6214",
        "new_drug_prescribed": "6233",
        "new_drug_recommended": "6213",
        "no_operation_yet": "3957",
        "no_required_or_warning_operation": "6230",
        "note": "6210",
        "note_writing_lang": "6211",
        "patient_applied_as_not_recommended": "6206",
        "patient_applied_as_recommended": "6202",
        "patient_applied_differently": "6171",
        "patient_didnot_apply": "6205",
        "patient_has_already_receiving": "6237",
        "patient_has_applied": "6155",
        "please_be_careful_about_warnings": "6203",
        "please_first_complete_the_required_fields": "6231",
        "please_first_correct_the_validations": "6226",
        "please_first_evaluate_the_warnings": "6217",
        "please_first_select_the_protocol_group": "6225",
        "please_select_the_drug_name": "4101",
        "prem": "4123",
        "previously_prescribed": "6228",
        "protocol_name": "3812",
        "terminated": "6200",
        "today": "6218",
        "treatment_finished": "6219",
        "treatment_started": "6221",
        "write_your_note_here": "6157"
    },
    "info": {
        "bef_aft_calculated_dosage": "6039",
        "bef_aft_input_delay": "4644",
        "bef_aft_input_dosage": "6020",
        "bef_aft_input_duration": "5982",
        "bef_aft_input_duration_wait": "6018",
        "bef_aft_input_minutes_ago_or_later": "5992",
        "bef_aft_input_period": "4641",
        "bef_aft_input_planned_dosage": "5525",
        "bef_aft_input_repeat": "5990",
        "bef_aft_input_volume": "4682",
        "chars_calculated_dosage": "4779",
        "chars_input_delay": "4627",
        "chars_input_dosage": "6033",
        "chars_input_duration": "5145",
        "chars_input_duration_wait": "5172",
        "chars_input_lab": "6043",
        "chars_input_length_cm": "5121",
        "chars_input_length_inch": "6007",
        "chars_input_minutes_ago_or_later": "6068",
        "chars_input_period": "6028",
        "chars_input_planned_dosage": "5974",
        "chars_input_repeat": "4608",
        "chars_input_volume": "6011",
        "chars_input_weight_kg": "5985",
        "chars_input_weight_lbs": "5995",
        "decimal_input_lab": "4689",
        "decimal_input_length_cm": "5984",
        "decimal_input_length_inch": "4618",
        "decimal_input_weight_kg": "5991",
        "decimal_input_weight_lbs": "4906",
        "dif_date_creatinine": "4607",
        "dif_date_new_weight_height": "6041",
        "dif_date_weight_height": "6051",
        "general_date_laboratory": "4822",
        "general_date_new_weight_height": "6054",
        "general_input_length_cm": "4610",
        "general_input_length_inch": "4658",
        "general_input_weight_kg": "6012",
        "general_input_weight_lbs": "5986",
        "information": "1675",
        "input_protocol_name": "5989",
        "len_calculated_dosage": "5993",
        "len_input_delay": "6044",
        "len_input_dosage": "5497",
        "len_input_duration": "6027",
        "len_input_duration_wait": "5999",
        "len_input_length_cm": "5143",
        "len_input_length_inch": "5983",
        "len_input_minutes_ago_or_later": "5148",
        "len_input_period": "6034",
        "len_input_planned_dosage": "6009",
        "len_input_repeat": "4690",
        "len_input_volume": "5502",
        "len_input_weight_kg": "5158",
        "len_input_weight_lbs": "6024",
        "max_input_length_cm": "6016",
        "max_input_length_inch": "6001",
        "max_input_weight_kg": "6000",
        "max_input_weight_lbs": "5988",
        "min_calculated_dosage": "4652",
        "min_input_dosage": "6014",
        "min_input_length_cm": "6017",
        "min_input_length_inch": "5962",
        "min_input_planned_dosage": "5987",
        "min_input_weight_kg": "5507",
        "min_input_weight_lbs": "5137",
        "required": "6052",
        "select_administration": "6015",
        "select_chemotherapy_type": "6021",
        "select_diagnosis": "5144",
        "select_drug_days": "4648",
        "select_frequency": "6019",
        "select_lab_days": "5998",
        "select_lab_drug_days": "6013",
        "select_lab_tests": "6031",
        "select_msg_days": "4662",
        "select_msg_drug_days": "4802",
        "select_note_days": "5963",
        "select_note_drug_days": "4649",
        "select_prem_days": "6022",
        "select_prem_drug_days": "6010",
        "select_protocol_group": "6026",
        "select_protocol_visibility": "4606",
        "select_the_drug": "4101",
        "select_therapy_unit": "6023",
        "select_treatment_starting_date": "6025",
        "select_unit": "6036",
        "select_visibility": "6064",
        "text_msg_drug": "5169",
        "text_note_drug": "6069",
        "warning": "4443"
    },
    "languages": {
        "en": "6238",
        "tr": "6239"
    },
    "operations": {
        "drug_approval_doctor_0": "6082",
        "save_bmi": "6077",
        "save_laboratory": "6078",
        "save_patient_details": "6081",
        "tr": "6080",
        "treatment_analyze": "6084"
    },
    "ops": {
        "<": "6115",
        "<=": "6113",
        "<>": "6112",
        "=": "6114",
        ">": "6116",
        ">=": "6099"
    },
    "report": {
        "drug_report": "6248",
        "material_report": "6250",
        "rest_report": "6247",
        "status_report": "6249"
    },
    "rule_engine": {
        "any_value": "6241",
        "everybody": "6240",
        "last_days": "6245",
        "last_value": "6246",
        "old_recorded_data": "6243",
        "requesting_data": "6242",
        "tr": "6244"
    }
};

export { variable_langs_id_list as options };