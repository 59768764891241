<template>
  <div>
    <div v-if="!is_chemotherapy_approval" style="position: fixed; z-index: 1000; top: 300px; right: 0px;">
      <b-button variant="warning" @click="f_savePharmacyOrder()" title="ilaç orderını kaydet"> <i class="fa fa-save"></i></b-button>
    </div>
    <b-card header-tag="header" style="min-height: 750px;">
      <b-row>
        <b-col cols="12">
          <b-card bg-variant="primary" style="margin-bottom: 0px; background-color: #3c9ca5!important;" no-body>
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <i class="fa fa-file-text-o"></i> Hasta Eczane
              <b-button v-if="!is_chemotherapy_approval" variant="warning" size="md" class="pull-right" @click="f_savePharmacyOrder()"><i class="fa fa-save"></i> ilaç orderını kaydet </b-button>
            </b-card-header>
            <b-row style="padding: 3px;">
              <b-col sm="12" md="12">
                <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                  <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                    <i class="fa fa-building-o"></i> Başvuru Protokolleri
                  </b-card-header>
                  <template v-if="patient_open_protocol_list.length > 0">
                    <template v-if="!is_chemotherapy_approval" v-for="(open_pro, open_pro_ind) in patient_open_protocol_list">
                      <b-row v-if="(user_hims_branch_id && user_hims_branch_id === open_pro.branch_id) && (!is_chemotherapy_approval || (is_chemotherapy_approval && hims_protocol_data && hims_protocol_data.selected_protocol && hims_protocol_data.selected_protocol.protocol_no === open_pro.protocol_no))" :key="'open_pro_' + open_pro_ind" style="padding: 5px; margin: 0px; border-bottom: solid 1px #ccbdbd;">
                        <b-col cols="12">
                          <div :style="SelectedProtocolInd === open_pro_ind ? 'background-color: #b0e8f5; cursor: pointer;' : 'cursor: pointer;'" @click="f_selectThisProtocol(open_pro_ind)">
                            <b-form-checkbox :checked="SelectedProtocolInd === open_pro_ind" style="display: inline;"></b-form-checkbox>
                            <i class="fa fa-hand-o-right" style="color: green;"></i> {{ open_pro.department_name }}
                            <i class="fa fa-user"></i> {{ open_pro.doctor }}
                            <i class="fa fa-key"></i> {{ open_pro.protocol_no }}
                            <i class="fa fa-calendar-o"></i> ({{ moment(open_pro.open_date).format('DD/MM/YYYY HH:mm') }})
                            <span :style="f_calculateStyleProtocolType(open_pro.type_id)">
                              [{{ CalculateProtocolType(open_pro.type_id) }}]
                            </span>
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                    <template v-if="is_chemotherapy_approval && hims_protocol_data && hims_protocol_data.selected_protocol">
                      <b-row style="padding: 5px; margin: 0px; border-bottom: solid 1px #ccbdbd;">
                        <b-col cols="12">
                          <div style="background-color: #b0e8f5; cursor: pointer;">
                            <i class="fa fa-hand-o-right" style="color: green;"></i> {{ hims_protocol_data.selected_protocol.department_name }}
                            <i class="fa fa-user"></i> {{ hims_protocol_data.selected_protocol.doctor }}
                            <i class="fa fa-key"></i> {{ hims_protocol_data.selected_protocol.protocol_no }}
                            <i class="fa fa-calendar-o"></i> ({{ moment(hims_protocol_data.selected_protocol.open_date).format('DD/MM/YYYY HH:mm') }})
                            <span :style="f_calculateStyleProtocolType(hims_protocol_data.selected_protocol.type_id)">
                              [{{ CalculateProtocolType(hims_protocol_data.selected_protocol.type_id) }}]
                            </span>
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                  </template>
                  <template v-else>
                    <span style="color: red">Hastanın Açık Protokolü Bulunmamaktadır.</span>
                  </template>
                </b-card>
              </b-col>
            </b-row>
            <b-row style="padding: 3px;">
              <b-col sm="12" md="4">
                <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                  <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                    <i class="fa fa-search"></i> İlaç Sorgulama
                  </b-card-header>
                  <b-row>
                    <b-col cols="12">
                      <b-input-group>
                        <b-form-input id="search_material" placeholder="Ürün adı ile arama yapabilirsiniz" type="text" v-model="d_materialSearch" @keydown.enter.native="ClickEnter"></b-form-input>
                        <b-input-group-append>
                          <b-button variant="success" @click="d_searchMaterial()">ara</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                  <div style="overflow-y: auto; height: 150px; overflow-x: hidden;">
                    <b-table head-variant="dark" striped responsive stacked="sm" bordered borderless small hover :items="d_searchMaterialList" :fields="d_searchMaterialColumns" select-mode="single" selectable selectedVariant="success" @row-dblclicked="rowSelectedSearchMaterial" @row-selected="">
                      <template v-slot:cell(row_no)="row">
                        {{ row.index + 1 }}
                      </template>
                    </b-table>
                  </div>
                </b-card>
              </b-col>
              <b-col sm="12" md="4" style="border-radius: 15px">
                <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                  <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                    <i class="fa fa-database"></i> Favori Grupları
                  </b-card-header>
                  <b-row>
                    <b-col cols="12">
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col sm="12" md="4">
                <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                  <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                    <i class="fa fa-asterisk"></i> Favori İlaçlar
                  </b-card-header>
                  <b-row>
                    <b-col cols="12">
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-card-footer footer-bg-variant="primary" header-text-variant="dark" class="p-1" style="background-color: #5db5bd!important;">
              <b-row>
                <b-col sm="12" md="4"></b-col>
                <b-col sm="12" md="4">
                  <!-- <b-button variant="warning" size="md" style="width: 100%;" @click="f_add()"><i class="fa fa-cloud-download"></i> ekle </b-button> -->
                </b-col>
                <b-col sm="12" md="4">
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
          <b-card no-body class="mb-1" header-tag="header" key="patient-diagnosis-list">
            <b-card-header header-bg-variant="success" class="p-1" header-text-variant="dark">
              Başvuru Protokolüne Eklenen Tedavi Order İlaçları
              <span class="pull-right">
                <b-form-input type="date" id="selected_add_this_date" v-model="selected_add_this_date" style="display: inline;"></b-form-input>
                <b-button v-if="selected_add_this_date" @click="f_addThisDate()">tarihi ekle</b-button>
                <b-button style="margin-right: 5px;" class="pull-right" @click="d_expandAllCollapsed ? d_expandAllCollapsed = false : d_expandAllCollapsed = true" title="Hepsini Aç-Kapa"><i class="fa fa-arrows-v"></i></b-button>
              </span>
            </b-card-header>
            <template v-if="d_showTable">
              <template v-for="(date, date_ind) in d_pharmacyList">
                <b-card no-body class="mb-1" header-tag="header" footer-tag="footer" border-variant="secondary" header-bg-variant="secondary" :key="date_ind">
                  <b-card-header header-bg-variant="secondary" class="p-1">
                    <b-row>
                      <b-col cols="1">
                        <b-form-checkbox switch v-if="DateFunctions.day_dif(date_ind, 'today') <= 0" v-model="d_selectedDate" :unchecked-value="''" :value="date_ind" style="display: inline;">
                        </b-form-checkbox>
                      </b-col>
                      <b-col cols="3">
                        <strong style="color: green;">{{ DateFormat(date_ind) }} TARİHLİ İLAÇ ORDERI</strong>
                      </b-col>
                      <b-col cols="8" v-b-toggle="'date_' + date_ind" style="cursor: pointer;">
                        <i class="fa fa-hand-o-right"></i>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-collapse :visible="d_expandAllCollapsed" :id="'date_' + date_ind">
                    <template :ref="'ref-' + date_ind">
                      <b-card no-body style="border: 0px; margin-bottom: 0px;">
                        <b-row>
                          <b-col cols="3" class="text-center">İsim</b-col>
                          <b-col style="margin: 0px; padding: 0px;" cols="1" class="text-center">Günde Kaç</b-col>
                          <b-col style="margin: 0px; padding: 0px;" cols="1" class="text-center">Miktar</b-col>
                          <b-col style="margin: 0px; padding: 0px;" cols="1" class="text-center">Birim</b-col>
                          <b-col style="margin: 0px; padding: 0px;" cols="1" class="text-center">Aç-Tok</b-col>
                          <b-col style="margin: 0px; padding: 0px;" cols="1" class="text-center">Uygulama Hızı</b-col>
                          <b-col style="margin: 0px; padding: 0px;" cols="1" class="text-center">Hız Ölçüsü</b-col>
                          <b-col style="margin: 0px; padding: 0px;" cols="1" class="text-center">Adet</b-col>
                          <b-col style="margin-left: 0px; padding-left: 0px;" cols="2" class="text-center">Uygulama Şekli</b-col>
                        </b-row>
                      </b-card>
                      <b-card no-body :style="'border: 0px; margin-bottom: 0px; padding-top: 5px; padding-bottom: 5px;' + f_calculateDrugBackground(ph_ind)" v-for="(ph, ph_ind) in date.list" :key="'date.list_' + ph_ind">
                        <b-row>
                          <b-col cols="3">
                            <b-button :disabled="ph.order_id === 0" size="sm" :variant="ph.willBeStopped ? 'danger' : 'success'" @click="ph.willBeStopped ? ph.willBeStopped = 0 : ph.willBeStopped = 1; $forceUpdate();">
                              <template v-if="ph.willBeStopped">
                                <img src="@/icon/7149_black.png" title="STOP-->Devam" style="width: 1.5em;" />
                              </template>
                              <template v-else>
                                <img src="@/icon/2147852_black.png" title="Devam-->STOP" style="width: 1.5em;" />
                              </template>
                            </b-button>
                            {{ ph_ind + 1 }}-) {{ ph.material_name }}
                          </b-col>
                          <template v-if="ph.willBeStopped">
                            <b-col cols="2">
                              Stop Notu:
                            </b-col>
                            <b-col cols="7">
                              <b-form-input type="text" v-model="ph.stop_note">
                              </b-form-input>
                            </b-col>
                          </template>
                          <template v-else>
                            <b-col cols="1" style="margin: 0px; padding: 0px;">
                              <b-form-select v-model="ph.dose_period" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !ph.material_id">
                                <option v-for="x in 100" :value="x">{{ x }}</option>
                              </b-form-select>
                            </b-col>
                            <b-col cols="1" style="margin: 0px; padding: 0px;">
                              <b-form-input type="number" min="1" step="1" v-model="ph.dose_quantity" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !ph.material_id"></b-form-input>
                            </b-col>
                            <b-col cols="1" style="margin: 0px; padding: 0px;">
                              <b-form-select v-model="ph.unit_id" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !ph.material_id">
                                <option v-for="(unit, unit_ind) in unit_hims[user.hospital_group][user.hospital_id].list" :value="unit.id"> {{ unit.name }}</option>
                              </b-form-select>
                            </b-col>
                            <b-col cols="1" style="margin: 0px; padding: 0px;">
                              <b-form-select v-model="ph.hungry_full" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !ph.material_id">
                                <option value="A">Aç</option>
                                <option value="T">Tok</option>
                                <option value="Y">Yemekle Birlikte</option>
                                <option value="F">Farketmez</option>
                              </b-form-select>
                            </b-col>
                            <b-col cols="1" style="margin: 0px; padding: 0px;">
                              <b-form-input type="number" min="1" step="1" v-model="ph.infusion_speed" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !ph.material_id"></b-form-input>
                            </b-col>
                            <b-col cols="1" style="margin: 0px; padding: 0px;">
                              <b-form-select v-model="ph.speed_measure" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !ph.material_id">
                                <option v-for="x in d_speedMeasureList" :value="x['value']">{{ x['label'] }}</option>
                              </b-form-select>
                            </b-col>
                            <b-col cols="1" style="margin: 0px; padding: 0px;">
                              <b-form-input type="number" min="1" step="1" v-model="ph.req_quantity_from_chemist" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !ph.material_id"></b-form-input>
                              <!-- <b-form-select v-model="ph.req_quantity_from_chemist" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !ph.material_id">
                                <option v-for="x in 100" :value="x">{{ x }}</option>
                              </b-form-select> -->
                            </b-col>
                            <b-col cols="2" style="margin-left: 0px; padding-left: 0px;">
                              <b-form-select v-model="ph.usage_type" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !ph.material_id">
                                <option v-for="x in d_usageTypeList" :value="x['id']">{{ x['name'] }}</option>
                              </b-form-select>
                            </b-col>
                          </template>
                        </b-row>
                        <b-row v-if="ph.willBeStopped === 0">
                          <b-col cols="1">
                            Not:
                          </b-col>
                          <b-col cols="11">
                            <b-form-input type="text" v-model="ph.doctor_note" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !ph.material_id">
                            </b-form-input>
                            <!-- <b-textarea v-model="ph.doctor_note" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !ph.material_id">
                            </b-textarea> -->
                          </b-col>
                        </b-row>
                      </b-card>
                    </template>
                    <!-- <b-table :ref="'ref-' + date_ind" :key="'table-' + date_ind" caption-top head-variant="dark" striped responsive stacked="sm" bordered borderless small hover :items="date.list" :fields="d_pharmacyColumns" selectable selectedVariant="" @row-selected="rowSelected">
                      <template v-slot:cell(actions)="row">
                        <b-dropdown variant="success" v-if="DateFunctions.day_dif(date_ind, 'today') <= 0 && row.item.material_id">
                          <b-dropdown-item @click="f_deleteMaterial(date_ind, row.index)"><i class="fa fa-trash"></i>listeden çıkar</b-dropdown-item>
                        </b-dropdown>
                      </template>
                      <template v-slot:cell(row_no)="row">
                        {{ row.index + 1 }}
                      </template>
                      <template v-slot:cell(unit_id)="row">
                        <div style="white-space: nowrap; width: 100px;">
                          <b-form-select v-model="row.item.unit_id" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !row.item.material_id">
                            <option v-for="(unit, unit_ind) in unit_hims[user.hospital_group].list" :value="unit.id"> {{ unit.name }}</option>
                          </b-form-select>
                        </div>
                      </template>
                      <template v-slot:cell(period_quantity)="row">
                        <div style="white-space: nowrap; width: 200px;">
                          <b-form-select v-model="row.item.dose_period" style="display: inline; width: 40%;" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !row.item.material_id">
                            <option v-for="x in 100" :value="x">{{ x }}</option>
                          </b-form-select>
                          <strong style="padding: 5px;">X</strong>
                          <b-form-select v-model="row.item.dose_quantity" style="display: inline; width: 40%;" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !row.item.material_id">
                            <option v-for="x in 100" :value="x">{{ x }}</option>
                          </b-form-select>
                        </div>
                      </template>
                      <template v-slot:cell(hungry_full)="row">
                        <div style="white-space: nowrap; width: 100px;">
                          <b-form-select v-model="row.item.hungry_full" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !row.item.material_id">
                            <option value="A">Aç</option>
                            <option value="T">Tok</option>
                            <option value="Y">Yemekle Birlikte</option>
                            <option value="F">Farketmez</option>
                          </b-form-select>
                        </div>
                      </template>
                      <template v-slot:cell(req_quantity_from_chemist)="row">
                        <div style="white-space: nowrap; width: 100px;">
                          <b-form-select v-model="row.item.req_quantity_from_chemist" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !row.item.material_id">
                            <option v-for="x in 100" :value="x">{{ x }}</option>
                          </b-form-select>
                        </div>
                      </template>
                      <template v-slot:cell(usage_type)="row">
                        <div style="white-space: nowrap; width: 100px;">
                          <b-form-select v-model="row.item.usage_type" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !row.item.material_id">
                            <option v-for="x in d_usageTypeList" :value="x['id']">{{ x['name'] }}</option>
                          </b-form-select>
                        </div>
                      </template>
                      <template v-slot:cell(infusion_speed)="row">
                        <b-form-input type="text" v-model="row.item.infusion_speed" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !row.item.material_id"></b-form-input>
                      </template>
                      <template v-slot:cell(speed_measure)="row">
                        <div style="white-space: nowrap; width: 100px;">
                          <b-form-select v-model="row.item.speed_measure" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !row.item.material_id">
                            <option v-for="x in d_speedMeasureList" :value="x['value']">{{ x['label'] }}</option>
                          </b-form-select>
                        </div>
                      </template>
                      <template v-slot:cell(doctor_note)="row">
                        <div>
                          <b-textarea v-model="row.item.doctor_note" :disabled="DateFunctions.day_dif(date_ind, 'today') > 0 || !row.item.material_id">
                          </b-textarea>
                        </div>
                      </template>
                    </b-table> -->
                  </b-collapse>
                </b-card>
              </template>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Datepicker from 'wisdom-vue-datepicker';
import vSelect from 'vue-select';
import WebService from '@/services/webservice';
import PharmacyService from '@/services/pharmacy';
import { DateFunctions } from '@/services/public/date_functions';
import moment from 'moment';
export default {
  name: 'PharmacyWidget',
  computed: mapGetters({
    StoreModal: 'modal',
    lang: 'lang'
  }),
  components: {
    Datepicker,
    vSelect
  },
  props: {
    patient_data: {
      type: Object,
      required: true
    },
    hims_protocol_data: {
      type: Object,
      required: false
    },
    is_chemotherapy_approval: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      selected_add_this_date: '',
      unit_hims: require('@/options/unit_hims').options,
      d_expandAllCollapsed: true,
      d_pharmacyList: {},
      d_pharmacyColumns: [{
        key: "actions",
        label: ""
      }, {
        key: "row_no",
        label: ""
      }, {
        key: "material_name",
        label: "Ad"
      }, {
        key: "unit_id",
        label: "unit"
      }, {
        key: "period_quantity",
        label: "Period (X) Kaç Kez"
      }, {
        key: "hungry_full",
        label: "Yemek İlişkisi"
      }, {
        key: "infusion_speed",
        label: "Uygulama Hızı"
      }, {
        key: "speed_measure",
        label: "Hız Ölçüsü"
      }, {
        key: "req_quantity_from_chemist",
        label: "Adet"
      }, {
        key: "usage_type",
        label: "Uygulama Şekli"
      }, {
        key: "doctor_note",
        label: "Not"
      }],
      d_usageTypeList: [
        { "name": "IV infüzyon", "id": "1" },
        { "name": "İNFÜZYON", "id": "10" },
        { "name": "RECTAL", "id": "11" },
        { "name": "İNHALER", "id": "12" },
        { "name": "NEBUL", "id": "13" },
        { "name": "HARİCEN", "id": "14" },
        { "name": "Yemek arası (Yemekle Birlikte)", "id": "15" },
        { "name": "İntravezikal(Mesane İçi)", "id": "16" },
        { "name": "Topical", "id": "17" },
        { "name": "İnhalasyon", "id": "18" },
        { "name": "Endotrakeal", "id": "19" },
        { "name": "IV bolus", "id": "2" },
        { "name": "İntraartiküler", "id": "20" },
        { "name": "İntraarteriyal", "id": "21" },
        { "name": "İntratekal", "id": "22" },
        { "name": "İntrakardiyal", "id": "23" },
        { "name": "İntraperitoneal", "id": "24" },
        { "name": "İntraspinal", "id": "25" },
        { "name": "İntraosseus", "id": "26" },
        { "name": "Vajinal", "id": "27" },
        { "name": "Sublingual", "id": "28" },
        { "name": "Bukkal", "id": "29" },
        { "name": "IV Puşe", "id": "3" },
        { "name": "Aplikasyon", "id": "30" },
        { "name": "İnstilasyon", "id": "31" },
        { "name": "İrigasyon", "id": "32" },
        { "name": "Burun içi", "id": "33" },
        { "name": "Göz içi", "id": "34" },
        { "name": "Kulak içi", "id": "35" },
        { "name": "BAKIM", "id": "36" },
        { "name": "Peg Katater", "id": "37" },
        { "name": "IM", "id": "4" },
        { "name": "ID", "id": "5" },
        { "name": "Subkütan", "id": "6" },
        { "name": "PO", "id": "7" },
        { "name": "SL", "id": "8" },
        { "name": "NG", "id": "9" }
      ],
      usage_type_wisdom_mlpcare_match: {
        '1': '7',
        '2': '7',
        '3': '33',
        '4': '7',
        '5': '35',
        '6': '28',
        '7': '34',
        '8': '18',
        '9': '5',
        '10': '4',
        '11': '27',
        '12': '1',
        '13': '1',
        '14': '20',
        '15': '17',
        '16': '26',
        '17': '24',
        '18': '22',
        '19': '16',
        '20': '24',
        '21': '23',
        '22': '11',
        '23': '6',
        '24': '11',
        '25': '18',
        '26': '6',
        '27': '6'
      },
      d_speedMeasureList: [
        { 'label': 'ml/saat', 'value': 'ml/saat' },
        { 'label': 'ml/dk', 'value': 'ml/dk' },
        { 'label': 'mg/saat', 'value': 'mg/saat' },
        { 'label': 'mg/dk', 'value': 'mg/dk' },
        { 'label': 'ünite/saat', 'value': 'ünite/saat' },
        { 'label': 'ünite/dk', 'value': 'ünite/dk' }
      ],
      wisdom_usage_types: [
        { 'label': 'Ağız içi', 'value': '1' },
        { 'label': 'Ağızdan (oral)', 'value': '2' },
        { 'label': 'Burun içi (intranazal)', 'value': '3' },
        { 'label': 'Cilt üzerine (epidermal)', 'value': '4' },
        { 'label': 'Dış kulak yolu', 'value': '5' },
        { 'label': 'Dil altı (sublingual)', 'value': '6' },
        { 'label': 'Göz üzerine', 'value': '7' },
        { 'label': 'İnhalasyon', 'value': '8' },
        { 'label': 'İntradermal', 'value': '9' },
        { 'label': 'İntramusküler', 'value': '10' },
        { 'label': 'İntravajinal', 'value': '11' },
        { 'label': 'İntravenöz', 'value': '12' },
        { 'label': 'intravenöz (Pompa ile)', 'value': '13' },
        { 'label': 'İntraartiküler', 'value': '14' },
        { 'label': 'İntrakaviter', 'value': '15' },
        { 'label': 'İntraligamenter', 'value': '16' },
        { 'label': 'İntraperitoneal', 'value': '17' },
        { 'label': 'İntratekal', 'value': '18' },
        { 'label': 'İntravezikal', 'value': '19' },
        { 'label': 'İntravitreal', 'value': '20' },
        { 'label': 'İntrakardiak', 'value': '21' },
        { 'label': 'Kolon', 'value': '22' },
        { 'label': 'Perinöral', 'value': '23' },
        { 'label': 'Rektal', 'value': '24' },
        { 'label': 'Solunum yolu', 'value': '25' },
        { 'label': 'Subkutan', 'value': '26' },
        { 'label': 'Transdermal', 'value': '27' }
      ],
      moment: moment,
      patient_open_protocol_list: [],
      SelectedProtocolInd: '',
      d_selectedProtocolData: {},
      d_selectedProtocolNo: '',
      d_preparedPharamcyList: [],
      d_materialSearch: '',
      d_searchMaterialList: [],
      d_searchMaterialColumns: [{
        key: "row_no",
        label: ""
      }, {
        key: "name",
        label: "Ad",
        sortable: true
      }, {
        key: "count",
        label: "Adet",
        sortable: true
      }],
      user_hims_branch_id: '',
      d_selectedDate: '',
      DateFunctions: DateFunctions,
      d_showTable: true
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    for (let i in this.user.position_department_title) {
      if (this.user.position_department_title[i].active && this.user.position_department_title[i].department.hims_branch_id) {
        this.user_hims_branch_id = parseInt(this.user.position_department_title[i].department.hims_branch_id);
        break;
      }
    }
    if (this.patient_data.open_protocol_list && this.patient_data.open_protocol_list.result && this.patient_data.open_protocol_list.result.length > 0) {
      for (let i in this.patient_data.open_protocol_list.result) {
        // içerisine ilaç düşümü yapılabilecek protokolleri seçiyoruz. günübirlik, acil, yatan hasta.
        if ([2, 3, 4].indexOf(this.patient_data.open_protocol_list.result[i]['type_id']) !== -1) {
          this.patient_open_protocol_list.push(this.patient_data.open_protocol_list.result[i]);
        }
      }
    }
    if (this.is_chemotherapy_approval && this.hims_protocol_data) {
      // this.d_pharmacyList = JSON.parse(JSON.stringify(this.hims_protocol_data.pharmacy_list));
      for (let i in this.patient_data.open_protocol_list.result) {
        if (this.patient_data.open_protocol_list.result[i].protocol_no === this.hims_protocol_data.selected_protocol.protocol_no) {
          this.f_selectThisProtocol(parseInt(i));
          break;
        }
      }
    }
    let today = new Date();
    this.selected_add_this_date = moment(today).format('YYYY-MM-DD');
  },
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_calculateDrugBackground: function (ph_ind) {
      if (ph_ind % 2 !== 0) {
        return '';
      } else {
        return 'background-color: #c8ced3;';
      }
    },
    f_addThisDate: function () {
      if (!this.d_pharmacyList[this.selected_add_this_date]) {
        this.d_pharmacyList[this.selected_add_this_date] = { 'list': [] };
        this.$forceUpdate();
      }
    },
    f_showTrueFalse: function () {
      this.d_showTable = false;
      setTimeout(function () {
        this.d_showTable = true;
        this.$forceUpdate();
      }.bind(this), 100)
    },
    f_selectDate: function (date) {
      if (DateFunctions.day_dif(date, 'today') <= 0) {
        this.d_selectedDate = date;
      } else {
        alert('Bugünden önceki tarihlerin orderlarını düzenlenememektedir.');
      }
    },
    DateFormat: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    f_savePharmacyOrder: function () {
      let modal_data = { 'type': 'confirm', 'text': 'İlaç order işlemini tamamlamak istediğinize emin misiniz?', 'centered': true, 'title': 'İlaç Order İşlemi', 'confirm': false, 'function_name': 'f_savePharmacyOrderConfirmed', 'arguments': [] };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_savePharmacyOrderConfirmed: function () {
      if (this.d_selectedProtocolNo) {
        let sending_ph_list = {};
        for (let dt in this.d_pharmacyList) {
          if (!this.d_selectedProtocolData.recorded_pharmacy_list[dt] || (this.d_selectedProtocolData.recorded_pharmacy_list[dt] && JSON.stringify(this.d_selectedProtocolData.recorded_pharmacy_list[dt]) !== JSON.stringify(this.d_pharmacyList[dt]))) {
            sending_ph_list[dt] = this.d_pharmacyList[dt];
          }
        }

        let data = {
          'pharmacy_list': sending_ph_list,
          'processID': this.d_selectedProtocolData.process_id,
          'resourceId': this.d_selectedProtocolData.resource_id,
          'protocolNo': this.d_selectedProtocolData.protocol_no,
          'hospitalCode': this.user.hospital_id,
          'hospitalGroup': this.user.hospital_group,
          'patient_id': this.patient_data.id
        }
        // console.log(data);
        // return;
        if (Object.keys(sending_ph_list).length > 0) {
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İlaç order kayıt işlemi yapılıyor. Lütfen bekleyiniz.' } });
          PharmacyService.save_pharmacy_order(data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status === 'success') {
                alert('İşlem tamamlandı.');
              } else {
                alert('error ', resp.data.message);
              }
            });
        } else {
          alert('İlaç değişikliği yapılmadığı tespit edildiği için order verilmedi');
        }
      } else {
        alert('İlaç orderı verebilmek için bir açık başvuru protokolü açık olması gerekmektedir.');
      }
    },
    ClickEnter (event) {
      if (event.which === 13) {
        this.d_searchMaterial();
      }
    },
    d_searchMaterial: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Ürün listesi getiriliyor.' } });
      this.d_searchMaterialList = [];
      if (this.d_materialSearch) {
        PharmacyService.search_material({ 'search': this.d_materialSearch })
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            if (resp.data.status === 'success') {
              this.d_searchMaterialList = resp.data.result;
            } else {
              alert('error ', resp.data.message);
            }
          });
      }
    },
    f_deleteMaterial: function (date_ind, index) {
      // console.log(index, date_ind);
      this.d_pharmacyList[date_ind].list.splice(index, 1);
      this.f_showTrueFalse();
    },
    rowSelected: function (selectedItems) {},
    rowSelectedSearchMaterial: function (selectedItems) {
      // console.log(selectedItems);
      if (this.d_selectedDate) {
        let det = false;
        for (let i in this.d_pharmacyList[this.d_selectedDate].list) {
          if (this.d_pharmacyList[this.d_selectedDate].list[i].material_id === selectedItems.id) {
            det = true;
            alert('Bu ürün eklenmiştir.');
            break;
          }
        }
        if (!det) {
          let data = {
            'material_name': selectedItems.name,
            'starting_date': this.d_selectedDate,
            'material_id': selectedItems.id,
            'unit_id': 1,
            'hungry_full': 'F',
            'usage_type': '1',
            'infusion_speed': '',
            'speed_measure': '',
            'dose_period': 1,
            'dose_quantity': 1,
            'doctor_note': '',
            'req_quantity_from_chemist': 1,
            'order_id': 0,
            'willBeStopped': 0,
            'stop_note': ''
          }
          this.d_pharmacyList[this.d_selectedDate].list.push(data);
          this.f_showTrueFalse();
          // this.$forceUpdate();
        }
      } else {
        alert('Lütfen öncelikle bir tarih seçimi yapınız.');
      }
    },
    f_add: function () {},
    f_selectThisProtocol: function (open_pro_ind) {
      this.d_pharmacyList = {};
      this.d_showTable = false;
      this.SelectedProtocolInd === open_pro_ind ? this.SelectedProtocolInd = '' : this.SelectedProtocolInd = open_pro_ind;
      if (this.SelectedProtocolInd !== '') {
        this.d_selectedProtocolData = JSON.parse(JSON.stringify(this.patient_open_protocol_list[open_pro_ind]));
        this.d_selectedProtocolData['recorded_pharmacy_list'] = {};
        this.d_selectedProtocolNo = this.patient_open_protocol_list[open_pro_ind].protocol_no;
        let query = 'processId=' + this.d_selectedProtocolData.process_id;
        query += '&hospitalGroup=' + this.user.hospital_group;
        query += '&hospitalCode=' + this.user.hospital_id;
        // console.log('query ', query);
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Başvuru protokolüne ait doktor order bilgisi getiriliyor' } });
        WebService.get_hims_protocol_pharmacy(query)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              let resp_data = resp.data.result;
              this.d_selectedProtocolData['recorded_pharmacy_list'] = resp_data;
              for (let i in resp_data) {
                if (!resp_data[i].is_stop && !resp_data[i].is_deleted) {
                  let x = {};
                  let dt = moment(resp_data[i].open_date_time).format('YYYY-MM-DD');
                  if (resp_data[i].material_id) {
                    x = {
                      'recorded': 1,
                      'willBeStopped': 0,
                      'stop_note': '',
                      'order_id': resp_data[i].id,
                      'material_name': resp_data[i].material_name,
                      'starting_date': resp_data[i].requested_date,
                      'material_id': resp_data[i].material_id,
                      'unit_id': resp_data[i].unit_id,
                      'hungry_full': resp_data[i].stomach, // A = Aç, T = Tok, Y = Yemek Arası Yemekle Birlikte, F farketmez
                      'usage_type': resp_data[i].usage,
                      'infusion_speed': resp_data[i].infusion_speed && resp_data[i].infusion_speed.split(' ')[0] ? resp_data[i].infusion_speed.split(' ')[0] : '',
                      'speed_measure': resp_data[i].infusion_speed && resp_data[i].infusion_speed.split(' ')[1] ? resp_data[i].infusion_speed.split(' ')[1] : '',
                      'dose_period': resp_data[i].dose_period,
                      'dose_quantity': resp_data[i].dose_quantity,
                      'doctor_note': resp_data[i].doctor_note ? resp_data[i].doctor_note : '',
                      'req_quantity_from_chemist': resp_data[i].request_quantity_from_chemist
                    }
                  } else if (resp_data[i].mixture_name) {
                    x = {
                      'recorded': 1,
                      'willBeStopped': 0,
                      'stop_note': '',
                      'order_id': resp_data[i].id,
                      'material_name': resp_data[i].mixture_name,
                      'starting_date': resp_data[i].requested_date,
                      'material_id': '',
                      'unit_id': '',
                      'hungry_full': resp_data[i].stomach, // A = Aç, T = Tok, Y = Yemek Arası Yemekle Birlikte, F farketmez
                      'usage_type': resp_data[i].usage,
                      'infusion_speed': resp_data[i].infusion_speed && resp_data[i].infusion_speed.split(' ')[0] ? resp_data[i].infusion_speed.split(' ')[0] : '',
                      'speed_measure': resp_data[i].infusion_speed && resp_data[i].infusion_speed.split(' ')[1] ? resp_data[i].infusion_speed.split(' ')[1] : '',
                      'dose_period': resp_data[i].dose_period,
                      'dose_quantity': resp_data[i].dose_quantity,
                      'doctor_note': resp_data[i].doctor_note ? resp_data[i].doctor_note : '',
                      'req_quantity_from_chemist': resp_data[i].request_quantity_from_chemist
                    }
                    resp_data[i].notes ? x.doctor_note += ' ' + resp_data[i].notes : x.doctor_note += '';
                  } else if (resp_data[i].treat_name) {
                    x = {
                      'recorded': 1,
                      'willBeStopped': 0,
                      'stop_note': '',
                      'order_id': resp_data[i].id,
                      'material_name': resp_data[i].treat_name,
                      'starting_date': resp_data[i].requested_date,
                      'material_id': '',
                      'unit_id': '',
                      'hungry_full': resp_data[i].stomach, // A = Aç, T = Tok, Y = Yemek Arası Yemekle Birlikte, F farketmez
                      'usage_type': resp_data[i].usage,
                      'infusion_speed': resp_data[i].infusion_speed && resp_data[i].infusion_speed.split(' ')[0] ? resp_data[i].infusion_speed.split(' ')[0] : '',
                      'speed_measure': resp_data[i].infusion_speed && resp_data[i].infusion_speed.split(' ')[1] ? resp_data[i].infusion_speed.split(' ')[1] : '',
                      'dose_period': resp_data[i].dose_period,
                      'dose_quantity': resp_data[i].dose_quantity,
                      'doctor_note': resp_data[i].doctor_note ? resp_data[i].doctor_note : '',
                      'req_quantity_from_chemist': resp_data[i].request_quantity_from_chemist
                    }
                    resp_data[i].notes ? x.doctor_note += ' ' + resp_data[i].notes : x.doctor_note += '';
                  }
                  // kayıtlılardan gelenlerle yeni hazırladıklarımızı aşağıda birleştiriyoruz.
                  if (!this.d_pharmacyList[dt]) {
                    this.d_pharmacyList[dt] = { 'list': [] };
                  }
                  this.d_pharmacyList[dt].list.push(x);
                  // Kayıtlı verileri ayrıca bir veri yapısı içerisinde tutuyoruz.
                  // Çünkü yeni oluşan tarih içerisindeki ilaçlar ile zaten kayıtlılar arasında bir fark var ise gönderilecek.
                  if (!this.d_selectedProtocolData.recorded_pharmacy_list[dt]) {
                    this.d_selectedProtocolData.recorded_pharmacy_list[dt] = { 'list': [] };
                  }
                  this.d_selectedProtocolData.recorded_pharmacy_list[dt].list.push(JSON.parse(JSON.stringify(x)));
                }
              }
            } else {
              // alert('Protokolde kayıtlı ilaç orderı tespit edilmedi. ');
              // alert('error: ', resp.data.message);
            }
            // kayıtlı verilerden gelen satırlar eklendikten sonra aşağıda kemoterapi tarafından gelen ilaç varsa kontrol ederek zaten kayıtlı datalar içerisinde yok ise ekliyoruz.
            if (this.is_chemotherapy_approval && this.hims_protocol_data) {
              for (let dt in this.hims_protocol_data.pharmacy_list) {
                for (let m in this.hims_protocol_data.pharmacy_list[dt].list) {
                  if (this.d_pharmacyList[dt]) {
                    let det = false;
                    for (let m2 in this.d_pharmacyList[dt].list) {
                      if (this.hims_protocol_data.pharmacy_list[dt].list[m].material_id === this.d_pharmacyList[dt].list[m2].material_id) {
                        det = true;
                        break;
                      }
                    }
                    if (!det) {
                      this.hims_protocol_data.pharmacy_list[dt].list[m].order_id = 0;
                      this.hims_protocol_data.pharmacy_list[dt].list[m].willBeStopped = 0;
                      this.hims_protocol_data.pharmacy_list[dt].list[m].stop_note = '';
                      this.d_pharmacyList[dt].list.push(this.hims_protocol_data.pharmacy_list[dt].list[m]);
                    }
                  } else {
                    this.d_pharmacyList[dt] = { 'list': [] };
                    this.hims_protocol_data.pharmacy_list[dt].list[m].order_id = 0;
                    this.hims_protocol_data.pharmacy_list[dt].list[m].willBeStopped = 0;
                    this.hims_protocol_data.pharmacy_list[dt].list[m].stop_note = '';
                    this.d_pharmacyList[dt].list.push(this.hims_protocol_data.pharmacy_list[dt].list[m]);
                  }
                }
              }
            }
            this.f_showTrueFalse();
          });
      } else {
        this.f_showTrueFalse();
        this.d_selectedProtocolData = {};
        this.d_selectedProtocolNo = '';
        this.d_pharmacyList = {};
      }
    },
    f_calculateStyleProtocolType (type_id) {
      if (type_id === 0) {
        return 'color: red; font-weight: bold;';
      } else if (type_id === 1) {
        return 'color: green; font-weight: bold;';
      } else if (type_id === 2) {
        return 'color: #b3246c; font-weight: bold;';
      } else if (type_id === 3) {
        return 'color: red; font-weight: bold;';
      } else if (type_id === 4) {
        return 'color: blue; font-weight: bold;';
      } else if (type_id === 5) {
        return 'color: black; font-weight: bold;';
      }
    },
    CalculateProtocolType: function (type_id) {
      if (type_id === 0) {
        return 'Diğer';
      } else if (type_id === 1) {
        return 'Poliklinik';
      } else if (type_id === 2) {
        return 'Yatan Hasta';
      } else if (type_id === 3) {
        return 'Acil';
      } else if (type_id === 4) {
        return 'Günübirlik';
      } else if (type_id === 5) {
        return 'TempInpatient';
      }
    }
  },
  watch: {
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_savePharmacyOrderConfirmed') {
          if (this.StoreModal.data.confirm) {
            this.f_savePharmacyOrderConfirmed();
          }
        }
      }
    }
  }
};

</script>

<style></style>

