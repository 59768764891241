<template>
  <div>
    <b-row :class="['add-edit-drug-row', 'add-edit-' + drug_type]" style="margin-bottom: 0px; border-bottom: solid 1px #969fa3;">
      <b-col sm="12" md="12" lg="4">
        <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'select_the_drug')">
          <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
            !
          </button>
          <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
            <table>
              <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.select_the_drug : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.select_the_drug">
                <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
              </tr>
              <tr>
                <td></td>
                <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
              </tr>
            </table>
          </div>
        </div>
        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '71')">?</b-badge>
        <b-input-group>
          <b-dropdown left variant="success" no-caret>
            <template slot="button-content"> <i class="icon-settings"></i> </template>
            <b-dropdown-item @click="add_premedication(drg_ind, 'before')" v-if="drug_type === 'drug'"> {{ $t('wdm16.4158') }} </b-dropdown-item>
            <b-dropdown-item @click="add_premedication(drg_ind, 'during')" v-if="drug_type === 'drug'"> {{ $t('wdm16.4241') }} </b-dropdown-item>
            <b-dropdown-item @click="add_premedication(drg_ind, 'after')" v-if="drug_type === 'drug'"> {{ $t('wdm16.3816') }} </b-dropdown-item>
            <b-dropdown-item @click="add_mix(drg_ind, prem_ind, drug_type)"> {{ $t('wdm16.3792') }} </b-dropdown-item>
            <b-dropdown-item @click="add_prem_note(drg_ind)" v-if="drug_type === 'drug'"> {{ $t('wdm16.3938') }} </b-dropdown-item>
            <b-dropdown-item @click="add_prem_lab(drg_ind)" v-if="drug_type === 'drug'"> {{ $t('wdm16.4256') }} </b-dropdown-item>
            <b-dropdown-item @click="add_prem_msg(drg_ind)" v-if="drug_type === 'drug'"> {{ $t('wdm16.3945') }} </b-dropdown-item>
            <b-dropdown-item @click="delete_this_drug(drug_type, drg_ind, prem_ind, 'none')">{{ $t('wdm16.4398') }}</b-dropdown-item>
          </b-dropdown>
          <b-input-group-append>
            <v-select v-if="search_all_drugs" style="width: 250px" v-model="drug_data.drug" @search="drug_search" :options="drug_search_list"></v-select>
            <v-select v-else v-model="drug_data.drug" style="width: 250px;" :options="favorite_drugs"></v-select>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col sm="12" md="12" lg="4">
        <b-row class="add-edit-drug-row">
          <b-col cols="12">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '72')">?</b-badge>
            <input type="checkbox" style="width: 15px; height: 15px;" v-model="search_all_drugs" name="search_all_drugs"> tüm ilaçlarda ara
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" md="12" lg="4">
        <b-row class="add-edit-drug-row" v-if="drug_type === 'drug'">
          <b-col cols="12" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '73')">?</b-badge>
            <b-form-group label="" label-for="drug_data_type">
              <b-form-select id="drug_data_type" :plain="true" v-model="drug_data.type">
                <option v-if="drug_data.drug && drug_data.drug.value !== 'radiotherapy'" value="drug">İlaç</option>
                <option v-if="drug_data.drug && drug_data.drug.value !== 'radiotherapy'" value="protocol_premedication">Protokol premedikasyonu</option>
                <option v-if="drug_data.drug && drug_data.drug.value === 'radiotherapy'" value="radiotherapy">Radyoterapi</option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row :class="['add-edit-drug-row', 'add-edit-' + drug_type]">
      <b-col sm="12" md="12" lg="4" class="add-edit-drug-col" style="border-right: solid 1px;">
        <b-row class="add-edit-drug-row">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '74')">?</b-badge>
            <span class="add-edit-caption"> {{ $t('wdm16.3837') }}</span>
            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'input_dosage')">
              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                !
              </button>
              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                <table>
                  <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.input_dosage : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.input_dosage">
                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                  </tr>
                </table>
              </div>
            </div>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            <b-form-input type="number" min="0" class="form-control-warning" id="dosage" v-model="drug_data.dosage" @keyup="dosage_calculate(drg_ind, prem_ind, 'none', drug_data.dosage, drug_data.unit, drug_type)" @mouseup="dosage_calculate(drg_ind, prem_ind, 'none', drug_data.dosage, drug_data.unit, drug_type)"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '75')">?</b-badge>
            <span class="add-edit-caption"> {{ $t('wdm16.3808') }}</span>
            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'select_unit')">
              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                !
              </button>
              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                <table>
                  <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.select_unit : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.select_unit">
                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                  </tr>
                </table>
              </div>
            </div>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            <!-- dikkat v-select onchange'inde function v-select değişim yapılmadığı halde protokol edit modda sürekli çalışmaya devam ediyor.  -->
            <!-- <v-select :onChange="dosage_calculate(drg_ind, prem_ind, 'none', drug_data.dosage, drug_data.unit, drug_type)" style="width: 100%; float: left; padding-right: 3px; padding-left: 3px;" :name="'center-drug' + drg_ind" v-model="drug_data.unit" ref="drg_unit235234" :options="unit_options"></v-select> -->
            <b-form-select id="drug_data_unit" :plain="true" v-model="drug_data.unit" @change="dosage_calculate(drg_ind, prem_ind, 'none', drug_data.dosage, drug_data.unit, drug_type)">
              <option v-for="(unit, unit_ind) in unit_options" :value="unit"> {{ unit.label }} </option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row" v-if="patient.selected || is_treatment_list">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '76')">?</b-badge>
            <span class="add-edit-caption">{{ $t('wdm16.4000') }}</span>
            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'calculated_dosage')">
              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                !
              </button>
              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                <table>
                  <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.calculated_dosage : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.calculated_dosage">
                    <td> <b> DİKKAT </b> </td>
                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                  </tr>
                </table>
              </div>
            </div>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            {{ drug_data.calculated_dosage }}
            <span v-if="drug_data.unit !== null">{{ drug_data.unit.value | show_unit_calculate }}</span>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row" v-if="patient.selected || is_treatment_list">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '77')">?</b-badge>
            <span class="add-edit-caption">{{ $t('wdm16.4071') }}</span>
            <!--before info planned-->
            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'input_planned_dosage')">
              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                !
              </button>
              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                <table>
                  <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.input_planned_dosage : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.input_planned_dosage">
                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                  </tr>
                </table>
              </div>
            </div>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            <b-input-group>
              <b-form-input type="number" min="0" class="form-control-warning" id="planned_dosage" v-model="drug_data.planned_dosage" @keyup="$forceUpdate()" @mouseup="$forceUpdate()"></b-form-input>
              <b-input-group-append>
                <b-input-group-text v-if="drug_data.unit !== null"> {{ drug_data.unit.value | show_unit_calculate }}</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '78')">?</b-badge>
            <span class="add-edit-caption">{{ $t('wdm16.3925') }}</span>
            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'select_frequency')">
              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                !
              </button>
              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                <table>
                  <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.select_frequency : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.select_frequency">
                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                  </tr>
                </table>
              </div>
            </div>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            <b-form-select id="drug_data_frequency" :plain="true" v-model="drug_data.frequency">
              <option v-for="(freq, freq_ind) in frequency" :value="freq"> {{ freq.label }} </option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '79')">?</b-badge>
            <span class="add-edit-caption">{{ $t('wdm16.4038') }}</span>
            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'select_administration')">
              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                !
              </button>
              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                <table>
                  <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.select_administration : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.select_administration">
                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                  </tr>
                </table>
              </div>
            </div>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            <b-form-select id="drug_data_dosage_form" :plain="true" v-model="drug_data.dosage_form">
              <option v-for="(dsg_form, dsg_form_ind) in dosage_form_options" :value="dsg_form"> {{ dsg_form.label }} </option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '80')">?</b-badge>
            <span class="add-edit-caption">{{ $t('wdm16.3938') }}</span>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            <b-form-input type="text" class="form-control-warning" :id="'drug_note' + drg_ind.toString() + '_' + drug_type + '_' + prem_ind" value="drug_data.drug_note" v-model="drug_data.drug_note" :placeholder="$t('wdm16.6157')"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '80')">?</b-badge>
            <span class="add-edit-caption"> Hızlı Not Seçimi </span>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            <b-form-select id="drug_data_dosage_form" :plain="true" v-model="quick_note" @change="drug_data.drug_note = quick_note">
              <option value="IV Puşe Verilecek"> IV Puşe Verilecek </option>
              <option value="24 saatte infüze edilecek"> 24 saatte infüze edilecek </option>
              <option value="48 saatte infüze edilecek"> 48 saatte infüze edilecek </option>
            </b-form-select>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" md="12" lg="4" class="add-edit-drug-col" v-if="['12', '13'].indexOf(drug_data.dosage_form.value) === -1" style="border-right: solid 1px;">
        <b-row class="add-edit-drug-row">
          <b-col cols="12" class="add-edit-drug-col" style="color: red;"> İlaç uygulaması intravenöz veya pompa infüzörü uygulaması değil </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" md="12" lg="4" class="add-edit-drug-col" v-else style="border-right: solid 1px;">
        <b-row class="add-edit-drug-row" v-if="drug_data.dosage_form.value === '12'">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '81')">?</b-badge>
            <span class="add-edit-caption">{{ $t('wdm16.4031') }}</span>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            {{ !drug_data.fluid ? drug_data.fluid = '' : '' }}
            <v-select :name="'center-drug_fluid' + drg_ind" v-model="drug_data.fluid" ref="drug_data.fluid" :options="fluid_options">
              <template slot="option" slot-scope="option">
                <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                  <span> {{ option.label }} </span>
                </div>
              </template>
            </v-select>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row" v-if="drug_data.fluid && drug_data.dosage_form.value === '12'">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '82')">?</b-badge>
            <span class="add-edit-caption">{{ $t('wdm16.3901') }}</span>
            <!--after info duration-->
            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'input_volume')">
              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                !
              </button>
              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                <table>
                  <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.input_volume : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.input_volume">
                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                  </tr>
                </table>
              </div>
            </div>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            <b-input-group>
              <b-form-input type="number" min="0" class="form-control-warning" id="fluid_value" v-model="drug_data.fluid_value"></b-form-input>
              <b-input-group-append>
                <b-input-group-text>ml</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '83')">?</b-badge>
            <span class="add-edit-caption">{{ $t('wdm16.4221') }}</span>
            <!--before info duration-->
            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'input_duration')">
              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                !
              </button>
              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                <table>
                  <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.input_duration : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.input_duration">
                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                  </tr>
                </table>
              </div>
            </div>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            <b-input-group>
              <b-form-input type="number" min="0" class="form-control-warning" id="duration" v-model="drug_data.duration"></b-form-input>
              <b-input-group-append>
                <b-input-group-text>{{ drug_data.dosage_form.value === '12' ? $t('wdm16.4397') : 'gün' }}</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row" v-if="drug_data.fluid && drug_data.dosage_form.value === '12'">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '84')">?</b-badge>
            <span class="add-edit-caption">{{ $t('wdm16.4059') }}</span>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            <span v-if="Number.isInteger(Math.round(drug_data.fluid_value / (drug_data.duration/60)))">
              {{ Math.round(drug_data.fluid_value / (drug_data.duration/60)) }} cc/saat
            </span>
          </b-col>
        </b-row>
        <b-row class="row add-edit-drug-row" v-if="drug_data.fluid && drug_data.dosage_form.value === '12'">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '85')">?</b-badge>
            <span class="add-edit-caption"> {{ $t('wdm16.4134') }} </span>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            <span v-if="Number.isInteger(Math.round((( drug_data.fluid_value / (drug_data.duration/60)) * 33) / 100))">
              {{ Math.round((( drug_data.fluid_value / (drug_data.duration/60)) * 33) / 100) }} damla/dakika
            </span>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row" v-if="drug_data.fluid && drug_data.dosage_form.value === '12'">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '86')">?</b-badge>
            <span class="add-edit-caption"> {{ $t('wdm16.3931') }} </span>
            <!--before info duration-->
            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'input_duration_wait')">
              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                !
              </button>
              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                <table>
                  <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.input_duration_wait : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.input_duration_wait">
                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                  </tr>
                </table>
              </div>
            </div>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            <b-input-group>
              <b-form-input type="number" min="0" class="form-control-warning" id="duration_wait" v-model="drug_data.duration_wait"></b-form-input>
              <b-input-group-append>
                <b-input-group-text>{{ $t('wdm16.4397') }}</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" md="12" lg="4" class="add-edit-drug-col" v-if="drug_type === 'drug'">
        <b-row class="add-edit-drug-row">
          <b-col cols="12" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '87')">?</b-badge>
            <b-form-group label="Model" label-for="drug_data_model" :label-cols="3" :horizontal="true">
              <b-form-select id="drug_data_model" :plain="true" v-model="drug_data.model" @change="ChangeDrugModel(drg_ind)">
                {{ drug_data.repeat === 0 || drug_data.repeat === '' ? drug_data.drug && drug_data.drug.value === 'radiotherapy' ? drug_data.model = 'radiotherapy' : drug_data.model = 'recipe' : drug_data.model }}
                <option v-if="drug_data.drug && drug_data.drug.value !== 'radiotherapy'" value="chemotherapy">Kemoterapi</option>
                <option v-if="drug_data.drug && drug_data.drug.value !== 'radiotherapy'" value="recipe">Reçete</option>
                <option v-if="drug_data.drug && drug_data.drug.value === 'radiotherapy'" value="radiotherapy">Radyoterapi</option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="add-edit-drug-row">
          <b-col cols="12" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '88')">?</b-badge>
            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'select_drug_days')">
              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                !
              </button>
              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                <table>
                  <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.select_drug_days : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.select_drug_days">
                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                  </tr>
                </table>
              </div>
            </div>
            <b-form-group label="Günler" :label-for="'select-drug-day_' + drg_ind" :horizontal="true">
              <b-input-group>
                <b-form-select :id="'select-drug-day_' + drg_ind" :plain="true">
                  <option disabled value="">{{ $t('wdm16.4055') }}</option>
                  <option v-for="day in parseInt(drug_data.period)" :value="day">{{ day }}</option>
                </b-form-select>
                <b-input-group-append>
                  <b-button @click="add_day_to_this_drug(drg_ind)" variant="primary"><i class="icon-plus"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="add-edit-drug-row">
          <b-col cols="12" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '89')">?</b-badge>
            <b-button v-for="day, dy_ind in drug_data.day" :key="'drug_data.day_' + dy_ind" variant="secondary" size="md" @click="delete_drug_day(drg_ind, dy_ind)">{{ day }}</b-button>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '90')">?</b-badge>
            <span class="add-edit-caption">{{ $t('wdm16.1179') }}</span>
            <!--info drug dosage -->
            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'input_period')">
              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                !
              </button>
              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                <table>
                  <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.input_period : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.input_period">
                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                  </tr>
                </table>
              </div>
            </div>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            <b-form-select @change="record_type_of_repeats_analyze()" v-model="drug_data.period" :plain="true">
              <option v-for="i in 365"> {{ i }} </option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '91')">?</b-badge>
            <span class="add-edit-caption">{{ $t('wdm16.4574') }}</span>
            <!--info drug dosage -->
            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'input_repeat')">
              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                !
              </button>
              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                <table>
                  <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.input_repeat : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.input_repeat">
                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                  </tr>
                </table>
              </div>
            </div>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            <b-form-select @change="record_type_of_repeats_analyze()" v-model="drug_data.repeat" :plain="true">
              <option value=""> Tekrar yok </option>
              <option v-for="i in 100"> {{ i }} </option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row" v-if="drug_data.model === 'recipe' && drug_data.repeat !== ''">
          <b-col cols="12" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '92')">?</b-badge>
            <b-form-select v-model="drug_data.recipe_data.record_type_of_repeats" @change="record_type_of_repeats_analyze()" :plain="true">
              <option value="one_record"> Döngü olmadan tek kayıt oluştur </option>
              <option value="new_record"> Her tekrardan yeni kayıt oluştur </option>
              <option value="new_cycles"> Her tekrardan yeni döngü oluştur </option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row" v-if="drug_data.model === 'recipe'">
          <b-col cols="12" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '93')">?</b-badge>
            <strong> Başlama tarihi = </strong> {{ DateFormat(drug_data.recipe_data.started_at) }}
            <br>
            <strong> Bitiş tarihi = </strong> {{ DateFormat(drug_data.recipe_data.ending_at) }}
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row">
          <b-col cols="6" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '94')">?</b-badge>
            <span class="add-edit-caption">{{ $t('wdm16.3849') }}</span>
            <!--info drug dosage -->
            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'input_delay')">
              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                !
              </button>
              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                <table>
                  <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.input_delay : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.input_delay">
                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                  </tr>
                </table>
              </div>
            </div>
          </b-col>
          <b-col cols="6" class="add-edit-drug-col">
            <b-form-input type="number" id="delay" v-model="drug_data.delay"></b-form-input>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" md="12" lg="4" class="add-edit-drug-col" v-if="drug_type !== 'drug'">
        <b-row class="add-edit-drug-row" v-if="!premedication_add">
          <b-col cols="12" class="add-edit-drug-col">
            <div v-if="main_drug.drug" style="text-align: left;">
              <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '95')">?</b-badge>
              <span class="add-edit-caption">{{ main_drug.drug.label }} günleri </span>
            </div>
            <div v-else style="text-align: left;">{{ $t('wdm16.4204') }}</div>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row">
          <b-col cols="12" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '96')">?</b-badge>
            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'select_prem_drug_days')">
              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                !
              </button>
              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                <table>
                  <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.select_prem_drug_days : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.select_prem_drug_days">
                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                  </tr>
                </table>
              </div>
            </div>
            <template v-for="(day, day_ind) in main_drug.day">
              <b-button v-if="drug_data.drug_days.indexOf(day) !== -1" variant="success" @click="delete_drug_day_prem(drg_ind, prem_ind, drug_data.drug_days.indexOf(day), drug_type)">{{ day }}</b-button>
              <b-button v-else variant="danger" @click="add_day_to_this_drug_day_prem(drg_ind, prem_ind, drug_type, day)">{{ day }}</b-button>
            </template>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row" v-if="drug_type !== 'during'">
          <b-col cols="12" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '97')">?</b-badge>
            <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, 'none', 'select_prem_days')">
              <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                !
              </button>
              <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                <table>
                  <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].info_list.select_prem_days : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].info_list.select_prem_days">
                    <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                    <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                  </tr>
                </table>
              </div>
            </div>
            <b-form-group label="Gün ekle" :label-for="'select-day-' + drug_type + '_' + drg_ind + '_' +  prem_ind" :horizontal="true">
              <b-input-group>
                <b-form-select :id="'select-day-' + drug_type + '_' + drg_ind + '_' +  prem_ind" :plain="true">
                  <option disabled value="">{{ $t('wdm16.4055') }}</option>
                  <option value="0">{{ $t('wdm16.3984') }}</option>
                  <option v-for="day in parseInt(main_drug.period)" :value="day">
                    {{ day }}
                  </option>
                </b-form-select>
                <b-input-group-append>
                  <b-button variant="success" @click="add_day_to_this_drug_prem(drg_ind, prem_ind)"><i class="icon-plus"></i></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="add-edit-drug-row" v-if="drug_type !== 'during'">
          <b-col cols="12" class="add-edit-drug-col">
            <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '98')">?</b-badge>
            <div v-for="day, dy_ind in drug_data.day" style="border-radius: 5px; background-color: #4dbd74; width: 70%;">
              <!-- <button class="btn-success" style="border-radius: 5px;" @click="delete_day_prem(drg_ind, prem_ind, dy_ind)"><i class="fa fa-minus"></i></button> -->
              {{ day === 0 ? 'aynı gün içinde ' : day + ' gün' }} {{ drug_type }}
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--mixxxxxxxxxxxxxxxxxxxxxxxxxxxx drug-->
    <b-row :class="['add-edit-drug-row', 'add-edit-mix-' + drug_type]" v-if="drug_data.drug_mix.length > 0">
      <b-col cols="12" class="add-edit-drug-col" style="">
        <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '99')">?</b-badge>
        <span v-if="drug_data.drug" style="font-weight: bold; font-style: italic;">{{ drug_data.drug.label }} karışımında kullanılan ilaçlar</span>
        <span v-else style="font-weight: bold; font-style: italic; color: red;">Lütfen ana ilacı seçiniz</span>
      </b-col>
    </b-row>
    <template v-for="(mx, mx_index) in drug_data.drug_mix">
      <b-row :class="['add-edit-drug-row', 'add-edit-mix-' + drug_type]">
        <b-col sm="12" md="12" lg="4">
          <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, mx_index, 'select_the_drug')">
            <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
              !
            </button>
            <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
              <table>
                <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].drug_mix[mx_index].info_list.select_the_drug : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].drug_mix[mx_index].info_list.select_the_drug">
                  <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                  <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                </tr>
                <tr>
                  <td></td>
                  <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                </tr>
              </table>
            </div>
          </div>
          <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '100')">?</b-badge>
          <b-input-group>
            <b-dropdown left variant="success" no-caret>
              <template slot="button-content"> <i class="icon-settings"></i> </template>
              <b-dropdown-item @click="delete_this_drug(drug_type, drg_ind, prem_ind, mx_index)">{{ $t('wdm16.4398') }}</b-dropdown-item>
            </b-dropdown>
            <b-input-group-append>
              <v-select v-if="search_all_drugs" style="width: 250px;" :name="'center-drug_mix' + drg_ind" v-model="mx.drug" ref="mx.drug" @search="drug_search" :options="drug_search_list"></v-select>
              <v-select v-else :name="'center-drug_mix' + drg_ind" v-model="mx.drug" style="width: 250px;" ref="mx.drug" :options="favorite_drugs"></v-select>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row :class="['add-edit-drug-row', 'add-edit-mix-' + drug_type]">
        <b-col sm="12" md="12" lg="4" class="add-edit-drug-col">
          <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '101')">?</b-badge>
          <b-row class="add-edit-drug-row">
            <b-col cols="6" class="add-edit-drug-col">
              <span class="add-edit-caption"> {{ $t('wdm16.3837') }}</span>
              <!--info drug dosage -->
              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, mx_index, 'input_dosage')">
                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                  !
                </button>
                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                  <table>
                    <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].drug_mix[mx_index].info_list.input_dosage : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].drug_mix[mx_index].info_list.input_dosage">
                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                    </tr>
                  </table>
                </div>
              </div>
            </b-col>
            <b-col cols="6" class="add-edit-drug-col">
              <b-form-input type="number" min="0" class="form-control-warning" id="dosage" v-model="mx.dosage" @keyup="dosage_calculate(drg_ind, prem_ind, mx_index, mx.dosage, mx.unit, drug_type)" @mouseup="dosage_calculate(drg_ind, prem_ind, mx_index, mx.dosage, mx.unit, drug_type)"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="add-edit-drug-row">
            <b-col cols="6" class="add-edit-drug-col">
              <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '102')">?</b-badge>
              <span class="add-edit-caption"> {{ $t('wdm16.3808') }}</span>
              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, mx_index, 'select_unit')">
                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                  !
                </button>
                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                  <table>
                    <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].drug_mix[mx_index].info_list.select_unit : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].drug_mix[mx_index].info_list.select_unit">
                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                    </tr>
                  </table>
                </div>
              </div>
            </b-col>
            <b-col cols="6" class="add-edit-drug-col">
              <b-form-select id="mx_unit" :plain="true" v-model="mx.unit" @change="dosage_calculate(drg_ind, prem_ind, mx_index, mx.dosage, mx.unit, drug_type)">
                <option v-for="(unit, unit_ind) in unit_options" :value="unit"> {{ unit.label }} </option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="add-edit-drug-row" v-if="patient.selected || is_treatment_list">
            <b-col cols="6" class="add-edit-drug-col">
              <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '103')">?</b-badge>
              <span class="add-edit-caption"> {{ $t('wdm16.4000') }}</span>
              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, mx_index, 'calculated_dosage')">
                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                  !
                </button>
                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                  <table>
                    <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].drug_mix[mx_index].info_list.calculated_dosage : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].drug_mix[mx_index].info_list.calculated_dosage">
                      <td> <b> DİKKAT </b> </td>
                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                    </tr>
                  </table>
                </div>
              </div>
            </b-col>
            <b-col cols="6" class="add-edit-drug-col">
              {{ mx.calculated_dosage }}
              <span v-if="mx.unit"> {{ mx.unit.value | show_unit_calculate }} </span>
            </b-col>
          </b-row>
          <b-row class="add-edit-drug-row" v-if="patient.selected || is_treatment_list">
            <b-col cols="6" class="add-edit-drug-col">
              <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '104')">?</b-badge>
              <span class="add-edit-caption">{{ $t('wdm16.4071') }}</span>
              <!--before info planned-->
              <div class="btn-group" style="float: left;" v-if="info_func(drg_ind, prem_ind, drug_type, mx_index, 'input_planned_dosage')">
                <button id="group1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style="background-color: rgb(0, 186, 180); width: 20px; height: 20px; border-radius: 10px 10px; color: white; text-align: center;">
                  !
                </button>
                <div class="dropdown-menu" aria-labelledby="group1" style="background-color: rgb(225, 254, 255); padding: 5px; border-radius: 10px 10px; box-shadow: -2px -2px 2px #3eb1a8; width: 300px;">
                  <table>
                    <tr v-for="(info, info_ind) in drug_type === 'drug' ? pro_inf_list.drugs[drg_ind].drug_mix[mx_index].info_list.input_planned_dosage : pro_inf_list.drugs[drg_ind][drug_type][prem_ind].drug_mix[mx_index].info_list.input_planned_dosage">
                      <td> <b> {{ $t('wdm16.' + variable_langs_id_list.info[info.validation]) }} </b> </td>
                      <td> {{ $t('wdm16.' + variable_langs_id_list.info[info.info]) }} {{ info.value }} </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td style="text-align: right;"> <img src="img/favicon.png" width="33px;" height="33px;"></td>
                    </tr>
                  </table>
                </div>
              </div>
            </b-col>
            <b-col cols="6" class="add-edit-drug-col" v-if="patient.selected || is_treatment_list">
              <b-input-group>
                <b-form-input type="number" min="0" class="form-control-warning" id="planned_dosage" v-model="mx.planned_dosage" @keyup="$forceUpdate()" @mouseup="$forceUpdate()"></b-form-input>
                <b-input-group-append>
                  <b-input-group-text v-if="mx.unit"> {{ mx.unit.value | show_unit_calculate }}</b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row class="add-edit-drug-row">
            <b-col cols="6" class="add-edit-drug-col">
              <b-badge v-if="help.show" style="cursor:pointer;" variant="warning" @click="store.commit('help_no', '105')">?</b-badge>
              <span class="add-edit-caption">{{ $t('wdm16.3938') }}</span>
            </b-col>
            <b-col cols="6" class="add-edit-drug-col">
              <b-form-input type="text" class="form-control-warning" :id="'drug_note' + drg_ind.toString() + '_' + drug_type + '_' + prem_ind + '_' + mx_index" value="mx.drug_note" v-model="mx.drug_note" :placeholder="$t('wdm16.6157')"></b-form-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';
// import ProtocolService from '@/services/public/protocol';
import {
  default as ConfirmForm
} from './ConfirmForm';
// import { DateFunctions } from '@/services/public/date_functions';
import { DosageCalculator } from '@/services/public/functions';
import {
  default as Modal
} from './Modal';
import vSelect from 'vue-select';
import moment from 'moment';
import OptionService from '@/services/option';

export default {
  name: 'TreatmentAddEditDrug',
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      lang: 'lang',
      help: 'help'
    })
  },
  props: {
    search_all_drugs: {
      type: Boolean,
      required: false
    },
    premedication_add: {
      type: Boolean,
      required: false
    },
    mother_children: {
      type: Object,
      required: false
    },
    drug_data: {
      type: Object,
      required: true
    },
    pro_inf_list: {
      type: Object,
      required: true
    },
    drg_ind: {
      type: Number,
      required: true
    },
    prem_ind: {
      type: String,
      required: true
    },
    treatment_data: {
      type: Object,
      required: true
    },
    drug_type: {
      type: String,
      required: true
    },
    main_drug: {
      type: Object,
      required: false
    },
    is_treatment_list: {
      type: Boolean,
      required: false
    },
    patient_data: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      store: store,
      quick_note: '',
      drug_search_list: [],
      favorite_drugs: require('@/options/favorite_drugs').options,
      info_options: require('@/options/info').options,
      fluid_options: require('@/options/fluids').options,
      dosage_form_options: require('@/options/dosage_form').options,
      frequency: require('@/options/frequency').options,
      unit_options: require('@/options/unit').options,
      variable_langs_id_list: require('@/options/variable_langs_id_list').options,
      types: ['before', 'after', 'during']
    };
  },
  beforeCreate: function () {},
  created: function () {},
  mounted: function () {},
  methods: {
    calculate_info_list_new: function (subject, drg_ind = 'none', prem_ind = 'none', mix_ind = 'none', drug_type = 'none') {
      if (subject === 'all_drugs') {
        this.pro_inf_list.others = {};
        this.pro_inf_list.drugs = {};
        for (let drg_ind in this.treatment_data.selected_protocol.drugs) {
          this.calculate_drug_info(drg_ind);
        }
      } else if (subject === 'new_drug') {
        this.calculate_drug_info(drg_ind);
      } else if (subject === 'delete_drug') {
        this.$delete(this.pro_inf_list.drugs, drg_ind);
      } else if (subject === 'add_premedication') {
        let pr_ind = '';
        if (Object.keys(this.pro_inf_list.drugs[drg_ind][drug_type]).length === 0) {
          pr_ind = 0;
        } else {
          pr_ind = Object.keys(this.pro_inf_list.drugs[drg_ind][drug_type]).length;
        }
        this.pro_inf_list.drugs[drg_ind][drug_type][pr_ind] = { info_list: { input_dosage: {}, input_volume: {}, calculated_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_frequency: {}, select_administration: {}, select_unit: {}, input_duration_wait: {}, select_prem_drug_days: {}, select_prem_days: {}, select_the_drug: {} }, drug_mix: {} };
      } else if (subject === 'add_mix') {
        if (drug_type === 'drug') {
          this.pro_inf_list.drugs[drg_ind].drug_mix[mix_ind] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_the_drug: {}, select_unit: {} } };
        } else {
          this.pro_inf_list.drugs[drg_ind][drug_type][prem_ind].drug_mix[mix_ind] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_the_drug: {}, select_unit: {} } };
        }
      } else if (subject === 'drug_go_up') {
        let arr = this.pro_inf_list.drugs[drg_ind];
        this.pro_inf_list.drugs[drg_ind] = this.pro_inf_list.drugs[drg_ind - 1];
        this.pro_inf_list.drugs[drg_ind - 1] = arr;
      } else if (subject === 'drug_go_down') {
        let arr = this.pro_inf_list.drugs[drg_ind];
        this.pro_inf_list.drugs[drg_ind] = this.pro_inf_list.drugs[drg_ind + 1];
        this.pro_inf_list.drugs[drg_ind + 1] = arr;
      }
    },
    calculate_drug_info: function (drg_ind) {
      let data = this.treatment_data.selected_protocol.drugs[drg_ind];
      this.pro_inf_list.drugs[drg_ind] = { info_list: { input_dosage: {}, input_volume: {}, calculated_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_frequency: {}, select_administration: {}, select_unit: {}, input_duration_wait: {}, select_drug_days: {}, input_period: {}, input_repeat: {}, input_delay: {}, select_the_drug: {}, select_note_drug_days: {}, select_lab_drug_days: {}, select_msg_drug_days: {}, select_note_days: {}, select_msg_days: {}, select_lab_days: {}, select_lab_tests: {}, text_note_drug: {} }, before: {}, after: {}, during: {}, drug_mix: {} };
      for (let mx in data.drug_mix) {
        this.pro_inf_list.drugs[drg_ind].drug_mix[mx] = { info_list: { input_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_unit: {}, select_the_drug: {} } };
      }
      for (let pr in this.types) {
        for (let pr_ind in data[this.types[pr]]) {
          this.pro_inf_list.drugs[drg_ind][this.types[pr]][pr_ind] = { info_list: { input_dosage: {}, input_volume: {}, calculated_dosage: {}, input_planned_dosage: {}, input_duration: {}, select_frequency: {}, select_administration: {}, select_unit: {}, input_duration_wait: {}, select_prem_drug_days: {}, select_prem_days: {}, select_the_drug: {} }, drug_mix: {} };
          for (let mx in data[this.types[pr]][pr_ind].drug_mix) {
            this.pro_inf_list.drugs[drg_ind][this.types[pr]][pr_ind].drug_mix[mx] = { info_list: { input_dosage: {}, input_volume: {}, input_planned_dosage: {}, input_duration: {}, select_the_drug: {}, select_unit: {} } };
          }
        }
      }
    },
    pro_inf_list_clean () {
      this.pro_inf_list.others = {};
      this.pro_inf_list.drugs = {};
    },
    delete_this_drug (type, drg_ind, prem_ind, mx_index) {
      if (type === 'drug') {
        if (mx_index === 'none') {
          this.treatment_data.selected_protocol.drugs.splice(drg_ind, 1);
        } else {
          this.treatment_data.selected_protocol.drugs[drg_ind].drug_mix.splice(mx_index, 1);
        }
      } else { // before during after
        if (mx_index === 'none') {
          this.treatment_data.selected_protocol.drugs[drg_ind][type].splice(prem_ind, 1);
        } else {
          this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].drug_mix.splice(mx_index, 1);
        }
      }
      this.calculate_info_list_new('all_drugs');
      this.$forceUpdate();
    },
    add_premedication (drg_ind, type) {
      let i = this.treatment_data.selected_protocol.drugs[drg_ind][type].length - 1;
      let drg_ord = 0;
      if (type && drg_ind !== undefined) {
        if (this.treatment_data.selected_protocol.drugs[drg_ind][type][i] === undefined) {
          drg_ord = 1;
        } else {
          drg_ord = parseInt(this.treatment_data.selected_protocol.drugs[drg_ind][type][i]['drug_order']) + 1;
        }
      }
      let prem = { day: [], minutes_drug_relation: [], drug_note: '', drug_days: [], drug_mix: [], unit: { value: 'mg', caption: 'mg' }, frequency: { caption: '1x1', value: '1x1' }, dosage_form: { caption: 'İntravenöz', value: '12' }, duration_wait: 0, drug_order: drg_ord, dosage: 0, planned_dosage: 0, fluid_value: 0, fluid: '', duration: 0, drug: '' };
      if (type === 'during') {
        prem.day = [0];
      }
      this.treatment_data.selected_protocol.drugs[drg_ind][type].push(prem);
      this.calculate_info_list_new('add_premedication', drg_ind, 'none', 'none', type);
    },
    add_mix (drg_ind, prem_ind, type) {
      let mix = { drug_note: '', unit: { value: 'mg', caption: 'mg' }, dosage: 0, planned_dosage: 0, drug: '' };
      let mix_ind = '';
      if (type === 'drug') {
        this.treatment_data.selected_protocol.drugs[drg_ind].drug_mix.push(mix);
        mix_ind = this.treatment_data.selected_protocol.drugs[drg_ind].drug_mix.length - 1;
      } else {
        this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].drug_mix.push(mix);
        mix_ind = this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].drug_mix.length - 1;
      }
      console.log('mix_ind :::::::', mix_ind);
      this.calculate_info_list_new('add_mix', drg_ind, prem_ind, mix_ind.toString(), type);
      this.$forceUpdate();
    },
    add_prem_note (drg_ind) {
      this.treatment_data.selected_protocol.drugs[drg_ind].premedications.note.list.push({ type: 'general', day: [], drug_days: [], note: '' });
      this.$forceUpdate();
    },
    add_prem_lab (drg_ind) {
      this.treatment_data.selected_protocol.drugs[drg_ind].premedications.laboratory.list.push({ day: [], drug_days: [], note: '', tests: [] });
      this.$forceUpdate();
    },
    add_prem_msg (drg_ind) {
      this.treatment_data.selected_protocol.drugs[drg_ind].premedications.message_to_patient.list.push({ type: 'general', day: [], drug_days: [], send_languages: [], message: '' });
      this.$forceUpdate();
    },
    delete_drug_day (d_index, dy_ind) {
      let day = this.treatment_data.selected_protocol.drugs[d_index].day[dy_ind];
      this.treatment_data.selected_protocol.drugs[d_index].day.splice(dy_ind, 1);
      let prem_types = ['before', 'during', 'after'];
      for (let pr in prem_types) {
        for (let prem_ind in this.treatment_data.selected_protocol.drugs[d_index][prem_types[pr]]) {
          let prem_drug_days = this.treatment_data.selected_protocol.drugs[d_index][prem_types[pr]][prem_ind].drug_days;
          prem_drug_days.splice(prem_drug_days.indexOf(day), 1);
        }
      }
      for (let msg_ind in this.treatment_data.selected_protocol.drugs[d_index].premedications.message_to_patient.list) {
        let msg_drug_days = this.treatment_data.selected_protocol.drugs[d_index].premedications.message_to_patient.list[msg_ind].drug_days;
        msg_drug_days.splice(msg_drug_days.indexOf(day), 1);
      }
      for (let nt_ind in this.treatment_data.selected_protocol.drugs[d_index].premedications.note.list) {
        let nt_drug_days = this.treatment_data.selected_protocol.drugs[d_index].premedications.note.list[nt_ind].drug_days;
        nt_drug_days.splice(nt_drug_days.indexOf(day), 1);
      }
      for (let lab_ind in this.treatment_data.selected_protocol.drugs[d_index].premedications.laboratory.list) {
        let lab_drug_days = this.treatment_data.selected_protocol.drugs[d_index].premedications.laboratory.list[lab_ind].drug_days;
        lab_drug_days.splice(lab_drug_days.indexOf(day), 1);
      }
    },
    add_day_to_this_drug: function (d_index) {
      let value = parseInt(document.getElementById('select-drug-day_' + d_index).value);
      if (this.treatment_data.selected_protocol.drugs[d_index].day.indexOf(value) === -1) {
        this.treatment_data.selected_protocol.drugs[d_index].day.push(value);
      }
      this.treatment_data.selected_protocol.drugs[d_index].day.sort((a, b) => a - b);
    },
    ChangeDrugModel: function (drg_ind) {
      let drg_data = this.treatment_data.selected_protocol.drugs[drg_ind];
      if (drg_data.model === 'recipe') {
        drg_data.recipe_data = { is_scattered_model: false, scattered_day_dosage_model: [], cycles: [], record_type_of_repeats: 'one_record', status: 'continuing', patient: {}, data_structure: 'continuously', operation_type: 'new_drug_prescribed', started_at: '', ending_at: '' };
        this.data_structure_analyze();
      } else {
        this.$delete(drg_data, 'recipe_data');
      }
      this.$forceUpdate();
    },
    data_structure_analyze: function () {
      let data = this.treatment_data.selected_protocol.drugs;
      for (let i in data) {
        if (data[i].model === 'recipe') {
          if (data[i].repeat && data[i].recipe_data.cycles.length === 0 && data[i].recipe_data.scattered_day_dosage_model.length === 0) {
            data[i].recipe_data.data_structure = 'ending_without_cycles';
          } else if (data[i].recipe_data.cycles.length > 0 && data[i].recipe_data.scattered_day_dosage_model.length === 0) {
            data[i].recipe_data.data_structure = 'ending_with_cycles';
          } else if (data[i].recipe_data.scattered_day_dosage_model.length > 0 && data[i].recipe_data.cycles.length === 0) {
            data[i].recipe_data.data_structure = 'ending_with_scattered';
          } else if (data[i].recipe_data.scattered_day_dosage_model.length > 0 && data[i].recipe_data.cycles.length > 0) {
            data[i].recipe_data.data_structure = 'ending_with_scattered_cycles';
          } else if ((parseInt(data[i].repeat) === 0 || data[i].repeat === '') && data[i].recipe_data.ending_at === '' && data[i].recipe_data.scattered_day_dosage_model.length === 0) {
            data[i].recipe_data.data_structure = 'continuously';
          }
          this.calculate_recipe_dates();
        }
      }
    },
    calculate_recipe_dates: function () {
      if (!this.treatment_data.started_at) {
        return;
      }
      let drg_data = this.treatment_data.selected_protocol.drugs;
      for (let i in drg_data) {
        if (drg_data[i].model === 'recipe') {
          if (drg_data[i].recipe_data.data_structure === 'ending_with_cycles') {
            for (let k = 0; k < drg_data[i].recipe_data.cycles.length; k++) {
              let calc_date = new Date(this.treatment_data.started_at);
              calc_date.setDate(calc_date.getDate() + parseInt(drg_data[i].delay));
              drg_data[i].recipe_data.started_at = moment(calc_date.toISOString()).format('YYYY-MM-DD');
              if (k === 0) {
                drg_data[i].recipe_data.cycles[0].started_at = moment(calc_date.toISOString()).format('YYYY-MM-DD');
                calc_date.setDate(calc_date.getDate() + parseInt(drg_data[i].period));
                drg_data[i].recipe_data.cycles[0].ending_at = moment(calc_date.toISOString()).format('YYYY-MM-DD');
                if (k === drg_data[i].recipe_data.cycles.length - 1) {
                  drg_data[i].recipe_data.ending_at = drg_data[i].recipe_data.cycles[k].ending_at;
                }
              } else {
                calc_date.setDate(calc_date.getDate() + (parseInt(drg_data[i].period) * k));
                drg_data[i].recipe_data.cycles[k].started_at = moment(calc_date.toISOString()).format('YYYY-MM-DD');
                calc_date.setDate(calc_date.getDate() + parseInt(drg_data[i].period));
                drg_data[i].recipe_data.cycles[k].ending_at = moment(calc_date.toISOString()).format('YYYY-MM-DD');
                if (k === drg_data[i].recipe_data.cycles.length - 1) {
                  drg_data[i].recipe_data.ending_at = drg_data[i].recipe_data.cycles[k].ending_at;
                }
              }
            }
          } else if (this.treatment_data.started_at && (drg_data[i].recipe_data.data_structure === 'continuously' || drg_data[i].recipe_data.data_structure === 'ending_without_cycles')) {
            let calc_date = new Date(this.treatment_data.started_at);
            calc_date.setDate(calc_date.getDate() + parseInt(drg_data[i].delay));
            drg_data[i].recipe_data.started_at = moment(calc_date.toISOString()).format('YYYY-MM-DD');
            if (drg_data[i].repeat) {
              let period = parseInt(drg_data[i].period);
              calc_date.setDate(calc_date.getDate() + parseInt(drg_data[i].repeat) * period);
              drg_data[i].recipe_data.ending_at = moment(calc_date.toISOString()).format('YYYY-MM-DD');
            } else {
              drg_data[i].recipe_data.ending_at = '';
            }
          }
        }
      }
      this.$forceUpdate();
    },
    record_type_of_repeats_analyze: function () {
      let data = this.treatment_data.selected_protocol.drugs;
      for (let i in data) {
        if (data[i].model !== 'recipe') {
          continue;
        }
        if (data[i].recipe_data.status === undefined) {
          data[i].recipe_data = { is_scattered_model: false, scattered_day_dosage_model: [], cycles: [], record_type_of_repeats: 'one_record', status: 'continuing', patient: {}, data_structure: 'continuously', operation_type: 'new_drug_prescribed', started_at: '', ending_at: '' };
        }
        if (!data[i].recipe_data.record_type_of_repeats) {
          data[i].recipe_data.record_type_of_repeats = 'one_record';
        }
        if (data[i].repeat === '') {
          data[i].recipe_data.record_type_of_repeats = 'one_record';
        }
        if (data[i].recipe_data.record_type_of_repeats === 'new_cycles' && !data[i].recipe_data.is_scattered_model) {
          data[i].recipe_data.cycles = [];
          for (let k = 0; k < parseInt(data[i].repeat); k++) {
            data[i].recipe_data.cycles.push({ days: data[i].day, started_at: '', ending_at: '', dosage: data[i].dosage, unit: data[i].unit.value, calculated_dosage: data[i].calculated_dosage, planned_dosage: data[i].planned_dosage, patient: [], frequency: data[i].frequency.value, period: data[i].period });
          }
        } else if (data[i].recipe_data.record_type_of_repeats === 'new_cycles' && data[i].recipe_data.is_scattered_model) {
          for (let k = 0; k < parseInt(data.repeat); k++) {
            data[i].recipe_data.cycles.push({ scattered_day_dosage_model: data[i].recipe_data.scattered_day_dosage_model });
          }
        } else {
          data[i].recipe_data.cycles = [];
        }
      }
      this.$forceUpdate();
      this.data_structure_analyze();
    },
    DateFormat: function (date) {
      if (date) {
        return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
      } else {
        return '';
      }
    },
    dosage_calculate: function (drg_ind, prem_ind, mix_ind, dosage, unit, type) {
      this.$forceUpdate();
      if (!unit || !this.patient.selected) {
        return;
      }
      let dose = 0;
      let calculated_dosage = 0;
      if (type === 'drug') {
        if (mix_ind === 'none') {
          dose = this.treatment_data.selected_protocol.drugs[drg_ind].dosage;
          if (dose) {
            calculated_dosage = DosageCalculator.calculate(unit.value, this.patient_data, dose);
            this.treatment_data.selected_protocol.drugs[drg_ind].calculated_dosage = calculated_dosage;
            this.treatment_data.selected_protocol.drugs[drg_ind].planned_dosage = calculated_dosage;
          } else {
            this.treatment_data.selected_protocol.drugs[drg_ind].calculated_dosage = '';
            this.treatment_data.selected_protocol.drugs[drg_ind].planned_dosage = '';
          }
        } else {
          dose = this.treatment_data.selected_protocol.drugs[drg_ind].drug_mix[mix_ind].dosage;
          if (dose) {
            calculated_dosage = DosageCalculator.calculate(unit.value, this.patient_data, dose);
            this.treatment_data.selected_protocol.drugs[drg_ind].drug_mix[mix_ind].calculated_dosage = calculated_dosage;
            this.treatment_data.selected_protocol.drugs[drg_ind].drug_mix[mix_ind].planned_dosage = calculated_dosage;
          } else {
            this.treatment_data.selected_protocol.drugs[drg_ind].drug_mix[mix_ind].calculated_dosage = '';
            this.treatment_data.selected_protocol.drugs[drg_ind].drug_mix[mix_ind].planned_dosage = '';
          }
        }
      } else { // before during after
        if (mix_ind === 'none') {
          dose = this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].dosage;
          if (dose) {
            calculated_dosage = DosageCalculator.calculate(unit.value, this.patient_data, dose);
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].calculated_dosage = calculated_dosage;
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].planned_dosage = calculated_dosage;
          } else {
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].calculated_dosage = '';
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].planned_dosage = '';
          }
        } else {
          dose = this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].drug_mix[mix_ind].dosage;
          if (dose) {
            calculated_dosage = DosageCalculator.calculate(unit.value, this.patient_data, dose);
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].drug_mix[mix_ind].calculated_dosage = calculated_dosage;
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].drug_mix[mix_ind].planned_dosage = calculated_dosage;
          } else {
            calculated_dosage = DosageCalculator.calculate(unit.value, this.patient_data, dose);
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].drug_mix[mix_ind].calculated_dosage = '';
            this.treatment_data.selected_protocol.drugs[drg_ind][type][prem_ind].drug_mix[mix_ind].planned_dosage = '';
          }
        }
      }
      this.$forceUpdate();
    },
    drug_search: function (search, loading) {
      if (this.search_all_drugs === true) {
        if (search && search.length > 2) {
          loading(true);
          let data = { 'search': search, 'wdm_type': 'wdm2' };
          OptionService.search_wdm_option(data)
            .then(resp => {
              if (resp.data.status === 'success') {
                this.drug_search_list = resp.data.result;
              } else {
                this.drug_search_list = [];
              }
              loading(false);
            });
        }
      }
    },
    info_func: function (info_drg_ind, prem_ind, type, mix_ind, subject, other_ind = 'none') {
      let res = false;
      if (subject === 'input_dosage') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].dosage;
            if (dosage_second < this.info_options[subject].min.value) {
              data.info_list[subject].min = this.info_options[subject].min;
              res = true;
            } else if (data.info_list[subject].min !== undefined) { this.$delete(data.info_list[subject], 'min'); }
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // drug mix
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            let dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].dosage;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && mix_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].dosage;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && prem_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind][type].length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // before after during mix
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            let dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].dosage;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && mix_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'input_planned_dosage') {
        let data = {};
        let dosage_second = '';
        if (type === 'drug') {
          if (mix_ind === 'none') {
            data = this.pro_inf_list.drugs[info_drg_ind];
            dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].planned_dosage;
            if (dosage_second < this.info_options[subject].min.value) {
              data.info_list[subject].min = this.info_options[subject].min;
              res = true;
            } else if (data.info_list[subject].min !== undefined) { this.$delete(data.info_list[subject], 'min'); }
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].planned_dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // drug mix
            data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].planned_dosage;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && mix_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].planned_dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].planned_dosage;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && prem_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind][type].length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].planned_dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // before after during mix
            data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].planned_dosage;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && mix_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let dosage_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].planned_dosage;
              let percent = Math.abs((dosage_first - dosage_second) * 100 / dosage_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = [];
            if (dosage_second) { dosage_chars = dosage_second.toString().split(''); }
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
        if (dosage_second < this.info_options[subject].min.value) {
          data.info_list[subject].min = this.info_options[subject].min;
          res = true;
        } else if (data.info_list[subject].min !== undefined) { this.$delete(data.info_list[subject], 'min'); }
      } else if (subject === 'input_volume') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let volume_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].fluid_value;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let volume_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].fluid_value;
              let percent = Math.abs((volume_first - volume_second) * 100 / volume_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = volume_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let volume_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].fluid_value;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && prem_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind][type].length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let volume_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].fluid_value;
              let percent = Math.abs((volume_first - volume_second) * 100 / volume_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = volume_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'input_duration') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let duration_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].duration;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let duration_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].duration;
              let percent = Math.abs((duration_first - duration_second) * 100 / duration_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = duration_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let duration_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].duration;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && prem_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind][type].length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let duration_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].duration;
              let percent = Math.abs((duration_first - duration_second) * 100 / duration_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = duration_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'select_unit') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        } else { // before after
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].unit === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        }
      } else if (subject === 'select_frequency') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].frequency === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].frequency === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        } else { // before after
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].frequency === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].frequency === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        }
      } else if (subject === 'select_administration') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        } else { // before after
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          } else {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            data.info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].dosage_form === null) {
              data.info_list[subject]['selection'] = this.info_options[subject].selection;
              res = true;
            }
          }
        }
      } else if (subject === 'input_duration_wait') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind];
            let duration_wait_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].duration_wait;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let duration_wait_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].duration_wait;
              let percent = Math.abs((duration_wait_first - duration_wait_second) * 100 / duration_wait_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // drug mix
            let data = this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind];
            let duration_wait_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].duration_wait;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && mix_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let duration_wait_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].duration_wait;
              let percent = Math.abs((duration_wait_first - duration_wait_second) * 100 / duration_wait_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            let duration_wait_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].duration_wait;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && prem_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind][type].length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let duration_wait_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].duration_wait;
              let percent = Math.abs((duration_wait_first - duration_wait_second) * 100 / duration_wait_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          } else { // before after during mix
            let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind];
            let duration_wait_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].duration_wait;
            if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length && mix_ind < this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix.length) { // yani yeni eklenen ilaçlarda önceki sonraki doz farkını hesaplamaya gerek yok.
              let duration_wait_first = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].duration_wait;
              let percent = Math.abs((duration_wait_first - duration_wait_second) * 100 / duration_wait_first);
              if (percent >= this.info_options[subject].bef_aft.value) {
                data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
                res = true;
              } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
            }
            let dosage_chars = duration_wait_second.toString().split('');
            let det = false;
            for (let i in dosage_chars) {
              if (this.info_options[subject].chars.value.indexOf(dosage_chars[i]) === -1) {
                data.info_list[subject]['chars'] = this.info_options[subject].chars;
                res = true;
                det = true;
                break;
              }
            }
            if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

            if (this.info_options[subject].len.value < dosage_chars.length) {
              data.info_list[subject]['len'] = this.info_options[subject].len;
              res = true;
            } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
          }
        }
      } else if (subject === 'select_prem_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_days.length === 0) {
          data.info_list[subject]['selection'] = this.info_options[subject].selection;
          res = true;
        }
      } else if (subject === 'select_prem_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].day.length === 0) {
          data.info_list[subject]['selection'] = this.info_options[subject].selection;
          res = true;
        }
      } else if (subject === 'select_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].day.length === 0) {
          data.info_list[subject]['selection'] = this.info_options[subject].selection;
          res = true;
        }
      } else if (subject === 'input_period') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        let period_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].period;
        if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki period farkını hesaplamaya gerek yok.
          let period_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].period;
          let percent = Math.abs((period_first - period_second) * 100 / period_first);
          if (percent >= this.info_options[subject].bef_aft.value) {
            data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
            res = true;
          } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
        }
        let period_chars = period_second.toString().split('');
        let det = false;
        for (let i in period_chars) {
          if (this.info_options[subject].chars.value.indexOf(period_chars[i]) === -1) {
            data.info_list[subject]['chars'] = this.info_options[subject].chars;
            res = true;
            det = true;
            break;
          }
        }
        if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

        if (this.info_options[subject].len.value < period_chars.length) {
          data.info_list[subject]['len'] = this.info_options[subject].len;
          res = true;
        } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
      } else if (subject === 'input_repeat') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        let repeat_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].repeat;
        if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki repeat farkını hesaplamaya gerek yok.
          let repeat_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].repeat;
          let percent = Math.abs((repeat_first - repeat_second) * 100 / repeat_first);
          if (percent >= this.info_options[subject].bef_aft.value) {
            data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
            res = true;
          } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
        }
        let repeat_chars = repeat_second.toString().split('');
        let det = false;
        for (let i in repeat_chars) {
          if (this.info_options[subject].chars.value.indexOf(repeat_chars[i]) === -1) {
            data.info_list[subject]['chars'] = this.info_options[subject].chars;
            res = true;
            det = true;
            break;
          }
        }
        if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

        if (this.info_options[subject].len.value < repeat_chars.length) {
          data.info_list[subject]['len'] = this.info_options[subject].len;
          res = true;
        } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
      } else if (subject === 'input_delay') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        let delay_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].delay;
        if (info_drg_ind < this.treatment_data.selected_protocol.drugs.length) { // yani yeni eklenen ilaçlarda önceki sonraki delay farkını hesaplamaya gerek yok.
          let delay_first = this.treatment_data.selected_protocol.drugs[info_drg_ind].delay;
          let percent = Math.abs((delay_first - delay_second) * 100 / delay_first);
          if (percent >= this.info_options[subject].bef_aft.value) {
            data.info_list[subject]['bef_aft'] = this.info_options[subject].bef_aft;
            res = true;
          } else if (data.info_list[subject]['bef_aft'] !== undefined) { this.$delete(data.info_list[subject], 'bef_aft'); }
        }
        let delay_chars = delay_second.toString().split('');
        let det = false;
        for (let i in delay_chars) {
          if (this.info_options[subject].chars.value.indexOf(delay_chars[i]) === -1) {
            data.info_list[subject]['chars'] = this.info_options[subject].chars;
            res = true;
            det = true;
            break;
          }
        }
        if (det === false && data.info_list[subject]['chars'] !== undefined) { this.$delete(data.info_list[subject], 'chars'); }

        if (this.info_options[subject].len.value < delay_chars.length) {
          data.info_list[subject]['len'] = this.info_options[subject].len;
          res = true;
        } else if (data.info_list[subject]['len'] !== undefined) { this.$delete(data.info_list[subject], 'len'); }
      } else if (subject === 'select_the_drug') {
        if (type === 'drug') {
          if (mix_ind === 'none') {
            this.pro_inf_list.drugs[info_drg_ind].info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          } else {
            this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind].info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind].drug_mix[mix_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind].drug_mix[mix_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          }
        } else { // before after during
          if (mix_ind === 'none') {
            this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          } else {
            this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].info_list[subject] = {};
            if (this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].drug === null) {
              this.pro_inf_list.drugs[info_drg_ind][type][prem_ind].drug_mix[mix_ind].info_list[subject] = this.info_options[subject];
              res = true;
            }
          }
        }
      } else if (subject === 'select_note_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.note.list[other_ind].drug_days.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_msg_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].drug_days.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_lab_drug_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.laboratory.list[other_ind].drug_days.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_note_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.note.list[other_ind].day.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_msg_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].day.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_lab_days') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.laboratory.list[other_ind].day.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'select_lab_tests') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.laboratory.list[other_ind].tests.length === 0) {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'text_note_drug') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.note.list[other_ind].note === undefined || this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.note.list[other_ind].note === '') {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'text_msg_drug') {
        let data = this.pro_inf_list.drugs[info_drg_ind];
        data.info_list[subject] = {};
        if (this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].message === undefined || this.treatment_data.selected_protocol.drugs[info_drg_ind].premedications.message_to_patient.list[other_ind].message === '') {
          data.info_list[subject] = this.info_options[subject];
          res = true;
        }
      } else if (subject === 'calculated_dosage') {
        let data = {};
        let dosage_second = '';
        if (type === 'drug') {
          if (mix_ind === 'none') {
            data = this.pro_inf_list.drugs[info_drg_ind];
            dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind].calculated_dosage;
          }
        } else { // before after during
          if (mix_ind === 'none') {
            data = this.pro_inf_list.drugs[info_drg_ind][type][prem_ind];
            dosage_second = this.treatment_data.selected_protocol.drugs[info_drg_ind][type][prem_ind].calculated_dosage;
          }
        }
        if (data.info_list) {
          if (dosage_second < this.info_options[subject].min.value) {
            data.info_list[subject].min = this.info_options[subject].min;
            res = true;
          } else if (data.info_list[subject].min !== undefined) { this.$delete(data.info_list[subject], 'min'); }
        }
      }
      return res;
    },
    add_day_to_this_drug_day_prem (d_index, prem_ind, prem_type, day) {
      if (this.treatment_data.selected_protocol.drugs[d_index][prem_type][prem_ind].drug_days.indexOf(day) === -1) {
        this.treatment_data.selected_protocol.drugs[d_index][prem_type][prem_ind].drug_days.push(day);
      }
    },
    add_day_to_this_drug_prem (d_index, prem_ind) {
      let value = parseInt(document.getElementById('select-day-' + this.drug_type + '_' + d_index + '_' + prem_ind).value);
      this.treatment_data.selected_protocol.drugs[d_index][this.drug_type][prem_ind].day = [];
      this.treatment_data.selected_protocol.drugs[d_index][this.drug_type][prem_ind].day.push(value);
    },
    delete_drug_day_prem (d_index, prem_ind, prem_drug_day_ind, prem_type) {
      this.treatment_data.selected_protocol.drugs[d_index][prem_type][prem_ind].drug_days.splice(prem_drug_day_ind, 1);
    },
    delete_day_prem (d_index, prem_ind, dy_ind) {
      this.treatment_data.selected_protocol.drugs[d_index][this.drug_type][prem_ind].day.splice(dy_ind, 1);
    }
  },
  watch: {
    'drug_data.fluid': function () {
      if (this.drug_data.fluid.value && this.drug_data.dosage_form.value === '12') {
        this.drug_data.fluid_value = this.drug_data.fluid.fluid_value;
      }
    },
    'drug_data.model': function () {
      this.$forceUpdate();
    },
    'mother_children.add_edit_drug': function () {
      // console.log('mother_children');
      this.$forceUpdate();
    }
  },
  components: {
    vSelect,
    Modal,
    ConfirmForm
  }
};

</script>

<style>
.add-edit-drug-col {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}

.add-edit-drug-row {}

.add-edit-before {
  background-color: #b6f9c2;
}

.add-edit-mix-before {
  background-color: #b6f9c2;
}

.add-edit-during {
  background-color: #ff9900;
}

.add-edit-mix-during {
  background-color: #f8ad3f;
}

.add-edit-drug {
  background-color: #99c7d8ab;
}

.add-edit-mix-drug {
  background-color: #99c7d8ab;
}

.add-edit-after {
  background-color: #e4e4a6;
}

.add-edit-mix-after {
  background-color: #e4e4a6;
}

.v-select .dropdown-menu {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  min-width: 300px;
  padding: 5px 0;
  margin: 0;
  /*width: 100%;*/
  overflow-y: scroll;
  border: 1px solid rgba(0, 0, 0, .26);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, .15);
  border-top: none;
  border-radius: 0 0 4px 4px;
  text-align: left;
  list-style: none;
  background: #fff;
}

.add-edit-caption {
  float: left;
  margin-top: 5px;
}

.form-control {
  background: transparent;
  color: black;
}

</style>

