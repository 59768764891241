var unit_hims = {
  'MLPCARE': {
    'ULS': {
      'list': [{
          "id": 1,
          "code": "ADET",
          "name": "Adet",
          "rec_stamp": "AAAAAjHBL2Q=",
          "rec_login_id": 1,
          "temp_old_code": "ADET",
          "order_invisible": null
        },
        {
          "id": 683,
          "code": "AMPUL",
          "name": "Ampul",
          "rec_stamp": "AAAAAjHBL2k=",
          "rec_login_id": 1,
          "temp_old_code": "AMPUL",
          "order_invisible": null
        },
        {
          "id": 780,
          "code": "Damla",
          "name": "Damla",
          "rec_stamp": "AAAAAo24dko=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 684,
          "code": "DRAJE",
          "name": "Draje",
          "rec_stamp": "AAAAAjHBL4A=",
          "rec_login_id": 1,
          "temp_old_code": "DRAJE",
          "order_invisible": null
        },
        {
          "id": 680,
          "code": "FLAKON",
          "name": "Flakon",
          "rec_stamp": "AAAAAjHBL4Q=",
          "rec_login_id": 1,
          "temp_old_code": "FLAKON",
          "order_invisible": null
        },
        {
          "id": 771,
          "code": "Gram ",
          "name": "Gram",
          "rec_stamp": "AAAAAjHBL4U=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 767,
          "code": "UI",
          "name": "İnternasyonel ünite",
          "rec_stamp": "AAAAAjHBL4Y=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 685,
          "code": "KAPSÜL",
          "name": "Kapsül",
          "rec_stamp": "AAAAAjHBL4g=",
          "rec_login_id": 1,
          "temp_old_code": "KAPSÜL",
          "order_invisible": null
        },
        {
          "id": 781,
          "code": "Kilogram",
          "name": "Kilogram",
          "rec_stamp": "AAAAAspPduE=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 2,
          "code": "KUTU",
          "name": "Kutu",
          "rec_stamp": "AAAAAjHBL4k=",
          "rec_login_id": 1,
          "temp_old_code": "KUTU",
          "order_invisible": null
        },
        {
          "id": 772,
          "code": "Mikrogram",
          "name": "Mikrogram",
          "rec_stamp": "AAAAAjHBL4s=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 769,
          "code": "MEQ",
          "name": "Miliequvalon",
          "rec_stamp": "AAAAAjHBL4w=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 770,
          "code": "Miligram",
          "name": "Miligram",
          "rec_stamp": "AAAAAjHBL40=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 690,
          "code": "Ml",
          "name": "Mililitre",
          "rec_stamp": "AAAAAjHBL44=",
          "rec_login_id": 1,
          "temp_old_code": "Ml",
          "order_invisible": null
        },
        {
          "id": 768,
          "code": "MIU",
          "name": "Milyon internasyonel",
          "rec_stamp": "AAAAAjHBL48=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 687,
          "code": "ÖLÇEK",
          "name": "Ölçek",
          "rec_stamp": "AAAAAjHBL5s=",
          "rec_login_id": 1,
          "temp_old_code": "ÖLÇEK",
          "order_invisible": null
        },
        {
          "id": 681,
          "code": "ŞİŞE",
          "name": "Şişe",
          "rec_stamp": "AAAAAjHBL6c=",
          "rec_login_id": 1,
          "temp_old_code": "ŞİŞE",
          "order_invisible": null
        },
        {
          "id": 682,
          "code": "TABLET",
          "name": "Tablet",
          "rec_stamp": "AAAAAjHBL6k=",
          "rec_login_id": 1,
          "temp_old_code": "TABLET",
          "order_invisible": null
        },
        {
          "id": 766,
          "code": "UNITE",
          "name": "Ünite",
          "rec_stamp": "AAAAAjHBL64=",
          "rec_login_id": 1,
          "temp_old_code": "UNITE",
          "order_invisible": null
        }
      ]
    },
    'FLR': {
      'list': [{
          "id": 1,
          "code": "ADET",
          "name": "Adet",
          "rec_stamp": "AAAAAjHBL2Q=",
          "rec_login_id": 1,
          "temp_old_code": "ADET",
          "order_invisible": null
        },
        {
          "id": 683,
          "code": "AMPUL",
          "name": "Ampul",
          "rec_stamp": "AAAAAjHBL2k=",
          "rec_login_id": 1,
          "temp_old_code": "AMPUL",
          "order_invisible": null
        },
        {
          "id": 773,
          "code": "Damla",
          "name": "Damla",
          "rec_stamp": "AAAAAo24dko=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 684,
          "code": "DRAJE",
          "name": "Draje",
          "rec_stamp": "AAAAAjHBL4A=",
          "rec_login_id": 1,
          "temp_old_code": "DRAJE",
          "order_invisible": null
        },
        {
          "id": 680,
          "code": "FLAKON",
          "name": "Flakon",
          "rec_stamp": "AAAAAjHBL4Q=",
          "rec_login_id": 1,
          "temp_old_code": "FLAKON",
          "order_invisible": null
        },
        {
          "id": 771,
          "code": "Gram ",
          "name": "Gram",
          "rec_stamp": "AAAAAjHBL4U=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 767,
          "code": "UI",
          "name": "İnternasyonel ünite",
          "rec_stamp": "AAAAAjHBL4Y=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 685,
          "code": "KAPSÜL",
          "name": "Kapsül",
          "rec_stamp": "AAAAAjHBL4g=",
          "rec_login_id": 1,
          "temp_old_code": "KAPSÜL",
          "order_invisible": null
        },
        {
          "id": 774,
          "code": "Kilogram",
          "name": "Kilogram",
          "rec_stamp": "AAAAAspPduE=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 2,
          "code": "KUTU",
          "name": "Kutu",
          "rec_stamp": "AAAAAjHBL4k=",
          "rec_login_id": 1,
          "temp_old_code": "KUTU",
          "order_invisible": null
        },
        {
          "id": 772,
          "code": "Mikrogram",
          "name": "Mikrogram",
          "rec_stamp": "AAAAAjHBL4s=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 769,
          "code": "MEQ",
          "name": "Miliequvalon",
          "rec_stamp": "AAAAAjHBL4w=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 770,
          "code": "Miligram",
          "name": "Miligram",
          "rec_stamp": "AAAAAjHBL40=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 690,
          "code": "Ml",
          "name": "Mililitre",
          "rec_stamp": "AAAAAjHBL44=",
          "rec_login_id": 1,
          "temp_old_code": "Ml",
          "order_invisible": null
        },
        {
          "id": 768,
          "code": "MIU",
          "name": "Milyon internasyonel",
          "rec_stamp": "AAAAAjHBL48=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 687,
          "code": "ÖLÇEK",
          "name": "Ölçek",
          "rec_stamp": "AAAAAjHBL5s=",
          "rec_login_id": 1,
          "temp_old_code": "ÖLÇEK",
          "order_invisible": null
        },
        {
          "id": 681,
          "code": "ŞİŞE",
          "name": "Şişe",
          "rec_stamp": "AAAAAjHBL6c=",
          "rec_login_id": 1,
          "temp_old_code": "ŞİŞE",
          "order_invisible": null
        },
        {
          "id": 682,
          "code": "TABLET",
          "name": "Tablet",
          "rec_stamp": "AAAAAjHBL6k=",
          "rec_login_id": 1,
          "temp_old_code": "TABLET",
          "order_invisible": null
        },
        {
          "id": 766,
          "code": "UNITE",
          "name": "Ünite",
          "rec_stamp": "AAAAAjHBL64=",
          "rec_login_id": 1,
          "temp_old_code": "UNITE",
          "order_invisible": null
        }
      ]
    },
    'GOZ': {
      'list': [{
          "id": 1,
          "code": "ADET",
          "name": "Adet",
          "rec_stamp": "AAAAAjHBL2Q=",
          "rec_login_id": 1,
          "temp_old_code": "ADET",
          "order_invisible": null
        },
        {
          "id": 683,
          "code": "AMPUL",
          "name": "Ampul",
          "rec_stamp": "AAAAAjHBL2k=",
          "rec_login_id": 1,
          "temp_old_code": "AMPUL",
          "order_invisible": null
        },
        {
          "id": 773,
          "code": "Damla",
          "name": "Damla",
          "rec_stamp": "AAAAAo24dko=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 684,
          "code": "DRAJE",
          "name": "Draje",
          "rec_stamp": "AAAAAjHBL4A=",
          "rec_login_id": 1,
          "temp_old_code": "DRAJE",
          "order_invisible": null
        },
        {
          "id": 680,
          "code": "FLAKON",
          "name": "Flakon",
          "rec_stamp": "AAAAAjHBL4Q=",
          "rec_login_id": 1,
          "temp_old_code": "FLAKON",
          "order_invisible": null
        },
        {
          "id": 771,
          "code": "Gram ",
          "name": "Gram",
          "rec_stamp": "AAAAAjHBL4U=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 767,
          "code": "UI",
          "name": "İnternasyonel ünite",
          "rec_stamp": "AAAAAjHBL4Y=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 685,
          "code": "KAPSÜL",
          "name": "Kapsül",
          "rec_stamp": "AAAAAjHBL4g=",
          "rec_login_id": 1,
          "temp_old_code": "KAPSÜL",
          "order_invisible": null
        },
        {
          "id": 774,
          "code": "Kilogram",
          "name": "Kilogram",
          "rec_stamp": "AAAAAspPduE=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 2,
          "code": "KUTU",
          "name": "Kutu",
          "rec_stamp": "AAAAAjHBL4k=",
          "rec_login_id": 1,
          "temp_old_code": "KUTU",
          "order_invisible": null
        },
        {
          "id": 772,
          "code": "Mikrogram",
          "name": "Mikrogram",
          "rec_stamp": "AAAAAjHBL4s=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 769,
          "code": "MEQ",
          "name": "Miliequvalon",
          "rec_stamp": "AAAAAjHBL4w=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 770,
          "code": "Miligram",
          "name": "Miligram",
          "rec_stamp": "AAAAAjHBL40=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 690,
          "code": "Ml",
          "name": "Mililitre",
          "rec_stamp": "AAAAAjHBL44=",
          "rec_login_id": 1,
          "temp_old_code": "Ml",
          "order_invisible": null
        },
        {
          "id": 768,
          "code": "MIU",
          "name": "Milyon internasyonel",
          "rec_stamp": "AAAAAjHBL48=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 687,
          "code": "ÖLÇEK",
          "name": "Ölçek",
          "rec_stamp": "AAAAAjHBL5s=",
          "rec_login_id": 1,
          "temp_old_code": "ÖLÇEK",
          "order_invisible": null
        },
        {
          "id": 681,
          "code": "ŞİŞE",
          "name": "Şişe",
          "rec_stamp": "AAAAAjHBL6c=",
          "rec_login_id": 1,
          "temp_old_code": "ŞİŞE",
          "order_invisible": null
        },
        {
          "id": 682,
          "code": "TABLET",
          "name": "Tablet",
          "rec_stamp": "AAAAAjHBL6k=",
          "rec_login_id": 1,
          "temp_old_code": "TABLET",
          "order_invisible": null
        },
        {
          "id": 766,
          "code": "UNITE",
          "name": "Ünite",
          "rec_stamp": "AAAAAjHBL64=",
          "rec_login_id": 1,
          "temp_old_code": "UNITE",
          "order_invisible": null
        }
      ]
    },
    'IZT': {
      'list': [{
          "id": 1,
          "code": "ADET",
          "name": "Adet",
          "rec_stamp": "AAAAAjHBL2Q=",
          "rec_login_id": 1,
          "temp_old_code": "ADET",
          "order_invisible": null
        },
        {
          "id": 683,
          "code": "AMPUL",
          "name": "Ampul",
          "rec_stamp": "AAAAAjHBL2k=",
          "rec_login_id": 1,
          "temp_old_code": "AMPUL",
          "order_invisible": null
        },
        {
          "id": 773,
          "code": "Damla",
          "name": "Damla",
          "rec_stamp": "AAAAAo24dko=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 684,
          "code": "DRAJE",
          "name": "Draje",
          "rec_stamp": "AAAAAjHBL4A=",
          "rec_login_id": 1,
          "temp_old_code": "DRAJE",
          "order_invisible": null
        },
        {
          "id": 680,
          "code": "FLAKON",
          "name": "Flakon",
          "rec_stamp": "AAAAAjHBL4Q=",
          "rec_login_id": 1,
          "temp_old_code": "FLAKON",
          "order_invisible": null
        },
        {
          "id": 771,
          "code": "Gram ",
          "name": "Gram",
          "rec_stamp": "AAAAAjHBL4U=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 767,
          "code": "UI",
          "name": "İnternasyonel ünite",
          "rec_stamp": "AAAAAjHBL4Y=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 685,
          "code": "KAPSÜL",
          "name": "Kapsül",
          "rec_stamp": "AAAAAjHBL4g=",
          "rec_login_id": 1,
          "temp_old_code": "KAPSÜL",
          "order_invisible": null
        },
        {
          "id": 774,
          "code": "Kilogram",
          "name": "Kilogram",
          "rec_stamp": "AAAAAspPduE=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 2,
          "code": "KUTU",
          "name": "Kutu",
          "rec_stamp": "AAAAAjHBL4k=",
          "rec_login_id": 1,
          "temp_old_code": "KUTU",
          "order_invisible": null
        },
        {
          "id": 772,
          "code": "Mikrogram",
          "name": "Mikrogram",
          "rec_stamp": "AAAAAjHBL4s=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 769,
          "code": "MEQ",
          "name": "Miliequvalon",
          "rec_stamp": "AAAAAjHBL4w=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 770,
          "code": "Miligram",
          "name": "Miligram",
          "rec_stamp": "AAAAAjHBL40=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 690,
          "code": "Ml",
          "name": "Mililitre",
          "rec_stamp": "AAAAAjHBL44=",
          "rec_login_id": 1,
          "temp_old_code": "Ml",
          "order_invisible": null
        },
        {
          "id": 768,
          "code": "MIU",
          "name": "Milyon internasyonel",
          "rec_stamp": "AAAAAjHBL48=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 687,
          "code": "ÖLÇEK",
          "name": "Ölçek",
          "rec_stamp": "AAAAAjHBL5s=",
          "rec_login_id": 1,
          "temp_old_code": "ÖLÇEK",
          "order_invisible": null
        },
        {
          "id": 681,
          "code": "ŞİŞE",
          "name": "Şişe",
          "rec_stamp": "AAAAAjHBL6c=",
          "rec_login_id": 1,
          "temp_old_code": "ŞİŞE",
          "order_invisible": null
        },
        {
          "id": 682,
          "code": "TABLET",
          "name": "Tablet",
          "rec_stamp": "AAAAAjHBL6k=",
          "rec_login_id": 1,
          "temp_old_code": "TABLET",
          "order_invisible": null
        },
        {
          "id": 766,
          "code": "UNITE",
          "name": "Ünite",
          "rec_stamp": "AAAAAjHBL64=",
          "rec_login_id": 1,
          "temp_old_code": "UNITE",
          "order_invisible": null
        }
      ]
    },
    'BHI': {
      'list': [{
          "id": 1,
          "code": "ADET",
          "name": "Adet",
          "rec_stamp": "AAAAAjHBL2Q=",
          "rec_login_id": 1,
          "temp_old_code": "ADET",
          "order_invisible": null
        },
        {
          "id": 683,
          "code": "AMPUL",
          "name": "Ampul",
          "rec_stamp": "AAAAAjHBL2k=",
          "rec_login_id": 1,
          "temp_old_code": "AMPUL",
          "order_invisible": null
        },
        {
          "id": 773,
          "code": "Damla",
          "name": "Damla",
          "rec_stamp": "AAAAAo24dko=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 684,
          "code": "DRAJE",
          "name": "Draje",
          "rec_stamp": "AAAAAjHBL4A=",
          "rec_login_id": 1,
          "temp_old_code": "DRAJE",
          "order_invisible": null
        },
        {
          "id": 680,
          "code": "FLAKON",
          "name": "Flakon",
          "rec_stamp": "AAAAAjHBL4Q=",
          "rec_login_id": 1,
          "temp_old_code": "FLAKON",
          "order_invisible": null
        },
        {
          "id": 771,
          "code": "Gram ",
          "name": "Gram",
          "rec_stamp": "AAAAAjHBL4U=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 767,
          "code": "UI",
          "name": "İnternasyonel ünite",
          "rec_stamp": "AAAAAjHBL4Y=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 685,
          "code": "KAPSÜL",
          "name": "Kapsül",
          "rec_stamp": "AAAAAjHBL4g=",
          "rec_login_id": 1,
          "temp_old_code": "KAPSÜL",
          "order_invisible": null
        },
        {
          "id": 774,
          "code": "Kilogram",
          "name": "Kilogram",
          "rec_stamp": "AAAAAspPduE=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 2,
          "code": "KUTU",
          "name": "Kutu",
          "rec_stamp": "AAAAAjHBL4k=",
          "rec_login_id": 1,
          "temp_old_code": "KUTU",
          "order_invisible": null
        },
        {
          "id": 772,
          "code": "Mikrogram",
          "name": "Mikrogram",
          "rec_stamp": "AAAAAjHBL4s=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 769,
          "code": "MEQ",
          "name": "Miliequvalon",
          "rec_stamp": "AAAAAjHBL4w=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 770,
          "code": "Miligram",
          "name": "Miligram",
          "rec_stamp": "AAAAAjHBL40=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 690,
          "code": "Ml",
          "name": "Mililitre",
          "rec_stamp": "AAAAAjHBL44=",
          "rec_login_id": 1,
          "temp_old_code": "Ml",
          "order_invisible": null
        },
        {
          "id": 768,
          "code": "MIU",
          "name": "Milyon internasyonel",
          "rec_stamp": "AAAAAjHBL48=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 687,
          "code": "ÖLÇEK",
          "name": "Ölçek",
          "rec_stamp": "AAAAAjHBL5s=",
          "rec_login_id": 1,
          "temp_old_code": "ÖLÇEK",
          "order_invisible": null
        },
        {
          "id": 681,
          "code": "ŞİŞE",
          "name": "Şişe",
          "rec_stamp": "AAAAAjHBL6c=",
          "rec_login_id": 1,
          "temp_old_code": "ŞİŞE",
          "order_invisible": null
        },
        {
          "id": 682,
          "code": "TABLET",
          "name": "Tablet",
          "rec_stamp": "AAAAAjHBL6k=",
          "rec_login_id": 1,
          "temp_old_code": "TABLET",
          "order_invisible": null
        },
        {
          "id": 766,
          "code": "UNITE",
          "name": "Ünite",
          "rec_stamp": "AAAAAjHBL64=",
          "rec_login_id": 1,
          "temp_old_code": "UNITE",
          "order_invisible": null
        }
      ]
    },
    'BAH': {
      'list': [{
          "id": 1,
          "code": "ADET",
          "name": "Adet",
          "rec_stamp": "AAAAAjHBL2Q=",
          "rec_login_id": 1,
          "temp_old_code": "ADET",
          "order_invisible": null
        },
        {
          "id": 683,
          "code": "AMPUL",
          "name": "Ampul",
          "rec_stamp": "AAAAAjHBL2k=",
          "rec_login_id": 1,
          "temp_old_code": "AMPUL",
          "order_invisible": null
        },
        {
          "id": 720,
          "code": "Damla",
          "name": "Damla",
          "rec_stamp": "AAAAAo24dko=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 684,
          "code": "DRAJE",
          "name": "Draje",
          "rec_stamp": "AAAAAjHBL4A=",
          "rec_login_id": 1,
          "temp_old_code": "DRAJE",
          "order_invisible": null
        },
        {
          "id": 680,
          "code": "FLAKON",
          "name": "Flakon",
          "rec_stamp": "AAAAAjHBL4Q=",
          "rec_login_id": 1,
          "temp_old_code": "FLAKON",
          "order_invisible": null
        },
        {
          "id": 716,
          "code": "Gram ",
          "name": "Gram",
          "rec_stamp": "AAAAAjHBL4U=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 712,
          "code": "UI",
          "name": "İnternasyonel ünite",
          "rec_stamp": "AAAAAjHBL4Y=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 685,
          "code": "KAPSÜL",
          "name": "Kapsül",
          "rec_stamp": "AAAAAjHBL4g=",
          "rec_login_id": 1,
          "temp_old_code": "KAPSÜL",
          "order_invisible": null
        },
        {
          "id": 8,
          "code": "Kilogram",
          "name": "Kilogram",
          "rec_stamp": "AAAAAspPduE=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 2,
          "code": "KUTU",
          "name": "Kutu",
          "rec_stamp": "AAAAAjHBL4k=",
          "rec_login_id": 1,
          "temp_old_code": "KUTU",
          "order_invisible": null
        },
        {
          "id": 717,
          "code": "Mikrogram",
          "name": "Mikrogram",
          "rec_stamp": "AAAAAjHBL4s=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 714,
          "code": "MEQ",
          "name": "Miliequvalon",
          "rec_stamp": "AAAAAjHBL4w=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 715,
          "code": "Miligram",
          "name": "Miligram",
          "rec_stamp": "AAAAAjHBL40=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 9,
          "code": "Ml",
          "name": "Mililitre",
          "rec_stamp": "AAAAAjHBL44=",
          "rec_login_id": 1,
          "temp_old_code": "Ml",
          "order_invisible": null
        },
        {
          "id": 713,
          "code": "MIU",
          "name": "Milyon internasyonel",
          "rec_stamp": "AAAAAjHBL48=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 687,
          "code": "ÖLÇEK",
          "name": "Ölçek",
          "rec_stamp": "AAAAAjHBL5s=",
          "rec_login_id": 1,
          "temp_old_code": "ÖLÇEK",
          "order_invisible": null
        },
        {
          "id": 681,
          "code": "ŞİŞE",
          "name": "Şişe",
          "rec_stamp": "AAAAAjHBL6c=",
          "rec_login_id": 1,
          "temp_old_code": "ŞİŞE",
          "order_invisible": null
        },
        {
          "id": 682,
          "code": "TABLET",
          "name": "Tablet",
          "rec_stamp": "AAAAAjHBL6k=",
          "rec_login_id": 1,
          "temp_old_code": "TABLET",
          "order_invisible": null
        },
        {
          "id": 719,
          "code": "UNITE",
          "name": "Ünite",
          "rec_stamp": "AAAAAjHBL64=",
          "rec_login_id": 1,
          "temp_old_code": "UNITE",
          "order_invisible": null
        }
      ]
    },
    'BUR': {
      'list': [{
          "id": 1,
          "code": "ADET",
          "name": "Adet",
          "rec_stamp": "AAAAAjHBL2Q=",
          "rec_login_id": 1,
          "temp_old_code": "ADET",
          "order_invisible": null
        },
        {
          "id": 683,
          "code": "AMPUL",
          "name": "Ampul",
          "rec_stamp": "AAAAAjHBL2k=",
          "rec_login_id": 1,
          "temp_old_code": "AMPUL",
          "order_invisible": null
        },
        {
          "id": 693,
          "code": "Damla",
          "name": "Damla",
          "rec_stamp": "AAAAAo24dko=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 684,
          "code": "DRAJE",
          "name": "Draje",
          "rec_stamp": "AAAAAjHBL4A=",
          "rec_login_id": 1,
          "temp_old_code": "DRAJE",
          "order_invisible": null
        },
        {
          "id": 680,
          "code": "FLAKON",
          "name": "Flakon",
          "rec_stamp": "AAAAAjHBL4Q=",
          "rec_login_id": 1,
          "temp_old_code": "FLAKON",
          "order_invisible": null
        },
        {
          "id": 689,
          "code": "Gram ",
          "name": "Gram",
          "rec_stamp": "AAAAAjHBL4U=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 705,
          "code": "UI",
          "name": "İnternasyonel ünite",
          "rec_stamp": "AAAAAjHBL4Y=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 685,
          "code": "KAPSÜL",
          "name": "Kapsül",
          "rec_stamp": "AAAAAjHBL4g=",
          "rec_login_id": 1,
          "temp_old_code": "KAPSÜL",
          "order_invisible": null
        },
        {
          "id": 8,
          "code": "Kilogram",
          "name": "Kilogram",
          "rec_stamp": "AAAAAspPduE=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 2,
          "code": "KUTU",
          "name": "Kutu",
          "rec_stamp": "AAAAAjHBL4k=",
          "rec_login_id": 1,
          "temp_old_code": "KUTU",
          "order_invisible": null
        },
        {
          "id": 696,
          "code": "Mikrogram",
          "name": "Mikrogram",
          "rec_stamp": "AAAAAjHBL4s=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 700,
          "code": "Miligram",
          "name": "Miligram",
          "rec_stamp": "AAAAAjHBL40=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 9,
          "code": "Ml",
          "name": "Mililitre",
          "rec_stamp": "AAAAAjHBL44=",
          "rec_login_id": 1,
          "temp_old_code": "Ml",
          "order_invisible": null
        },
        {
          "id": 687,
          "code": "ÖLÇEK",
          "name": "Ölçek",
          "rec_stamp": "AAAAAjHBL5s=",
          "rec_login_id": 1,
          "temp_old_code": "ÖLÇEK",
          "order_invisible": null
        },
        {
          "id": 681,
          "code": "ŞİŞE",
          "name": "Şişe",
          "rec_stamp": "AAAAAjHBL6c=",
          "rec_login_id": 1,
          "temp_old_code": "ŞİŞE",
          "order_invisible": null
        },
        {
          "id": 682,
          "code": "TABLET",
          "name": "Tablet",
          "rec_stamp": "AAAAAjHBL6k=",
          "rec_login_id": 1,
          "temp_old_code": "TABLET",
          "order_invisible": null
        },
        {
          "id": 705,
          "code": "UNITE",
          "name": "Ünite",
          "rec_stamp": "AAAAAjHBL64=",
          "rec_login_id": 1,
          "temp_old_code": "UNITE",
          "order_invisible": null
        }
      ]
    },
    'GZU': {
      'list': [{
          "id": 1,
          "code": "ADET",
          "name": "Adet",
          "rec_stamp": "AAAAAjHBL2Q=",
          "rec_login_id": 1,
          "temp_old_code": "ADET",
          "order_invisible": null
        },
        {
          "id": 683,
          "code": "AMPUL",
          "name": "Ampul",
          "rec_stamp": "AAAAAjHBL2k=",
          "rec_login_id": 1,
          "temp_old_code": "AMPUL",
          "order_invisible": null
        },
        {
          "id": 773,
          "code": "Damla",
          "name": "Damla",
          "rec_stamp": "AAAAAo24dko=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 684,
          "code": "DRAJE",
          "name": "Draje",
          "rec_stamp": "AAAAAjHBL4A=",
          "rec_login_id": 1,
          "temp_old_code": "DRAJE",
          "order_invisible": null
        },
        {
          "id": 680,
          "code": "FLAKON",
          "name": "Flakon",
          "rec_stamp": "AAAAAjHBL4Q=",
          "rec_login_id": 1,
          "temp_old_code": "FLAKON",
          "order_invisible": null
        },
        {
          "id": 771,
          "code": "Gram ",
          "name": "Gram",
          "rec_stamp": "AAAAAjHBL4U=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 767,
          "code": "UI",
          "name": "İnternasyonel ünite",
          "rec_stamp": "AAAAAjHBL4Y=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 685,
          "code": "KAPSÜL",
          "name": "Kapsül",
          "rec_stamp": "AAAAAjHBL4g=",
          "rec_login_id": 1,
          "temp_old_code": "KAPSÜL",
          "order_invisible": null
        },
        {
          "id": 774,
          "code": "Kilogram",
          "name": "Kilogram",
          "rec_stamp": "AAAAAspPduE=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 2,
          "code": "KUTU",
          "name": "Kutu",
          "rec_stamp": "AAAAAjHBL4k=",
          "rec_login_id": 1,
          "temp_old_code": "KUTU",
          "order_invisible": null
        },
        {
          "id": 772,
          "code": "Mikrogram",
          "name": "Mikrogram",
          "rec_stamp": "AAAAAjHBL4s=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 769,
          "code": "MEQ",
          "name": "Miliequvalon",
          "rec_stamp": "AAAAAjHBL4w=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 770,
          "code": "Miligram",
          "name": "Miligram",
          "rec_stamp": "AAAAAjHBL40=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 690,
          "code": "Ml",
          "name": "Mililitre",
          "rec_stamp": "AAAAAjHBL44=",
          "rec_login_id": 1,
          "temp_old_code": "Ml",
          "order_invisible": null
        },
        {
          "id": 768,
          "code": "MIU",
          "name": "Milyon internasyonel",
          "rec_stamp": "AAAAAjHBL48=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 687,
          "code": "ÖLÇEK",
          "name": "Ölçek",
          "rec_stamp": "AAAAAjHBL5s=",
          "rec_login_id": 1,
          "temp_old_code": "ÖLÇEK",
          "order_invisible": null
        },
        {
          "id": 681,
          "code": "ŞİŞE",
          "name": "Şişe",
          "rec_stamp": "AAAAAjHBL6c=",
          "rec_login_id": 1,
          "temp_old_code": "ŞİŞE",
          "order_invisible": null
        },
        {
          "id": 682,
          "code": "TABLET",
          "name": "Tablet",
          "rec_stamp": "AAAAAjHBL6k=",
          "rec_login_id": 1,
          "temp_old_code": "TABLET",
          "order_invisible": null
        },
        {
          "id": 766,
          "code": "UNITE",
          "name": "Ünite",
          "rec_stamp": "AAAAAjHBL64=",
          "rec_login_id": 1,
          "temp_old_code": "UNITE",
          "order_invisible": null
        }
      ]
    },
    'GEB': {
      'list': [{
          "id": 1,
          "code": "ADET",
          "name": "Adet",
          "rec_stamp": "AAAAAaNY0+Q=",
          "rec_login_id": 1,
          "temp_old_code": "ADET",
          "order_invisible": null
        },
        {
          "id": 683,
          "code": "AMPUL",
          "name": "Ampul",
          "rec_stamp": "AAAAAaNY0+k=",
          "rec_login_id": 1,
          "temp_old_code": "AMPUL",
          "order_invisible": null
        },
        {
          "id": 773,
          "code": "Damla",
          "name": "Damla",
          "rec_stamp": "AAAAAe9SgoA=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 684,
          "code": "DRAJE",
          "name": "Draje",
          "rec_stamp": "AAAAAaNY1AE=",
          "rec_login_id": 1,
          "temp_old_code": "DRAJE",
          "order_invisible": null
        },
        {
          "id": 680,
          "code": "FLAKON",
          "name": "Flakon",
          "rec_stamp": "AAAAAaNY1AU=",
          "rec_login_id": 1,
          "temp_old_code": "FLAKON",
          "order_invisible": null
        },
        {
          "id": 770,
          "code": "Gram ",
          "name": "Gram",
          "rec_stamp": "AAAAAaNY1AY=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 766,
          "code": "UI",
          "name": "İnternasyonel ünite",
          "rec_stamp": "AAAAAaNY1Ac=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 685,
          "code": "KAPSÜL",
          "name": "Kapsül",
          "rec_stamp": "AAAAAaNY1Ak=",
          "rec_login_id": 1,
          "temp_old_code": "KAPSÜL",
          "order_invisible": null
        },
        {
          "id": 774,
          "code": "Kilogram",
          "name": "Kilogram",
          "rec_stamp": "AAAAAuJ87TM=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 2,
          "code": "KUTU",
          "name": "Kutu",
          "rec_stamp": "AAAAAaNY1Ao=",
          "rec_login_id": 1,
          "temp_old_code": "KUTU",
          "order_invisible": null
        },
        {
          "id": 771,
          "code": "Mikrogram",
          "name": "Mikrogram",
          "rec_stamp": "AAAAAaNY1Aw=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 768,
          "code": "MEQ",
          "name": "Miliequvalon",
          "rec_stamp": "AAAAAaNY1A0=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 769,
          "code": "Miligram",
          "name": "Miligram",
          "rec_stamp": "AAAAAaNY1A4=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 690,
          "code": "Ml",
          "name": "Mililitre",
          "rec_stamp": "AAAAAaNY1A8=",
          "rec_login_id": 1,
          "temp_old_code": "Ml",
          "order_invisible": null
        },
        {
          "id": 767,
          "code": "MIU",
          "name": "Milyon internasyonel",
          "rec_stamp": "AAAAAaNY1BA=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 687,
          "code": "ÖLÇEK",
          "name": "Ölçek",
          "rec_stamp": "AAAAAaNY1Bw=",
          "rec_login_id": 1,
          "temp_old_code": "ÖLÇEK",
          "order_invisible": null
        },
        {
          "id": 681,
          "code": "ŞİŞE",
          "name": "Şişe",
          "rec_stamp": "AAAAAaNY1Cg=",
          "rec_login_id": 1,
          "temp_old_code": "ŞİŞE",
          "order_invisible": null
        },
        {
          "id": 682,
          "code": "TABLET",
          "name": "Tablet",
          "rec_stamp": "AAAAAaNY1Co=",
          "rec_login_id": 1,
          "temp_old_code": "TABLET",
          "order_invisible": null
        },
        {
          "id": 772,
          "code": "UNITE",
          "name": "Ünite",
          "rec_stamp": "AAAAAa6l5tI=",
          "rec_login_id": 1,
          "temp_old_code": "UNITE",
          "order_invisible": null
        }
      ]
    },
    'CAN': {
      'list': [{
          "id": 1,
          "code": "ADET",
          "name": "Adet",
          "rec_stamp": "AAAAAjHBL2Q=",
          "rec_login_id": 1,
          "temp_old_code": "ADET",
          "order_invisible": null
        },
        {
          "id": 683,
          "code": "AMPUL",
          "name": "Ampul",
          "rec_stamp": "AAAAAjHBL2k=",
          "rec_login_id": 1,
          "temp_old_code": "AMPUL",
          "order_invisible": null
        },
        {
          "id": 773,
          "code": "Damla",
          "name": "Damla",
          "rec_stamp": "AAAAAo24dko=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 684,
          "code": "DRAJE",
          "name": "Draje",
          "rec_stamp": "AAAAAjHBL4A=",
          "rec_login_id": 1,
          "temp_old_code": "DRAJE",
          "order_invisible": null
        },
        {
          "id": 680,
          "code": "FLAKON",
          "name": "Flakon",
          "rec_stamp": "AAAAAjHBL4Q=",
          "rec_login_id": 1,
          "temp_old_code": "FLAKON",
          "order_invisible": null
        },
        {
          "id": 771,
          "code": "Gram ",
          "name": "Gram",
          "rec_stamp": "AAAAAjHBL4U=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 767,
          "code": "UI",
          "name": "İnternasyonel ünite",
          "rec_stamp": "AAAAAjHBL4Y=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 685,
          "code": "KAPSÜL",
          "name": "Kapsül",
          "rec_stamp": "AAAAAjHBL4g=",
          "rec_login_id": 1,
          "temp_old_code": "KAPSÜL",
          "order_invisible": null
        },
        {
          "id": 774,
          "code": "Kilogram",
          "name": "Kilogram",
          "rec_stamp": "AAAAAspPduE=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 2,
          "code": "KUTU",
          "name": "Kutu",
          "rec_stamp": "AAAAAjHBL4k=",
          "rec_login_id": 1,
          "temp_old_code": "KUTU",
          "order_invisible": null
        },
        {
          "id": 772,
          "code": "Mikrogram",
          "name": "Mikrogram",
          "rec_stamp": "AAAAAjHBL4s=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 769,
          "code": "MEQ",
          "name": "Miliequvalon",
          "rec_stamp": "AAAAAjHBL4w=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 770,
          "code": "Miligram",
          "name": "Miligram",
          "rec_stamp": "AAAAAjHBL40=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 690,
          "code": "Ml",
          "name": "Mililitre",
          "rec_stamp": "AAAAAjHBL44=",
          "rec_login_id": 1,
          "temp_old_code": "Ml",
          "order_invisible": null
        },
        {
          "id": 768,
          "code": "MIU",
          "name": "Milyon internasyonel",
          "rec_stamp": "AAAAAjHBL48=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 687,
          "code": "ÖLÇEK",
          "name": "Ölçek",
          "rec_stamp": "AAAAAjHBL5s=",
          "rec_login_id": 1,
          "temp_old_code": "ÖLÇEK",
          "order_invisible": null
        },
        {
          "id": 681,
          "code": "ŞİŞE",
          "name": "Şişe",
          "rec_stamp": "AAAAAjHBL6c=",
          "rec_login_id": 1,
          "temp_old_code": "ŞİŞE",
          "order_invisible": null
        },
        {
          "id": 682,
          "code": "TABLET",
          "name": "Tablet",
          "rec_stamp": "AAAAAjHBL6k=",
          "rec_login_id": 1,
          "temp_old_code": "TABLET",
          "order_invisible": null
        },
        {
          "id": 766,
          "code": "UNITE",
          "name": "Ünite",
          "rec_stamp": "AAAAAjHBL64=",
          "rec_login_id": 1,
          "temp_old_code": "UNITE",
          "order_invisible": null
        }
      ]
    },
    'BTH': {
      'list': [{
          "id": 1,
          "code": "ADET",
          "name": "Adet",
          "rec_stamp": "AAAAAjHBL2Q=",
          "rec_login_id": 1,
          "temp_old_code": "ADET",
          "order_invisible": null
        },
        {
          "id": 683,
          "code": "AMPUL",
          "name": "Ampul",
          "rec_stamp": "AAAAAjHBL2k=",
          "rec_login_id": 1,
          "temp_old_code": "AMPUL",
          "order_invisible": null
        },
        {
          "id": 773,
          "code": "Damla",
          "name": "Damla",
          "rec_stamp": "AAAAAo24dko=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 684,
          "code": "DRAJE",
          "name": "Draje",
          "rec_stamp": "AAAAAjHBL4A=",
          "rec_login_id": 1,
          "temp_old_code": "DRAJE",
          "order_invisible": null
        },
        {
          "id": 680,
          "code": "FLAKON",
          "name": "Flakon",
          "rec_stamp": "AAAAAjHBL4Q=",
          "rec_login_id": 1,
          "temp_old_code": "FLAKON",
          "order_invisible": null
        },
        {
          "id": 771,
          "code": "Gram ",
          "name": "Gram",
          "rec_stamp": "AAAAAjHBL4U=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 767,
          "code": "UI",
          "name": "İnternasyonel ünite",
          "rec_stamp": "AAAAAjHBL4Y=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 685,
          "code": "KAPSÜL",
          "name": "Kapsül",
          "rec_stamp": "AAAAAjHBL4g=",
          "rec_login_id": 1,
          "temp_old_code": "KAPSÜL",
          "order_invisible": null
        },
        {
          "id": 774,
          "code": "Kilogram",
          "name": "Kilogram",
          "rec_stamp": "AAAAAspPduE=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 2,
          "code": "KUTU",
          "name": "Kutu",
          "rec_stamp": "AAAAAjHBL4k=",
          "rec_login_id": 1,
          "temp_old_code": "KUTU",
          "order_invisible": null
        },
        {
          "id": 772,
          "code": "Mikrogram",
          "name": "Mikrogram",
          "rec_stamp": "AAAAAjHBL4s=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 769,
          "code": "MEQ",
          "name": "Miliequvalon",
          "rec_stamp": "AAAAAjHBL4w=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 770,
          "code": "Miligram",
          "name": "Miligram",
          "rec_stamp": "AAAAAjHBL40=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 690,
          "code": "Ml",
          "name": "Mililitre",
          "rec_stamp": "AAAAAjHBL44=",
          "rec_login_id": 1,
          "temp_old_code": "Ml",
          "order_invisible": null
        },
        {
          "id": 768,
          "code": "MIU",
          "name": "Milyon internasyonel",
          "rec_stamp": "AAAAAjHBL48=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 687,
          "code": "ÖLÇEK",
          "name": "Ölçek",
          "rec_stamp": "AAAAAjHBL5s=",
          "rec_login_id": 1,
          "temp_old_code": "ÖLÇEK",
          "order_invisible": null
        },
        {
          "id": 681,
          "code": "ŞİŞE",
          "name": "Şişe",
          "rec_stamp": "AAAAAjHBL6c=",
          "rec_login_id": 1,
          "temp_old_code": "ŞİŞE",
          "order_invisible": null
        },
        {
          "id": 682,
          "code": "TABLET",
          "name": "Tablet",
          "rec_stamp": "AAAAAjHBL6k=",
          "rec_login_id": 1,
          "temp_old_code": "TABLET",
          "order_invisible": null
        },
        {
          "id": 766,
          "code": "UNITE",
          "name": "Ünite",
          "rec_stamp": "AAAAAjHBL64=",
          "rec_login_id": 1,
          "temp_old_code": "UNITE",
          "order_invisible": null
        }
      ]
    },
    'PEN': {
      'list': [{
          "id": 1,
          "code": "ADET",
          "name": "Adet",
          "rec_stamp": "AAAAAjHBL2Q=",
          "rec_login_id": 1,
          "temp_old_code": "ADET",
          "order_invisible": null
        },
        {
          "id": 683,
          "code": "AMPUL",
          "name": "Ampul",
          "rec_stamp": "AAAAAjHBL2k=",
          "rec_login_id": 1,
          "temp_old_code": "AMPUL",
          "order_invisible": null
        },
        {
          "id": 773,
          "code": "Damla",
          "name": "Damla",
          "rec_stamp": "AAAAAo24dko=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 684,
          "code": "DRAJE",
          "name": "Draje",
          "rec_stamp": "AAAAAjHBL4A=",
          "rec_login_id": 1,
          "temp_old_code": "DRAJE",
          "order_invisible": null
        },
        {
          "id": 680,
          "code": "FLAKON",
          "name": "Flakon",
          "rec_stamp": "AAAAAjHBL4Q=",
          "rec_login_id": 1,
          "temp_old_code": "FLAKON",
          "order_invisible": null
        },
        {
          "id": 771,
          "code": "Gram ",
          "name": "Gram",
          "rec_stamp": "AAAAAjHBL4U=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 767,
          "code": "UI",
          "name": "İnternasyonel ünite",
          "rec_stamp": "AAAAAjHBL4Y=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 685,
          "code": "KAPSÜL",
          "name": "Kapsül",
          "rec_stamp": "AAAAAjHBL4g=",
          "rec_login_id": 1,
          "temp_old_code": "KAPSÜL",
          "order_invisible": null
        },
        {
          "id": 774,
          "code": "Kilogram",
          "name": "Kilogram",
          "rec_stamp": "AAAAAspPduE=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 2,
          "code": "KUTU",
          "name": "Kutu",
          "rec_stamp": "AAAAAjHBL4k=",
          "rec_login_id": 1,
          "temp_old_code": "KUTU",
          "order_invisible": null
        },
        {
          "id": 772,
          "code": "Mikrogram",
          "name": "Mikrogram",
          "rec_stamp": "AAAAAjHBL4s=",
          "rec_login_id": 1804,
          "temp_old_code": null,
          "order_invisible": null
        },
        {
          "id": 769,
          "code": "MEQ",
          "name": "Miliequvalon",
          "rec_stamp": "AAAAAjHBL4w=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 770,
          "code": "Miligram",
          "name": "Miligram",
          "rec_stamp": "AAAAAjHBL40=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 690,
          "code": "Ml",
          "name": "Mililitre",
          "rec_stamp": "AAAAAjHBL44=",
          "rec_login_id": 1,
          "temp_old_code": "Ml",
          "order_invisible": null
        },
        {
          "id": 768,
          "code": "MIU",
          "name": "Milyon internasyonel",
          "rec_stamp": "AAAAAjHBL48=",
          "rec_login_id": 1804,
          "temp_old_code": "-",
          "order_invisible": null
        },
        {
          "id": 687,
          "code": "ÖLÇEK",
          "name": "Ölçek",
          "rec_stamp": "AAAAAjHBL5s=",
          "rec_login_id": 1,
          "temp_old_code": "ÖLÇEK",
          "order_invisible": null
        },
        {
          "id": 681,
          "code": "ŞİŞE",
          "name": "Şişe",
          "rec_stamp": "AAAAAjHBL6c=",
          "rec_login_id": 1,
          "temp_old_code": "ŞİŞE",
          "order_invisible": null
        },
        {
          "id": 682,
          "code": "TABLET",
          "name": "Tablet",
          "rec_stamp": "AAAAAjHBL6k=",
          "rec_login_id": 1,
          "temp_old_code": "TABLET",
          "order_invisible": null
        },
        {
          "id": 766,
          "code": "UNITE",
          "name": "Ünite",
          "rec_stamp": "AAAAAjHBL64=",
          "rec_login_id": 1,
          "temp_old_code": "UNITE",
          "order_invisible": null
        }
      ]
    }
  }
};

export { unit_hims as options };
