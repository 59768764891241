<template>
  <div>
    <template>
      <div class="row" style="margin-bottom: 3px;">
        <div class="col-sm-12">
          <strong v-if="calculate_cycle_type().length === 1">{{ calculate_cycle_type()[0] }}, </strong>
          <template v-if="!p_patients_chemo_list && DateFunctions.day_dif(p_wdmr11or12List[p_componentType][p_wdmr11or12_ind].date, 'today') === 0">
            <i style="color: green; font-weight: bold;"> {{ date_dif_today(p_wdmr11or12List[p_componentType][p_wdmr11or12_ind].date) }} </i>
          </template>
          <template v-else>
            <i style="color: #b20000;"> {{ date_dif_today(p_wdmr11or12List[p_componentType][p_wdmr11or12_ind].date) }} </i>
          </template>
        </div>
      </div>
      <b-row class="drugs-list">
        <template v-for="(wdmr_data, wdmr_data_ind) in p_wdmr11or12List[p_componentType][p_wdmr11or12_ind].list">
          <b-col sm="12" md="12">
            <b-badge style="font-size: 10px; margin: 1px; white-space: normal; color: #23282c;" :id="wdmr_data.id + '_' + p_wdmr11or12List[p_componentType][p_wdmr11or12_ind].date" :class="calculate_status_class(wdmr_data)">
              <strong v-if="calculate_cycle_type().length > 1">
                {{ d_namingData[wdmr_data_ind].cycle }}. {{ $t('wdm16.4164') }};
                <template v-if="p_componentType === 'daily_followup'">
                  {{ d_namingData[wdmr_data_ind].day }}. {{ $t('wdm16.1246') }}
                </template>
              </strong> {{ d_namingData[wdmr_data_ind].drug }}
              <template v-if="wdmr_data.type === 'wdm12'"> {{ d_namingData[wdmr_data_ind].given_dosage }} </template>
              <template v-else> {{ d_namingData[wdmr_data_ind].planned_dosage }} </template>
              <template> {{ d_namingData[wdmr_data_ind].unit | show_unit_calculate }} </template>
            </b-badge>
            <b-popover :ref="wdmr_data.id + '_' + p_wdmr11or12List[p_componentType][p_wdmr11or12_ind].date" :target="wdmr_data.id + '_' + p_wdmr11or12List[p_componentType][p_wdmr11or12_ind].date" triggers="hover focus">
              <template slot="title">
                {{ d_namingData[wdmr_data_ind].log_user }}
              </template>
              {{ d_namingData[wdmr_data_ind].log_status }}
            </b-popover>
          </b-col>
        </template>
      </b-row>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { show_unit_calculate } from '../../filters/index';
import { DateFunctions } from '../../services/public/date_functions';

export default {
  name: 'PatientDrugDayNaming',
  computed: {
    ...mapGetters({
      lang: 'lang'
    })
  },
  props: {
    p_wdmr11or12List: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    p_wdmr11or12_ind: {
      type: Number,
      required: false
    },
    p_wdmr11or12: {
      type: Object,
      required: false
    },
    p_patients_chemo_list: {
      type: Boolean,
      required: false,
      default: false
    },
    mother_children: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    p_componentType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      DateFunctions: DateFunctions,
      all_users: [],
      d_namingData: []
    };
  },
  beforeCreate: function () {},
  created: function () {
    this.all_users = JSON.parse(localStorage.getItem('all_users'));
    this.f_createNamingData();
  },
  mounted: function () {},
  methods: {
    f_createNamingData: function () {
      this.d_namingData = [];
      for (let i in this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list) {
        let wdmr_data = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[i];
        let append_data = { 'cycle': '', 'day': '', 'drug': '', 'given_dosage': '', 'planned_dosage': '', 'unit': '', 'log_user': '', 'log_status': '' };

        try {
          append_data['cycle'] = wdmr_data.data.general.repeat.val;
        } catch (err) {}

        try {
          append_data['day'] = Object.keys(wdmr_data.data.general.selected_days)[0];
        } catch (err) {}

        try {
          append_data['drug'] = wdmr_data.data.general.drug.val.label;
        } catch (err) {}

        try {
          append_data['given_dosage'] = wdmr_data.data.general.given_dosage.val;
        } catch (err) {}

        try {
          append_data['planned_dosage'] = wdmr_data.data.general.planned_dosage.val;
        } catch (err) {}

        try {
          append_data['unit'] = wdmr_data.data.general.dosage.unit.value;
        } catch (err) {}

        try {
          append_data['log_user'] = wdmr_data.data.status.status_log.list[wdmr_data.data.status.status_log.list.length - 1].status_log_user.val.label;
        } catch (err) {}

        try {
          append_data['log_status'] = wdmr_data.data.status.status_log.list[wdmr_data.data.status.status_log.list.length - 1].status_log_status.val.label;
        } catch (err) {
          append_data['log_status'] = ' Henüz İşlem Yapılmadı ';
        }
        this.d_namingData.push(append_data);
      }
      this.$forceUpdate();
    },
    f_motherChildren: function (op) {
      if (this.mother_children[op] === 0) {
        this.mother_children[op] = 1;
      } else {
        this.mother_children[op] = 0;
      }
    },
    f_sortObject: function (data) {
      let result = {};
      if (!this.p_patients_chemo_list) {
        for (let drg in this.treatment_data.protocol_cycles.drug_order) {
          let drg_key = this.treatment_data.protocol_cycles.drug_order[drg];
          if (data[drg_key] !== undefined) {
            result[drg_key] = JSON.parse(JSON.stringify(data[drg_key]));
          }
        }
      } else {
        for (let drg in this.treatment_data.drug_order) {
          let drg_key = this.treatment_data.drug_order[drg];
          if (data[drg_key] !== undefined) {
            result[drg_key] = JSON.parse(JSON.stringify(data[drg_key]));
          }
        }
      }
      return result;
    },
    get_user_name: function (wdmr_data) {
      try {
        return wdmr_data.data.status.status_log.list[wdmr_data.data.status.status_log.list.length - 1].status_log_user.val.label;
      } catch (err) {
        return '';
      }
    },
    get_from_all_users: function (username) {
      for (let i in this.all_users) {
        if (this.all_users[i].username === username) {
          return this.all_users[i].group + ' ' + this.all_users[i].first_name + ' ' + this.all_users[i].last_name;
        }
      }
    },
    calculate_status_class: function (wdmr_data) {
      let status = '';
      try {
        status = wdmr_data.data.status.status_log.list[wdmr_data.data.status.status_log.list.length - 1].status_log_status.val.value;
      } catch (err) {}
      if (['no_operation_yet', 'doctor_5', 'drug_approving_cancelled'].indexOf(status) !== -1) {
        return 'drug-style_doctor-approval';
      } else if (['doctor_3', 'doctor_4', 'doctor_7', 'doctor_9',  'doctor_10',  'doctor_11',  'doctor_12',  'doctor_13', 'administration_0', 'administration_1'].indexOf(status) !== -1) {
        return 'drug-style_finished';
      } else if (['doctor_6', 'doctor_14'].indexOf(status) !== -1) {
        return 'drug-style_cancelled';
      } else if (['doctor_0', 'doctor_8', 'nurse_1'].indexOf(status) !== -1) {
        return 'drug-style_drug-choose';
      } else if (['nurse_0', 'nurse_2', 'pharmacy_1', 'pharmacy_2', 'pharmacy_3', 'pharmacy_4'].indexOf(status) !== -1) {
        return 'drug-style_pharmacy';
      } else if (['pharmacy_0', 'preparation_3', 'preparation_4'].indexOf(status) !== -1) {
        return 'drug-style_drug-preparation';
      } else if (['doctor_1', 'doctor_2'].indexOf(status) !== -1) {
        return 'drug-style_doctor-preparation';
      } else if (['preparation_0', 'preparation_1', 'preparation_2', 'match_1'].indexOf(status) !== -1) {
        return 'drug-style_match';
      } else if (['match_0', 'administration_2', 'administration_3', 'administration_4'].indexOf(status) !== -1) {
        return 'drug-style_administration';
      }
    },
    date_dif_today: function (dt) {
      let date = new Date(dt);
      let dateMsec = date.getTime();
      let today = new Date();
      today.setHours(8, 0, 0, 0);
      let todayMsec = today.getTime();
      let msecPerDay = 86400000;
      let day_dif = Math.round((dateMsec / msecPerDay) - (todayMsec / msecPerDay));
      if (day_dif === 0) {
        return moment(date).format('DD/MM/YYYY') + ' (' + this.$t('wdm16.6218') + ')';
      } else if (day_dif === 1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6207') + ')';
      } else if (day_dif > 1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6227') + ')';
      } else if (day_dif === -1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6208') + ')';
      } else if (day_dif < -1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' ' + this.$t('wdm16.6215') + ')';
      }
    },
    calculate_cycle_type: function () {
      let cycle_day_list = [];
      try {
        for (let wdmr_ind in this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list) {
          let wdmr_data = this.p_wdmr11or12List[this.p_componentType][this.p_wdmr11or12_ind].list[wdmr_ind];
          let cycle = wdmr_data.data.general.repeat.val;
          let day_no = Object.keys(wdmr_data.data.general.selected_days)[0];
          let k = cycle + '. ' + this.$t('wdm16.4164');
          if (this.p_componentType === 'daily_followup') {
            k += '; ' + day_no + '. ' + this.$t('wdm16.1246');
          }
          if (cycle_day_list.indexOf(k) === -1) {
            cycle_day_list.push(k);
          }
        }
      } catch (err) {}
      return cycle_day_list;
    }
  },
  filters: {},
  watch: {
    'mother_children.forceUpdate': function () {
      this.$forceUpdate();
    },
    'mother_children.update_naming': function () {
      this.f_createNamingData();
    }
  },
  components: {}
};

</script>

<style>
.no_operation_yet {
  background-color: #fae482e0;
  border-radius: 3px;
  width: 100px;
  padding: 3px;
  word-wrap: break-word;
  margin: 1px;
}

.operation_completed {
  background-color: #4dbd74;
  border-radius: 3px;
  width: 100px;
  padding: 3px;
  word-wrap: break-word;
  margin: 1px;
}

.operation_continueing {
  background-color: #29d;
  border-radius: 3px;
  width: 100px;
  padding: 3px;
  word-wrap: break-word;
  margin: 1px;
}

.drug_approving_cancelled {
  background-color: #f86c6b;
  border-radius: 3px;
  width: 100px;
  padding: 3px;
  word-wrap: break-word;
  margin: 1px;
  text-decoration: line-through;
}

.drugs-list {
  border: solid 1px #c7b9b9;
  margin: 0px;
  background: #d4eae5;
  box-shadow: -1px -1px 1px;
  border-radius: 5px;
  /*min-height: 70px;*/
}

.drug-style_doctor-approval {
  background-color: #ffc107;
}

.drug-style_drug-choose {
  background-color: #47D4DC;
}

.drug-style_pharmacy {
  background-color: #9e9e9e;
}

.drug-style_drug-preparation {
  background-color: #9e9e9e;
}

.drug-style_doctor-preparation {
  background-color: #9e9e9e;
}

.drug-style_match {
  background-color: #47D4DC;
}

.drug-style_administration {
  background-color: #47D4DC;
}

.drug-style_finished {
  background-color: #4dbd74;
}

.drug-style_cancelled {
  background-color: #f86c6b;
}

.drug-style_doctor-approval::before {
  padding-right: 2px;
  font-family: "FontAwesome";
  content: "\f0f0";
}

.drug-style_drug-choose::before {
  padding-right: 2px;
  font-family: "FontAwesome";
  content: "\f00b";
}

.drug-style_pharmacy::before {
  padding-right: 2px;
  font-family: "FontAwesome";
  content: "\f0fa";
}

.drug-style_drug-preparation::before {
  padding-right: 2px;
  font-family: "FontAwesome";
  content: "\f0fa";
}

.drug-style_doctor-preparation::before {
  padding-right: 2px;
  font-family: "FontAwesome";
  content: "\f0fa";
}

.drug-style_match::before {
  padding-right: 2px;
  font-family: "FontAwesome";
  content: "\f07e";
}

.drug-style_administration::before {
  padding-right: 2px;
  font-family: "FontAwesome";
  content: "\f004";
}

.drug-style_finished::before {
  padding-right: 2px;
  font-family: "FontAwesome";
  content: "\f00c";
}

.drug-style_cancelled::before {
  padding-right: 2px;
  font-family: "FontAwesome";
  content: "\f256";
}

</style>

