<template>
  <div>
    <b-card class="mb-1" no-body header-tag="header" footer-tag="footer">
      <b-card-header header-bg-variant="success" class="p-1">
        <strong>Fatura Başvuru Protokolü Bilgileri</strong>
        <span style="color: red"> <-- Eczaneye seçtiğiniz ilaçları gönderebilmek için lütfen bir başvuru protokolü seçimi yapınız. --> </span>
      </b-card-header>
      <b-row style="margin: 2px;text-align: center;">
        <b-col cols="12">
          <template v-if="patient_data && patient_data.open_protocol_list && patient_data.open_protocol_list.result && patient_data.open_protocol_list.result.length > 0">
            <b-row v-for="(open_pro, open_pro_ind) in patient_data.open_protocol_list.result" :key="open_pro_ind" style="padding: 5px; margin: 0px; border-bottom: solid 1px #ccbdbd;">
              <b-col cols="12">
                <div :style="SelectedProtocolInd === open_pro_ind ? 'background-color: #b0e8f5; cursor: pointer;' : 'cursor: pointer;'" @click="f_selectThisProtocol(open_pro_ind)">
                  <i class="fa fa-hand-o-right" style="color: green;"></i> {{ open_pro.department_name }}
                  <i class="fa fa-user"></i> {{ open_pro.doctor }}
                  <i class="fa fa-key"></i> {{ open_pro.protocol_no }}
                  <i class="fa fa-calendar-o"></i> ({{ moment(open_pro.open_date).format('DD/MM/YYYY HH:mm') }})
                  <span :style="f_calculateStyleProtocolType(open_pro.type_id)">
                      [{{ CalculateProtocolType(open_pro.type_id) }}]
                    </span>
                </div>
              </b-col>
            </b-row>
          </template>
          <!--           <template v-else>
            <strong style="color: red;">
              Açık başvuru protokolü bulunmadığından ilaçları eczane sistemine gönderilememektedir. İlaçları eczaneye göndermek istiyorsanız lütfen ilgili görevli ile irtibata geçiniz.
            </strong>
          </template> -->
        </b-col>
      </b-row>
      <!-- <b-card-footer footer-bg-variant="secondary" header-text-variant="dark" class="p-1"></b-card-footer> -->
    </b-card>
    <template v-for="(day, dy_ind) in treatment_order_data.days">
      <template v-for="(drug, drug_key) in day">
        <template v-if="drug.before && drug.before.length > 0">
          <brand-selection-premedication :treatment_order_data="treatment_order_data" :drug="drug" :dy_ind="dy_ind" :drug_key="drug_key" :prem_type="'before'" :treatment_data="treatment_data" :d_selectedProtocolNo="d_selectedProtocolNo"></brand-selection-premedication>
        </template>
        <template v-if="drug.during && drug.during.length > 0">
          <brand-selection-premedication :treatment_order_data="treatment_order_data" :drug="drug" :dy_ind="dy_ind" :drug_key="drug_key" :prem_type="'during'" :treatment_data="treatment_data" :d_selectedProtocolNo="d_selectedProtocolNo"></brand-selection-premedication>
        </template>
        <!--nurse_approval drugggggg-->
        <template v-if="drug.brands !== undefined">
          <div class="col-12" style="background-color: rgb(77, 212, 106); text-align: center;">
            <strong> {{ treatment_data.protocol_cycles.drugs[drug_key].drug.label }} </strong>
          </div>
          <table class="col-12">
            <thead>
              <tr style="background-color: rgb(190, 223, 197); text-align: center; font-weight: bold;">
                <th style="width: 15%;"> İlaç </th>
                <th colspan="2" style="width: 80%;"> Ürün </th>
                <th style="width: 3%;"> Adet </th>
                <th style="width: 2%;"> </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowspan="3">
                  <b> {{ treatment_data.protocol_cycles.drugs[drug_key].drug.label }} </b>
                  <br>
                  <div>
                    (Verilecek Doz: {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug.cycle_index].days[drug.day_index].data.given_dosage }} {{ treatment_data.protocol_cycles.drugs[drug_key].cycles[drug.cycle_index].days[drug.day_index].data.unit.value | show_unit_calculate}}
                  </div>
                  <div>
                    <template v-if="d_selectedProtocolNo">
                      <!-- <template v-if="f_atLeastOneBrandInStock(drug.brands)"> -->
                      <template v-if="f_atLeastOneBrandMatch(drug.brands)">
                        <b-form-checkbox v-model="drug.pharmacy_status" :value="1" :unchecked-value="0">Eczane İstemi</b-form-checkbox>
                      </template>
                      <template v-else>
                        <small style="color: red;">Bu üründen stokta görünmüyor.</small>
                      </template>
                    </template>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="padding: 5px;">
                  <v-select ref="selected_brand" name="selected_brand" v-model="drug.selected_brand" :options="drug.brands">
                    <template slot="option" slot-scope="option">
                      <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;" :disabled="true">
                        <template v-if="d_selectedProtocolNo && option.stock">
                          <span :style="option.stock.count ? 'color: green' : 'color: red'"> [ stok = {{ option.stock.count }} ] </span>
                        </template>
                        <span> {{ option.label }} </span>
                      </div>
                    </template>
                  </v-select>
                </td>
                <td style="padding: 5px;">
                  <input type="number" name="count" :id="dy_ind + drug_key + 'none' + 'drug' + 'none'" value="1">
                </td>
                <td style="text-align: right; padding-right: 10px;">
                  <button type="button" class="btn btn-success" @click="add_barcode(dy_ind, drug_key, 'none', 'drug', 'none')"><i class="fa fa-plus fa-1x"></i></button>
                </td>
              </tr>
              <tr style="border-bottom: solid 1px black;">
                <td colspan="4">
                  <div v-for="(brand, brand_ind) in drug.brand_count" style="background-color: #f1ffc1; width: 100%;">
                    <b-button variant="danger" @click="delete_barcode(dy_ind, drug_key, 'none', 'drug', 'none', brand_ind)"><i class="fa fa-minus fa-1x"></i></b-button>
                    {{ brand.label }} <small> ( {{ brand_ind }} )</small>
                    <template v-if="f_calculateBrandStock(brand_ind, drug.brands)">
                      <strong style="color: #0089ff;"> [ Stok:  {{ f_calculateBrandStock(brand_ind, drug.brands) }} ] </strong>
                    </template>
                    <template v-else>
                      <strong style="color: red;"> [ Stok: yok ] </strong>
                    </template>
                    <strong style="color: blue;"> [Adet: {{ brand.count }} ] </strong>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <template v-if="drug.fluid !== undefined">
            <div class="col-12" style="background-color: rgb(77, 212, 106); text-align: center;">
              <strong> {{ treatment_data.protocol_cycles.drugs[drug_key].drug.label }} ( mayi ) </strong>
            </div>
            <table class="col-12">
              <thead>
                <tr style="background-color: rgb(190, 223, 197); text-align: center; font-weight: bold;">
                  <th style="width: 15%;"> Drug </th>
                  <th colspan="2" style="width: 80%;"> Brands </th>
                  <th style="width: 3%;"> Count </th>
                  <th style="width: 2%;"> </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="3">
                    <b> {{ drug.fluid.name.label }} </b>
                    <br>
                    <div>
                      ( Uygulanacak Hacim = {{ drug.fluid.fluid_value }} ml )
                    </div>
                    <div>
                      <template v-if="d_selectedProtocolNo">
                        <template v-if="f_atLeastOneBrandMatch(drug.fluid.brands)">
                          <b-form-checkbox v-model="drug.fluid.pharmacy_status" :value="1" :unchecked-value="0">Eczane İstemi</b-form-checkbox>
                        </template>
                        <template v-else>
                          <small style="color: red;">Bu üründen stokta görünmüyor.</small>
                        </template>
                      </template>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="padding: 5px;">
                    <v-select ref="selected_brand" name="selected_brand" v-model="drug.fluid.selected_brand" :options="drug.fluid.brands">
                      <template slot="option" slot-scope="option">
                        <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;">
                          <template v-if="d_selectedProtocolNo && option.stock">
                            <span :style="option.stock.count ? 'color: green' : 'color: red'"> [ stok = {{ option.stock.count }} ] </span>
                          </template>
                          <span> {{ option.label }} </span>
                        </div>
                      </template>
                    </v-select>
                  </td>
                  <td style="padding: 5px;">
                    <input type="number" name="count" :id="'fluid' + dy_ind + drug_key + 'none' + 'drug' + 'none'" value="1">
                  </td>
                  <td style="text-align: right; padding-right: 10px;">
                    <button type="button" class="btn btn-success" @click="add_barcode(dy_ind, drug_key, 'none', 'drug', 'none', true)"><i class="fa fa-plus fa-1x"></i></button>
                  </td>
                </tr>
                <tr style="border-bottom: solid 1px black;">
                  <td colspan="4">
                    <div v-for="(brand, brand_ind) in drug.fluid.brand_count" style="background-color: #f1ffc1; width: 100%;">
                      <b-button variant="danger" @click="delete_barcode(dy_ind, drug_key, 'none', 'drug', 'none', brand_ind, true)"><i class="fa fa-minus fa-1x"></i></b-button>
                      {{ brand.label }} <small> ( {{ brand_ind }} )</small>
                      <template v-if="f_calculateBrandStock(brand_ind, drug.fluid.brands)">
                        <strong style="color: #0089ff;"> [ Stok:  {{ f_calculateBrandStock(brand_ind, drug.fluid.brands) }} ] </strong>
                      </template>
                      <template v-else>
                        <strong style="color: red;"> [ Stok: yok ] </strong>
                      </template>
                      <strong style="color: blue;"> [Adet: {{ brand.count }} ] </strong>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <!--mixxxxxxxxxxxxxxxx-->
          <template v-for="(mix, mix_ind) in drug.drug_mix">
            <div class="col-12" style="background-color: rgb(77, 212, 106); text-align: center;">
              <strong> {{ treatment_data.protocol_cycles.drugs[drug_key].drug.label }} mix drug
              </strong>
            </div>
            <table class="col-12">
              <thead>
                <tr style="background-color: rgb(190, 223, 197); text-align: center; font-weight: bold;">
                  <th style="width: 15%;"> İlaç </th>
                  <th colspan="2" style="width: 80%;"> Ürün </th>
                  <th style="width: 3%;"> Adet </th>
                  <th style="width: 2%;"> </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowspan="3">
                    <b> {{ mix.name.label }}  </b>
                    <br>
                    <div>
                      (Verilecek Doz: {{ mix.given_dosage }} {{ mix.unit_value | show_unit_calculate }} )
                    </div>
                    <div>
                      <template v-if="d_selectedProtocolNo">
                        <template v-if="f_atLeastOneBrandMatch(mix.brands)">
                          <b-form-checkbox v-model="mix.pharmacy_status" :value="1" :unchecked-value="0">Eczane İstemi</b-form-checkbox>
                        </template>
                        <template v-else>
                          <small style="color: red;">Bu üründen stokta görünmüyor.</small>
                        </template>
                      </template>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="padding: 5px;">
                    <v-select ref="selected_brand" name="selected_brand" v-model="mix.selected_brand" :options="mix.brands">
                      <template slot="option" slot-scope="option">
                        <div style="white-space: pre-line; border-bottom: solid 1px #c8ced3; vertical-align: middle; color: #323442;" :disabled="true">
                          <template v-if="d_selectedProtocolNo && option.stock">
                            <span :style="option.stock.count ? 'color: green' : 'color: red'"> [ stok = {{ option.stock.count }} ] </span>
                          </template>
                          <span> {{ option.label }} </span>
                        </div>
                      </template>
                    </v-select>
                  </td>
                  <td style="padding: 5px;">
                    <input type="number" name="count" :id="dy_ind + drug_key + 'none' + 'drug' + mix_ind" value="1">
                  </td>
                  <td style="text-align: right; padding-right: 10px;">
                    <button type="button" class="btn btn-success" @click="add_barcode(dy_ind, drug_key, 'none', 'drug', mix_ind)"><i class="fa fa-plus fa-1x"></i></button>
                  </td>
                </tr>
                <tr style="border-bottom: solid 1px black;">
                  <td colspan="4">
                    <div v-for="(brand, brand_ind) in mix.brand_count" style="background-color: #f1ffc1; width: 100%;">
                      <b-button variant="danger" @click="delete_barcode(dy_ind, drug_key, 'none', 'drug', mix_ind, brand_ind)"><i class="fa fa-minus fa-1x"></i></b-button>
                      {{ brand.label }} <small> ( {{ brand_ind }} )</small>
                      <template v-if="f_calculateBrandStock(brand_ind, mix.brands)">
                        <strong style="color: #0089ff;"> [ Stok:  {{ f_calculateBrandStock(brand_ind, mix.brands) }} ] </strong>
                      </template>
                      <template v-else>
                        <strong style="color: red;"> [ Stok: yok ] </strong>
                      </template>
                      <strong style="color: blue;"> [Adet: {{ brand.count }} ] </strong>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </template>
        <template v-if="drug.after && drug.after.length > 0">
          <brand-selection-premedication :treatment_order_data="treatment_order_data" :drug="drug" :dy_ind="dy_ind" :drug_key="drug_key" :prem_type="'after'" :treatment_data="treatment_data" :d_selectedProtocolNo="d_selectedProtocolNo"></brand-selection-premedication>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';
import {
  default as BrandSelectionPremedication
} from './BrandSelectionPremedication';

export default {
  name: 'BrandSelection',
  computed: {
    ...mapGetters({
      patient: 'patient_selected'
    })
  },
  props: {
    treatment_order_data: {
      type: Object,
      required: false
    },
    hims_protocol_data: {
      type: Object,
      required: false
    },
    treatment_data: {
      type: Object,
      required: false
    },
    patient_data: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      SelectedProtocolInd: '',
      moment: moment,
      d_selectedProtocolNo: ''
    };
  },
  created () {},
  mounted () {
    // SAMI SAMI SAMI (treatmentcycledrugbuttons dosyasında otomatik ilaç doldurmayı aktifleştirmeyi unutma)
    if (this.patient_data.open_protocol_list && this.patient_data.open_protocol_list.result && this.patient_data.open_protocol_list.result.length > 0) {
      this.f_selectThisProtocol(0);
    }
  },
  methods: {
    f_calculateBrandStock: function (barcode_no, brand_list) {
      for (let b in brand_list) {
        if (brand_list[b].value === barcode_no) {
          if (brand_list[b].stock && brand_list[b].stock.count !== undefined) {
            return brand_list[b].stock.count;
          }
        }
      }
      return false;
    },
    f_calculateBrands: function (brands) {
      if (this.d_selectedProtocolNo) {}
    },
    f_selectThisProtocol: function (open_pro_ind) {
      this.SelectedProtocolInd === open_pro_ind ? this.SelectedProtocolInd = '' : this.SelectedProtocolInd = open_pro_ind;
      if (this.SelectedProtocolInd !== '') {
        this.hims_protocol_data.selected_protocol = this.patient_data.open_protocol_list.result[open_pro_ind];
        this.d_selectedProtocolNo = this.patient_data.open_protocol_list.result[open_pro_ind].protocol_no;
        this.f_changePharmacyStatus(1);
      } else {
        this.hims_protocol_data.selected_protocol = {};
        this.d_selectedProtocolNo = '';
        this.f_changePharmacyStatus(0);
      }
    },
    f_changePharmacyStatus: function (status) {
      // status = 0, 1
      for (let day in this.treatment_order_data.days) {
        for (let drug_key in this.treatment_order_data.days[day]) {
          // console.log('drug_key', drug_key);
          // herhangi bir brand stoğu var ise o zaman check 1 olabilir.
          this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key], status);
          if (this.treatment_order_data.days[day][drug_key]['drug_mix']) {
            for (let mx in this.treatment_order_data.days[day][drug_key]['drug_mix']) {
              // console.log('mx', mx);
              this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key]['drug_mix'][mx], status);
            }
          }
          if (this.treatment_order_data.days[day][drug_key].fluid) {
            this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key].fluid, status);
          }
          let prem_types = ['before', 'after', 'during'];
          for (let p in prem_types) {
            for (let k in this.treatment_order_data.days[day][drug_key][prem_types[p]]) {
              // console.log('prem ind', k);
              this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key][prem_types[p]][k], status);
              if (this.treatment_order_data.days[day][drug_key][prem_types[p]][k].fluid) {
                this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key][prem_types[p]][k].fluid, status);
              }
              if (this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix']) {
                for (let mx in this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix']) {
                  // console.log('mix ', mx);
                  this.f_controlBrandStock(this.treatment_order_data.days[day][drug_key][prem_types[p]][k]['drug_mix'][mx], status);
                }
              }
            }
          }
        }
      }
    },
    f_controlBrandStock: function (data, status) {
      // status = 1 ise
      if (status && data.brands) {
        if (this.f_atLeastOneBrandMatch(data.brands)) {
          data['pharmacy_status'] = 1;
        } else {
          data['pharmacy_status'] = 0;
        }
      } else {
        data['pharmacy_status'] = 0;
      }
    },
    f_atLeastOneBrandInStock: function (brand_list) {
      for (let b in brand_list) {
        if (brand_list[b].stock && brand_list[b].stock.count) {
          return true;
        }
      }
      return false;
    },
    f_atLeastOneBrandMatch: function (brand_list) {
      for (let b in brand_list) {
        if (brand_list[b].stock !== undefined) {
          return true;
        }
      }
      return false;
    },
    f_calculateStyleProtocolType (type_id) {
      if (type_id === 0) {
        return 'color: red; font-weight: bold;';
      } else if (type_id === 1) {
        return 'color: green; font-weight: bold;';
      } else if (type_id === 2) {
        return 'color: #b3246c; font-weight: bold;';
      } else if (type_id === 3) {
        return 'color: red; font-weight: bold;';
      } else if (type_id === 4) {
        return 'color: blue; font-weight: bold;';
      } else if (type_id === 5) {
        return 'color: black; font-weight: bold;';
      }
    },
    CalculateProtocolType: function (type_id) {
      if (type_id === 0) {
        return 'Diğer';
      } else if (type_id === 1) {
        return 'Poliklinik';
      } else if (type_id === 2) {
        return 'Yatan Hasta';
      } else if (type_id === 3) {
        return 'Acil';
      } else if (type_id === 4) {
        return 'Günübirlik';
      } else if (type_id === 5) {
        return 'TempInpatient';
      }
    },
    add_barcode: function (dy_ind, drug_key, prem_ind, type, mix_ind, fluid = false) {
      let id_no = '';
      if (fluid) {
        id_no = 'fluid' + dy_ind.toString() + drug_key + prem_ind.toString() + type + mix_ind;
      } else {
        id_no = dy_ind.toString() + drug_key + prem_ind.toString() + type + mix_ind;
      }
      let count = document.getElementById(id_no).value;
      let data = {};
      if (type === 'drug') {
        if (mix_ind === 'none') {
          if (fluid) {
            data = this.treatment_order_data.days[dy_ind][drug_key].fluid;
          } else {
            data = this.treatment_order_data.days[dy_ind][drug_key];
          }
        } else {
          data = this.treatment_order_data.days[dy_ind][drug_key].drug_mix[mix_ind];
        }
      } else { // before after during
        if (mix_ind === 'none') {
          if (fluid) {
            data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind].fluid;
          } else {
            data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind];
          }
        } else {
          data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind].drug_mix[mix_ind];
        }
      }
      if (!data.selected_brand) {
        alert('Lütfen öncelikle ürün seçiminizi yapınız.');
        return;
      }
      let barcode_no = data.selected_brand.value;
      if (this.d_selectedProtocolNo) {
        if (data.selected_brand.stock !== undefined) {
          data.barcode_list.push(barcode_no);
          data.brand_count[barcode_no] = {};
          data.brand_count[barcode_no].count = count;
          data.brand_count[barcode_no].label = data.selected_brand.label;
        } else {
          alert('Bu ürün wisdomera ile hbys arasında eşleştirme yapılmamıştır. Eşleştirme işlemi devam etmektedir. Tamamladığında eczaneye gönderebilirsiniz.');
        }
      } else {
        data.barcode_list.push(barcode_no);
        data.brand_count[barcode_no] = {};
        data.brand_count[barcode_no].count = count;
        data.brand_count[barcode_no].label = data.selected_brand.label;
      }
      this.$forceUpdate();
    },
    delete_barcode: function (dy_ind, drug_key, prem_ind, type, mix_ind, brand_ind, fluid = false) {
      if (type === 'drug') {
        if (mix_ind === 'none') {
          if (fluid) {
            let data = this.treatment_order_data.days[dy_ind][drug_key].fluid;
            data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
            this.$delete(data.brand_count, brand_ind);
          } else {
            let data = this.treatment_order_data.days[dy_ind][drug_key];
            data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
            this.$delete(data.brand_count, brand_ind);
          }
        } else { // drug mix
          let data = this.treatment_order_data.days[dy_ind][drug_key].drug_mix[mix_ind];
          data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
          this.$delete(data.brand_count, brand_ind);
        }
      } else {
        if (mix_ind === 'none') {
          if (fluid) {
            let data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind].fluid;
            data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
            this.$delete(data.brand_count, brand_ind);
          } else {
            let data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind];
            data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
            this.$delete(data.brand_count, brand_ind);
          }
        } else {
          let data = this.treatment_order_data.days[dy_ind][drug_key][type][prem_ind].drug_mix[mix_ind];
          data.barcode_list.splice(data.barcode_list.indexOf(brand_ind), 1);
          this.$delete(data.brand_count, brand_ind);
        }
      }
      this.$forceUpdate();
    }
  },
  watch: {},
  components: {
    vSelect,
    BrandSelectionPremedication
  }
};

</script>

<style type="text/css">
td,
th {}

</style>

